import {
  COMPONENTS_MALFUNCTIONS_CREATE_ERROR,
  COMPONENTS_MALFUNCTIONS_CREATE_REQUEST,
  COMPONENTS_MALFUNCTIONS_CREATE_SUCCESS,
  COMPONENTS_MALFUNCTIONS_DELETE_ERROR,
  COMPONENTS_MALFUNCTIONS_DELETE_REQUEST,
  COMPONENTS_MALFUNCTIONS_DELETE_SUCCESS,
  COMPONENTS_MALFUNCTIONS_UPDATE_ERROR,
  COMPONENTS_MALFUNCTIONS_UPDATE_REQUEST,
  COMPONENTS_MALFUNCTIONS_UPDATE_SUCCESS,
} from "store/actions";

export default function componentsMalfunctionsApiReducer(state, action) {
  switch (action.type) {
    case COMPONENTS_MALFUNCTIONS_CREATE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case COMPONENTS_MALFUNCTIONS_CREATE_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case COMPONENTS_MALFUNCTIONS_CREATE_SUCCESS: {
      const { componentId } = action.payload;

      const components = state.components.map((item) => {
        if (item.componentId === componentId) {
          return {
            ...item,
            malfunctions: item.malfunctions.concat(action.payload),
          };
        }
        return item;
      });
      return {
        ...state,
        components,
        modal: {
          display: false,
        },
        loading: false,
        error: false,
      };
    }
    case COMPONENTS_MALFUNCTIONS_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case COMPONENTS_MALFUNCTIONS_DELETE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case COMPONENTS_MALFUNCTIONS_DELETE_SUCCESS: {
      const { componentId, malfunctionId } = action.payload;
      const components = state.components.map((component) => {
        if (component.componentId === componentId) {
          const malfunctions = component.malfunctions.filter(
            (malfunction) => malfunction.id !== malfunctionId,
          );
          return {
            ...component,
            malfunctions: [
              ...malfunctions,
            ],
          };
        }
        return component;
      });
      return {
        ...state,
        components,
        modal: {
          display: false,
        },
        loading: false,
        error: false,
      };
    }
    case COMPONENTS_MALFUNCTIONS_UPDATE_ERROR: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case COMPONENTS_MALFUNCTIONS_UPDATE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case COMPONENTS_MALFUNCTIONS_UPDATE_SUCCESS: {
      return {
        ...state,
        modal: {
          display: false,
        },
        loading: false,
        error: false,
      };
    }
    default:
      return state;
  }
}

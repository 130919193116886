import React, { Component } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import PropTypes from "prop-types";
import i18n from "i18n";
import css from "./CustomerCard.module.scss";

class CustomerCard extends Component {
  render() {
    const {
      onClick, title, assessmentsCountGrouped, isOPL,
    } = this.props;
    const { customerId, assets } = assessmentsCountGrouped;
    return (
      <div className={css.container}>
        <div className={css.inner}>
          <div className={css.heading}>
            <div className={css.title}>{title}</div>
          </div>
          <div className={css.structureAssessments}>
            <Grid fluid>
              <Row key={customerId} className={css.gridHeader}>
                <Col sm={5}>{i18n.t("global.assets")}</Col>
                <Col sm={3}>{i18n.t("global.machines")}</Col>
                <Col sm={4}>{i18n.t("global.assessments")}</Col>
              </Row>
              {
                assets && (
                  assets.map((asset) => (
                    asset.machines.map((machine, index) => (
                      <Row key={"".concat(asset.assetId).concat("_").concat(machine.machineId)} className={css.gridRow}>
                        <Col sm={5}>
                          <span
                            className={css.assessmentsLink}
                            onClick={() => onClick(customerId, asset.assetId)}
                          >
                            {index === 0 ? asset.assetName : ""}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <span
                            className={css.assessmentsLink}
                            onClick={() => onClick(customerId, asset.assetId, machine.machineId)}
                          >
                            {machine.machineName}
                          </span>
                        </Col>
                        <Col sm={4}>
                          {isOPL ? machine.assessmentsCount : machine.assessmentsPublishedCount}
                        </Col>
                      </Row>
                    ))
                  ))
                )
              }
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

CustomerCard.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  assessmentsCountGrouped: PropTypes.object,
  isOPL: PropTypes.bool,
};

CustomerCard.defaultProps = {
  title: "",
  subTitle: "",
};
export default CustomerCard;

const { NODE_ENV, REACT_APP_ENVIRONMENT } = process.env;

export const appLayouts = {
  PRINT: "print",
  DEFAULT: "default",
};

export const userRoles = {
  HPP: "hydro.production.manager",
  OPL: "hydro.opl",
};

export const structureStatus = {
  OK: "ok",
  WARNING: "warning",
  ALARM: "arlam",
  INVALID: "invalid",
};

export const cardStatus = {
  OPERATIONAL: "operational",
  ANOMALY_DETECTED: "anomaly_detected",
  RISK_OF_DAMAGE: "risk_of_damage",
};

export const recommendations = {
  NORMAL: "normal",
  OPTIMISATION: "optimisation",
  OTHER: "other",
  OVERHAUL_PLANING: "overhaul_planning",
  SPARE_PARTS_OPITIMISATION: "spare_optimisation",
  TREND_ANALYSIS: "trend_analysis",
};

export const assetTypes = {
  ASSET: "asset",
  COMPONENT: "sensorgroup",
  SUB_COMPONENT: "sensor",
};

export const messageTypes = {
  MESSAGE: "message",
  COMMENT: "comment",
  PUBLISH: "publish",
  SIGNUP: "signup",
};

export const assessmentReleaseStatus = {
  DRAFT: "draft",
  LATEST: "latest",
};

export const cardTypes = {
  MALFUNCTION: "malfunction",
  ACOUSTIC: "acoustic",
  VIBRATION: "vibration",
  ANOMALY: "anomaly",
  ADDITIONAL_INFORMATION: "Additional Information",
};

export const turbineTypes = {
  FRANCIS: "francis",
  FRANCIS_MIV: "francis-miv",
  FRANCIS_HORIZONTAL: "francis-horizontal",
  KAPLAN_HORIZONTAL: "kaplan-horizontal",
  KAPLAN_VERTICAL: "kaplan-vertical",
  PELTON_MIV: "pelton-miv",
  PELTON_HORIZONTAL: "pelton-horizontal",
  SYNCHRONOUS_CONDENSER: "synchronous-condenser",
};

export const malfunctionTypes = {
  HSI: "hsi",
  AC: "ac",
};

export const fileUploadTypes = {
  ARGUMENTATION: "argumentation",
  GLOSSARY: "glossary",
  MESSAGE: "message",
  COMMENT: "comment",
  SUMMARY: "summary",
};

/* eslint no-nested-ternary: "off" */

export const APP_URL = REACT_APP_ENVIRONMENT === "staging"
  ? "https://opl.staging.voith.io"
  : REACT_APP_ENVIRONMENT === "prod"
    ? "https://opl.industrial.voith.io"
    : NODE_ENV === "development"
      ? "http://localhost:3000"
      : "http://localhost:3000";

export const AUTH_REDIRECT_URL = REACT_APP_ENVIRONMENT === "staging"
  ? "https://auth.staging.voith.io"
  : REACT_APP_ENVIRONMENT === "prod"
    ? "https://auth.industrial.voith.io"
    : NODE_ENV === "development"
      ? "http://localhost:3000"
      : "http://localhost:3000";

export const APP_ERROR_REDIRECT = "https://ui.industrial.voith.io/#/error";

export const MAX_UPLOAD_SIZE = 5.722 * 1024 * 1024;

import {
  APP_INIT_REQUEST,
  APP_INIT_COMPLETE,
  APP_INIT_ERROR,
  APP_GLOBAL_ERROR,
  APP_FORBIDDEN_ACCESS,
  APP_LAYOUT_CHANGE,
  APP_SET_PATHNAME,
} from "store/actions";
import { appLayouts } from "utils/constants";

const initialState = {
  init: false,
  loading: false,
  error: false,
  forbidden: false,
  layout: appLayouts.DEFAULT,
  pathname: "/",
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case APP_INIT_REQUEST:
      return {
        ...state,
        init: false,
      };
    case APP_INIT_COMPLETE:
      return {
        ...state,
        init: true,
      };
    case APP_INIT_ERROR:
      return {
        ...state,
        init: false,
      };
    case APP_GLOBAL_ERROR:
      return {
        ...state,
        init: false,
        error: true,
      };
    case APP_FORBIDDEN_ACCESS:
      return {
        ...state,
        error: true,
        forbidden: true,
      };
    case APP_LAYOUT_CHANGE: {
      const { layout } = action.payload;
      return {
        ...state,
        layout,
      };
    }
    case APP_SET_PATHNAME: {
      const { pathname } = action.payload;
      return {
        ...state,
        pathname,
      };
    }
    default:
      return state;
  }
};

export default app;

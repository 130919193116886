import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import css from "./Button.module.scss";

class Button extends PureComponent {
  render() {
    const {
      className,
      type,
      small,
      disabled,
      onClick,
      color,
      children,
      error,
    } = this.props;
    let classes = className;
    if (type === "primary") {
      classes = [classes, css.typePrimary].join(" ");
    }
    if (type === "secondary") {
      classes = [classes, css.typeSecondary].join(" ");
    }
    if (type === "outlined") {
      classes = [classes, css.typeOutlined].join(" ");
    }
    if (color === "warning" && !disabled) {
      classes = [classes, css.colorWarning].join(" ");
    }
    if (color === "gray" && !disabled) {
      classes = [classes, css.colorGray].join(" ");
    }
    if (color === "error" && !disabled) {
      classes = [classes, css.colorError].join(" ");
    }
    if (small) {
      classes = [classes, css.sizeSmall].join(" ");
    }
    if (disabled) {
      classes = [classes, css.typeDisabled].join(" ");
    }
    return (
      <>
        <button className={classes} onClick={onClick} disabled={disabled}>
          {children}
          {error && <div className={css.errorText}>{error}</div>}
        </button>
      </>
    );
  }
}

Button.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  action: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Button.defaultProps = {
  type: "primary",
};

export default Button;

export * from "./api/customers";
export * from "./upload/glossary";
export const CUSTOMER_SELECT = "CUSTOMER_SELECT";
export const CUSTOMER_CLEAR = "CUSTOMER_CLEAR";
export const CUSTOMER_EDIT = "CUSTOMER_EDIT";
export const CUSTOMER_SET_DEFAULTS = "CUSTOMER_SET_DEFAULTS";

export function selectCustomer(customerId) {
  return (dispatch, getState) => {
    const { customers } = getState();
    const customer = customers.customers.find(
      (customer) => customer.id === customerId,
    );
    if (customer) {
      dispatch({
        type: CUSTOMER_SELECT,
        payload: {
          customer,
        },
      });
    }
    return customer;
  };
}

export function clearCustomer() {
  return (dispatch) => {
    dispatch({
      type: CUSTOMER_CLEAR,
    });
  };
}

export function editCustomer(customer) {
  return (dispatch) => {
    dispatch({
      type: CUSTOMER_EDIT,
      payload: {
        customer,
      },
    });
  };
}

export function setCustomerDefaultSettings(settings) {
  return (dispatch) => {
    dispatch({
      type: CUSTOMER_SET_DEFAULTS,
      payload: {
        settings,
      },
    });
  };
}

import reduceReducers from "reduce-reducers";
import { commentsReducer } from "./comments";
import commentsApiReducer from "./api/comments";
import { uploadCommentsReducer } from "./upload/comments";

const initialState = {
  comments: [],
  loading: false,
  error: false,
  draft: null,
};

export default reduceReducers(
  initialState,
  commentsReducer,
  commentsApiReducer,
  uploadCommentsReducer,
);

import reduceReducers from "reduce-reducers";
import { turbineTypes } from "utils/constants";
import componentsApiReducer from "./api/components";
import componentsMalfunctionsApiReducer from "./api/malfunctions";
import componentsPlacementApiReducer from "./api/placements";
import modalReducer from "./modal";
import components from "./components";
import searchReducer from "./search";

const initialState = {
  components: [],
  loading: false,
  error: false,
  modal: {},
  language: "en",
  turbineType: turbineTypes.FRANCIS,
  page: "",
  search: {
    display: false,
    hasResult: false,
    params: "",
    results: [],
  },
};

export default reduceReducers(
  initialState,
  componentsApiReducer,
  modalReducer,
  components,
  componentsMalfunctionsApiReducer,
  componentsPlacementApiReducer,
  searchReducer,
);

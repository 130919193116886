/**
 * @fileoverview Screen component for health assessment edit view
 * @author  Florian Berg
 */

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Actions from "store/actions";
import Headline from "components/elements/Headline";
import Button from "components/elements/Button";
import Section from "components/layout/Section";
import MarkdownEdit from "components/input/MarkdownEdit";
import Field from "components/input/Field";
import i18n from "i18n";
import NavigationBar from "components/layout/NavigationBar";
import css from "./GlossaryEdit.module.scss"; // Import css modules stylesheet as styles

class GlossaryEdit extends Component {
  componentWillUnmount() {
    const { clearHealthAssessmentDraft } = this.props;
    clearHealthAssessmentDraft();
  }

  handleSubmit = async () => {
    const {
      showModal,
      updateCustomer, customer,
    } = this.props;
    showModal({
      type: "action",
      message: i18n.t("modals.saveWarning"),
      actionText: i18n.t("global.save"),
      primaryAction: async () => {
        const success = await updateCustomer(customer);
        if (success) {
          window.history.back();
        }
      },
    });
  };

  handleCancel = async () => {
    const { showModal, closeModal } = this.props;
    showModal({
      type: "action",
      style: "warning",
      message: i18n.t("modals.discardWarning"),
      actionText: i18n.t("global.discard"),
      primaryAction: async () => {
        closeModal();
        window.history.back();
      },
    });
  };

  handleChange = async (update) => {
    const { id, value } = update;
    const { customer, editCustomer } = this.props;
    switch (id) {
      case "glossary":
        editCustomer({
          ...customer,
          glossary: value,
        });
        break;
      default:
        console.log("unknown update", update.value);
    }
  };

  _validate = () => {};

  render() {
    const { customer, getGlossaryUploadLink } = this.props;
    if (!customer) {
      return null;
    }
    const {
      glossary,
    } = customer;
    return (
      <div className={css.screen}>
        <NavigationBar
          backAction={this.handleCancel}
          title={`${i18n.t("global.back").toUpperCase()}`}
          small
        />
        <Section borderTop noMargin padding>
          <Headline>{i18n.t("views.editGlossary.title")}</Headline>
        </Section>
        <Section borderTop noMargin padding>
          <Field
            id="glossary"
            onChange={this.handleChange}
          >
            {(id, onChange) => (
              <MarkdownEdit
                id={id}
                placeholder={i18n.t("global.textFiledPlaceHolderDefault")}
                text={glossary}
                update={onChange}
                onRequestUploadLink={getGlossaryUploadLink}
              />
            )}
          </Field>
        </Section>
        <Section noMargin padding>
          <Button type="outlined" onClick={this.handleCancel}>
            {i18n.t("global.cancel")}
          </Button>
          <Button type="primary" onClick={this.handleSubmit}>
            {i18n.t("global.save")}
          </Button>
        </Section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customer: state.customers.current,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(Actions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GlossaryEdit);

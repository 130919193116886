import {
  MALFUNCTIONS_GET_REQUEST,
  MALFUNCTIONS_GET_SUCCESS,
  MALFUNCTIONS_GET_ERROR,
  MACHINE_MALFUNCTIONS_GET_REQUEST,
  MACHINE_MALFUNCTIONS_GET_SUCCESS,
  MACHINE_MALFUNCTIONS_GET_ERROR,
} from "store/actions";

export function malfunctionsApiReducer(state, action) {
  switch (action.type) {
    case MALFUNCTIONS_GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MALFUNCTIONS_GET_SUCCESS: {
      const {
        turbineType, malfunctions, componentsMalfunctions,
      } = action.payload;
      return {
        ...state,
        loading: false,
        turbines: {
          ...state.turbines,
          [turbineType]: {
            malfunctions,
            componentsMalfunctions,
          },
        },
      };
    }
    case MALFUNCTIONS_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case MACHINE_MALFUNCTIONS_GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MACHINE_MALFUNCTIONS_GET_SUCCESS: {
      const {
        structureId, machineMalfunctions,
      } = action.payload;
      return {
        ...state,
        loading: false,
        machines: {
          ...state.machines,
          [structureId]: machineMalfunctions.malfunctions,
        },
      };
    }
    case MACHINE_MALFUNCTIONS_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}

export default malfunctionsApiReducer;

import React from "react";
import { connect } from "react-redux";
import * as Actions from "store/actions";
import { bindActionCreators } from "redux";
import css from "./MalfunctionCard.module.scss";
import i18n from "i18n";

function handleEditModal(malfunction, showComponentsModal) {
  const data = {
    display: true,
    type: "EDIT_MALFUNCTION",
    data: malfunction,
  };

  showComponentsModal(data);
}

function handleDeleteModal(malfunction, showComponentsModal) {
  const data = {
    display: true,
    type: "DELETE_MALFUNCTION",
    data: malfunction,
  };

  showComponentsModal(data);
}

function MalfunctionCard({ malfunction, showComponentsModal }) {
  const { name, nameTranslated, type } = malfunction;
  return (
    <section className={css.card}>
      <div className={css.header}>
        <h4>{ i18n.t("components.malfunctions.malfunction_name") }</h4>
        <span>{ nameTranslated || name }</span>
      </div>
      <div className={css.details}>
        <h4>{ i18n.t("components.type") }</h4>
        <span>{ type.toUpperCase() }</span>
        <div className={css.actions}>
          <button onClick={() => handleEditModal(malfunction, showComponentsModal)}>
            <span className="icon-edit" />
          </button>
          <button onClick={() => handleDeleteModal(malfunction, showComponentsModal)}>
            <span className="icon-delete_01" />
          </button>
        </div>
      </div>
    </section>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(Actions, dispatch),
  };
}

export default connect(
  undefined,
  mapDispatchToProps,
)(MalfunctionCard);

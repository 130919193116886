/**
 * @fileoverview Generic Edit message component
 * @author  Florian Berg
 */

import React, { Component } from "react";
import Section from "components/layout/Section";
import TitleBar from "components/layout/TitleBar";
import i18n from "i18n";
import MultilineInput from "components/input/MultilineInput";
import Button from "components/elements/Button";
import MultiFileUpload from "components/blocks/MultiFileUpload";
import css from "./MessageEdit.module.scss";

class MessageEdit extends Component {
  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  updateReducer = (state, action) => {
    const { id, value } = action;
    const { updateIndex } = action;
    const { files = [] } = state;
    switch (id) {
      case "text":
        return { ...state, body: value };
      case "file":
        if (value) {
          // update existing file
          if (updateIndex !== undefined) {
            return {
              ...state,
              files: files.map((file, index) => {
                if (index === updateIndex) {
                  return value;
                }
                return file;
              }),
            };
          }
          // add new file
          return { ...state, files: [...files, value] };
        }
        // delete existing file
        if (updateIndex !== undefined) {
          return {
            ...state,
            files: files.filter((file, index) => index !== updateIndex),
          };
        }
        break;
      default:
        return state;
    }
    return state;
  }

  handleUpdate(action) {
    const { draft, onUpdate } = this.props;
    onUpdate(this.updateReducer(draft, action));
  }

  render() {
    const {
      draft, title, onClose, onSend, onRequestUploadLink,
    } = this.props;
    const { body, files } = draft;
    return (
      <div className={css.container}>
        <div className={css.inner} onClick={(e) => e.stopPropagation()}>
          <Section className={css.header} noMargin>
            <TitleBar
              title={title}
              action={onClose}
              actionIcon="close"
              huge
              noBorder
            />
          </Section>
          <Section className={css.TextField}>
            <MultilineInput
              id="text"
              text={body}
              update={this.handleUpdate}
              className={css.multiLineInput}
              placeholder={`${i18n.t(
                "views.messagePopUp.textFieldPlaceholder",
              )}`}
              small
            />
          </Section>
          <Section className={css.footer} noMargin>
            <MultiFileUpload
              onUpdate={this.handleUpdate}
              onRequestUploadLink={onRequestUploadLink}
              files={files}
            />
            <div className={css.footerRight}>
              <Button
                className={css.sendButton}
                disabled={!body}
                error={!body ? `${i18n.t("errors.errorNoTextForMessage")}` : ""}
                onClick={onSend}
              >
                {`${i18n.t("global.send")}`}
              </Button>
            </div>
          </Section>
        </div>
      </div>
    );
  }
}

export default MessageEdit;

import React, { Component } from "react";
import PropTypes from "prop-types";
import MultiFileUpload from "components/blocks/MultiFileUpload";
import Paragraph from "components/elements/Paragraph";
import Tabs from "components/elements/Tabs";
import css from "./MarkdownEdit.module.scss";

class MarkdownEdit extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  handleChange = (event) => {
    const { update, id } = this.props;
    if (update) {
      update({ id, value: event.target.value });
    }
  };

  handleFileUpload = ({ value }) => {
    const { url, mime, name } = value;
    const { update, id, text } = this.props;
    if (mime === "image/png" || mime === "image/jpeg") {
      update({ id, value: `${text || ""}\n ![${name}]( ${url} "")` });
    } else {
      update({ id, value: `${text || ""}\n [${name}]( ${url} "")` });
    }
  }

  render() {
    const {
      className,
      text,
      placeholder,
      id,
      small,
      onRequestUploadLink,
    } = this.props;

    return (
      <div
        className={[className, css.multilineInput, small ? css.small : css.normal].join(" ")}
      >
        <div className={css.input}>
          <Tabs>
            <div label="Edit">
              <textarea
                ref={this.input}
                id={id}
                onChange={this.handleChange}
                placeholder={placeholder}
                className={[css.textArea, small ? css.small : css.normal].join(" ")}
                value={text || undefined}
              />
              {onRequestUploadLink && (
                <MultiFileUpload
                  className={css.fileUpload}
                  onUpdate={this.handleFileUpload}
                  onRequestUploadLink={onRequestUploadLink}
                />
              )}
            </div>
            <div label="Preview">
              <div className={css.preview}>
                <Paragraph
                  markdown={text}
                />
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    );
  }
}

MarkdownEdit.propTypes = {
  editable: PropTypes.bool,
  text: PropTypes.string,
  placeholder: PropTypes.string,
  update: PropTypes.func,
};

MarkdownEdit.defaultProps = {
  editable: true,
  placeholder: "Enter Text",
};
export default MarkdownEdit;

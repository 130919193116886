import { format } from "date-fns-tz";
import i18n from "i18n";

export function formatDate(input, formatString = "dd/MM/yyyy, HH:mm z") {
  try {
    if (input) {
      const date = new Date(input);
      return format(date, formatString);
    }
    return i18n.t("global.notAvailable");
  } catch (error) {
    console.log(error);
    return i18n.t("global.notAvailable");
  }
}

export default formatDate;

import { AUTH_USER_ERROR } from "store/actions/auth/";
import { api } from "utils/api";
import { closeModal, showMessage } from "store/actions/notifications/";
import i18n from "i18n";
import { getRouteParams } from "utils/url";
import { fileUploadTypes } from "utils/constants";

export const COMMENTS_UPLOAD_LINK_REQUEST = "COMMENTS_UPLOAD_LINK_REQUEST";
export const COMMENTS_UPLOAD_LINK_SUCCESS = "COMMENTS_UPLOAD_LINK_SUCCESS";
export const COMMENTS_UPLOAD_LINK_ERROR = "COMMENTS_UPLOAD_LINK_ERROR";

export function getCommentUploadLink(file) {
  return async (dispatch) => {
    try {
      dispatch({
        type: COMMENTS_UPLOAD_LINK_REQUEST,
      });
      const { customerId, assessmentId } = getRouteParams();
      const result = await api.post(
        `/customers/${customerId}/files/presigned-url`,
        {
          mime: file.mime,
          filename: file.filename,
          size: file.size,
          type: fileUploadTypes.COMMENT,
          assessmentId: Number.parseInt(assessmentId, 10),
        },
        {
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
        },
      );
      const { status } = result;
      if (status === 200) {
        const { data } = result;
        dispatch({
          type: COMMENTS_UPLOAD_LINK_SUCCESS,
        });
        return data;
      }
      throw new Error(result.statusText);
    } catch (error) {
      const { response } = error;
      if (response) {
        const { data } = response;
        console.error(data);
        if (data.status === 401) {
          dispatch({
            type: AUTH_USER_ERROR,
          });
        } else {
          dispatch({
            type: COMMENTS_UPLOAD_LINK_ERROR,
          });
          dispatch(
            showMessage({
              title: i18n.t("global.error"),
              text: i18n.t("errors.errorGetUploadlink"),
              type: "error",
            }),
          );
          dispatch(closeModal());
        }
      }
    }
    return "";
  };
}

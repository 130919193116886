import React, { Component } from "react";
import PropTypes from "prop-types";
import Section from "components/layout/Section";
import Argumentation from "components/blocks/Argumentation";
import css from "./Argumentations.module.scss";

class Argumentations extends Component {
  render() {
    const { argumentations } = this.props;

    if (!argumentations) {
      return null;
    }
    const filteredArgumentations = argumentations.filter(
      (argumentation) => !argumentation.isHidden,
    );

    if (filteredArgumentations.length < 1) {
      return null;
    }

    return (
      <>
        <Section className={css.argumentations}>
          {filteredArgumentations.map((argumentation, index) => (
            <Argumentation
              index={index + 1}
              key={argumentation.uuid}
              {...argumentation}
            />
          ))}
        </Section>
      </>
    );
  }
}

Argumentations.propTypes = {
  argumentations: PropTypes.array,
};

export default Argumentations;

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { languages } from "utils/components";
import * as Actions from "store/actions";
import Button from "components/elements/Button";
import Spinner from "components/elements/Spinner";
import Translation from "components/elements/Translation";
import i18n from "i18n";
import css from "./ModalAddComponent.module.scss";

class ModalAddComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      componentName: "",
      componentSort: 0,
      loading: false,
      translations: [],
      count: 0,
      displayTranslations: false,
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAddTranslation = this.handleAddTranslation.bind(this);
    this.handleDeleteTranslation = this.handleDeleteTranslation.bind(this);
    this.handleSelectTranslation = this.handleSelectTranslation.bind(this);
  }

  handleChange(prop, value) {
    this.setState((state) => ({
      ...state,
      [prop]: value,
    }));
  }

  async primaryAction() {
    const { componentName, componentSort, translations } = this.state;
    const { createComponent, getComponents } = this.props;
    this.setState({ loading: true });
    await createComponent(componentName, Number.parseInt(componentSort, 10), translations);
    await getComponents(); // update the components state so that changes already take effect
  }

  handleAddTranslation() {
    const { count } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      displayTranslations: true,
      count: count + 1,
      translations: [
        ...prevState.translations,
        {
          id: null,
          name: "",
          text: "",
          languageCode: "",
          count,
        },
      ],
    }));
  }

  handleSelectTranslation(e, translation) {
    const { translations } = this.state;
    // *
    const filtred = translations.filter((item) => item.count !== translation.count);
    this.setState((prevState) => ({
      ...prevState,
      translations: [
        ...filtred,
        {
          id: null,
          text: translation.text,
          languageCode: e.value.item.languageCode,
          name: translation.name,
          count: translation.count,
        },
      ],
    }));
    // */
  }

  handleInput(e, translation) {
    const { translations } = this.state;
    const filtred = translations.filter((item) => item.count !== translation.count);
    // *
    this.setState((prevState) => ({
      ...prevState,
      translations: [
        ...filtred,
        {
          ...translation,
          name: e,
          text: e,
        },
      ],
    }));
    // */
  }

  handleDeleteTranslation(translation) {
    const { translations } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      translations: translations.filter((item) => item.id !== translation.id),
    }));
  }

  render() {
    const {
      handleInput,
      handleAddTranslation,
      handleDeleteTranslation,
      handleSelectTranslation,
      props: {
        closeComponentsModal,
      },
      state: {
        error,
        componentName,
        componentSort,
        loading,
        translations,
      },
    } = this;
    const languagesRemaining = [...languages
      .filter(({ id }) => translations.some((item) => item.id === id))].length;
    const disableAddTranslationButton = (languages.length - 1) <= languagesRemaining;
    return (
      <div className={[css.inner, css.action].join(" ")}>
        { loading ? <Spinner className={css.spinner}>{i18n.t("components.messages.creating_category")}</Spinner> : (
          <>
            <button onClick={closeComponentsModal} className={css.close}>
              <span className="icon-close" />
            </button>
            <div className={css.title}>
              {i18n.t("components.add_category_title")}
            </div>
            <div className={css.content}>
              <div>
                <h4>{i18n.t("components.category_name")}</h4>
                <div className={[css.inputContainer, error ? css.error : ""].join(" ")}>
                  <input
                    id={componentName}
                    type="text"
                    onChange={(e) => this.handleChange("componentName", e.target.value)}
                    placeholder={i18n.t("components.category_name")}
                    className={css.input}
                    value={componentName}
                  />
                  {error && (
                    <span className={css.errorText}>
                      {i18n.t("validation.textTooLong")}
                    </span>
                  )}
                </div>
              </div>
              <div className={css.divSort}>
                <h4>{i18n.t("components.sort")}</h4>
                <div className={css.inputContainer}>
                  <input
                    id={componentSort}
                    type="text"
                    onChange={(e) => this.handleChange("componentSort", e.target.value)}
                    placeholder={i18n.t("components.sort")}
                    className={css.input}
                    value={componentSort}
                  />
                </div>
              </div>
              <div>
                <div className={css.divTranslations}>
                  <h4>{i18n.t("components.translations")}</h4>
                  <button disabled={disableAddTranslationButton} onClick={handleAddTranslation}>
                    <span className="icon-add" style={{ fontSize: "24px" }} />
                    <p>{i18n.t("components.add_translation")}</p>
                  </button>
                </div>
                <div className={[css.translations]}>
                  { translations.length >= 1 ? translations.map((translation) => (
                    <Translation
                      translation={translation}
                      translations={translations}
                      key={translation.count}
                      onChange={handleSelectTranslation}
                      handleInput={handleInput}
                      deleteTranslation={handleDeleteTranslation}
                    />
                  )) : (<p>{i18n.t("components.no_translations_founded")}</p>)}
                </div>
              </div>
            </div>
            <div className={css.buttons}>
              <Button className={css.btn} onClick={closeComponentsModal} type="outlined">
                {i18n.t("global.cancel")}
              </Button>
              <Button
                onClick={() => {
                  this.primaryAction();
                }}
                type="primary"
                className={css.btn}
              >
                {i18n.t("components.buttons.labels.save")}
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    defaultLanguage: state.components.language,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(Actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalAddComponent);

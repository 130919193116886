/**
 * @fileoverview Screen component for health assessment edit view
 * @author  Florian Berg
 */

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Actions from "store/actions";
import Headline from "components/elements/Headline";
import Button from "components/elements/Button";
import Section from "components/layout/Section";
import DropDown from "components/input/DropDown";
import TextField from "components/input/TextField";
import DateEntry from "components/input/DateEntry";
import MultilineInput from "components/input/MultilineInput";
import MarkdownEdit from "components/input/MarkdownEdit";
import { recursiveFind } from "utils/helper";
import Field from "components/input/Field";
import NavigationBar from "components/layout/NavigationBar";
import i18n from "i18n";
import { navigate } from "@reach/router";
import timeout from "utils/asyncTimeOut";
import { healthAssessmentValidator } from "utils/validators";
import css from "./HealthAssessmentEdit.module.scss"; // Import css modules stylesheet as styles

class HealthAssessmentEdit extends Component {
  constructor(props) {
    super(props);
    this.selectedAsset = {};
    this.machines = [];
  }

  async componentDidMount() {
    const { assessmentId, assessments, editHealthAssessment } = this.props;
    try {
      const assessment = assessments.filter(
        (assessment) => assessment.id === Number.parseInt(assessmentId, 10),
      )[0];
      if (!assessment) {
        throw new Error("could not find assessment");
      }
      editHealthAssessment(assessment);
    } catch (error) {
      const { customerId } = this.props;
      console.error(error);
      await timeout(500);
      navigate(`/${customerId}/assessments/`);
    }
  }

  componentWillUnmount() {
    const { clearHealthAssessmentDraft } = this.props;
    clearHealthAssessmentDraft();
  }

  handleSubmit = async () => {
    const {
      assessment, showModal, saveHealthAssessment,
    } = this.props;
    const errors = healthAssessmentValidator(assessment);
    if (errors.length) {
      showModal({
        type: "action",
        style: "warning",
        message: (
          <div>
            <span>{`${i18n.t("global.missingData")}: `}</span>
            {errors.map((error, index, errors) => (
              <span key={error.name}>
                {`${error.name}${
                  errors.length - index === 1 ? "" : ", "
                }`}
              </span>
            ))}
          </div>
        ),
        actionText: i18n.t("global.save"),
        primaryAction: async () => {
          const {
            assessment,
            customerId,
            assetId,
            machineId,
          } = this.props;
          const success = await saveHealthAssessment(
            customerId,
            assessment,
          );
          if (success) {
            let path = `/${customerId}/assessments/${assessment.id}`;
            if (assetId && !machineId) {
              path = `/${customerId}/${assetId}/assessments/${assessment.id}`;
            } else if (assetId && machineId) {
              path = `/${customerId}/${assetId}/${machineId}/assessments/${assessment.id}`;
            }
            navigate(path);
          }
        },
      });
    } else {
      showModal({
        type: "action",
        style: "ok",
        message: i18n.t("modals.saveHealthAssessment"),
        actionText: i18n.t("global.save"),
        primaryAction: async () => {
          const {
            assessment,
            customerId,
            assetId,
            machineId,
          } = this.props;
          const success = await saveHealthAssessment(
            customerId,
            assessment,
          );
          if (success) {
            let path = `/${customerId}/assessments/${assessment.id}`;
            if (assetId && !machineId) {
              path = `/${customerId}/${assetId}/assessments/${assessment.id}`;
            } else if (assetId && machineId) {
              path = `/${customerId}/${assetId}/${machineId}/assessments/${assessment.id}`;
            }
            navigate(path);
          }
        },
      });
    }
  };

  handleCancel = async () => {
    const { showModal, closeModal } = this.props;
    showModal({
      type: "action",
      style: "warning",
      message: i18n.t("modals.cancelEditHealthAssessment"),
      actionText: i18n.t("global.discard"),
      primaryAction: async () => {
        closeModal();
        window.history.back();
      },
    });
  };

  handleDelete = async () => {
    const { showModal, deleteHealthAssessment } = this.props;
    showModal({
      type: "action",
      style: "error",
      message: i18n.t("modals.deleteHealthAssessment"),
      actionText: i18n.t("global.delete"),
      primaryAction: async () => {
        const {
          assessmentId, customerId, assetId, machineId,
        } = this.props;
        await deleteHealthAssessment(customerId, assessmentId);
        let path = `/${customerId}/assessments`;
        if (assetId && !machineId) {
          path = `/${customerId}/${assetId}/assessments`;
        } else if (assetId && machineId) {
          path = `/${customerId}/${assetId}/${machineId}/assessments`;
        }
        navigate(path);
      },
    });
  };

  handleChange = async (update) => {
    const { id, value } = update;
    const { item } = value;
    const {
      assessment, editHealthAssessment, structures,
    } = this.props;
    switch (id) {
      case "name":
        editHealthAssessment({
          ...assessment,
          title: value,
        });
        break;
      case "summary":
        editHealthAssessment({
          ...assessment,
          summary: value,
        });
        break;
      case "editorsNote":
        editHealthAssessment({
          ...assessment,
          editorsNote: value,
        });
        break;
      case "asset": {
        const asset = structures.find((s) => s.id === item.id);
        this.selectedAsset = item;
        this.machines = asset.children;
        editHealthAssessment({
          ...assessment,
          structureId: undefined,
        });
        break;
      }
      case "machine":
        editHealthAssessment({
          ...assessment,
          structureId: item.id,
        });
        break;
      case "startDate":
        editHealthAssessment({
          ...assessment,
          timeWindowStart: update.value.toISOString(),
        });
        break;
      case "endDate":
        editHealthAssessment({
          ...assessment,
          timeWindowEnd: update.value.toISOString(),
        });
        break;
      default:
        console.log("unknown update", update.value);
    }
  };

  _validate = () => {};

  render() {
    const {
      structures, assessment, getSummaryUploadLink,
    } = this.props;
    if (!assessment) {
      return null;
    }
    const {
      summary,
      editorsNote,
      timeWindowStart,
      timeWindowEnd,
      title,
      structureId,
    } = assessment;

    const selectedMachine = recursiveFind(structures, Number(structureId));
    if (selectedMachine) {
      this.selectedAsset = recursiveFind(structures, Number(selectedMachine.parentId));
      if (this.selectedAsset) {
        this.machines = this.selectedAsset.children;
      }
    }

    return (
      <div className={css.screen}>
        <NavigationBar
          backAction={this.handleCancel}
          title={`${i18n.t("global.back").toUpperCase()}`}
          small
        />
        <Section borderTop noMargin padding>
          <Headline>{i18n.t("views.editHealthAssessment.title")}</Headline>
        </Section>
        <Section borderTop noMargin padding>
          <Field
            id="asset"
            label={i18n.t("views.editHealthAssessment.assetLabel")}
            onChange={this.handleChange}
            required
          >
            {(id, onChange) => (
              <DropDown
                id={id}
                update={onChange}
                placeholder={i18n.t("global.dropDownPlaceholderDefault")}
                items={structures}
                selected={this.selectedAsset}
                minLevel={0}
                maxLevel={0}
              />
            )}
          </Field>
        </Section>
        <Section borderTop noMargin padding>
          <Field
            id="machine"
            label={i18n.t("views.editHealthAssessment.machineLabel")}
            onChange={this.handleChange}
            required
          >
            {(id, onChange) => (
              <DropDown
                id={id}
                update={onChange}
                placeholder={i18n.t("global.dropDownPlaceholderDefault")}
                items={this.machines}
                selected={selectedMachine}
                minLevel={0}
                maxLevel={0}
              />
            )}
          </Field>
          <div className={css.turbineTypeDiv}>
            <span className={css.turbineTypeLabel}>
              {i18n.t("views.editHealthAssessment.turbineTypeLabel")}
            </span>
            <span className={css.turbineType}>
              {selectedMachine ? selectedMachine.turbineType : ""}
            </span>
          </div>
        </Section>
        <Section borderTop noMargin padding>
          <Field
            id="name"
            label={i18n.t("views.editHealthAssessment.reportNameLabel")}
            onChange={this.handleChange}
          >
            {(id, onChange) => (
              <TextField
                id={id}
                update={onChange}
                text={title}
              />
            )}
          </Field>
        </Section>
        <Section borderTop noMargin padding row>
          <Field
            id="startDate"
            label={i18n.t("views.editHealthAssessment.startDateLabel")}
            onChange={this.handleChange}
          >
            {(id, onChange) => (
              <DateEntry
                id={id}
                update={onChange}
                date={timeWindowStart}
              />
            )}
          </Field>
          <Field
            id="endDate"
            label={i18n.t("views.editHealthAssessment.endDateLabel")}
            onChange={this.handleChange}
          >
            {(id, onChange) => (
              <DateEntry
                id={id}
                update={onChange}
                date={timeWindowEnd}
              />
            )}
          </Field>
        </Section>
        <Section borderTop noMargin padding>
          <Field
            id="summary"
            label={i18n.t("views.editHealthAssessment.summaryLabel")}
            onChange={this.handleChange}
          >
            {(id, onChange) => (
              <MarkdownEdit
                id={id}
                placeholder={i18n.t("global.textFiledPlaceHolderDefault")}
                text={summary}
                update={onChange}
                onRequestUploadLink={getSummaryUploadLink}
              />
            )}
          </Field>
        </Section>
        <Section borderTop noMargin padding>
          <Section>
            <Field
              id="editorsNote"
              label={i18n.t("views.editHealthAssessment.editorNoteLabel")}
              onChange={this.handleChange}
            >
              {(id, onChange) => (
                <MultilineInput
                  id={id}
                  placeholder={i18n.t("global.textFiledPlaceHolderDefault")}
                  text={editorsNote}
                  update={onChange}
                />
              )}
            </Field>
          </Section>
          <Section>
            <Button type="outlined" onClick={this.handleCancel}>
              {i18n.t("global.cancel")}
            </Button>
            <Button type="primary" onClick={this.handleSubmit}>
              {i18n.t("global.save")}
            </Button>
          </Section>
        </Section>
        <Section small title="Actions" borderTop padding>
          <Button color="error" small onClick={this.handleDelete}>
            {i18n.t("views.editHealthAssessment.buttons.delete")}
          </Button>
        </Section>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const { customers } = state;
  const { customerId } = props;
  const customer = customers.customers.find((c) => c.id === Number(customerId));
  return {
    structures: customer.structures,
    assessments: state.assessments.assessments[customerId],
    assessment: state.assessments.draft,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(Actions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HealthAssessmentEdit);

/**
 * @fileoverview Screen component for assessment overview view
 * @author  Florian Berg
 */

import React, { Component } from "react";
import { navigate } from "@reach/router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { showMessage, setAppLayout, closeAllNotifications } from "store/actions";

import Page from "components/layout/Page";
import StatusBar from "components/elements/StatusBar";
import GenericStatus from "components/elements/GenericStatus";
import i18n from "i18n";
import { formatDate } from "utils/date";
import {
  cardType,
  getRecommonendationTypeName, getCardStatusTypeName,
} from "utils/types";
import {
  getMalfunctionSVGPathByType,
  getTurbineGroupsMalfunctions,
} from "utils/malfunctions";
import { filterCardsByType } from "utils/cards";
import { appLayouts } from "utils/constants";
import HealthAssessmentSummary from "components/blocks/HealthAssessmentSummary";
import HotspotView from "components/blocks/HotspotView";
import Section from "components/layout/Section";
import { distinctArray } from "utils/helper";
import CardTypeList from "components/blocks/CardTypeList";
import CardContent from "components/blocks/CardContent";
import GeneralInformation from "components/blocks/GeneralInformation";
import AttachedCards from "components/elements/AttachedCards";
import { ReactComponent as Logo } from "assets/svgs/voith_logo.svg";
import NavigationBar from "components/layout/NavigationBar";
import { getPlacementsWithMalfunctionsName } from "utils/placements";
import css from "./HealthAssessmentPrintView.module.scss";

const Status = ({
  responsible, creator, published, activityAt, publishedAt,
}) => (
  <Section className={css.border}>
    <StatusBar
      className={css.statusBar}
      items={[
        <GenericStatus
          label={`
          ${responsible ? i18n.t("global.responsible") : ""} 
          ${responsible && creator ? " / " : ""} 
          ${creator ? i18n.t("global.creator") : ""}`}
          status={`
          ${
            responsible
              ? `${responsible.firstName} ${responsible.lastName}`
              : ""
          }
          ${responsible && creator ? " / " : ""} 
          ${creator ? `${creator.firstName} ${creator.lastName}` : ""}
        `}
        />,
        <GenericStatus
          label={`${
            published
              ? i18n.t("global.published")
              : i18n.t("global.lastActivity")
          }`}
          status={`${
            published
              ? formatDate(publishedAt)
              : formatDate(activityAt)
          }`}
        />,
      ]}
    />
  </Section>
);

class HealthAssessmentPrintView extends Component {
  constructor(props) {
    super(props);
    const { assessment } = props;
    if (!assessment) {
      props.showMessage({
        title: i18n.t("global.error"),
        text: i18n.t("errors.errorAssessmentNotFound"),
        type: "error",
      });
      const { customerId } = this.props;
      navigate(`/${customerId}/assessments/`);
    }
  }

  componentDidMount() {
    const { setAppLayout, closeAllNotifications } = this.props;
    setAppLayout(appLayouts.PRINT);

    if (window.matchMedia) {
      const mediaQueryList = window.matchMedia("print");
      mediaQueryList.addListener((mql) => {
        if (mql.matches) {
          console.log("beforePrint");
        } else {
          console.log("afterPrint");
        }
      });
    }

    this.setDocumentTitle();

    setTimeout(() => {
      closeAllNotifications();
      window.print();
    }, 800);
  }

  componentWillUnmount() {
    const { setAppLayout } = this.props;
    setAppLayout(appLayouts.DEFAULT);
  }

  setDocumentTitle() {
    const { assessment } = this.props;
    const { title } = assessment;
    const cardPath = assessment.cards[0].structurePath;
    const reportPath = cardPath.substring(0, cardPath.lastIndexOf(" - "));
    document.title = `${reportPath} - ${title}`;
  }

  render() {
    const {
      editable,
      assessment,
      cardsOverview,
      svgPath,
      glossary,
      turbine,
      turbineType,
      malfunctions,
      groupsMalfunctions,
    } = this.props;

    if (!assessment) {
      return null;
    }

    const {
      summary,
      timeWindowStart,
      timeWindowEnd,
      title,
      activityAt,
      publishedAt,
      asset,
      cards,
      published,
      responsible,
      creator,
    } = assessment;

    return (
      <div>
        <div className={css.screen}>
          <div className={css.logoVoithDiv}><Logo className={css.logoVoith} /></div>
          <NavigationBar
            backAction={() => {
              window.history.back();
            }}
            title={`${i18n.t("global.back").toUpperCase()}`}
            className={css.navigationBar}
            small
          />
          <Page>
            <HealthAssessmentSummary
              preline={asset ? asset.name : ""}
              title={title}
              showEditorNote={editable}
              summary={summary}
              timeWindowStart={timeWindowStart}
              timeWindowEnd={timeWindowEnd}
              cards={cards}
            />
          </Page>
          <Page className={css.svgTurbineSection}>
            <HotspotView
              malfunctions={malfunctions}
              machine={assessment.machine}
              svgPath={svgPath}
              cards={cards}
              onNavigateToCard={this.handleViewCard}
              onCreateCard={this.handleCreateCard}
              editable={false}
              groupsMalfunctions={groupsMalfunctions}
              turbineType={turbineType}
            />
          </Page>
          <Page
            fulllwitdth
          >
            {
            cardType.filter(({ type }) => {
              const distinctCardTypes = distinctArray(
                cards,
                "type",
              );
              return distinctCardTypes.includes(type);
            })
              .filter(({ hasHotspot }) => !hasHotspot)
              .map((type) => (
                <Section className={css.tagList} key={type.type} white borderTop>
                  <CardTypeList
                    typeFilter={type}
                    cards={cards}
                  />
                </Section>
              ))
            }
            <StatusBar
              className={css.attachedCards}
              items={[
                <AttachedCards
                  label=""
                  cards={cardsOverview}
                />,
                <GenericStatus
                  icon="calendar"
                  label={i18n.t("global.timeWindowOfData")}
                  status={`${formatDate(timeWindowStart, "dd/MM/yyyy")} - ${formatDate(timeWindowEnd, "dd/MM/yyyy")}`}
                />,
              ]}
            />
          </Page>
          {cards.map((card) => (
            <Page
              fulllwitdth
            >
              <CardContent
                className={css.noPadding}
                card={card}
                cardMalfunction={turbine.malfunctions.find((m) => m.id === card.malfunctionId)}
              />
              <Section
                className={css.border}
                white
                noMargin
                borderTop
              >
                <StatusBar
                  className={css.status}
                  items={[
                    <GenericStatus label={i18n.t("global.status")} status={i18n.t(getCardStatusTypeName(card.statusType))} />,
                    <GenericStatus label={i18n.t("views.editAssessment.recommendationTypeLabel")} status={i18n.t(getRecommonendationTypeName(card.recommendationType))} />,
                    <GenericStatus label={i18n.t("views.assessment.publishedInLabel")} status={assessment.title} />,
                  ]}
                />
              </Section>

            </Page>
          ))}
          <Page>
            <GeneralInformation
              title={`${i18n.t("global.generalInformation")}`}
              glossary={glossary}
              preline={asset ? asset.name : ""}
            />
            <Status
              creator={creator}
              responsible={responsible}
              published={published}
              activityAt={activityAt}
              publishedAt={publishedAt}
            />
          </Page>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const {
    auth,
    assessments,
    customers,
    malfunctions,
    components,
  } = state;
  const { customerId, assessmentId } = props;
  const currentCustomer = customers.customers.find(
    (customer) => customer.id === Number.parseInt(customerId, 10),
  );
  if (!currentCustomer) {
    return null;
  }
  const assessment = assessments.assessments[customerId].find(
    (assessment) => assessment.id === Number.parseInt(assessmentId, 10),
  );
  if (assessment) {
    if (assessment.publishedAt) {
      assessment.published = true;
    } else {
      assessment.status = "draft";
      assessment.published = false;
    }
    const assessmentHasHotspotCardTypes = filterCardsByType(
      assessment.cards,
      cardType
        .filter((card) => card.hasHotspot).map(({ type }) => type),
    ).length > 0;
    const { machine: { turbine: { turbineType } } } = assessment;
    const turbine = malfunctions.turbines[turbineType];
    const hidePlantGraphic = assessment.machine.turbine.hideGraphic
      || (!assessmentHasHotspotCardTypes && auth.isHPP);
    const groupsMalfunctions = getTurbineGroupsMalfunctions(
      turbine.componentsMalfunctions, assessment.machine.malfunctions, assessment.published,
      assessment.cards,
    );
    const cardsOverview = assessments.assessmentsoverview[Number(customerId)].find(
      (a) => a.id === Number(assessmentId),
    ).cards;
    const malfunctionsPlacements = getPlacementsWithMalfunctionsName(
      components.placement[turbineType],
      turbine.componentsMalfunctions,
    );
    return {
      assessment,
      glossary: currentCustomer.glossary,
      hidePlantGraphic,
      svgPath: getMalfunctionSVGPathByType(currentCustomer.type),
      editable: auth.isOPL && !assessment.published,
      cardsOverview,
      malfunctions: malfunctionsPlacements,
      groupsMalfunctions,
      turbine,
      turbineType,
    };
  }
  return null;
}
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ showMessage, setAppLayout, closeAllNotifications }, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HealthAssessmentPrintView);

import "./App.scss";

import React, { Component } from "react";
import { Provider } from "react-redux";
import { Router } from "@reach/router";
import ApplicationHeader from "components/layout/ApplicationHeader";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

import GlossaryEdit from "screens/Glossery/GlossaryEdit";
import AppHandler from "./components/AppHandler";
import Modal from "./screens/Overlays/Modal";
import Notifications from "./screens/Overlays/Notifications";
import Message from "./screens/Overlays/Message";
import Comment from "./screens/Overlays/Comment";
import ModalComponents from "./screens/Overlays/ModalComponents";
import CustomerSelect from "./screens/CustomerSelect/CustomerSelect";
import AssessmentOverview from "./screens/Assessment/AssessmentOverview";
import Assessment from "./screens/Assessment/Assessment";
import AssessmentEdit from "./screens/Assessment/AssessmentEdit";
import HealthAssessmentOverview from "./screens/HealthAssessment/HealthAssessmentOverview";
import HealthAssessmentEdit from "./screens/HealthAssessment/HealthAssessmentEdit";
import HealthAssessmentPrintView from "./screens/HealthAssessment/HealthAssessmentPrintView";
import ActivityStream from "./screens/Activities/ActivityStream";
import Settings from "./screens/Settings/Settings";
import Malfunctions from "./screens/Malfunctions/Malfunctions";
import Placement from "./screens/Malfunctions/Placement";
import store from "./store";

class App extends Component {
  render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <Provider store={store}>
          <AppHandler
            header={<ApplicationHeader title={process.env.REACT_APP_NAME} />}
            overlays={[
              <Message key="Message" />,
              <Comment key="Comment" />,
              <Modal key="Modal" />,
              <Notifications key="Notifications" />,
              <ModalComponents key="ModalComponents" />,
            ]}
          >
            <Router className="view" primary={false}>
              <CustomerSelect path="/" />
              <Settings path=":customerId/settings" />
              <GlossaryEdit path=":customerId/glossary/edit" />
              <ActivityStream path=":customerId/activities" />
              <HealthAssessmentOverview path=":customerId/assessments" />
              <HealthAssessmentOverview path=":customerId/:assetId/assessments" />
              <HealthAssessmentOverview path=":customerId/:assetId/:machineId/assessments" />
              <HealthAssessmentEdit path=":customerId/assessments/:assessmentId/edit" />
              <HealthAssessmentEdit path=":customerId/:assetId/assessments/:assessmentId/edit" />
              <HealthAssessmentEdit path=":customerId/:assetId/:machineId/assessments/:assessmentId/edit" />
              <HealthAssessmentPrintView path=":customerId/assessments/:assessmentId/print" />
              <AssessmentOverview path=":customerId/assessments/:assessmentId" />
              <AssessmentOverview path=":customerId/:assetId/assessments/:assessmentId" />
              <AssessmentOverview path=":customerId/:assetId/:machineId/assessments/:assessmentId" />
              <Assessment path=":customerId/assessments/:assessmentId/cards/:cardId" />
              <Assessment path=":customerId/:assetId/assessments/:assessmentId/cards/:cardId" />
              <Assessment path=":customerId/:assetId/:machineId/assessments/:assessmentId/cards/:cardId" />
              <AssessmentEdit path=":customerId/assessments/:assessmentId/cards/:cardId/edit" />
              <AssessmentEdit path=":customerId/:assetId/assessments/:assessmentId/cards/:cardId/edit" />
              <AssessmentEdit path=":customerId/:assetId/:machineId/assessments/:assessmentId/cards/:cardId/edit" />
              <Malfunctions path="malfunctions/edit" />
              <Placement path="malfunctions/placement" />
            </Router>
          </AppHandler>
        </Provider>
      </DndProvider>
    );
  }
}

export default App;

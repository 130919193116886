import React, { Component } from "react";
import i18n from "i18n";
import { connect } from "react-redux";
import * as Actions from "store/actions";
import { bindActionCreators } from "redux";
import css from "./Search.module.scss";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: "",
      timeout: 0,
    };
    this.handleOnKeyUp = this.handleOnKeyUp.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  async handleOnKeyUp(e) {
    const {
      state: {
        timeout,
      },
      handleSearch,
    } = this;

    if (timeout) {
      clearTimeout(timeout);
    }

    await this.setState({
      params: e.target.value,
    });
    handleSearch();
  }

  handleSearch() {
    const {
      state: {
        params,
      },
      props: {
        searchComponents,
      },
    } = this;
    searchComponents(params);
  }

  handleReset(e) {
    e.preventDefault();
    const { params } = this.state;
    const { clearSearch } = this.props;
    if (params.length >= 1) {
      this.setState({
        params: "",
        timeout: 0,
      });
      clearSearch();
    }
  }

  render() {
    const { handleOnKeyUp, handleReset, state: { params } } = this;
    return (
      <form className={css.form} onSubmit={handleReset}>
        <input
          type="text"
          placeholder={i18n.t("components.search_for_malfunction")}
          className={css.input}
          onChange={handleOnKeyUp}
          value={params}
        />
        <button>
          <span className={params.length >= 1 ? "icon-close" : "icon-search"} style={{ fontSize: "24px" }} />
        </button>
      </form>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(Actions, dispatch),
  };
}

export default connect(undefined, mapDispatchToProps)(Search);

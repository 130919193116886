import uid from "utils/uid";
import { createAssessmentCard, updateAssessmentCard } from "./api/cards";

export const ASSESSMENT_CARD_EDIT = "ASSESSMENT_CARD_EDIT";
export const ASSESSMENT_CARD_CLEAR_DRAFT = "ASSESSMENT_CARD_CLEAR_DRAFT";
export const ASSESSMENT_CARD_CREATE_DRAFT = "ASSESSMENT_CARD_CREATE_DRAFT";
export const ASSESSMENT_CARD_RESET_DRAFT = "ASSESSMENT_CARD_RESET_DRAFT";

export function createAssessmentCardDraft(assessmentId, type, options) {
  return async (dispatch) => {
    const draftId = uid();
    dispatch({
      type: ASSESSMENT_CARD_CREATE_DRAFT,
      payload: {
        card: {
          id: draftId,
          type,
          ...options,
          assessmentId: Number.parseInt(assessmentId, 10),
          createdAt: Date.now(),
          activityAt: Date.now(),
          updatedAt: Date.now(),
          argumentations: [],
          recommendations: [],
          risks: [],
          draft: true,
        },
      },
    });
    return draftId;
  };
}

export function saveAssessmentCard(customerId, assessmentId, card) {
  return async (dispatch) => {
    const { draft } = card;
    if (draft) {
      const success = await dispatch(createAssessmentCard(customerId, assessmentId, card));
      return success;
    }
    const success = await dispatch(updateAssessmentCard(customerId, assessmentId, card));
    return success;
  };
}

export function editAssessmentCard(card) {
  return (dispatch) => {
    dispatch({
      type: ASSESSMENT_CARD_EDIT,
      payload: {
        card,
      },
    });
  };
}

export function resetAssessmentCard() {
  return async (dispatch) => {
    dispatch({
      type: ASSESSMENT_CARD_RESET_DRAFT,
      payload: {
        defaults: {
          hsiType: null,
          acType: null,
          status: null,
          hsi: 0,
        },
      },
    });
  };
}

export function clearAssessmentCardDraft() {
  return (dispatch) => {
    dispatch({
      type: ASSESSMENT_CARD_CLEAR_DRAFT,
    });
  };
}

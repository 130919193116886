export * from "./api/messages";
export * from "./upload/messages";

export const MESSAGES_NEW_MESSAGE = "MESSAGES_NEW_MESSAGE";
export const MESSAGES_EDIT_MESSAGE = "MESSAGES_EDIT_MESSAGE";
export const MESSAGES_CLEAR_EDIT = "MESSAGES_CLEAR_EDIT";

export function createNewMessage(messageType) {
  return (dispatch) => {
    dispatch({
      type: MESSAGES_NEW_MESSAGE,
      payload: {
        type: messageType,
      },
    });
  };
}

export function editMessage(message, messageType) {
  return (dispatch) => {
    dispatch({
      type: MESSAGES_EDIT_MESSAGE,
      payload: { message, messageType },
    });
  };
}

export function clearEditMessage() {
  return (dispatch) => {
    dispatch({
      type: MESSAGES_CLEAR_EDIT,
    });
  };
}

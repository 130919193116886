import React, { Component } from "react";
import PropTypes from "prop-types";
import i18n from "i18n";
import Section from "components/layout/Section";
import Figure from "components/elements/Figure";
import css from "./Argumentation.module.scss";
import Paragraph from "../elements/Paragraph";

class Argumentation extends Component {
  render() {
    const {
      caption, details, title, file, index,
    } = this.props;
    return (
      <Section>
        {title && <h3 className={css.title}>{title}</h3>}
        {details && <Paragraph className={css.details} markdown={details} small />}
        {file && (
          <Figure
            url={file.url}
            caption={
              caption
                ? `${i18n.t("viewComponents.image.caption")} ${index}: ${caption}`
                : ""
            }
          />
        )}
      </Section>
    );
  }
}

Argumentation.propTypes = {
  caption: PropTypes.string,
  details: PropTypes.string,
  title: PropTypes.string,
  file: PropTypes.shape({
    argumentationUuid: PropTypes.string,
    createdAt: PropTypes.string,
    id: PropTypes.number,
    mime: PropTypes.string,
    name: PropTypes.string,
    responseId: PropTypes.number,
    size: PropTypes.number,
    updatedAt: PropTypes.string,
    url: PropTypes.string,
  }),
  index: PropTypes.number,
};

export default Argumentation;

import i18n from "i18n";
import malfunctionsSVGUrlFrancis from "assets/svgs/malfunctions_francis-turbine.svg";
import malfunctionsSVGUrlFrancisMiv from "assets/svgs/malfunctions_francis-turbine+miv.svg";
import malfunctionsSVGUrlFrancisHorizontal from "assets/svgs/malfunctions_francis-horizontal.svg";
import malfunctionsSVGUrlKaplanHorizontal from "assets/svgs/malfunctions_kaplan-horizontal.svg";
import malfunctionsSVGUrlKaplanVertical from "assets/svgs/malfunctions_kaplan-vertical.svg";
import malfunctionsSVGUrlPeltonMiv from "assets/svgs/malfunctions_pelton+miv.svg";
import malfunctionsSVGUrlPeltonHorizontal from "assets/svgs/malfunctions_pelton-horizontal.svg";
import malfunctionsSVGUrlSynchronousCondenser from "assets/svgs/malfunctions_synchronous-condenser.svg";
import { cardTypes, turbineTypes } from "utils/constants";

// this will replace all "." in malfunction id to "_" since we cannot use "." for
// i18next because we already use "." as a delimiter for nested objects.
export function sanitizeIdForTranslation(id) {
  if (id) {
    return id.replace(/\./g, "_");
  }
  return "";
}

export function getMalfunctionSVGPathByType(type) {
  switch (type) {
    case turbineTypes.FRANCIS:
      return malfunctionsSVGUrlFrancis;
    case turbineTypes.FRANCIS_MIV:
      return malfunctionsSVGUrlFrancisMiv;
    case turbineTypes.FRANCIS_HORIZONTAL:
      return malfunctionsSVGUrlFrancisHorizontal;
    case turbineTypes.KAPLAN_VERTICAL:
      return malfunctionsSVGUrlKaplanVertical;
    case turbineTypes.KAPLAN_HORIZONTAL:
      return malfunctionsSVGUrlKaplanHorizontal;
    case turbineTypes.PELTON_MIV:
      return malfunctionsSVGUrlPeltonMiv;
    case turbineTypes.PELTON_HORIZONTAL:
      return malfunctionsSVGUrlPeltonHorizontal;
    case turbineTypes.SYNCHRONOUS_CONDENSER:
      return malfunctionsSVGUrlSynchronousCondenser;
    default:
      return malfunctionsSVGUrlFrancis;
  }
}

export function getTurbineGroupsMalfunctions(turbineMalfunctions, machineMalfunctions,
  published, cards) {
  const groups = [];
  turbineMalfunctions.forEach((component) => {
    component.malfunctions.forEach((malfunction) => {
      // filter grouped malfunctions
      if (malfunction.groupId) {
        let found;
        if (published) {
          // only consider malfunctions that were used in the published report
          found = cards.find((card) => card.malfunctionId === malfunction.malfunctionId);
        } else {
          // only consider malfunctions that are enabled for the machine
          found = machineMalfunctions.find(
            (m) => m.malfunctionId === malfunction.malfunctionId,
          );
        }
        if (found) {
          const existingGroup = groups.find((item) => item.groupId === malfunction.groupId);
          if (existingGroup) {
            existingGroup.malfunctions.push(malfunction);
          } else {
            const group = {
              groupId: malfunction.groupId,
              malfunctions: [malfunction],
            };
            groups.push(group);
          }
        }
      }
    });
  });
  return groups;
}

export function extractMalfunctions(turbineMalfunctions) {
  const malfunctions = [];
  turbineMalfunctions.map((component) => {
    component.malfunctions.map((malfunction) => {
      const item = {
        id: malfunction.malfunctionId,
        name: malfunction.malfunctionName,
        type: malfunction.malfunctionType,
      };
      malfunctions.push(item);
      return malfunction;
    });
    return component;
  });
  return malfunctions;
}

export function getInputConfigurationByCardType(type) {
  const {
    MALFUNCTION, ACOUSTIC, VIBRATION, ANOMALY, ADDITIONAL_INFORMATION,
  } = cardTypes;
  const config = {
    hasHSI: false,
    hasMalfunctionHSI: false,
    hasMalfunctionAC: false,
    hasStatus: false,
    hasTag: false,
    hasLinkedComponent: false,
  };
  switch (type) {
    case MALFUNCTION: {
      config.hasHSI = true;
      config.hasMalfunctionHSI = true;
      return config;
    }
    case ACOUSTIC: {
      config.hasMalfunctionAC = true;
      config.hasStatus = true;
      return config;
    }
    case VIBRATION: {
      config.hasHSI = true;
      config.hasTag = true;
      config.hasLinkedComponent = true;
      return config;
    }
    case ANOMALY: {
      config.hasTag = true;
      config.hasStatus = true;
      config.hasLinkedComponent = true;
      return config;
    }
    case ADDITIONAL_INFORMATION: {
      config.hasTag = true;
      config.hasStatus = true;
      config.hasLinkedComponent = true;
      return config;
    }
    default: {
      return config;
    }
  }
}

export const malfunctionsPagesOptions = [
  {
    id: "edit",
    name: i18n.t("components.edit_malfunctions"),
    path: "malfunctions/edit",
  },
  {
    id: "placement",
    name: i18n.t("components.malfunctions_placement"),
    path: "malfunctions/placement",
  },
];

export function getLanguageCodeParam() {
  let queryParam = "";
  const { language } = i18n;
  if (language !== "en") {
    queryParam = `?languageCode=${language}`;
  }
  return queryParam;
}

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import css from "./TitleBar.module.scss";

class TitleBar extends PureComponent {
  render() {
    const {
      small,
      large,
      huge,
      title,
      subline,
      actionIcon,
      action,
      marginTop,
      noBorder,
    } = this.props;
    return (
      <div
        className={[
          huge
            ? css.containerHuge
            : css.container,
          large
            ? css.containerLarge
            : css.container,
          small
            ? css.containerSmall
            : css.container,
          marginTop ? css.marginTop : null,
          noBorder ? css.noBorder : null,
        ].join(" ")}
      >
        <div className={css.title}>{title}</div>
        {subline && <div className={css.subline}>{subline}</div>}
        {action && (
          <button
            onClick={action}
            className={small ? css.actionButtonSmall : css.actionButton}
          >
            <span className={`icon-${actionIcon}`} />
          </button>
        )}
      </div>
    );
  }
}

TitleBar.propTypes = {
  title: PropTypes.string,
  subline: PropTypes.string,
  action: PropTypes.func,
  small: PropTypes.bool,
  actionIcon: PropTypes.string,
  marginTop: PropTypes.bool,
};

TitleBar.defaultProps = {
  title: "(No Title)",
};
export default TitleBar;

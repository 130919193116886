import React, { Component } from "react";
import PropTypes from "prop-types";
import Paragraph from "components/elements/Paragraph";
import css from "./HealthAssessmentSummary.module.scss";

class HealthAssessmentSummary extends Component {
  render() {
    const {
      preline,
      title,
      summary,
      editorsNote,
      showEditorNote,
    } = this.props;

    return (
      <div className={css.container}>
        <div className={css.inner}>
          <h5 className={css.preline}>
            { preline }
          </h5>
          <h2 className={css.title}>
            { title }
          </h2>
          {editorsNote && showEditorNote && (
            <Paragraph
              title="Editor’s note"
              className={css.notes}
              hidden
              markdown={editorsNote}
            />
          )}
          {summary && (
            <Paragraph
              className={css.summary}
              markdown={summary}
            />
          )}
        </div>
      </div>
    );
  }
}

HealthAssessmentSummary.propTypes = {
  showEditorNote: PropTypes.bool,
  timeWindowStart: PropTypes.string,
  timeWindowEnd: PropTypes.string,
  editorNote: PropTypes.string,
  summary: PropTypes.string,
  subTitle: PropTypes.string,
  cards: PropTypes.array,
};

export default HealthAssessmentSummary;

import AttachedCards from "components/elements/AttachedCards";
import Tag from "components/elements/Tag";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { formatDate } from "utils/date";
import i18n from "i18n";
import { getReleaseStatusType, getReleaseStatusName } from "utils/types";
import CountStatus from "components/elements/CountStatus";
import GenericCard from "components/elements/GenericCard";
import css from "./HealthAssessmentCard.module.scss";

class HealthAssessmentCard extends Component {
  render() {
    const {
      onClick, title, status, timeStamp, subTitle, cards, commentCount,
    } = this.props;
    return (
      <GenericCard
        onClick={onClick}
        topLeft={(
          <div className={css.heading}>
            <div className={css.title}>{title}</div>
            <div className={css.subTitle}>{subTitle}</div>
          </div>
        )}
        topRight={<CountStatus className={css.commentCount} icon="documentation" status={commentCount} />}
        bottomLeft={<AttachedCards cards={cards} className={css.attachedCards} />}
        bottomRight={(
          <>
            <div className={css.batch}>
              {status && (
              <Tag
                type={getReleaseStatusType(status)}
                title={i18n.t(getReleaseStatusName(status))}
              />
              )}
            </div>
            <div className={css.timeStamp}>
              <div>{formatDate(timeStamp)}</div>
            </div>
          </>
          )}
      />
    );
  }
}

HealthAssessmentCard.propTypes = {
  onClick: PropTypes.func,
  status: PropTypes.string,
  timeStamp: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  cards: PropTypes.object,
};

HealthAssessmentCard.defaultProps = {
  status: "draft",
  timeStamp: new Date().toISOString(),
  title: "Title",
  subTitle: "SubTitle",
};
export default HealthAssessmentCard;

import React, { useRef, useState, useEffect } from "react";
import {
  INITIAL_VALUE,
  ReactSVGPanZoom,
  TOOL_NONE,
} from "react-svg-pan-zoom";
import MalfunctionGraphicItem from "components/blocks/MalfunctionGraphicItem";
import PropTypes from "prop-types";

const MalfunctionsSynchronousCondenser = React.forwardRef(({
  width,
  height,
  onDrag,
  onStop,
  malfunctions,
  handleMouseOver,
  disabled,
}, ref) => {
  const Viewer = useRef(null);
  const [tool, setTool] = useState(TOOL_NONE);
  const [value, setValue] = useState(INITIAL_VALUE);
  const [autoPan] = useState(false);
  const [panOutside] = useState(false);
  const [backgroundColor] = useState("#ffffff");

  useEffect(() => {
    Viewer.current.fitToViewer();
  }, []);

  return (
    <ReactSVGPanZoom
      background={backgroundColor}
      ref={Viewer}
      width={width}
      height={height}
      tool={tool}
      onChangeTool={setTool}
      value={value}
      detectAutoPan={autoPan}
      onChangeValue={setValue}
      preventPanOutside={panOutside}
      miniatureProps={{
        position: "none",
      }}
    >
      <svg viewBox="0 0 918 612" version="1.1">
        <filter id="dropshadow" width="200%" height="200%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
          <feOffset dx="0" dy="2" result="offsetblur" />
          <feComponentTransfer>
            <feFuncA type="linear" slope="0.2" />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <defs>
          <circle cx="8" cy="9" r="8" filter="url(#dropshadow)" id="default" fill="#C4C4C4" />
          <g filter="url(#dropshadow)" id="malfunction">
            <path d="M27.1004 14.4L29.7004 11.8L26.6004 6.2L23.0004 7.1L20.3004 5.5L19.4004 2H13.0004L12.0004 5.5L9.20039 7.1L5.80039 6.1L2.50039 11.7L5.00039 14.3V17.6L2.40039 20.2L5.50039 25.8L9.00039 24.8L11.7004 26.4L12.6004 30H19.0004L20.0004 26.5L22.8004 24.9L26.3004 25.9L29.6004 20.3L27.0004 17.7L27.1004 14.4Z" fill="#2D4275" />
            <path d="M17.002 9H15.002V18H17.002V9Z" fill="white" />
            <path d="M17.002 20H15.002V22H17.002V20Z" fill="white" />
          </g>
          <g filter="url(#dropshadow)" id="normal">
            <path fill="#2D4275" d="M8,15.75 C7.01042,15.75 6.05339,15.5612 5.12891,15.1836 C4.20443,14.806 3.39062,14.2591 2.6875,13.543 C1.98438,12.8529 1.44401,12.0456 1.06641,11.1211 C0.688802,10.1966 0.5,9.23958 0.5,8.25 C0.5,7.22135 0.695312,6.2513 1.08594,5.33984 C1.48958,4.42839 2.02995,3.63411 2.70703,2.95703 C3.38411,2.27995 4.17839,1.73958 5.08984,1.33594 C6.0013,0.945312 6.97135,0.75 8,0.75 C9.02865,0.75 9.9987,0.945312 10.9102,1.33594 C11.8216,1.72656 12.6159,2.26693 13.293,2.95703 C13.9831,3.63411 14.5234,4.42839 14.9141,5.33984 C15.3047,6.2513 15.5,7.22135 15.5,8.25 C15.5,9.23958 15.3112,10.1966 14.9336,11.1211 C14.556,12.0456 14.0156,12.8529 13.3125,13.543 C12.6094,14.2591 11.7956,14.806 10.8711,15.1836 C9.94661,15.5612 8.98958,15.75 8,15.75 Z" />
            <path fill="#FFF" d="M8.625,7 L8.625,12 L7.375,12 L7.375,7 L8.625,7 Z M8.625,4.5 L8.625,5.75 L7.375,5.75 L7.375,4.5 L8.625,4.5 Z" />
          </g>
          <g filter="url(#dropshadow)" id="normal-highlight">
            <path fill="#15D4F1" d="M8,15.75 C7.01042,15.75 6.05339,15.5612 5.12891,15.1836 C4.20443,14.806 3.39062,14.2591 2.6875,13.543 C1.98438,12.8529 1.44401,12.0456 1.06641,11.1211 C0.688802,10.1966 0.5,9.23958 0.5,8.25 C0.5,7.22135 0.695312,6.2513 1.08594,5.33984 C1.48958,4.42839 2.02995,3.63411 2.70703,2.95703 C3.38411,2.27995 4.17839,1.73958 5.08984,1.33594 C6.0013,0.945312 6.97135,0.75 8,0.75 C9.02865,0.75 9.9987,0.945312 10.9102,1.33594 C11.8216,1.72656 12.6159,2.26693 13.293,2.95703 C13.9831,3.63411 14.5234,4.42839 14.9141,5.33984 C15.3047,6.2513 15.5,7.22135 15.5,8.25 C15.5,9.23958 15.3112,10.1966 14.9336,11.1211 C14.556,12.0456 14.0156,12.8529 13.3125,13.543 C12.6094,14.2591 11.7956,14.806 10.8711,15.1836 C9.94661,15.5612 8.98958,15.75 8,15.75 Z" />
            <path fill="#FFF" d="M8.625,7 L8.625,12 L7.375,12 L7.375,7 L8.625,7 Z M8.625,4.5 L8.625,5.75 L7.375,5.75 L7.375,4.5 L8.625,4.5 Z" />
          </g>
          <g filter="url(#dropshadow)" id="warning" transform="translate(.317 -.142)">
            <polygon fill="#FFD200" points="8.883 .75 13.238 8.25 17.555 15.75 .25 15.75" />
            <path fill="#FFF" d="M9.625,12.625 L9.625,13.875 L8.375,13.875 L8.375,12.625 L9.625,12.625 Z M9.625,5.75 L9.625,11.375 L8.375,11.375 L8.375,5.75 L9.625,5.75 Z" />
          </g>
          <g filter="url(#dropshadow)" id="alert" transform="translate(.317 -.142)">
            <polygon fill="#FF0032" points="8 .5 15.5 4.836 15.5 13.488 8 17.824 .5 13.488 .5 4.836" />
            <path fill="#FFF" d="M8.625,12.375 L8.625,13.625 L7.375,13.625 L7.375,12.375 L8.625,12.375 Z M8.625,5.5 L8.625,11.125 L7.375,11.125 L7.375,5.5 L8.625,5.5 Z" />
          </g>
          <g fill="none" id="add" filter="url(#dropshadow)">
            <path fill="#C4C4C4" d="M15,7.5 C15,8.5417 14.8047,9.5117 14.4141,10.4102 C14.0234,11.3216 13.4831,12.1224 12.793,12.8125 C12.1159,13.4896 11.3216,14.0234 10.4102,14.4141 C9.5117,14.8047 8.5417,15 7.5,15 C6.4583,15 5.4883,14.8047 4.5898,14.4141 C3.6784,14.0234 2.8776,13.4896 2.1875,12.8125 C1.5104,12.1224 0.9766,11.3216 0.5859,10.4102 C0.1953,9.5117 0,8.5417 0,7.5 C0,6.4583 0.1953,5.4883 0.5859,4.5898 C0.9766,3.67839 1.5104,2.88411 2.1875,2.20703 C2.8776,1.51693 3.6784,0.97656 4.5898,0.58594 C5.4883,0.19531 6.4583,0 7.5,0 C8.5417,0 9.5117,0.19531 10.4102,0.58594 C11.3216,0.97656 12.1159,1.51693 12.793,2.20703 C13.4831,2.88411 14.0234,3.67839 14.4141,4.5898 C14.8047,5.4883 15,6.4583 15,7.5 Z" />
            <polygon fill="#FFF" points="8.004 7.01 10.79 7.01 10.79 8.004 8.004 8.004 8.004 10.888 6.996 10.888 6.996 8.004 4.21 8.004 4.21 7.01 6.996 7.01 6.996 4.112 8.004 4.112" />
          </g>
          <g clipPath="url(#clip0)" id="group-add">
            <path d="M15.5911 13.2864L17.2648 11.6765L15.34 8.11769L13.0805 8.62609L11.4068 7.6093L10.8209 5.40625H6.72031L6.1345 7.6093L4.37709 8.62609L2.20124 7.94823L0.109077 11.507L1.69912 13.2016V15.32L0.0253906 17.0146L2.03387 20.4886L4.20972 19.8108L5.88345 20.8276L6.46925 23.1154H10.4862L11.1557 20.9123L12.9131 19.8955L15.089 20.5734L17.1811 17.0146L15.5074 15.32L15.5911 13.2864Z" fill="#202020" />
            <path d="M9.31584 9.8125H8.06055V15.5743H9.31584V9.8125Z" fill="white" />
            <path d="M9.31584 16.8447H8.06055V18.1157H9.31584V16.8447Z" fill="white" />
            <path d="M17.1809 3.88091L17.7667 3.28778L17.0135 2.01679L16.1767 2.18626L15.5909 1.84733L15.4235 1H13.9171L13.6661 1.84733L12.9966 2.18626L12.2434 1.93206L11.4902 3.20305L12.076 3.79618V4.55877L11.4902 5.23663L12.2434 6.50762L13.0803 6.25342L13.6661 6.59235L13.8335 7.43968H15.3398L15.5909 6.59235L16.2604 6.25342L17.0972 6.50762L17.8504 5.23663L17.2646 4.6435L17.1809 3.88091Z" fill="#202020" />
            <path d="M14.8381 2.61035H14.3359V4.72867H14.8381V2.61035Z" fill="white" />
            <path d="M14.8381 5.23633H14.3359V5.74472H14.8381V5.23633Z" fill="white" />
            <path d="M22.6207 8.54106L23.2065 7.94794L22.4533 6.67695L21.6164 6.84641L21.0306 6.50748L20.8632 5.66016H19.3569L19.1058 6.50748L18.52 6.84641L17.7668 6.59221L17.0137 7.8632L17.5995 8.45633V9.21892L17.0137 9.81205L17.7668 11.083L18.6037 10.8288L19.1895 11.1678L19.3569 12.0151H20.8632L21.1143 11.1678L21.7838 10.8288L22.6207 11.083L23.3738 9.81205L22.788 9.21892L22.6207 8.54106Z" fill="#202020" />
            <path d="M20.3615 7.27051H19.8594V9.38882H20.3615V7.27051Z" fill="white" />
            <path d="M20.3615 9.89648H19.8594V10.3201H20.3615V9.89648Z" fill="white" />
          </g>
          <g clipPath="url(#clip0)" id="group-normal">
            <path d="M15.5911 13.2864L17.2648 11.6765L15.34 8.11769L13.0805 8.62609L11.4068 7.6093L10.8209 5.40625H6.72031L6.1345 7.6093L4.37709 8.62609L2.20124 7.94823L0.109077 11.507L1.69912 13.2016V15.32L0.0253906 17.0146L2.03387 20.4886L4.20972 19.8108L5.88345 20.8276L6.46925 23.1154H10.4862L11.1557 20.9123L12.9131 19.8955L15.089 20.5734L17.1811 17.0146L15.5074 15.32L15.5911 13.2864Z" fill="#2D4375" />
            <path d="M9.31584 9.8125H8.06055V15.5743H9.31584V9.8125Z" fill="white" />
            <path d="M9.31584 16.8447H8.06055V18.1157H9.31584V16.8447Z" fill="white" />
            <path d="M17.1809 3.88091L17.7667 3.28778L17.0135 2.01679L16.1767 2.18626L15.5909 1.84733L15.4235 1H13.9171L13.6661 1.84733L12.9966 2.18626L12.2434 1.93206L11.4902 3.20305L12.076 3.79618V4.55877L11.4902 5.23663L12.2434 6.50762L13.0803 6.25342L13.6661 6.59235L13.8335 7.43968H15.3398L15.5909 6.59235L16.2604 6.25342L17.0972 6.50762L17.8504 5.23663L17.2646 4.6435L17.1809 3.88091Z" fill="#2D4375" />
            <path d="M14.8381 2.61035H14.3359V4.72867H14.8381V2.61035Z" fill="white" />
            <path d="M14.8381 5.23633H14.3359V5.74472H14.8381V5.23633Z" fill="white" />
            <path d="M22.6207 8.54106L23.2065 7.94794L22.4533 6.67695L21.6164 6.84641L21.0306 6.50748L20.8632 5.66016H19.3569L19.1058 6.50748L18.52 6.84641L17.7668 6.59221L17.0137 7.8632L17.5995 8.45633V9.21892L17.0137 9.81205L17.7668 11.083L18.6037 10.8288L19.1895 11.1678L19.3569 12.0151H20.8632L21.1143 11.1678L21.7838 10.8288L22.6207 11.083L23.3738 9.81205L22.788 9.21892L22.6207 8.54106Z" fill="#2D4375" />
            <path d="M20.3615 7.27051H19.8594V9.38882H20.3615V7.27051Z" fill="white" />
            <path d="M20.3615 9.89648H19.8594V10.3201H20.3615V9.89648Z" fill="white" />
          </g>
          <g clipPath="url(#clip0)" id="group-warning">
            <path d="M15.5911 13.2864L17.2648 11.6765L15.34 8.11769L13.0805 8.62609L11.4068 7.6093L10.8209 5.40625H6.72031L6.1345 7.6093L4.37709 8.62609L2.20124 7.94823L0.109077 11.507L1.69912 13.2016V15.32L0.0253906 17.0146L2.03387 20.4886L4.20972 19.8108L5.88345 20.8276L6.46925 23.1154H10.4862L11.1557 20.9123L12.9131 19.8955L15.089 20.5734L17.1811 17.0146L15.5074 15.32L15.5911 13.2864Z" fill="#FFD200" />
            <path d="M9.31584 9.8125H8.06055V15.5743H9.31584V9.8125Z" fill="white" />
            <path d="M9.31584 16.8447H8.06055V18.1157H9.31584V16.8447Z" fill="white" />
            <path d="M17.1809 3.88091L17.7667 3.28778L17.0135 2.01679L16.1767 2.18626L15.5909 1.84733L15.4235 1H13.9171L13.6661 1.84733L12.9966 2.18626L12.2434 1.93206L11.4902 3.20305L12.076 3.79618V4.55877L11.4902 5.23663L12.2434 6.50762L13.0803 6.25342L13.6661 6.59235L13.8335 7.43968H15.3398L15.5909 6.59235L16.2604 6.25342L17.0972 6.50762L17.8504 5.23663L17.2646 4.6435L17.1809 3.88091Z" fill="#FFD200" />
            <path d="M14.8381 2.61035H14.3359V4.72867H14.8381V2.61035Z" fill="white" />
            <path d="M14.8381 5.23633H14.3359V5.74472H14.8381V5.23633Z" fill="white" />
            <path d="M22.6207 8.54106L23.2065 7.94794L22.4533 6.67695L21.6164 6.84641L21.0306 6.50748L20.8632 5.66016H19.3569L19.1058 6.50748L18.52 6.84641L17.7668 6.59221L17.0137 7.8632L17.5995 8.45633V9.21892L17.0137 9.81205L17.7668 11.083L18.6037 10.8288L19.1895 11.1678L19.3569 12.0151H20.8632L21.1143 11.1678L21.7838 10.8288L22.6207 11.083L23.3738 9.81205L22.788 9.21892L22.6207 8.54106Z" fill="#FFD200" />
            <path d="M20.3615 7.27051H19.8594V9.38882H20.3615V7.27051Z" fill="white" />
            <path d="M20.3615 9.89648H19.8594V10.3201H20.3615V9.89648Z" fill="white" />
          </g>
          <g clipPath="url(#clip0)" id="group-alert">
            <path d="M15.5911 13.2864L17.2648 11.6765L15.34 8.11769L13.0805 8.62609L11.4068 7.6093L10.8209 5.40625H6.72031L6.1345 7.6093L4.37709 8.62609L2.20124 7.94823L0.109077 11.507L1.69912 13.2016V15.32L0.0253906 17.0146L2.03387 20.4886L4.20972 19.8108L5.88345 20.8276L6.46925 23.1154H10.4862L11.1557 20.9123L12.9131 19.8955L15.089 20.5734L17.1811 17.0146L15.5074 15.32L15.5911 13.2864Z" fill="#FF0032" />
            <path d="M9.31584 9.8125H8.06055V15.5743H9.31584V9.8125Z" fill="white" />
            <path d="M9.31584 16.8447H8.06055V18.1157H9.31584V16.8447Z" fill="white" />
            <path d="M17.1809 3.88091L17.7667 3.28778L17.0135 2.01679L16.1767 2.18626L15.5909 1.84733L15.4235 1H13.9171L13.6661 1.84733L12.9966 2.18626L12.2434 1.93206L11.4902 3.20305L12.076 3.79618V4.55877L11.4902 5.23663L12.2434 6.50762L13.0803 6.25342L13.6661 6.59235L13.8335 7.43968H15.3398L15.5909 6.59235L16.2604 6.25342L17.0972 6.50762L17.8504 5.23663L17.2646 4.6435L17.1809 3.88091Z" fill="#FF0032" />
            <path d="M14.8381 2.61035H14.3359V4.72867H14.8381V2.61035Z" fill="white" />
            <path d="M14.8381 5.23633H14.3359V5.74472H14.8381V5.23633Z" fill="white" />
            <path d="M22.6207 8.54106L23.2065 7.94794L22.4533 6.67695L21.6164 6.84641L21.0306 6.50748L20.8632 5.66016H19.3569L19.1058 6.50748L18.52 6.84641L17.7668 6.59221L17.0137 7.8632L17.5995 8.45633V9.21892L17.0137 9.81205L17.7668 11.083L18.6037 10.8288L19.1895 11.1678L19.3569 12.0151H20.8632L21.1143 11.1678L21.7838 10.8288L22.6207 11.083L23.3738 9.81205L22.788 9.21892L22.6207 8.54106Z" fill="#FF0032" />
            <path d="M20.3615 7.27051H19.8594V9.38882H20.3615V7.27051Z" fill="white" />
            <path d="M20.3615 9.89648H19.8594V10.3201H20.3615V9.89648Z" fill="white" />
          </g>
        </defs>
        <path d="M201 203H198V353H201V203Z" fill="#808080" />
        <path d="M387.5 203H385V352.5H387.5V203Z" fill="#808080" />
        <path d="M150 250H113V257H150V250Z" fill="#808080" />
        <path d="M385 331H201V336H385V331Z" fill="#808080" />
        <path d="M385 294H201V299H385V294Z" fill="#808080" />
        <path d="M385 257H201V262H385V257Z" fill="#808080" />
        <path d="M385 219H201V224H385V219Z" fill="#808080" />
        <path d="M472 305.5H439.5V312L472 312.5V305.5Z" fill="#808080" />
        <path d="M472 243H439V250H472V243Z" fill="#808080" />
        <path d="M385 353H387H385V353Z" fill="#808080" stroke="#808080" strokeWidth="0.2" />
        <path d="M113.5 263.496L95.5 263.499V292.499H113.5V263.496Z" fill="#D4D4D4" />
        <path d="M198 263H149V293H198V263Z" fill="#D4D4D4" />
        <path d="M439.5 263.5H387.5V292.5H439.5V263.5Z" fill="#D4D4D4" />
        <path d="M472.14 263H472V293H558.86H559V263H472.14Z" fill="#D4D4D4" />
        <path d="M149.5 258.5H113.5V297.5H149.5V258.5Z" fill="white" />
        <path d="M383 381V368" stroke="white" strokeWidth="0.2" />
        <path d="M385 336H201V353H385V336Z" fill="white" />
        <path d="M385 299H201.5V331.5H385V299Z" fill="white" />
        <path d="M385 261H201V294H385V261Z" fill="white" />
        <path d="M385 224H201V257H385V224Z" fill="white" />
        <path d="M385 203H201V219H385V203Z" fill="white" />
        <path d="M472 252H439V304H472V252Z" fill="white" />
        <path d="M577 273H568V283H577V273Z" fill="white" />
        <path d="M198 175V202H202.761V187.877H382.959V202H388V175H198Z" fill="white" />
        <path d="M558.446 265.417L558.149 265V265.417H558L558.149 265.556V291H569V283.631V283.214V265.417H558.446Z" fill="white" />
        <path d="M113 250V257H150V250H113ZM149.297 256.352H113.703V250.648H149.297V256.352Z" fill="black" />
        <path d="M472 252.281V252H439V252.281V252.562V278V303.438V303.719V304H471.723H472V277.859V252.281ZM471.312 303.297H439.832V278.141V252.703H471.312V278.141V303.297Z" fill="black" />
        <path d="M113 293V292.296H95.6716V263.704H113V263H95V293" fill="black" />
        <path d="M198.216 263.047H149.484V263.745H198.216V263.047Z" fill="black" />
        <path d="M198.076 292.086H149.484V292.784H198.076V292.086Z" fill="black" />
        <path d="M439.498 263.047H387.416V263.745H439.498V263.047Z" fill="black" />
        <path d="M439.498 292.086H387.416V292.784H439.498V292.086Z" fill="black" />
        <path d="M113 258V278V298H150V278V258H113ZM149.297 277.859V297.155H113.703V277.859V258.563H149.297V277.859Z" fill="black" />
        <path d="M437.683 252.16H433.215V262.214H437.683V252.16Z" fill="#878787" />
        <path d="M437.823 252.02H437.543H437.124H433.215H432.936V262.073V262.352H437.823V262.073V252.02ZM437.124 261.794H433.634V252.718H437.124V261.794Z" fill="black" />
        <path d="M437.683 252.16H433.215V262.214H437.683V252.16Z" fill="#878787" />
        <path d="M437.823 252.02H437.543H437.124H433.215H432.936V262.073V262.352H437.823V262.073V252.02ZM437.124 261.794H433.634V252.718H437.124V261.794Z" fill="black" />
        <path d="M478.733 252.16H474.265V262.214H478.733V252.16Z" fill="#878787" />
        <path d="M478.872 252.02H478.593H478.174H474.265H473.985V262.073V262.352H478.872V262.073V252.02ZM478.174 261.794H474.684V252.718H478.174V261.794Z" fill="black" />
        <path d="M478.454 293.637H473.985V303.83H478.454V293.637Z" fill="#878787" />
        <path d="M473.847 293.352V293.77V303.964H474.126H478.036H478.734V293.352M474.545 303.265V294.05H478.036V303.265H474.545Z" fill="black" />
        <path d="M437.544 293.637H433.076V303.83H437.544V293.637Z" fill="#878787" />
        <path d="M432.936 293.352L432.936 293.77V303.964H433.215H437.125H437.823V293.352M433.634 303.265V294.05H437.125V303.265H433.634Z" fill="black" />
        <path d="M577 272.953V272.673H568.377V264.822H568.099H558.642V263.28V263H558.225H472V263.701H557.947V265.243V278V278.14V290.897V292.299H472V293H558.503V292.72V291.318H568.377V291.037V283.467H576.583H577V278.28V272.953ZM567.682 272.953V277.86V278V282.907V290.477H558.642V278V277.86V265.523H567.682V272.953ZM576.305 278V282.616H568.377V278V277.86V273.497H576.305V278Z" fill="black" />
        <path d="M388 202V175H198V202M382.551 201.318H203.169V188.091H382.551V201.318ZM202.471 187.545V201.455H198.699V175.818H387.301V201.455H383.25V187.545" fill="black" />
        <path d="M203.802 187.93H203.104V202H203.802V187.93Z" fill="black" />
        <path d="M205.757 187.93H205.059V202H205.757V187.93Z" fill="black" />
        <path d="M207.572 187.93H206.874V202H207.572V187.93Z" fill="black" />
        <path d="M209.387 187.93H208.688V202H209.387V187.93Z" fill="black" />
        <path d="M211.343 187.93H210.645V202H211.343V187.93Z" fill="black" />
        <path d="M213.157 187.93H212.459V202H213.157V187.93Z" fill="black" />
        <path d="M214.973 187.93H214.274V202H214.973V187.93Z" fill="black" />
        <path d="M216.928 187.93H216.229V202H216.928V187.93Z" fill="black" />
        <path d="M218.742 187.93H218.044V202H218.742V187.93Z" fill="black" />
        <path d="M220.558 187.93H219.859V202H220.558V187.93Z" fill="black" />
        <path d="M222.513 187.93H221.814V202H222.513V187.93Z" fill="black" />
        <path d="M224.328 187.93H223.63V202H224.328V187.93Z" fill="black" />
        <path d="M226.142 187.93H225.444V202H226.142V187.93Z" fill="black" />
        <path d="M228.098 187.93H227.399V202H228.098V187.93Z" fill="black" />
        <path d="M229.913 187.93H229.215V202H229.913V187.93Z" fill="black" />
        <path d="M231.727 187.93H231.029V202H231.727V187.93Z" fill="black" />
        <path d="M233.684 187.93H232.985V202H233.684V187.93Z" fill="black" />
        <path d="M235.498 187.93H234.8V202H235.498V187.93Z" fill="black" />
        <path d="M237.313 187.93H236.615V202H237.313V187.93Z" fill="black" />
        <path d="M239.268 187.93H238.57V202H239.268V187.93Z" fill="black" />
        <path d="M241.083 187.93H240.385V202H241.083V187.93Z" fill="black" />
        <path d="M242.898 187.93H242.2V202H242.898V187.93Z" fill="black" />
        <path d="M244.853 187.93H244.155V202H244.853V187.93Z" fill="black" />
        <path d="M246.669 187.93H245.971V202H246.669V187.93Z" fill="black" />
        <path d="M248.483 187.93H247.785V202H248.483V187.93Z" fill="black" />
        <path d="M250.439 187.93H249.741V202H250.439V187.93Z" fill="black" />
        <path d="M252.254 187.93H251.556V202H252.254V187.93Z" fill="black" />
        <path d="M254.069 187.93H253.371V202H254.069V187.93Z" fill="black" />
        <path d="M256.024 187.93H255.326V202H256.024V187.93Z" fill="black" />
        <path d="M257.839 187.93H257.141V202H257.839V187.93Z" fill="black" />
        <path d="M259.654 187.93H258.956V202H259.654V187.93Z" fill="black" />
        <path d="M261.609 187.93H260.911V202H261.609V187.93Z" fill="black" />
        <path d="M263.425 187.93H262.727V202H263.425V187.93Z" fill="black" />
        <path d="M265.239 187.93H264.541V202H265.239V187.93Z" fill="black" />
        <path d="M267.194 187.93H266.496V202H267.194V187.93Z" fill="black" />
        <path d="M269.01 187.93H268.312V202H269.01V187.93Z" fill="black" />
        <path d="M270.824 187.93H270.126V202H270.824V187.93Z" fill="black" />
        <path d="M272.78 187.93H272.082V202H272.78V187.93Z" fill="black" />
        <path d="M274.595 187.93H273.896V202H274.595V187.93Z" fill="black" />
        <path d="M276.41 187.93H275.712V202H276.41V187.93Z" fill="black" />
        <path d="M278.365 187.93H277.667V202H278.365V187.93Z" fill="black" />
        <path d="M280.18 187.93H279.481V202H280.18V187.93Z" fill="black" />
        <path d="M281.995 187.93H281.297V202H281.995V187.93Z" fill="black" />
        <path d="M283.95 187.93H283.252V202H283.95V187.93Z" fill="black" />
        <path d="M285.766 187.93H285.067V202H285.766V187.93Z" fill="black" />
        <path d="M287.58 187.93H286.882V202H287.58V187.93Z" fill="black" />
        <path d="M289.536 187.93H288.838V202H289.536V187.93Z" fill="black" />
        <path d="M291.35 187.93H290.652V202H291.35V187.93Z" fill="black" />
        <path d="M293.165 187.93H292.467V202H293.165V187.93Z" fill="black" />
        <path d="M295.121 187.93H294.423V202H295.121V187.93Z" fill="black" />
        <path d="M296.935 187.93H296.237V202H296.935V187.93Z" fill="black" />
        <path d="M298.751 187.93H298.053V202H298.751V187.93Z" fill="black" />
        <path d="M300.706 187.93H300.008V202H300.706V187.93Z" fill="black" />
        <path d="M302.521 187.93H301.823V202H302.521V187.93Z" fill="black" />
        <path d="M304.336 187.93H303.638V202H304.336V187.93Z" fill="black" />
        <path d="M306.291 187.93H305.593V202H306.291V187.93Z" fill="black" />
        <path d="M308.106 187.93H307.408V202H308.106V187.93Z" fill="black" />
        <path d="M309.921 187.93H309.223V202H309.921V187.93Z" fill="black" />
        <path d="M311.877 187.93H311.179V202H311.877V187.93Z" fill="black" />
        <path d="M313.691 187.93H312.993V202H313.691V187.93Z" fill="black" />
        <path d="M315.507 187.93H314.809V202H315.507V187.93Z" fill="black" />
        <path d="M317.462 187.93H316.764V202H317.462V187.93Z" fill="black" />
        <path d="M319.276 187.93H318.578V202H319.276V187.93Z" fill="black" />
        <path d="M321.092 187.93H320.394V202H321.092V187.93Z" fill="black" />
        <path d="M323.047 187.93H322.349V202H323.047V187.93Z" fill="black" />
        <path d="M324.862 187.93H324.164V202H324.862V187.93Z" fill="black" />
        <path d="M326.677 187.93H325.979V202H326.677V187.93Z" fill="black" />
        <path d="M328.632 187.93H327.934V202H328.632V187.93Z" fill="black" />
        <path d="M330.447 187.93H329.749V202H330.447V187.93Z" fill="black" />
        <path d="M332.262 187.93H331.563V202H332.262V187.93Z" fill="black" />
        <path d="M334.218 187.93H333.52V202H334.218V187.93Z" fill="black" />
        <path d="M336.032 187.93H335.334V202H336.032V187.93Z" fill="black" />
        <path d="M337.848 187.93H337.149V202H337.848V187.93Z" fill="black" />
        <path d="M339.803 187.93H339.104V202H339.803V187.93Z" fill="black" />
        <path d="M341.618 187.93H340.92V202H341.618V187.93Z" fill="black" />
        <path d="M343.433 187.93H342.734V202H343.433V187.93Z" fill="black" />
        <path d="M345.388 187.93H344.689V202H345.388V187.93Z" fill="black" />
        <path d="M347.203 187.93H346.505V202H347.203V187.93Z" fill="black" />
        <path d="M349.017 187.93H348.319V202H349.017V187.93Z" fill="black" />
        <path d="M350.974 187.93H350.275V202H350.974V187.93Z" fill="black" />
        <path d="M352.788 187.93H352.09V202H352.788V187.93Z" fill="black" />
        <path d="M354.603 187.93H353.905V202H354.603V187.93Z" fill="black" />
        <path d="M356.559 187.93H355.86V202H356.559V187.93Z" fill="black" />
        <path d="M358.373 187.93H357.675V202H358.373V187.93Z" fill="black" />
        <path d="M360.188 187.93H359.49V202H360.188V187.93Z" fill="black" />
        <path d="M362.143 187.93H361.445V202H362.143V187.93Z" fill="black" />
        <path d="M363.959 187.93H363.261V202H363.959V187.93Z" fill="black" />
        <path d="M365.773 187.93H365.075V202H365.773V187.93Z" fill="black" />
        <path d="M367.728 187.93H367.03V202H367.728V187.93Z" fill="black" />
        <path d="M369.544 187.93H368.846V202H369.544V187.93Z" fill="black" />
        <path d="M371.358 187.93H370.66V202H371.358V187.93Z" fill="black" />
        <path d="M373.314 187.93H372.616V202H373.314V187.93Z" fill="black" />
        <path d="M375.129 187.93H374.431V202H375.129V187.93Z" fill="black" />
        <path d="M376.944 187.93H376.246V202H376.944V187.93Z" fill="black" />
        <path d="M378.899 187.93H378.201V202H378.899V187.93Z" fill="black" />
        <path d="M380.714 187.93H380.016V202H380.714V187.93Z" fill="black" />
        <path d="M382.529 187.93H381.831V202H382.529V187.93Z" fill="black" />
        <path d="M388 381V354H383.24V368.123H203.041V354H198V381H388Z" fill="white" />
        <path d="M382.529 353.664H381.831V367.906H382.529V353.664Z" fill="black" />
        <path d="M380.714 353.664H380.016V367.906H380.714V353.664Z" fill="black" />
        <path d="M378.899 353.664H378.201V367.906H378.899V353.664Z" fill="black" />
        <path d="M376.944 353.664H376.246V367.906H376.944V353.664Z" fill="black" />
        <path d="M375.129 353.664H374.431V367.906H375.129V353.664Z" fill="black" />
        <path d="M373.314 353.664H372.616V367.906H373.314V353.664Z" fill="black" />
        <path d="M371.358 353.664H370.66V367.906H371.358V353.664Z" fill="black" />
        <path d="M369.544 353.664H368.846V367.906H369.544V353.664Z" fill="black" />
        <path d="M367.728 353.664H367.03V367.906H367.728V353.664Z" fill="black" />
        <path d="M365.773 353.664H365.075V367.906H365.773V353.664Z" fill="black" />
        <path d="M363.959 353.664H363.261V367.906H363.959V353.664Z" fill="black" />
        <path d="M362.143 353.664H361.445V367.906H362.143V353.664Z" fill="black" />
        <path d="M360.188 353.664H359.49V367.906H360.188V353.664Z" fill="black" />
        <path d="M358.373 353.664H357.675V367.906H358.373V353.664Z" fill="black" />
        <path d="M356.559 353.664H355.86V367.906H356.559V353.664Z" fill="black" />
        <path d="M354.603 353.664H353.905V367.906H354.603V353.664Z" fill="black" />
        <path d="M352.788 353.664H352.09V367.906H352.788V353.664Z" fill="black" />
        <path d="M350.974 353.664H350.275V367.906H350.974V353.664Z" fill="black" />
        <path d="M349.017 353.664H348.319V367.906H349.017V353.664Z" fill="black" />
        <path d="M347.203 353.664H346.505V367.906H347.203V353.664Z" fill="black" />
        <path d="M345.388 353.664H344.689V367.906H345.388V353.664Z" fill="black" />
        <path d="M343.433 353.664H342.734V367.906H343.433V353.664Z" fill="black" />
        <path d="M341.618 353.664H340.92V367.906H341.618V353.664Z" fill="black" />
        <path d="M339.803 353.664H339.104V367.906H339.803V353.664Z" fill="black" />
        <path d="M337.848 353.664H337.149V367.906H337.848V353.664Z" fill="black" />
        <path d="M336.032 353.664H335.334V367.906H336.032V353.664Z" fill="black" />
        <path d="M334.218 353.664H333.52V367.906H334.218V353.664Z" fill="black" />
        <path d="M332.262 353.664H331.563V367.906H332.262V353.664Z" fill="black" />
        <path d="M330.447 353.664H329.749V367.906H330.447V353.664Z" fill="black" />
        <path d="M328.632 353.664H327.934V367.906H328.632V353.664Z" fill="black" />
        <path d="M326.677 353.664H325.979V367.906H326.677V353.664Z" fill="black" />
        <path d="M324.862 353.664H324.164V367.906H324.862V353.664Z" fill="black" />
        <path d="M323.047 353.664H322.349V367.906H323.047V353.664Z" fill="black" />
        <path d="M321.092 353.664H320.394V367.906H321.092V353.664Z" fill="black" />
        <path d="M319.276 353.664H318.578V367.906H319.276V353.664Z" fill="black" />
        <path d="M317.462 353.664H316.764V367.906H317.462V353.664Z" fill="black" />
        <path d="M315.507 353.664H314.809V367.906H315.507V353.664Z" fill="black" />
        <path d="M313.691 353.664H312.993V367.906H313.691V353.664Z" fill="black" />
        <path d="M311.877 353.664H311.179V367.906H311.877V353.664Z" fill="black" />
        <path d="M309.921 353.664H309.223V367.906H309.921V353.664Z" fill="black" />
        <path d="M308.106 353.664H307.408V367.906H308.106V353.664Z" fill="black" />
        <path d="M306.291 353.664H305.593V367.906H306.291V353.664Z" fill="black" />
        <path d="M304.336 353.664H303.638V367.906H304.336V353.664Z" fill="black" />
        <path d="M302.521 353.664H301.823V367.906H302.521V353.664Z" fill="black" />
        <path d="M300.706 353.664H300.008V367.906H300.706V353.664Z" fill="black" />
        <path d="M298.751 353.664H298.053V367.906H298.751V353.664Z" fill="black" />
        <path d="M296.935 353.664H296.237V367.906H296.935V353.664Z" fill="black" />
        <path d="M294.98 353.664H294.282V367.906H294.98V353.664Z" fill="black" />
        <path d="M293.165 353.664H292.467V367.906H293.165V353.664Z" fill="black" />
        <path d="M291.35 353.664H290.652V367.906H291.35V353.664Z" fill="black" />
        <path d="M289.395 353.664H288.697V367.906H289.395V353.664Z" fill="black" />
        <path d="M287.58 353.664H286.882V367.906H287.58V353.664Z" fill="black" />
        <path d="M285.766 353.664H285.067V367.906H285.766V353.664Z" fill="black" />
        <path d="M283.809 353.664H283.111V367.906H283.809V353.664Z" fill="black" />
        <path d="M281.995 353.664H281.297V367.906H281.995V353.664Z" fill="black" />
        <path d="M280.18 353.664H279.481V367.906H280.18V353.664Z" fill="black" />
        <path d="M278.225 353.664H277.526V367.906H278.225V353.664Z" fill="black" />
        <path d="M276.41 353.664H275.712V367.906H276.41V353.664Z" fill="black" />
        <path d="M274.595 353.664H273.896V367.906H274.595V353.664Z" fill="black" />
        <path d="M272.64 353.664H271.941V367.906H272.64V353.664Z" fill="black" />
        <path d="M270.824 353.664H270.126V367.906H270.824V353.664Z" fill="black" />
        <path d="M269.01 353.664H268.312V367.906H269.01V353.664Z" fill="black" />
        <path d="M267.055 353.664H266.356V367.906H267.055V353.664Z" fill="black" />
        <path d="M265.239 353.664H264.541V367.906H265.239V353.664Z" fill="black" />
        <path d="M263.425 353.664H262.727V367.906H263.425V353.664Z" fill="black" />
        <path d="M261.469 353.664H260.771V367.906H261.469V353.664Z" fill="black" />
        <path d="M259.654 353.664H258.956V367.906H259.654V353.664Z" fill="black" />
        <path d="M257.839 353.664H257.141V367.906H257.839V353.664Z" fill="black" />
        <path d="M255.884 353.664H255.186V367.906H255.884V353.664Z" fill="black" />
        <path d="M254.069 353.664H253.371V367.906H254.069V353.664Z" fill="black" />
        <path d="M252.254 353.664H251.556V367.906H252.254V353.664Z" fill="black" />
        <path d="M250.299 353.664H249.601V367.906H250.299V353.664Z" fill="black" />
        <path d="M248.483 353.664H247.785V367.906H248.483V353.664Z" fill="black" />
        <path d="M246.669 353.664H245.971V367.906H246.669V353.664Z" fill="black" />
        <path d="M244.713 353.664H244.015V367.906H244.713V353.664Z" fill="black" />
        <path d="M242.898 353.664H242.2V367.906H242.898V353.664Z" fill="black" />
        <path d="M241.083 353.664H240.385V367.906H241.083V353.664Z" fill="black" />
        <path d="M239.128 353.664H238.43V367.906H239.128V353.664Z" fill="black" />
        <path d="M237.313 353.664H236.615V367.906H237.313V353.664Z" fill="black" />
        <path d="M235.498 353.664H234.8V367.906H235.498V353.664Z" fill="black" />
        <path d="M233.543 353.664H232.845V367.906H233.543V353.664Z" fill="black" />
        <path d="M231.727 353.664H231.029V367.906H231.727V353.664Z" fill="black" />
        <path d="M229.913 353.664H229.215V367.906H229.913V353.664Z" fill="black" />
        <path d="M227.958 353.664H227.26V367.906H227.958V353.664Z" fill="black" />
        <path d="M226.142 353.664H225.444V367.906H226.142V353.664Z" fill="black" />
        <path d="M224.328 353.664H223.63V367.906H224.328V353.664Z" fill="black" />
        <path d="M222.372 353.664H221.674V367.906H222.372V353.664Z" fill="black" />
        <path d="M220.558 353.664H219.859V367.906H220.558V353.664Z" fill="black" />
        <path d="M218.742 353.664H218.044V367.906H218.742V353.664Z" fill="black" />
        <path d="M216.787 353.664H216.089V367.906H216.787V353.664Z" fill="black" />
        <path d="M214.973 353.664H214.274V367.906H214.973V353.664Z" fill="black" />
        <path d="M213.157 353.664H212.459V367.906H213.157V353.664Z" fill="black" />
        <path d="M211.202 353.664H210.504V367.906H211.202V353.664Z" fill="black" />
        <path d="M209.387 353.664H208.688V367.906H209.387V353.664Z" fill="black" />
        <path d="M207.572 353.664H206.874V367.906H207.572V353.664Z" fill="black" />
        <path d="M205.616 353.664H204.918V367.906H205.616V353.664Z" fill="black" />
        <path d="M203.802 353.664H203.104V367.906H203.802V353.664Z" fill="black" />
        <path d="M198 354H387" stroke="black" strokeWidth="0.5" />
        <path d="M385.601 223.819L201.288 223.68H201.009V257.191H201.288H201.567H385.182H385.601M384.903 256.493H201.567V224.378H384.903V256.493Z" fill="black" />
        <path d="M387.834 202.734H197.937L197.657 263.334V292.517L197.937 353.117H387.555H387.834V292.517V263.334V202.734ZM201.567 203.433H384.902V219.071H201.567V203.433ZM384.902 352.419H201.567V336.501H384.902V352.419ZM387.136 292.517V352.558H385.74V335.942H385.461H201.008V352.558H198.774L198.635 302.57V253.42L198.774 203.433H201.008V219.769H201.427H385.042H385.321H385.6V203.433H386.997V263.334" fill="black" />
        <path d="M385.601 260.965H201.288H201.009V277.721V294.476H201.288H201.707H385.74V294.197V277.86V261.384V260.965H385.601ZM384.903 277.86V293.918H201.567V277.86V261.803H384.903V277.86Z" fill="black" />
        <path d="M385.601 298.383H201.288H201.009V331.894H201.288H385.601V331.615V298.802V298.383ZM384.903 331.336H201.567V299.221H384.903V331.336Z" fill="black" />
        <path d="M150 306H113V353H150V306Z" fill="#DBDBDB" />
        <path d="M472 312.5H439.5V352.5H472V312.5Z" fill="#DBDBDB" />
        <path d="M149.903 306.473H149.205V352.83H149.903V306.473Z" fill="black" />
        <path d="M113.879 306.473H113.181V352.83H113.879V306.473Z" fill="black" />
        <path d="M472 243.302V243H439V243.302V251H439.277H471.168H471.445H472V243.302ZM471.244 250.396H439.832V243.755H471.244V250.396Z" fill="black" />
        <path d="M663 289H631V353H663V289Z" fill="#D4D4D4" />
        <path d="M695 289H663V353H695V289Z" fill="#D4D4D4" />
        <path d="M728 353L727.719 352.584V289H695L695.281 289.416V353H728Z" fill="#D4D4D4" />
        <path d="M760 289H727V353H760V289Z" fill="#D4D4D4" />
        <path d="M792 353L791.719 352.584V289H759L759.281 289.416V353H792Z" fill="#D4D4D4" />
        <path d="M824 289H792V353H824V289Z" fill="#D4D4D4" />
        <path d="M663 224H631V289H663V224Z" fill="#D4D4D4" />
        <path d="M695 224H663V289H695V224Z" fill="#D4D4D4" />
        <path d="M728 289L727.719 288.578V224H695L695.281 224.422V289H728Z" fill="#D4D4D4" />
        <path d="M760 224H727V289H760V224Z" fill="#D4D4D4" />
        <path d="M792 289L791.719 288.578V224H759L759.281 224.422V289H792Z" fill="#D4D4D4" />
        <path d="M824 224H792V289H824V224Z" fill="#D4D4D4" />
        <path d="M230 380H218V389H230V380Z" fill="white" />
        <path d="M279 380H267V389H279V380Z" fill="white" />
        <path d="M328 380H316V389H328V380Z" fill="white" />
        <path d="M377 380H365V389H377V380Z" fill="white" />
        <path d="M150 299H113V306.5H150V299Z" fill="#808080" />
        <path d="M113 299V307H150V299H113ZM149.297 306.259H113.703V299.741H149.297V306.259Z" fill="black" />
        <path d="M656.763 248.952V248.254H640.706H638.891V248.952H640.706H656.763Z" fill="black" />
        <path d="M656.763 256.348H638.891V257.046H656.763V256.348Z" fill="black" />
        <path d="M656.763 264.312H638.891V265.011H656.763V264.312Z" fill="black" />
        <path d="M656.763 241.132V240.434H650.759H638.891V241.132H650.759H656.763Z" fill="black" />
        <path d="M824.181 352.416V289.583V224.375H823.902H759.672V224.654H759.532V224.375H695.302V224.654H695.162V224.375H630.932H630.653V352.416H472.311V312.482V312.342V305.221H472.032H439.917H439.219V312.482V352.556H431.399V352.835V388.441H376.804V381.041H387.695V353.254H197.797V380.901H218.183V388.302H170.011V352.277H169.731H13.3447V352.975H169.312V388.302V388.581V389H432.098V388.721V388.441V353.115H909.356V352.416H824.181ZM203.382 354.092H382.668V367.636H203.382V354.092ZM198.635 380.343V354.092H202.684V368.334H383.366V354.092H387.137V380.343H198.635ZM229.214 388.441H218.881V381.041H229.214V388.441ZM267.194 388.441H229.912V381.041H267.194V388.441ZM278.224 388.441H267.892V381.041H278.224V388.441ZM316.204 388.441H278.923V381.041H316.204V388.441ZM327.235 388.441H316.902V381.041H327.235V388.441ZM365.214 388.441H327.933V381.041H365.214V388.441ZM376.106 388.441H365.773V381.041H376.106V388.441ZM471.753 352.416H439.917V312.761H471.753V352.416ZM471.753 312.063H439.917V305.919H471.753V312.063ZM662.628 352.416H631.211V289.164H662.628V352.416ZM662.628 288.466H631.211V225.073H662.628V288.466ZM694.743 289.583V352.416H663.326V289.164H694.743V289.583ZM694.743 288.466H663.326V225.073H694.743V288.466ZM726.858 352.416H695.581V289.164H726.858V352.416ZM726.858 288.466H695.581V225.073H726.858V288.466ZM759.253 289.583V352.416H727.836V289.164H759.253V289.583ZM759.253 288.466H727.836V225.073H759.253V288.466ZM791.368 352.416H760.09V289.164H791.368V352.416ZM791.368 288.466H760.09V225.073H791.368V288.466ZM823.622 289.583V352.416H792.206V289.164H823.622V289.583ZM823.622 288.466H792.206V225.073H823.622V288.466Z" fill="black" />
        <path d="M656.763 320.438H638.891V321.136H656.763V320.438Z" fill="black" />
        <path d="M656.763 328.543H638.891V329.241H656.763V328.543Z" fill="black" />
        <path d="M656.763 305.218V304.52H650.759H638.891V305.218H650.759H656.763Z" fill="black" />
        <path d="M656.763 313.183V312.484H640.706H638.891V313.183H640.706H656.763Z" fill="black" />
        <path d="M688.879 313.183V312.484H672.821H671.006V313.183H672.821H688.879Z" fill="black" />
        <path d="M688.879 320.438H671.006V321.136H688.879V320.438Z" fill="black" />
        <path d="M688.879 328.543H671.006V329.241H688.879V328.543Z" fill="black" />
        <path d="M688.879 305.218V304.52H682.874H671.006V305.218H682.874H688.879Z" fill="black" />
        <path d="M721.133 320.438H703.26V321.136H721.133V320.438Z" fill="black" />
        <path d="M721.133 328.543H703.26V329.241H721.133V328.543Z" fill="black" />
        <path d="M721.133 305.218V304.52H715.128H703.26V305.218H715.128H721.133Z" fill="black" />
        <path d="M721.133 313.183V312.484H705.075H703.26V313.183H705.075H721.133Z" fill="black" />
        <path d="M753.248 320.438H735.375V321.136H753.248V320.438Z" fill="black" />
        <path d="M753.248 328.543H735.375V329.241H753.248V328.543Z" fill="black" />
        <path d="M753.248 305.218V304.52H747.244H735.375V305.218H747.244H753.248Z" fill="black" />
        <path d="M753.248 313.183V312.484H737.19H735.375V313.183H737.19H753.248Z" fill="black" />
        <path d="M785.504 320.438H767.631V321.136H785.504V320.438Z" fill="black" />
        <path d="M785.504 328.543H767.631V329.241H785.504V328.543Z" fill="black" />
        <path d="M785.504 305.218V304.52H779.499H767.631V305.218H779.499H785.504Z" fill="black" />
        <path d="M785.504 313.183V312.484H769.446H767.631V313.183H769.446H785.504Z" fill="black" />
        <path d="M817.619 320.438H799.746V321.136H817.619V320.438Z" fill="black" />
        <path d="M817.619 328.543H799.746V329.241H817.619V328.543Z" fill="black" />
        <path d="M817.619 305.218V304.52H811.615H799.746V305.218H811.615H817.619Z" fill="black" />
        <path d="M817.619 313.183V312.484H801.561H799.746V313.183H801.561H817.619Z" fill="black" />
        <path d="M688.879 256.348H671.006V257.046H688.879V256.348Z" fill="black" />
        <path d="M688.879 264.312H671.006V265.011H688.879V264.312Z" fill="black" />
        <path d="M688.879 241.132V240.434H682.874H671.006V241.132H682.874H688.879Z" fill="black" />
        <path d="M688.879 248.952V248.254H672.821H671.006V248.952H672.821H688.879Z" fill="black" />
        <path d="M721.133 248.952V248.254H705.075H703.26V248.952H705.075H721.133Z" fill="black" />
        <path d="M721.133 256.348H703.26V257.046H721.133V256.348Z" fill="black" />
        <path d="M721.133 264.312H703.26V265.011H721.133V264.312Z" fill="black" />
        <path d="M721.133 241.132V240.434H715.128H703.26V241.132H715.128H721.133Z" fill="black" />
        <path d="M753.248 248.952V248.254H737.19H735.375V248.952H737.19H753.248Z" fill="black" />
        <path d="M753.248 256.348H735.375V257.046H753.248V256.348Z" fill="black" />
        <path d="M753.248 264.312H735.375V265.011H753.248V264.312Z" fill="black" />
        <path d="M753.248 241.132V240.434H747.244H735.375V241.132H747.244H753.248Z" fill="black" />
        <path d="M785.504 248.952V248.254H769.446H767.631V248.952H769.446H785.504Z" fill="black" />
        <path d="M785.504 256.348H767.631V257.046H785.504V256.348Z" fill="black" />
        <path d="M785.504 264.312H767.631V265.011H785.504V264.312Z" fill="black" />
        <path d="M785.504 241.132V240.434H779.499H767.631V241.132H779.499H785.504Z" fill="black" />
        <path d="M817.619 248.952V248.254H801.561H799.746V248.952H801.561H817.619Z" fill="black" />
        <path d="M817.619 256.348H799.746V257.046H817.619V256.348Z" fill="black" />
        <path d="M817.619 264.312H799.746V265.011H817.619V264.312Z" fill="black" />
        <path d="M817.619 241.132V240.434H811.615H799.746V241.132H811.615H817.619Z" fill="black" />
        <g id="malfunctions">
          {
            malfunctions.length >= 1 && malfunctions.map((malfunction) => (
              <MalfunctionGraphicItem
                onClick={(e) => handleMouseOver(e)}
                onDrag={onDrag}
                onStop={(e, draggableData) => onStop(malfunction, draggableData)}
                key={malfunction.malfunctionId}
                ref={ref}
                value={value}
                onMouseOver={() => handleMouseOver(malfunction)}
                onFocus={() => handleMouseOver(malfunction)}
                malfunction={malfunction}
                disabled={disabled}
              />
            ))
          }
        </g>
      </svg>
    </ReactSVGPanZoom>
  );
});

MalfunctionsSynchronousCondenser.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  onDrag: PropTypes.func.isRequired,
  onStop: PropTypes.func.isRequired,
  malfunctions: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleMouseOver: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default MalfunctionsSynchronousCondenser;

import React, { Component } from "react";
import Section from "components/layout/Section";
import Search from "components/input/Search";

class MalfunctionSearch extends Component {
  render() {
    return (
      <Section>
        <Search />
      </Section>
    );
  }
}

export default MalfunctionSearch;

import {
  GET_CUSTOMER_ACTIVITIES_REQUEST,
  GET_CUSTOMER_ACTIVITIES_SUCCESS,
  GET_CUSTOMER_ACTIVITIES_ERROR,
  GET_ASSESSMENT_ACTIVITIES_REQUEST,
  GET_ASSESSMENT_ACTIVITIES_SUCCESS,
  GET_ASSESSMENT_ACTIVITIES_ERROR,
} from "store/actions";

export function activitesApiReducer(state, action) {
  switch (action.type) {
    case GET_CUSTOMER_ACTIVITIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_CUSTOMER_ACTIVITIES_SUCCESS: {
      const { activities, customerId } = action.payload;
      return {
        ...state,
        customers: {
          ...state.customers,
          [customerId]: activities,
        },
        loading: false,
        error: false,
      };
    }
    case GET_CUSTOMER_ACTIVITIES_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case GET_ASSESSMENT_ACTIVITIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_ASSESSMENT_ACTIVITIES_SUCCESS: {
      const { activities, assessmentId } = action.payload;
      return {
        ...state,
        assessments: {
          ...state.assessments,
          [assessmentId]: activities,
        },
        loading: false,
        error: false,
      };
    }
    case GET_ASSESSMENT_ACTIVITIES_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}

export default activitesApiReducer;

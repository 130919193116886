export const ASSESSMENTS_EDIT = "ASSESSMENTS_EDIT";
export const ASSESSMENTS_CLEAR_EDIT = "ASSESSMENTS_CLEAR_EDIT";

export function editHealthAssessment(assessment) {
  return (dispatch) => {
    dispatch({
      type: ASSESSMENTS_EDIT,
      payload: {
        assessment,
      },
    });
  };
}

export function clearHealthAssessmentDraft() {
  return (dispatch) => {
    dispatch({
      type: ASSESSMENTS_CLEAR_EDIT,
    });
  };
}

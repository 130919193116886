import {
  COMPONENTS_GET_REQUEST,
  COMPONENTS_GET_SUCCESS,
  COMPONENTS_GET_ERROR,
  COMPONENTS_CREATE_REQUEST,
  COMPONENTS_CREATE_SUCCESS,
  COMPONENTS_DELETE_ERROR,
  COMPONENTS_DELETE_REQUEST,
  COMPONENTS_DELETE_SUCCESS,
  COMPONENTS_UPDATE_REQUEST,
  COMPONENTS_UPDATE_SUCCESS,
  COMPONENTS_CREATE_ERROR,
  COMPONENTS_UPDATE_ERROR,
} from "store/actions";

export default function componentsApiReducer(state, action) {
  switch (action.type) {
    case COMPONENTS_GET_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case COMPONENTS_GET_SUCCESS: {
      const { components } = action.payload;
      return {
        ...state,
        components: [
          ...components,
        ],
        loading: false,
        error: false,
      };
    }
    case COMPONENTS_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case COMPONENTS_CREATE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case COMPONENTS_CREATE_SUCCESS: {
      return {
        ...state,
        components: state.components.concat(action.payload),
        modal: {
          display: false,
        },
        loading: false,
        error: false,
      };
    }
    case COMPONENTS_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case COMPONENTS_DELETE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case COMPONENTS_DELETE_SUCCESS: {
      const { componentId } = action.payload;
      const { components } = state;
      return {
        ...state,
        components: components.filter((component) => component.componentId !== componentId),
        modal: {
          display: false,
        },
        loading: false,
        error: false,
      };
    }
    case COMPONENTS_UPDATE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case COMPONENTS_UPDATE_SUCCESS: {
      return {
        ...state,
        modal: {
          display: false,
        },
        loading: false,
        error: false,
      };
    }
    case COMPONENTS_CREATE_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case COMPONENTS_UPDATE_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    default:
      return state;
  }
}

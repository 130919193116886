import React, { useRef, useState, useEffect } from "react";
import {
  INITIAL_VALUE,
  ReactSVGPanZoom,
  TOOL_NONE,
} from "react-svg-pan-zoom";
import MalfunctionGraphicItem from "components/blocks/MalfunctionGraphicItem";
import PropTypes from "prop-types";
import css from "./MalfunctionsFrancisTurbineMiv.module.scss";

const MalfunctionsFrancisTurbineMiv = React.forwardRef(({
  width,
  height,
  onDrag,
  onStop,
  malfunctions,
  handleMouseOver,
  disabled,
}, ref) => {
  const Viewer = useRef(null);
  const [tool, setTool] = useState(TOOL_NONE);
  const [value, setValue] = useState(INITIAL_VALUE);
  const [autoPan] = useState(false);
  const [panOutside] = useState(false);
  const [backgroundColor] = useState("#ffffff");

  useEffect(() => {
    Viewer.current.fitToViewer();
  }, []);

  return (
    <ReactSVGPanZoom
      background={backgroundColor}
      ref={Viewer}
      width={width}
      height={height}
      tool={tool}
      onChangeTool={setTool}
      value={value}
      detectAutoPan={autoPan}
      onChangeValue={setValue}
      preventPanOutside={panOutside}
      miniatureProps={{
        position: "none",
      }}
    >
      <svg
        viewBox="0 0 918 612"
        version="1.1"
      >
        <filter id="dropshadow" width="200%" height="200%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
          <feOffset dx="0" dy="2" result="offsetblur" />
          <feComponentTransfer>
            <feFuncA type="linear" slope="0.2" />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <defs>
          <circle cx="8" cy="9" r="8" filter="url(#dropshadow)" id="default" fill="#C4C4C4" />
          <g filter="url(#dropshadow)" id="malfunction">
            <path d="M27.1004 14.4L29.7004 11.8L26.6004 6.2L23.0004 7.1L20.3004 5.5L19.4004 2H13.0004L12.0004 5.5L9.20039 7.1L5.80039 6.1L2.50039 11.7L5.00039 14.3V17.6L2.40039 20.2L5.50039 25.8L9.00039 24.8L11.7004 26.4L12.6004 30H19.0004L20.0004 26.5L22.8004 24.9L26.3004 25.9L29.6004 20.3L27.0004 17.7L27.1004 14.4Z" fill="#2D4275" />
            <path d="M17.002 9H15.002V18H17.002V9Z" fill="white" />
            <path d="M17.002 20H15.002V22H17.002V20Z" fill="white" />
          </g>
          <g filter="url(#dropshadow)" id="normal">
            <path fill="#2D4275" d="M8,15.75 C7.01042,15.75 6.05339,15.5612 5.12891,15.1836 C4.20443,14.806 3.39062,14.2591 2.6875,13.543 C1.98438,12.8529 1.44401,12.0456 1.06641,11.1211 C0.688802,10.1966 0.5,9.23958 0.5,8.25 C0.5,7.22135 0.695312,6.2513 1.08594,5.33984 C1.48958,4.42839 2.02995,3.63411 2.70703,2.95703 C3.38411,2.27995 4.17839,1.73958 5.08984,1.33594 C6.0013,0.945312 6.97135,0.75 8,0.75 C9.02865,0.75 9.9987,0.945312 10.9102,1.33594 C11.8216,1.72656 12.6159,2.26693 13.293,2.95703 C13.9831,3.63411 14.5234,4.42839 14.9141,5.33984 C15.3047,6.2513 15.5,7.22135 15.5,8.25 C15.5,9.23958 15.3112,10.1966 14.9336,11.1211 C14.556,12.0456 14.0156,12.8529 13.3125,13.543 C12.6094,14.2591 11.7956,14.806 10.8711,15.1836 C9.94661,15.5612 8.98958,15.75 8,15.75 Z" />
            <path fill="#FFF" d="M8.625,7 L8.625,12 L7.375,12 L7.375,7 L8.625,7 Z M8.625,4.5 L8.625,5.75 L7.375,5.75 L7.375,4.5 L8.625,4.5 Z" />
          </g>
          <g filter="url(#dropshadow)" id="normal-highlight">
            <path fill="#15D4F1" d="M8,15.75 C7.01042,15.75 6.05339,15.5612 5.12891,15.1836 C4.20443,14.806 3.39062,14.2591 2.6875,13.543 C1.98438,12.8529 1.44401,12.0456 1.06641,11.1211 C0.688802,10.1966 0.5,9.23958 0.5,8.25 C0.5,7.22135 0.695312,6.2513 1.08594,5.33984 C1.48958,4.42839 2.02995,3.63411 2.70703,2.95703 C3.38411,2.27995 4.17839,1.73958 5.08984,1.33594 C6.0013,0.945312 6.97135,0.75 8,0.75 C9.02865,0.75 9.9987,0.945312 10.9102,1.33594 C11.8216,1.72656 12.6159,2.26693 13.293,2.95703 C13.9831,3.63411 14.5234,4.42839 14.9141,5.33984 C15.3047,6.2513 15.5,7.22135 15.5,8.25 C15.5,9.23958 15.3112,10.1966 14.9336,11.1211 C14.556,12.0456 14.0156,12.8529 13.3125,13.543 C12.6094,14.2591 11.7956,14.806 10.8711,15.1836 C9.94661,15.5612 8.98958,15.75 8,15.75 Z" />
            <path fill="#FFF" d="M8.625,7 L8.625,12 L7.375,12 L7.375,7 L8.625,7 Z M8.625,4.5 L8.625,5.75 L7.375,5.75 L7.375,4.5 L8.625,4.5 Z" />
          </g>
          <g filter="url(#dropshadow)" id="warning" transform="translate(.317 -.142)">
            <polygon fill="#FFD200" points="8.883 .75 13.238 8.25 17.555 15.75 .25 15.75" />
            <path fill="#FFF" d="M9.625,12.625 L9.625,13.875 L8.375,13.875 L8.375,12.625 L9.625,12.625 Z M9.625,5.75 L9.625,11.375 L8.375,11.375 L8.375,5.75 L9.625,5.75 Z" />
          </g>
          <g filter="url(#dropshadow)" id="alert" transform="translate(.317 -.142)">
            <polygon fill="#FF0032" points="8 .5 15.5 4.836 15.5 13.488 8 17.824 .5 13.488 .5 4.836" />
            <path fill="#FFF" d="M8.625,12.375 L8.625,13.625 L7.375,13.625 L7.375,12.375 L8.625,12.375 Z M8.625,5.5 L8.625,11.125 L7.375,11.125 L7.375,5.5 L8.625,5.5 Z" />
          </g>
          <g fill="none" id="add" filter="url(#dropshadow)">
            <path fill="#C4C4C4" d="M15,7.5 C15,8.5417 14.8047,9.5117 14.4141,10.4102 C14.0234,11.3216 13.4831,12.1224 12.793,12.8125 C12.1159,13.4896 11.3216,14.0234 10.4102,14.4141 C9.5117,14.8047 8.5417,15 7.5,15 C6.4583,15 5.4883,14.8047 4.5898,14.4141 C3.6784,14.0234 2.8776,13.4896 2.1875,12.8125 C1.5104,12.1224 0.9766,11.3216 0.5859,10.4102 C0.1953,9.5117 0,8.5417 0,7.5 C0,6.4583 0.1953,5.4883 0.5859,4.5898 C0.9766,3.67839 1.5104,2.88411 2.1875,2.20703 C2.8776,1.51693 3.6784,0.97656 4.5898,0.58594 C5.4883,0.19531 6.4583,0 7.5,0 C8.5417,0 9.5117,0.19531 10.4102,0.58594 C11.3216,0.97656 12.1159,1.51693 12.793,2.20703 C13.4831,2.88411 14.0234,3.67839 14.4141,4.5898 C14.8047,5.4883 15,6.4583 15,7.5 Z" />
            <polygon fill="#FFF" points="8.004 7.01 10.79 7.01 10.79 8.004 8.004 8.004 8.004 10.888 6.996 10.888 6.996 8.004 4.21 8.004 4.21 7.01 6.996 7.01 6.996 4.112 8.004 4.112" />
          </g>
          <g clipPath="url(#clip0)" id="group-add">
            <path d="M15.5911 13.2864L17.2648 11.6765L15.34 8.11769L13.0805 8.62609L11.4068 7.6093L10.8209 5.40625H6.72031L6.1345 7.6093L4.37709 8.62609L2.20124 7.94823L0.109077 11.507L1.69912 13.2016V15.32L0.0253906 17.0146L2.03387 20.4886L4.20972 19.8108L5.88345 20.8276L6.46925 23.1154H10.4862L11.1557 20.9123L12.9131 19.8955L15.089 20.5734L17.1811 17.0146L15.5074 15.32L15.5911 13.2864Z" fill="#202020" />
            <path d="M9.31584 9.8125H8.06055V15.5743H9.31584V9.8125Z" fill="white" />
            <path d="M9.31584 16.8447H8.06055V18.1157H9.31584V16.8447Z" fill="white" />
            <path d="M17.1809 3.88091L17.7667 3.28778L17.0135 2.01679L16.1767 2.18626L15.5909 1.84733L15.4235 1H13.9171L13.6661 1.84733L12.9966 2.18626L12.2434 1.93206L11.4902 3.20305L12.076 3.79618V4.55877L11.4902 5.23663L12.2434 6.50762L13.0803 6.25342L13.6661 6.59235L13.8335 7.43968H15.3398L15.5909 6.59235L16.2604 6.25342L17.0972 6.50762L17.8504 5.23663L17.2646 4.6435L17.1809 3.88091Z" fill="#202020" />
            <path d="M14.8381 2.61035H14.3359V4.72867H14.8381V2.61035Z" fill="white" />
            <path d="M14.8381 5.23633H14.3359V5.74472H14.8381V5.23633Z" fill="white" />
            <path d="M22.6207 8.54106L23.2065 7.94794L22.4533 6.67695L21.6164 6.84641L21.0306 6.50748L20.8632 5.66016H19.3569L19.1058 6.50748L18.52 6.84641L17.7668 6.59221L17.0137 7.8632L17.5995 8.45633V9.21892L17.0137 9.81205L17.7668 11.083L18.6037 10.8288L19.1895 11.1678L19.3569 12.0151H20.8632L21.1143 11.1678L21.7838 10.8288L22.6207 11.083L23.3738 9.81205L22.788 9.21892L22.6207 8.54106Z" fill="#202020" />
            <path d="M20.3615 7.27051H19.8594V9.38882H20.3615V7.27051Z" fill="white" />
            <path d="M20.3615 9.89648H19.8594V10.3201H20.3615V9.89648Z" fill="white" />
          </g>
          <g clipPath="url(#clip0)" id="group-normal">
            <path d="M15.5911 13.2864L17.2648 11.6765L15.34 8.11769L13.0805 8.62609L11.4068 7.6093L10.8209 5.40625H6.72031L6.1345 7.6093L4.37709 8.62609L2.20124 7.94823L0.109077 11.507L1.69912 13.2016V15.32L0.0253906 17.0146L2.03387 20.4886L4.20972 19.8108L5.88345 20.8276L6.46925 23.1154H10.4862L11.1557 20.9123L12.9131 19.8955L15.089 20.5734L17.1811 17.0146L15.5074 15.32L15.5911 13.2864Z" fill="#2D4375" />
            <path d="M9.31584 9.8125H8.06055V15.5743H9.31584V9.8125Z" fill="white" />
            <path d="M9.31584 16.8447H8.06055V18.1157H9.31584V16.8447Z" fill="white" />
            <path d="M17.1809 3.88091L17.7667 3.28778L17.0135 2.01679L16.1767 2.18626L15.5909 1.84733L15.4235 1H13.9171L13.6661 1.84733L12.9966 2.18626L12.2434 1.93206L11.4902 3.20305L12.076 3.79618V4.55877L11.4902 5.23663L12.2434 6.50762L13.0803 6.25342L13.6661 6.59235L13.8335 7.43968H15.3398L15.5909 6.59235L16.2604 6.25342L17.0972 6.50762L17.8504 5.23663L17.2646 4.6435L17.1809 3.88091Z" fill="#2D4375" />
            <path d="M14.8381 2.61035H14.3359V4.72867H14.8381V2.61035Z" fill="white" />
            <path d="M14.8381 5.23633H14.3359V5.74472H14.8381V5.23633Z" fill="white" />
            <path d="M22.6207 8.54106L23.2065 7.94794L22.4533 6.67695L21.6164 6.84641L21.0306 6.50748L20.8632 5.66016H19.3569L19.1058 6.50748L18.52 6.84641L17.7668 6.59221L17.0137 7.8632L17.5995 8.45633V9.21892L17.0137 9.81205L17.7668 11.083L18.6037 10.8288L19.1895 11.1678L19.3569 12.0151H20.8632L21.1143 11.1678L21.7838 10.8288L22.6207 11.083L23.3738 9.81205L22.788 9.21892L22.6207 8.54106Z" fill="#2D4375" />
            <path d="M20.3615 7.27051H19.8594V9.38882H20.3615V7.27051Z" fill="white" />
            <path d="M20.3615 9.89648H19.8594V10.3201H20.3615V9.89648Z" fill="white" />
          </g>
          <g clipPath="url(#clip0)" id="group-warning">
            <path d="M15.5911 13.2864L17.2648 11.6765L15.34 8.11769L13.0805 8.62609L11.4068 7.6093L10.8209 5.40625H6.72031L6.1345 7.6093L4.37709 8.62609L2.20124 7.94823L0.109077 11.507L1.69912 13.2016V15.32L0.0253906 17.0146L2.03387 20.4886L4.20972 19.8108L5.88345 20.8276L6.46925 23.1154H10.4862L11.1557 20.9123L12.9131 19.8955L15.089 20.5734L17.1811 17.0146L15.5074 15.32L15.5911 13.2864Z" fill="#FFD200" />
            <path d="M9.31584 9.8125H8.06055V15.5743H9.31584V9.8125Z" fill="white" />
            <path d="M9.31584 16.8447H8.06055V18.1157H9.31584V16.8447Z" fill="white" />
            <path d="M17.1809 3.88091L17.7667 3.28778L17.0135 2.01679L16.1767 2.18626L15.5909 1.84733L15.4235 1H13.9171L13.6661 1.84733L12.9966 2.18626L12.2434 1.93206L11.4902 3.20305L12.076 3.79618V4.55877L11.4902 5.23663L12.2434 6.50762L13.0803 6.25342L13.6661 6.59235L13.8335 7.43968H15.3398L15.5909 6.59235L16.2604 6.25342L17.0972 6.50762L17.8504 5.23663L17.2646 4.6435L17.1809 3.88091Z" fill="#FFD200" />
            <path d="M14.8381 2.61035H14.3359V4.72867H14.8381V2.61035Z" fill="white" />
            <path d="M14.8381 5.23633H14.3359V5.74472H14.8381V5.23633Z" fill="white" />
            <path d="M22.6207 8.54106L23.2065 7.94794L22.4533 6.67695L21.6164 6.84641L21.0306 6.50748L20.8632 5.66016H19.3569L19.1058 6.50748L18.52 6.84641L17.7668 6.59221L17.0137 7.8632L17.5995 8.45633V9.21892L17.0137 9.81205L17.7668 11.083L18.6037 10.8288L19.1895 11.1678L19.3569 12.0151H20.8632L21.1143 11.1678L21.7838 10.8288L22.6207 11.083L23.3738 9.81205L22.788 9.21892L22.6207 8.54106Z" fill="#FFD200" />
            <path d="M20.3615 7.27051H19.8594V9.38882H20.3615V7.27051Z" fill="white" />
            <path d="M20.3615 9.89648H19.8594V10.3201H20.3615V9.89648Z" fill="white" />
          </g>
          <g clipPath="url(#clip0)" id="group-alert">
            <path d="M15.5911 13.2864L17.2648 11.6765L15.34 8.11769L13.0805 8.62609L11.4068 7.6093L10.8209 5.40625H6.72031L6.1345 7.6093L4.37709 8.62609L2.20124 7.94823L0.109077 11.507L1.69912 13.2016V15.32L0.0253906 17.0146L2.03387 20.4886L4.20972 19.8108L5.88345 20.8276L6.46925 23.1154H10.4862L11.1557 20.9123L12.9131 19.8955L15.089 20.5734L17.1811 17.0146L15.5074 15.32L15.5911 13.2864Z" fill="#FF0032" />
            <path d="M9.31584 9.8125H8.06055V15.5743H9.31584V9.8125Z" fill="white" />
            <path d="M9.31584 16.8447H8.06055V18.1157H9.31584V16.8447Z" fill="white" />
            <path d="M17.1809 3.88091L17.7667 3.28778L17.0135 2.01679L16.1767 2.18626L15.5909 1.84733L15.4235 1H13.9171L13.6661 1.84733L12.9966 2.18626L12.2434 1.93206L11.4902 3.20305L12.076 3.79618V4.55877L11.4902 5.23663L12.2434 6.50762L13.0803 6.25342L13.6661 6.59235L13.8335 7.43968H15.3398L15.5909 6.59235L16.2604 6.25342L17.0972 6.50762L17.8504 5.23663L17.2646 4.6435L17.1809 3.88091Z" fill="#FF0032" />
            <path d="M14.8381 2.61035H14.3359V4.72867H14.8381V2.61035Z" fill="white" />
            <path d="M14.8381 5.23633H14.3359V5.74472H14.8381V5.23633Z" fill="white" />
            <path d="M22.6207 8.54106L23.2065 7.94794L22.4533 6.67695L21.6164 6.84641L21.0306 6.50748L20.8632 5.66016H19.3569L19.1058 6.50748L18.52 6.84641L17.7668 6.59221L17.0137 7.8632L17.5995 8.45633V9.21892L17.0137 9.81205L17.7668 11.083L18.6037 10.8288L19.1895 11.1678L19.3569 12.0151H20.8632L21.1143 11.1678L21.7838 10.8288L22.6207 11.083L23.3738 9.81205L22.788 9.21892L22.6207 8.54106Z" fill="#FF0032" />
            <path d="M20.3615 7.27051H19.8594V9.38882H20.3615V7.27051Z" fill="white" />
            <path d="M20.3615 9.89648H19.8594V10.3201H20.3615V9.89648Z" fill="white" />
          </g>
        </defs>
        <path className={css.cls1} d="M679.6,306H325.1a2.43,2.43,0,0,1-2.4-2.4h0V281.1a2.43,2.43,0,0,1,2.4-2.4h129a2.43,2.43,0,0,0,2.4-2.4h0v-9.4a2.43,2.43,0,0,0-2.4-2.4H287.6a2.43,2.43,0,0,1-2.4-2.4h0v-7.5a2.43,2.43,0,0,1,2.4-2.4h65.7a2.43,2.43,0,0,0,2.4-2.4h0V211.9a2.43,2.43,0,0,0-2.4-2.4H308.8a2.43,2.43,0,0,1-2.4-2.4h0v-35a2.43,2.43,0,0,0-2.4-2.4H276.2a2.37,2.37,0,0,1-2.4-2.4h0V145.8a2.37,2.37,0,0,1,2.4-2.4h47.1a2.43,2.43,0,0,0,2.4-2.4h0v-4.5a2.43,2.43,0,0,1,2.4-2.4h41.7a2.43,2.43,0,0,1,2.4,2.4h0V139a2.43,2.43,0,0,1-2.4,2.4H364a2.37,2.37,0,0,0-2.4,2.4h0v1.9a2.43,2.43,0,0,0,2.4,2.4h5.8a2.43,2.43,0,0,1,2.4,2.4h0V153a2.43,2.43,0,0,1-2.4,2.4H335.4a2.37,2.37,0,0,0-2.4,2.4h0v9.5a2.37,2.37,0,0,0,2.4,2.4H466.2a2.37,2.37,0,0,0,2.4-2.4h0V150.5a2.43,2.43,0,0,1,2.4-2.4h.8a2.43,2.43,0,0,1,2.4,2.4h0v23.1a2.43,2.43,0,0,1-2.4,2.4h-51a2.43,2.43,0,0,0-2.4,2.4h0V313.9a2.43,2.43,0,0,0,2.4,2.4H679.6" />
        <path className={css.cls2} d="M128.9,34c37.7,62.7,80.7,53.1,87.3,53.8" />
        <path className={css.cls2} d="M120.6,59.9c22.6,37.3,58.8,38.4,70.1,36" />
        <path className={css.cls2} d="M567.1,176.4v8.2a2.86,2.86,0,0,1-2.8,2.8H257.8a2.86,2.86,0,0,1-2.8-2.8V113.4a2.8,2.8,0,0,0-2.4-2.8c-7-1.1-27.6-5.6-36.4-22.9" />
        <rect className={css.cls3} x="165.25" y="183.13" width="73.8" height="3.8" />
        <rect className={css.cls4} x="162.45" y="186.95" width="79.3" height="16.3" />
        <circle className={css.cls5} cx="223.3" cy="178.5" r="4.7" />
        <circle className={css.cls5} cx="211.2" cy="178.5" r="4.7" />
        <circle className={css.cls5} cx="193.2" cy="178.5" r="4.7" />
        <circle className={css.cls5} cx="181.1" cy="178.5" r="4.7" />
        <rect className={css.cls6} x="180.88" y="115.38" width="42.2" height="58.4" />
        <rect className={css.cls3} x="223.29" y="165.08" width="10.8" height="4.1" />
        <polygon className={css.cls5} points="223.1 161.8 234.1 161.8 234.1 133 223.1 116 223.1 161.8" />
        <rect className={css.cls3} x="170.2" y="165.1" width="10.8" height="4.1" />
        <polygon className={css.cls5} points="181 161.8 170.2 161.8 170.2 133 181 116 181 161.8" />
        <rect className={css.cls6} x="180.88" y="111.05" width="42.2" height="4.3" />
        <rect className={css.cls3} x="214.59" y="86.93" width="1.8" height="24.2" />
        <path className={css.cls6} d="M215.4,104.2h0a3,3,0,0,0,3-3V92a3,3,0,0,0-3-3h0a3,3,0,0,0-3,3v9.2A3.08,3.08,0,0,0,215.4,104.2Z" />
        <rect className={css.cls3} x="187.92" y="95.41" width="1.8" height="15.4" />
        <path className={css.cls6} d="M188.8,107.1h0a3,3,0,0,0,3-3v-3.7a3,3,0,0,0-3-3h0a3,3,0,0,0-3,3v3.7A3,3,0,0,0,188.8,107.1Z" />
        <circle className={css.cls6} cx="366.6" cy="144.6" r="17.9" />
        <path className={css.cls5} d="M811.6,436.5H296.8a46.35,46.35,0,0,1-27.4-9l-89.9-66.6-.6-.4c7.8-10.8,21.7-20.5,46.5-22.6,23.4-2,36.8-9.5,44.5-18.4l.4.3,44.4,31.7H811.9" />
        <path className={css.cls7} d="M630.6,327.6H750.8a2.37,2.37,0,0,1,2.4,2.4h0v49.1" />
        <path className={css.cls7} d="M620.1,285.9h8.1a2.43,2.43,0,0,1,2.4,2.4v90.8" />
        <rect className={css.cls8} y="142.5" width="42.8" height="209.1" />
        <path className={css.cls9} d="M42.3,67.2V214.7h66a46,46,0,0,1,26.8,8.6l112.1,80.2h0c-7.8,8.7-21.2,16-44.2,18-24.6,2.1-38.5,11.7-46.3,22.4l-.1.1-40.3-29.8h-74v37.3H0" />
        <path className={css.cls5} d="M42.3,67.2V214.7h66a46,46,0,0,1,26.8,8.6l112.1,80.2h0c-7.8,8.7-21.2,16-44.2,18-24.6,2.1-38.5,11.7-46.3,22.4l-.1.1-40.3-29.8h-74v37.3" />
        <path className={css.cls9} d="M169.1,394s-4.6-50.9,56.2-56.1c64.6-5.6,53.6-53.7,53.6-53.7" />
        <path className={css.cls9} d="M146.9,377.8s-4.6-50.9,56.2-56.2c64.6-5.6,53.7-53.7,53.7-53.7" />
        <path className={css.cls10} d="M702.4,58.6h48a2.37,2.37,0,0,1,2.4,2.4h0V79.5a2.43,2.43,0,0,1-2.4,2.4H729.8a2.43,2.43,0,0,0-2.4,2.4v4a2.43,2.43,0,0,0,2.4,2.4h20.6a2.43,2.43,0,0,1,2.4,2.4h0v95a2.43,2.43,0,0,1-2.4,2.4H727.2a2.43,2.43,0,0,0-2.4,2.4v14c0,1.3-1.1,3.2-2.4,3.2h-4.9a2.43,2.43,0,0,0-2.4,2.4h0V248a2.43,2.43,0,0,0,2.4,2.4h42.7a2.43,2.43,0,0,1,2.4,2.4v35.4a2.43,2.43,0,0,1-2.4,2.4H717.4A2.43,2.43,0,0,0,715,293h0v.5a2.43,2.43,0,0,0,2.4,2.4h7.2a2.43,2.43,0,0,1,2.4,2.4h0a2.43,2.43,0,0,1-2.4,2.4h-7.3a2.43,2.43,0,0,0-2.4,2.4h0V306a2.43,2.43,0,0,0,2.4,2.4h12.1a2.43,2.43,0,0,1,2.4,2.4h0v49.4a2.43,2.43,0,0,1-2.4,2.4h-5.6a2.43,2.43,0,0,0-2.4,2.4v8.8" />
        <path className={css.cls3} d="M726.5,381.8h43.9l.1,24.6S744,407.2,739,425Z" />
        <path className={css.cls3} d="M644.1,425.1c-3.9-17.9-28.5-18.9-31.5-18.6l.1-24.6h45.5l-14.1,43.2" />
        <path className={css.cls11} d="M739,425.1l11.8,91.2H632.2L644,425.1" />
        <rect className={css.cls6} x="756.2" y="223.2" width="22.3" height="79" />
        <rect className={css.cls6} x="778.6" y="223.2" width="22.3" height="79" />
        <line className={css.cls9} x1="783.3" y1="236.7" x2="796.2" y2="236.7" />
        <line className={css.cls9} x1="783.3" y1="241" x2="796.2" y2="241" />
        <line className={css.cls9} x1="783.3" y1="245.4" x2="796.2" y2="245.4" />
        <line className={css.cls9} x1="783.3" y1="249.7" x2="796.2" y2="249.7" />
        <line className={css.cls9} x1="778.6" y1="262.8" x2="801" y2="262.8" />
        <rect className={css.cls6} x="801" y="223.2" width="22.3" height="79" />
        <line className={css.cls9} x1="805.7" y1="236.7" x2="818.6" y2="236.7" />
        <line className={css.cls9} x1="805.7" y1="241" x2="818.6" y2="241" />
        <line className={css.cls9} x1="805.7" y1="245.4" x2="818.6" y2="245.4" />
        <line className={css.cls9} x1="805.7" y1="249.7" x2="818.6" y2="249.7" />
        <line className={css.cls9} x1="801" y1="262.8" x2="823.3" y2="262.8" />
        <rect className={css.cls6} x="823.3" y="223.2" width="22.3" height="79" />
        <line className={css.cls9} x1="828" y1="236.7" x2="840.9" y2="236.7" />
        <line className={css.cls9} x1="828" y1="241" x2="840.9" y2="241" />
        <line className={css.cls9} x1="828" y1="245.4" x2="840.9" y2="245.4" />
        <line className={css.cls9} x1="828" y1="249.7" x2="840.9" y2="249.7" />
        <line className={css.cls9} x1="823.3" y1="262.8" x2="845.7" y2="262.8" />
        <rect className={css.cls6} x="845.7" y="223.2" width="22.3" height="79" />
        <line className={css.cls9} x1="850.4" y1="236.7" x2="863.3" y2="236.7" />
        <line className={css.cls9} x1="850.4" y1="241" x2="863.3" y2="241" />
        <line className={css.cls9} x1="850.4" y1="245.4" x2="863.3" y2="245.4" />
        <line className={css.cls9} x1="850.4" y1="249.7" x2="863.3" y2="249.7" />
        <line className={css.cls9} x1="783.3" y1="275.7" x2="796.2" y2="275.7" />
        <line className={css.cls9} x1="783.3" y1="280" x2="796.2" y2="280" />
        <line className={css.cls9} x1="783.3" y1="284.4" x2="796.2" y2="284.4" />
        <line className={css.cls9} x1="783.3" y1="288.7" x2="796.2" y2="288.7" />
        <line className={css.cls9} x1="761" y1="236.7" x2="773.9" y2="236.7" />
        <line className={css.cls9} x1="761" y1="241" x2="773.9" y2="241" />
        <line className={css.cls9} x1="761" y1="245.4" x2="773.9" y2="245.4" />
        <line className={css.cls9} x1="761" y1="249.7" x2="773.9" y2="249.7" />
        <line className={css.cls9} x1="756.2" y1="262.8" x2="778.6" y2="262.8" />
        <line className={css.cls9} x1="761" y1="275.7" x2="773.9" y2="275.7" />
        <line className={css.cls9} x1="761" y1="280" x2="773.9" y2="280" />
        <line className={css.cls9} x1="761" y1="284.4" x2="773.9" y2="284.4" />
        <line className={css.cls9} x1="761" y1="288.7" x2="773.9" y2="288.7" />
        <line className={css.cls9} x1="805.7" y1="275.7" x2="818.6" y2="275.7" />
        <line className={css.cls9} x1="805.7" y1="280" x2="818.6" y2="280" />
        <line className={css.cls9} x1="805.7" y1="284.4" x2="818.6" y2="284.4" />
        <line className={css.cls9} x1="805.7" y1="288.7" x2="818.6" y2="288.7" />
        <line className={css.cls9} x1="828" y1="275.7" x2="840.9" y2="275.7" />
        <line className={css.cls9} x1="828" y1="280" x2="840.9" y2="280" />
        <line className={css.cls9} x1="828" y1="284.4" x2="840.9" y2="284.4" />
        <line className={css.cls9} x1="828" y1="288.7" x2="840.9" y2="288.7" />
        <line className={css.cls9} x1="850.4" y1="275.7" x2="863.3" y2="275.7" />
        <line className={css.cls9} x1="850.4" y1="280" x2="863.3" y2="280" />
        <line className={css.cls9} x1="850.4" y1="284.4" x2="863.3" y2="284.4" />
        <line className={css.cls9} x1="850.4" y1="288.7" x2="863.3" y2="288.7" />
        <line className={css.cls9} x1="845.7" y1="262.8" x2="868" y2="262.8" />
        <rect className={css.cls6} x="868" y="223.2" width="22.3" height="79" />
        <line className={css.cls9} x1="872.7" y1="236.7" x2="885.6" y2="236.7" />
        <line className={css.cls9} x1="872.7" y1="241" x2="885.6" y2="241" />
        <line className={css.cls9} x1="872.7" y1="245.4" x2="885.6" y2="245.4" />
        <line className={css.cls9} x1="872.7" y1="249.7" x2="885.6" y2="249.7" />
        <line className={css.cls9} x1="872.7" y1="275.7" x2="885.6" y2="275.7" />
        <line className={css.cls9} x1="872.7" y1="280" x2="885.6" y2="280" />
        <line className={css.cls9} x1="872.7" y1="284.4" x2="885.6" y2="284.4" />
        <line className={css.cls9} x1="872.7" y1="288.7" x2="885.6" y2="288.7" />
        <line className={css.cls9} x1="868" y1="262.8" x2="890.3" y2="262.8" />
        <line className={css.cls9} x1="654.8" y1="381.8" x2="728" y2="381.8" />
        <rect className={css.cls3} x="663.8" y="338.1" width="7.2" height="20.1" />
        <rect className={css.cls3} x="711.8" y="338.1" width="7.2" height="20.1" />
        <rect className={css.cls4} x="674.5" y="338.1" width="33.6" height="25.1" />
        <rect className={css.cls3} x="656.3" y="278.6" width="19.3" height="5.7" />
        <rect className={css.cls6} x="679.6" y="275.1" width="23.6" height="63.1" />
        <rect className={css.cls6} x="679.6" y="202.6" width="23.6" height="53.8" />
        <rect className={css.cls6} x="679.6" y="363.2" width="23.6" height="10.6" />
        <rect className={css.cls4} x="664.5" y="71.8" width="53.6" height="25.8" />
        <rect className={css.cls3} x="653.8" y="76.8" width="7.2" height="20.1" />
        <rect className={css.cls3} x="721.8" y="76.8" width="7.2" height="20.1" />
        <rect className={css.cls3} x="653.8" y="182.5" width="7.2" height="20.1" />
        <rect className={css.cls3} x="721.8" y="182.5" width="7.2" height="20.1" />
        <rect className={css.cls6} x="672.4" y="65.3" width="37.9" height="6.5" />
        <rect className={css.cls6} x="674.5" y="97.6" width="33.6" height="8" />
        <rect className={css.cls6} x="674.5" y="176.1" width="33.6" height="6.5" />
        <rect className={css.cls4} x="664.5" y="182.5" width="53.6" height="20.1" />
        <rect className={css.cls3} x="559.3" y="105.6" width="265.1" height="70.4" />
        <path className={css.cls9} d="M632.2,516.3a95.6,95.6,0,0,0,20.2,59.3" />
        <ellipse className={css.cls11} cx="810.8" cy="394.1" rx="42.3" ry="42.5" />
        <path className={css.cls9} d="M750.7,516.3a8.61,8.61,0,0,0,10,6.9" />
        <rect className={css.cls4} x="564.8" y="113" width="4.7" height="55.6" />
        <rect className={css.cls4} x="573.6" y="113" width="7.2" height="55.6" />
        <rect className={css.cls4} x="585" y="113" width="9.8" height="55.6" />
        <rect className={css.cls4} x="599" y="113" width="12.3" height="55.6" />
        <rect className={css.cls4} x="615.5" y="113" width="14.8" height="55.6" />
        <rect className={css.cls4} x="634.5" y="113" width="17.4" height="55.6" />
        <rect className={css.cls4} x="656.1" y="113" width="19.9" height="55.6" />
        <rect className={css.cls4} x="680.2" y="113" width="22.4" height="55.6" />
        <rect className={css.cls4} x="813.6" y="113" width="4.7" height="55.6" />
        <rect className={css.cls4} x="802.2" y="113" width="7.2" height="55.6" />
        <rect className={css.cls4} x="788.2" y="113" width="9.8" height="55.6" />
        <rect className={css.cls4} x="771.7" y="113" width="12.3" height="55.6" />
        <rect className={css.cls4} x="752.7" y="113" width="14.8" height="55.6" />
        <rect className={css.cls4} x="731.2" y="113" width="17.4" height="55.6" />
        <rect className={css.cls4} x="707.1" y="113" width="19.9" height="55.6" />
        <rect className={css.cls4} x="679.6" y="51.6" width="23.6" height="13.8" />
        <rect className={css.cls4} x="688.6" y="39" width="5.7" height="12.6" />
        <path className={css.cls12} d="M511.8,231.8h1.6a5.8,5.8,0,0,1,5.8,5.8h0v47.6a2.8,2.8,0,0,1-2.8,2.8h-7.5a2.8,2.8,0,0,1-2.8-2.8h0V237.6A5.66,5.66,0,0,1,511.8,231.8Z" />
        <path className={css.cls5} d="M547,235.2h0a7.38,7.38,0,0,1,7.4,7.4h0V257a7.38,7.38,0,0,1-7.4,7.4h0a7.38,7.38,0,0,1-7.4-7.4V242.7A7.47,7.47,0,0,1,547,235.2Z" />
        <line className={css.cls9} x1="542.8" y1="247.1" x2="542.8" y2="258.8" />
        <line className={css.cls9} x1="545.6" y1="247.8" x2="545.6" y2="259.5" />
        <line className={css.cls9} x1="548.5" y1="247.8" x2="548.5" y2="259.5" />
        <line className={css.cls9} x1="551.3" y1="247.1" x2="551.3" y2="258.8" />
        <path className={css.cls5} d="M566.7,235.2h0a7.38,7.38,0,0,1,7.4,7.4V257a7.38,7.38,0,0,1-7.4,7.4h0a7.38,7.38,0,0,1-7.4-7.4h0V242.7a7.47,7.47,0,0,1,7.4-7.5Z" />
        <line className={css.cls9} x1="562.5" y1="247.1" x2="562.5" y2="258.8" />
        <line className={css.cls9} x1="565.3" y1="247.8" x2="565.3" y2="259.5" />
        <line className={css.cls9} x1="568.1" y1="247.8" x2="568.1" y2="259.5" />
        <line className={css.cls9} x1="571" y1="247.1" x2="571" y2="258.8" />
        <rect className={css.cls6} x="520.4" y="258.9" width="105.7" height="31.7" />
        <rect className={css.cls6} x="520.4" y="216.2" width="13.5" height="22.9" />
        <polygon className={css.cls5} points="520.4 230.8 512.6 283.5 520.4 283.5 520.4 230.8" />
        <path className={css.cls4} d="M512.6,222.3h0a2.43,2.43,0,0,1,2.4,2.4v4.7a2.43,2.43,0,0,1-2.4,2.4h0a2.43,2.43,0,0,1-2.4-2.4h0v-4.7a2.31,2.31,0,0,1,2.4-2.4Z" />
        <line className={css.cls9} x1="527.1" y1="264.5" x2="527.1" y2="285.9" />
        <rect className={css.cls9} x="599.5" y="249.1" width="1.4" height="9.8" />
        <circle className={css.cls4} cx="600.5" cy="245.6" r="4.2" />
        <line className={css.cls13} x1="599.2" y1="246.4" x2="600.5" y2="245.6" />
        <path className={css.cls12} d="M530.2,239.3a3.13,3.13,0,0,1-6.2.8v-.9h6.2Z" />
        <path className={css.cls9} d="M527.1,242.8v6.6a5.85,5.85,0,0,0,5.7,5.8h0a5.88,5.88,0,0,0,3.6-1.3l3.1-2.5" />
        <rect className={css.cls3} x="588" y="244.7" width="2.8" height="7.4" />
        <path className={css.cls12} d="M586.6,241.4h2.8a2.26,2.26,0,0,1,2.3,2.3h0a2.26,2.26,0,0,1-2.3,2.3h-2.8a2.26,2.26,0,0,1-2.3-2.3h0A2.26,2.26,0,0,1,586.6,241.4Z" />
        <rect className={css.cls12} x="613.6" y="253.6" width="7.7" height="5.1" />
        <circle className={css.cls5} cx="617.4" cy="249" r="6.4" />
        <rect className={css.cls4} x="520.4" y="290.7" width="7.2" height="4.5" />
        <rect className={css.cls4} x="618.9" y="290.7" width="7.2" height="4.5" />
        <circle className={css.cls12} cx="543.9" cy="275.1" r="6" />
        <circle className={css.cls12} cx="565.9" cy="275.1" r="6" />
        <circle className={css.cls12} cx="587.8" cy="275.1" r="6" />
        <circle className={css.cls12} cx="609.8" cy="275.1" r="6" />
        <path className={css.cls9} d="M583.4,243.7h0a4.8,4.8,0,0,0-4.8,4.8h0v9.8" />
        <rect className={css.cls12} x="582.5" y="253.3" width="25.4" height="5.5" />
        <rect className={css.cls4} x="661" y="265.7" width="60.8" height="9.3" />
        <rect className={css.cls3} x="707.1" y="278.6" width="19.3" height="5.7" />
        <rect className={css.cls4} x="664.5" y="256.4" width="53.6" height="9.3" />
        <ellipse className={css.cls11} cx="589" cy="394" rx="26.4" ry="26.5" />
        <rect className={css.cls4} x="655.1" y="373.9" width="72.5" height="7.9" />
        <path className={css.cls5} d="M727.6,381.8s1.2,15.2,11.3,24.6v18.7H643.8V406.4h0c10.2-9.4,11.3-24.6,11.3-24.6Z" />
        <path className={css.cls5} d="M669.9,381.8s-.8,15.2-7.3,24.6" />
        <path className={css.cls5} d="M712.8,381.8s.8,15.2,7.3,24.6" />
        <path className={css.cls5} d="M685.5,381.8s-.5,15.2-5,24.6" />
        <path className={css.cls5} d="M697.3,381.8s.5,15.2,4.9,24.6" />
        <rect className={css.cls14} x="643.8" y="406.4" width="95.2" height="18.7" />
        <line className={css.cls9} x1="614.6" y1="406.4" x2="643.8" y2="406.4" />
        <line className={css.cls9} x1="739" y1="406.4" x2="770.5" y2="406.4" />
        <rect className={css.cls15} x="620.5" y="403.9" width="19.7" height="2.2" />
        <rect className={css.cls15} x="620.5" y="381.8" width="19.7" height="2.2" />
        <rect className={css.cls15} x="743.1" y="403.9" width="19.7" height="2.2" />
        <rect className={css.cls15} x="743.1" y="381.9" width="19.7" height="2.2" />
        <polygon className={css.cls4} points="746.3 379.9 759.7 379.9 759.7 409.3 746.3 415.3 746.3 379.9" />
        <polyline className={css.cls4} points="637.3 402.6 637.3 415.3 623.9 409.3 623.9 379.9 637.3 379.9 637.3 402.6" />
        <line className={css.cls9} x1="42.8" y1="314.3" x2="42.8" y2="313.3" />
        <line className={css.cls16} x1="42.8" y1="311.2" x2="42.8" y2="303.9" />
        <line className={css.cls17} x1="42.8" y1="302.9" x2="42.8" y2="216.7" />
        <line className={css.cls9} x1="42.8" y1="215.7" x2="42.8" y2="214.7" />
        <polyline className={css.cls4} points="68.6 238.4 68.6 323.9 57.5 323.9 57.5 146 68.6 146 68.6 238.4" />
        <polyline className={css.cls4} points="92.5 238.4 92.5 323.9 81.4 323.9 81.4 146 92.5 146 92.5 238.4" />
        <line className={css.cls9} x1="42.8" y1="142.4" y2="142.4" />
        <rect className={css.cls18} x="361" y="351.6" width="144.8" height="85" />
        <ellipse className={css.cls4} cx="435.6" cy="394.9" rx="52.9" ry="53.1" />
        <line className={css.cls19} x1="398.3" y1="357.5" x2="473.2" y2="432.3" />
        <line className={css.cls20} x1="398.3" y1="357.5" x2="398.3" y2="357.5" />
        <line className={css.cls21} x1="401.2" y1="360.3" x2="471.7" y2="430.9" />
        <line className={css.cls20} x1="473.2" y1="432.3" x2="473.2" y2="432.3" />
        <line className={css.cls19} x1="398.3" y1="432.3" x2="473.2" y2="357.5" />
        <line className={css.cls20} x1="398.3" y1="432.3" x2="398.3" y2="432.3" />
        <line className={css.cls21} x1="401.2" y1="429.5" x2="471.7" y2="358.9" />
        <line className={css.cls20} x1="473.2" y1="357.5" x2="473.2" y2="357.5" />
        <circle className={css.cls3} cx="435.6" cy="394.9" r="17.7" />
        <path className={css.cls6} d="M400.1,532h13.4V420.6a7,7,0,0,1-6.8,5,6.64,6.64,0,0,1-6.6-5Z" />
        <path className={css.cls4} d="M393.1,533.3a13.8,13.8,0,0,1,27.6,0Z" />
        <polygon className={css.cls22} points="410 424.6 442.7 402.2 428.6 387 401.9 413.9 410 424.6" />
        <circle className={css.cls4} cx="435.6" cy="394.5" r="10.4" />
        <circle className={css.cls4} cx="406.9" cy="418.7" r="6.9" />
        <line className={css.cls20} x1="502.9" y1="352" x2="502.9" y2="352.1" />
        <line className={css.cls23} x1="502.9" y1="358" x2="502.9" y2="433.1" />
        <line className={css.cls20} x1="502.9" y1="436" x2="502.9" y2="436.1" />
        <line className={css.cls20} x1="522.8" y1="352" x2="522.8" y2="352.1" />
        <line className={css.cls23} x1="522.8" y1="358" x2="522.8" y2="433.1" />
        <line className={css.cls20} x1="522.8" y1="436" x2="522.8" y2="436.1" />
        <rect className={css.cls4} x="508.2" y="347.4" width="9.2" height="93.4" />
        <rect className={css.cls4} x="503.4" y="350" width="4.9" height="88.1" />
        <rect className={css.cls4} x="517.5" y="350" width="4.8" height="88.1" />
        <line className={css.cls20} x1="348.8" y1="352" x2="348.8" y2="352.1" />
        <line className={css.cls23} x1="348.8" y1="358" x2="348.8" y2="433.1" />
        <line className={css.cls20} x1="348.8" y1="436" x2="348.8" y2="436.1" />
        <line className={css.cls20} x1="368.6" y1="352" x2="368.6" y2="352.1" />
        <line className={css.cls23} x1="368.6" y1="358" x2="368.6" y2="433.1" />
        <line className={css.cls20} x1="368.6" y1="436" x2="368.6" y2="436.1" />
        <rect className={css.cls4} x="354.1" y="347.4" width="9.2" height="93.4" />
        <rect className={css.cls4} x="349.2" y="350" width="4.9" height="88.1" />
        <rect className={css.cls4} x="363.3" y="350" width="4.9" height="88.1" />
        <rect className={css.cls3} x="455.8" y="228.5" width="2.8" height="25" />
        <path className={css.cls12} d="M457.2,225.2h9.7a2.26,2.26,0,0,1,2.3,2.3h0a2.26,2.26,0,0,1-2.3,2.3h-9.7a2.26,2.26,0,0,1-2.3-2.3h0A2.26,2.26,0,0,1,457.2,225.2Z" />
        <path className={css.cls6} d="M384.7,251.8h68.5a17.52,17.52,0,0,1,17.5,17.5v4.5a17.52,17.52,0,0,1-17.5,17.5H384.7a17.52,17.52,0,0,1-17.5-17.5v-4.5A17.52,17.52,0,0,1,384.7,251.8Z" />
        <polygon className={css.cls6} points="405.4 247.6 388.1 247.6 388.9 243.3 404.5 243.3 405.4 247.6" />
        <rect className={css.cls12} x="386.6" y="248.1" width="42.8" height="3.7" />
        <path className={css.cls12} d="M434,251.7a3.13,3.13,0,1,1,6.2-.8h0v.8Z" />
        <path className={css.cls9} d="M437.1,245.5h4a2.48,2.48,0,0,0,2.5-2.5h0v-3.2" />
        <rect className={css.cls4} x="383.5" y="291.3" width="7.2" height="4.5" />
        <rect className={css.cls4} x="447.3" y="291.3" width="7.2" height="4.5" />
        <rect className={css.cls9} x="446.3" y="241.6" width="1.4" height="9.8" />
        <circle className={css.cls4} cx="447.3" cy="238.1" r="4.2" />
        <line className={css.cls13} x1="446" y1="238.9" x2="447.3" y2="238.1" />
        <path className={css.cls5} d="M375.8,235.5h0a7.38,7.38,0,0,1,7.4-7.4h24.1a7.38,7.38,0,0,1,7.4,7.4h0a7.38,7.38,0,0,1-7.4,7.4H383.2a7.45,7.45,0,0,1-7.4-7.4Z" />
        <line className={css.cls9} x1="386.6" y1="239.7" x2="409.1" y2="239.7" />
        <line className={css.cls9} x1="387.5" y1="236.9" x2="410.4" y2="236.9" />
        <line className={css.cls9} x1="387.5" y1="234.1" x2="410.4" y2="234.1" />
        <line className={css.cls9} x1="386.6" y1="231.2" x2="409.1" y2="231.2" />
        <path className={css.cls6} d="M377.5,225.9h3.1a3.8,3.8,0,0,1,3.8,3.8v11.7a3.8,3.8,0,0,1-3.8,3.8h-3.1a3.8,3.8,0,0,1-3.8-3.8V229.7A3.8,3.8,0,0,1,377.5,225.9Z" />
        <path className={css.cls6} d="M407.5,206.8h20.1a1.69,1.69,0,0,1,1.7,1.7h0a1.69,1.69,0,0,1-1.7,1.7H407.5v-3.4Z" />
        <polygon className={css.cls6} points="425.4 226.3 411.4 226.3 410.4 213.7 426.4 213.7 425.4 226.3" />
        <polygon className={css.cls6} points="411.4 206.2 425.4 206.2 426.4 213.3 410.4 213.3 411.4 206.2" />
        <path className={css.cls6} d="M405.9,203.9h0a2.26,2.26,0,0,1,2.3,2.3v5.2a2.26,2.26,0,0,1-2.3,2.3h0a2.26,2.26,0,0,1-2.3-2.3v-5.2A2.33,2.33,0,0,1,405.9,203.9Z" />
        <polygon className={css.cls6} points="428.6 248.1 408.1 248.1 409.1 225.8 427.6 225.8 428.6 248.1" />
        <path className={css.cls9} d="M429.3,208.5h5.3a2.48,2.48,0,0,1,2.5,2.5v40.8" />
        <polygon className={css.cls6} points="484.2 160.2 457.4 160.2 458.7 153.6 482.9 153.6 484.2 160.2" />
        <path className={css.cls5} d="M449.9,129.9h37.4a11.5,11.5,0,0,1,11.5,11.5h0a11.5,11.5,0,0,1-11.5,11.5H449.9a11.5,11.5,0,0,1-11.5-11.5h0A11.44,11.44,0,0,1,449.9,129.9Z" />
        <line className={css.cls9} x1="455" y1="148" x2="489.9" y2="148" />
        <line className={css.cls9} x1="456.4" y1="143.6" x2="491.9" y2="143.6" />
        <line className={css.cls9} x1="456.4" y1="139.2" x2="491.9" y2="139.2" />
        <line className={css.cls9} x1="455" y1="134.8" x2="489.9" y2="134.8" />
        <path className={css.cls6} d="M441,126.5h4.9a5.85,5.85,0,0,1,5.9,5.9v18.1a5.85,5.85,0,0,1-5.9,5.9H441a5.85,5.85,0,0,1-5.9-5.9V132.4A5.85,5.85,0,0,1,441,126.5Z" />
        <path className={css.cls9} d="M352,134h17.7a2.43,2.43,0,0,1,2.4,2.4h0v2.5a2.43,2.43,0,0,1-2.4,2.4h-5.8a2.37,2.37,0,0,0-2.4,2.4h0v1.9a2.43,2.43,0,0,0,2.4,2.4h5.8a2.43,2.43,0,0,1,2.4,2.4h0v2.5a2.43,2.43,0,0,1-2.4,2.4H352.5" />
        <circle className={css.cls9} cx="366.6" cy="144.6" r="17.9" />
        <g id="malfunctions">
          {
            malfunctions.length >= 1 && malfunctions.map((malfunction) => (
              <MalfunctionGraphicItem
                onClick={(e) => handleMouseOver(e)}
                onDrag={onDrag}
                onStop={(e, draggableData) => onStop(malfunction, draggableData)}
                key={malfunction.malfunctionId}
                ref={ref}
                value={value}
                onMouseOver={() => handleMouseOver(malfunction)}
                onFocus={() => handleMouseOver(malfunction)}
                malfunction={malfunction}
                disabled={disabled}
              />
            ))
          }
        </g>
      </svg>
    </ReactSVGPanZoom>
  );
});

MalfunctionsFrancisTurbineMiv.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  onDrag: PropTypes.func.isRequired,
  onStop: PropTypes.func.isRequired,
  malfunctions: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleMouseOver: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default MalfunctionsFrancisTurbineMiv;

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import i18n from "i18n";
import css from "./ToggleButton.module.scss";

class ToggleButton extends PureComponent {
  render() {
    const {
      onChange, checked, className, id,
    } = this.props;
    return (
      <div
        className={[
          css.container,
          className,
          checked ? css.checked : null,
        ].join(" ")}
      >
        <span>{i18n.t("viewComponents.toggleButton.off")}</span>
        <label htmlFor={id} className={css.switch}>
          <input id={id} onChange={onChange} type="checkbox" checked={checked} />
          <div />
        </label>
        <span>{i18n.t("viewComponents.toggleButton.on")}</span>
      </div>
    );
  }
}

ToggleButton.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

ToggleButton.defaultProps = {
  checked: false,
};

export default ToggleButton;

import React from "react";
import PropTypes from "prop-types";
import i18n from "i18n";
import { GenericMessage } from "./GenericMessage";

function Message(props) {
  const {
    className,
    body,
    user,
    mine,
    createdAt,
    editAction,
    files,
  } = props;
  return (
    <GenericMessage
      className={className}
      timeStamp={createdAt}
      editAction={mine && editAction}
      body={body}
      user={user}
      owner={mine}
      meta={i18n.t("views.activityStream.wroteMessage")}
      files={files}
    />
  );
}

Message.propTypes = {
  className: PropTypes.string,
  body: PropTypes.string,
  user: PropTypes.object,
  mine: PropTypes.bool,
  createdAt: PropTypes.string,
  editAction: PropTypes.func,
  files: PropTypes.array,
};

Message.defaultProps = {};
export default Message;

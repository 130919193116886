import reduceReducers from "reduce-reducers";
import { activitesApiReducer } from "./api/activities";

const initialState = {
  customers: [],
  assessments: [],
  subscribed: false,
  loading: false,
  error: false,
};

export default reduceReducers(initialState, activitesApiReducer);

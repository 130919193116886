import React, { Component } from "react";
import PropTypes from "prop-types";
import { cardStatus } from "utils/constants";
import i18n from "i18n";
import { getCardStatusTypeClass } from "utils/types";
import css from "./AttachedCards.module.scss";

class AttachedCards extends Component {
  renderHeading = () => (
    <div className={css.heading}>
      <span className={["icon-group", css.icon].join(" ")} />
      <div className={css.title}>
        {i18n.t("viewComponents.attachedCards.attachedCardsLabel")}
      </div>
    </div>
  )

  renderStatus() {
    const { cards } = this.props;
    if (cards.total === 0) {
      return <span>{i18n.t("viewComponents.attachedCards.noCardsAvailable")}</span>;
    }
    return (
      <div className={css.status}>
        {cards.riskOfDamage > 0 && (
        <div className={getCardStatusTypeClass(cardStatus.RISK_OF_DAMAGE)}>
          <span
            className={["icon-status_alarm_small", css.countIcon].join(" ")}
          />
          <span className={css.count}>{cards.riskOfDamage}</span>
        </div>
        )}
        {cards.anomalyDetected > 0 && (
        <div className={getCardStatusTypeClass(cardStatus.ANOMALY_DETECTED)}>
          <span
            className={["icon-status_warning_small", css.countIcon].join(
              " ",
            )}
          />
          <span className={css.count}>{cards.anomalyDetected}</span>
        </div>
        )}
        {cards.operational > 0 && (
        <div className={getCardStatusTypeClass(cardStatus.OPERATIONAL)}>
          <span
            className={["icon-status_ok_small", css.countIcon].join(" ")}
          />
          <span className={css.count}>{cards.operational}</span>
        </div>
        )}
        {cards.noStatus > 0 && (
        <div className="noStatus">
          <span
            className={["icon-status_invalid_small", css.countIcon].join(
              " ",
            )}
          />
          <span className={css.count}>{cards.noStatus}</span>
        </div>
        )}
      </div>
    );
  }

  render() {
    const { className } = this.props;
    return (
      <div className={[className]}>
        {this.renderHeading()}
        {this.renderStatus()}
      </div>
    );
  }
}

AttachedCards.propTypes = {
  cards: PropTypes.object,
};
export default AttachedCards;

import React, { Component } from "react";
import PropTypes from "prop-types";
import i18n from "i18n";
import Comment from "components/elements/Comment";
import Activity from "components/elements/Activity";
import Message from "components/elements/Message";
import css from "./MessageList.module.scss";

import Spinner from "../elements/Spinner";

class MessageList extends Component {
  render() {
    const { messages, loading, onEdit } = this.props;
    if (!messages) {
      return loading && <Spinner className={css.Spinner} />;
    }
    return (
      <div className={css.List}>
        {messages.map((entry) => {
          const { type } = entry;
          if (type) {
            switch (type) {
              case "published": {
                const { assessment } = entry;
                const { responsible } = assessment;
                return (
                  <Activity
                    className={css.entry}
                    key={entry.key}
                    user={responsible}
                    meta={(
                      <span>
                        {`${i18n.t("views.activityStream.published")} `}
                        <strong>{assessment.title}</strong>
                      </span>
)}
                    link={`assessments/${assessment.id}`}
                    icon="icon-available_not_in_quantity"
                    {...entry}
                  />
                );
              }
              case "commented": {
                const { assessment, comment, card } = entry;
                const { user } = comment;
                const { malfunction, tag } = card;
                return (
                  <Activity
                    className={css.entry}
                    user={user}
                    key={entry.key}
                    meta={(
                      <span>
                        {`${i18n.t("views.activityStream.commentedOn")} `}
                        <strong>
                          {malfunction ? `${malfunction.name} ` : `${tag} `}
                        </strong>
                        {`${i18n.t("views.activityStream.in")} `}
                        <strong>
                          {assessment.title}
                        </strong>
                      </span>
                    )}
                    link={`assessments/${card.assessmentId}/cards/${card.id}`}
                    {...entry}
                  />
                );
              }
              case "messaged": {
                const { message, key, mine } = entry;
                return (
                  <Message
                    editAction={() => onEdit(message)}
                    className={css.entry}
                    key={key}
                    mine={mine}
                    {...message}
                  />
                );
              }
              case "signedup": {
                const { user, key } = entry;
                return (
                  <Activity
                    className={css.entry}
                    key={key}
                    user={user}
                    meta={(
                      <span>
                        {`${i18n.t("views.activityStream.signedUpFor")} `}
                        <strong>OPL Interact</strong>
                      </span>
                      )}
                    icon="icon-available_not_in_quantity"
                    {...entry}
                  />
                );
              }
              default:
                return null;
            }
          } else {
            const { id } = entry;
            return (
              <Comment
                editAction={() => onEdit(entry)}
                className={css.entry}
                key={id}
                {...entry}
              />
            );
          }
        })}
        {loading && <Spinner className={css.Spinner} />}
      </div>
    );
  }
}

MessageList.propTypes = {
  messages: PropTypes.array,
};

MessageList.defaultProps = {};
export default MessageList;

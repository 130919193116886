import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import css from "./Tag.module.scss";

class Tag extends PureComponent {
  render() {
    const {
      className, type, small, title,
    } = this.props;
    if (!title) {
      return null;
    }
    const classes = [
      className,
      css.tag,
      css[type],
      small ? css.small : null,
    ].join(" ");
    return (
      <div className={classes}>
        <span>{title}</span>
      </div>
    );
  }
}

Tag.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  small: PropTypes.bool,
};

Tag.defaultProps = {};
export default Tag;

import React, { Component } from "react";
import PropTypes from "prop-types";
import css from "./Tabs.module.scss";

function Tab({
  className, onClick, label, activeTab,
}) {
  return (
    <button
      onClick={() => { onClick(label); }}
      className={[css.tab, className, activeTab === label ? css.active : null].join(" ")}
    >
      {label}
    </button>
  );
}

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  }

  constructor(props) {
    super(props);
    const { children } = this.props;
    {
      const { props } = Array.isArray(children) ? children[0] : children;
      const { label } = props;
      this.state = {
        activeTab: label || "",
      };
    }
    this.onClickTabItem = this.onClickTabItem.bind(this);
  }

  onClickTabItem(tab) {
    this.setState({ activeTab: tab });
  }

  render() {
    const {
      onClickTabItem,
      props: {
        children,
      },
      state: {
        activeTab,
      },
    } = this;

    return (
      <>
        <ol className={css.tabs}>
          {children.map((child) => {
            const { label } = child.props;
            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })}
        </ol>
        <>
          {children.map((child) => {
            if (child.props.label !== activeTab) return null;
            return child.props.children;
          })}
        </>
      </>
    );
  }
}

export default Tabs;

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import css from "./Popup.module.scss";

class Popup extends PureComponent {
  componentDidMount() {
    const { onClose } = this.props;
    document.body.classList.add("no-scroll");
    document.addEventListener(
      "keydown",
      (event) => {
        const { key } = event;
        if (key === "Escape") {
          onClose();
        }
      },
      { once: true },
    );
  }

  componentWillUnmount() {
    document.body.classList.remove("no-scroll");
  }

  render() {
    const {
      children,
      className,
      onClose,
      onEdit,
    } = this.props;
    if (!children) {
      return null;
    }
    return (
      <div
        className={[
          css.container,
          className,
        ].join(" ")}
        onClick={onClose}
      >
        <div className={css.inner} onClick={(event) => event.stopPropagation()}>
          <div className={css.buttons}>
            {onEdit && (
              <button onClick={onEdit} className={css.edit}>
                <span className="icon-edit" />
              </button>
            )}
            <button onClick={onClose} className={css.close}>
              <span className="icon-close" />
            </button>
          </div>

          {children}
        </div>
      </div>
    );
  }
}

Popup.propTypes = {
  children: PropTypes.node,
  onEdit: PropTypes.func,
  onClose: PropTypes.func,
};

export default Popup;

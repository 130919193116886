import {
  COMMENTS_UPLOAD_LINK_REQUEST,
  COMMENTS_UPLOAD_LINK_SUCCESS,
  COMMENTS_UPLOAD_LINK_ERROR,
} from "store/actions";

export function uploadCommentsReducer(state, action) {
  switch (action.type) {
    case COMMENTS_UPLOAD_LINK_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case COMMENTS_UPLOAD_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case COMMENTS_UPLOAD_LINK_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}

export default uploadCommentsReducer;

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import css from "./CountStatus.module.scss";

class CountStatus extends PureComponent {
  render() {
    const {
      className, label, status, icon, type,
    } = this.props;
    return (
      <div className={[css[type], css.container, className].join(" ")}>
        {label && (
        <div className={css.title}>
          <span>{label}</span>
        </div>
        )}
        <div className={[css.status, status === 0 ? css.disabled : null].join(" ")}>
          <span className={css.count}>
            {status}
          </span>
          {icon && (
          <span
            className={[`icon-${icon}`, css.icon].join(" ")}
          />
          )}
        </div>
      </div>
    );
  }
}

CountStatus.propTypes = {
  status: PropTypes.number,
  label: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
};

CountStatus.defaultProps = {
  status: 0,
};

export default CountStatus;

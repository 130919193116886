import React, { Component } from "react";
import PropTypes from "prop-types";
import css from "./SnackBar.module.scss";

class SnackBar extends Component {
  componentDidMount() {
    // check if we have a notification that has to disappear
    const {
      sticky, timeStamp, duration, id, onClose,
    } = this.props;
    if (!sticky) {
      const now = Date.now();
      const timeToDisappear = timeStamp + duration;
      const timeDelta = timeToDisappear - now;
      if (timeDelta) {
        setTimeout(() => {
          onClose(id);
        }, timeDelta);
      }
    }
  }

  render() {
    const {
      type, text, title, onClose, id,
    } = this.props;
    return (
      <div className={css.container}>
        <div className={[css.inner, css[type]].join(" ")}>
          <button
            onClick={() => {
              onClose(id);
            }}
            className={css.close}
          >
            <span className="icon-close" />
          </button>

          <div className={css.title}>{title}</div>
          <div className={css.text}>{text}</div>
        </div>
      </div>
    );
  }
}

SnackBar.propTypes = {
  name: PropTypes.string,
};

export default SnackBar;

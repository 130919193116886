import React, { Component } from "react";
import PropTypes from "prop-types";
import css from "./IconHeadline.module.scss";

export default class IconHeadline extends Component {
  render() {
    const {
      className, title, icon, small,
    } = this.props;
    return (
      <div className={[className, small ? css.small : css.normal].join(" ")}>
        {icon && <span className={`${icon} ${css.icon}`} /> }
        {title}
      </div>
    );
  }
}

IconHeadline.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  small: PropTypes.bool,
};

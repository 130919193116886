import {
  ASSESSMENT_CARD_EDIT,
  ASSESSMENT_CARD_CREATE_DRAFT,
  ASSESSMENT_CARD_CLEAR_DRAFT,
  ASSESSMENT_CARD_RESET_DRAFT,
} from "store/actions";

export function assessmentCardsReducer(state, action) {
  switch (action.type) {
    case ASSESSMENT_CARD_EDIT: {
      const { card } = action.payload;
      return {
        ...state,
        draft: card,
      };
    }
    case ASSESSMENT_CARD_CREATE_DRAFT: {
      const { card } = action.payload;
      return {
        ...state,
        draft: card,
      };
    }
    case ASSESSMENT_CARD_RESET_DRAFT: {
      const { defaults } = action.payload;
      return {
        ...state,
        draft: {
          ...state.draft,
          ...defaults,
        },
      };
    }
    case ASSESSMENT_CARD_CLEAR_DRAFT: {
      return {
        ...state,
        draft: null,
      };
    }
    default:
      return state;
  }
}

export default assessmentCardsReducer;

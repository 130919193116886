import React, { Component } from "react";
import PropTypes from "prop-types";
import i18n from "i18n";
import { limitNumber } from "utils/helper";
import css from "./NumberInput.module.scss";

class NumberInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
    };
  }

  handleChange = (event) => {
    const {
      update, id,
      min, max,
    } = this.props;
    const { value } = event.target;
    const parsedValue = Number.parseFloat(value);
    if (parsedValue > max || parsedValue < min) {
      this.setState({ error: true });
    } else if (update) {
      this.setState({ error: false });
      update({ id, value: limitNumber(value, min, max) });
    }
  };

  render() {
    const {
      id,
      value, placeholder, min, max,
    } = this.props;
    const { error } = this.state;
    return (
      <div className={[css.container, error ? css.error : ""].join(" ")}>
        <input
          id={id}
          type="number"
          onChange={this.handleChange}
          placeholder={placeholder}
          className={css.input}
          value={value}
          ref={(ref) => {
            this.ref = ref;
          }}
          min={min}
          max={max}
          step=".01"
        />
        {error && (
          <span className={css.errorText}>
            {`${i18n.t("validation.numberRange")} ${min} - ${max}`}
          </span>
        )}
      </div>
    );
  }
}

NumberInput.propTypes = {
  editable: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.number,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
};

NumberInput.defaultProps = {
  editable: true,
  placeholder: "0.00",
  label: "Label",
  required: false,
  min: 0,
  max: 99999,
};
export default NumberInput;

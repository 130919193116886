import React, { Component } from "react";
import PropTypes from "prop-types";
import css from "./Field.module.scss";

class Field extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    error: PropTypes.string,
    required: PropTypes.bool,
    children: PropTypes.func,
  }

  render() {
    const {
      className, labelClassName, id, required, label, children, onChange, error,
    } = this.props;
    return (
      <div className={className}>
        {
          label && (
          <label htmlFor={id} className={[css.label, labelClassName, error ? css.hasError : null].join(" ")}>
            {`${label} ${required ? "(required)" : ""}`}
          </label>
          )
        }
        { children(id, onChange) }
        <div className={css.error}>
          {error}
        </div>
      </div>
    );
  }
}

export default Field;

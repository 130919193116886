import reduceReducers from "reduce-reducers";
import { customersReducer } from "./customers";
import { customersApiReducer } from "./api/customers";

const initialState = {
  customers: [],
  current: null,
  loading: false,
  error: false,
  showGlossary: false,
};

export default reduceReducers(
  initialState,
  customersReducer,
  customersApiReducer,
);

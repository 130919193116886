import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import css from "./Page.module.scss";

class Page extends PureComponent {
  render() {
    const {
      children,
      header,
      footer,
      className,
      fullWidth,
    } = this.props;
    if (!children) {
      return null;
    }
    return (
      <div
        className={[
          fullWidth ? css.containerFull : css.container,
          className,
        ].join(" ")}
      >
        <div className={css.inner}>
          <div className={css.header}>{header}</div>
          {children}
          <div className={css.footer}>{footer}</div>
        </div>
      </div>
    );
  }
}

Page.propTypes = {
  children: PropTypes.node,
};

export default Page;

import i18n from "i18n";
import { api } from "utils/api";
import { showMessage } from "store/actions/notifications/";
import { closeComponentsModal, updatePlacementList, setPlacementList } from "store/actions/components";
import { getPlacementsWithMalfunctionsName, formatMalfunctionsGroup } from "utils/placements";
import { errorHandler } from "./components";

export const COMPONENTS_PLACEMENT_GET_LIST_ERROR = "COMPONENTS_PLACEMENT_GET_LIST_ERROR";
export const COMPONENTS_PLACEMENT_GET_LIST_REQUEST = "COMPONENTS_PLACEMENT_GET_LIST_REQUEST";
export const COMPONENTS_PLACEMENT_GET_LIST_SUCCESS = "COMPONENTS_PLACEMENT_GET_LIST_SUCCESS";

export const COMPONENTS_PLACEMENT_POST_MALFUNCTION_ERROR = "COMPONENTS_PLACEMENT_POST_MALFUNCTION_ERROR";
export const COMPONENTS_PLACEMENT_POST_MALFUNCTION_REQUEST = "COMPONENTS_PLACEMENT_POST_MALFUNCTION_REQUEST";
export const COMPONENTS_PLACEMENT_POST_MALFUNCTION_SUCCESS = "COMPONENTS_PLACEMENT_POST_MALFUNCTION_SUCCESS";

export const COMPONENTS_PLACEMENT_UPDATE_MALFUNCTION_ERROR = "COMPONENTS_PLACEMENT_UPDATE_MALFUNCTION_ERROR";
export const COMPONENTS_PLACEMENT_UPDATE_MALFUNCTION_SUCCESS = "COMPONENTS_PLACEMENT_UPDATE_MALFUNCTION_SUCCESS";
export const COMPONENTS_PLACEMENT_UPDATE_MALFUNCTION_REQUEST = "COMPONENTS_PLACEMENT_UPDATE_MALFUNCTION_REQUEST";

export const COMPONENTS_PLACEMENT_DELETE_MALFUNCTION_ERROR = "COMPONENTS_PLACEMENT_DELETE_MALFUNCTION_ERROR";
export const COMPONENTS_PLACEMENT_DELETE_MALFUNCTION_SUCCESS = "COMPONENTS_PLACEMENT_DELETE_MALFUNCTION_SUCCESS";
export const COMPONENTS_PLACEMENT_DELETE_MALFUNCTION_REQUEST = "COMPONENTS_PLACEMENT_DELETE_MALFUNCTION_REQUEST";

export const COMPONENTS_PLACEMENT_CREATE_GROUP_MALFUNCTION_ERROR = "COMPONENTS_PLACEMENT_CREATE_GROUP_MALFUNCTION_ERROR";
export const COMPONENTS_PLACEMENT_CREATE_GROUP_MALFUNCTION_SUCCESS = "COMPONENTS_PLACEMENT_CREATE_GROUP_MALFUNCTION_SUCCESS";
export const COMPONENTS_PLACEMENT_CREATE_GROUP_MALFUNCTION_REQUEST = "COMPONENTS_PLACEMENT_CREATE_GROUP_MALFUNCTION_REQUEST";

export const COMPONENTS_PLACEMENT_REMOVE_GROUP_MALFUNCTION_ERROR = "COMPONENTS_PLACEMENT_REMOVE_GROUP_MALFUNCTION_ERROR";
export const COMPONENTS_PLACEMENT_REMOVE_GROUP_MALFUNCTION_SUCCESS = "COMPONENTS_PLACEMENT_REMOVE_GROUP_MALFUNCTION_SUCCESS";
export const COMPONENTS_PLACEMENT_REMOVE_GROUP_MALFUNCTION_REQUEST = "COMPONENTS_PLACEMENT_REMOVE_GROUP_MALFUNCTION_REQUEST";

export const COMPONENTS_PLACEMENT_UPDATE_GROUP_MALFUNCTION_ERROR = "COMPONENTS_PLACEMENT_UPDATE_GROUP_MALFUNCTION_ERROR";
export const COMPONENTS_PLACEMENT_UPDATE_GROUP_MALFUNCTION_SUCCESS = "COMPONENTS_PLACEMENT_UPDATE_GROUP_MALFUNCTION_SUCCESS";
export const COMPONENTS_PLACEMENT_UPDATE_GROUP_MALFUNCTION_REQUEST = "COMPONENTS_PLACEMENT_UPDATE_GROUP_MALFUNCTION_REQUEST";

export const COMPONENTS_PLACEMENT_ADD_MALFUNCTION_TO_GROUP_ERROR = "COMPONENTS_PLACEMENT_ADD_MALFUNCTION_TO_GROUP_ERROR";
export const COMPONENTS_PLACEMENT_ADD_MALFUNCTION_TO_GROUP_SUCCESS = "COMPONENTS_PLACEMENT_ADD_MALFUNCTION_TO_GROUP_SUCCESS";
export const COMPONENTS_PLACEMENT_ADD_MALFUNCTION_TO_GROUP_REQUEST = "COMPONENTS_PLACEMENT_ADD_MALFUNCTION_TO_GROUP_REQUEST";

export const COMPONENTS_PLACEMENT_DELETE_GROUP_MALFUNCTION_ERROR = "COMPONENTS_PLACEMENT_DELETE_GROUP_MALFUNCTION_ERROR";
export const COMPONENTS_PLACEMENT_DELETE_GROUP_MALFUNCTION_SUCCESS = "COMPONENTS_PLACEMENT_DELETE_GROUP_MALFUNCTION_SUCCESS";
export const COMPONENTS_PLACEMENT_DELETE_GROUP_MALFUNCTION_REQUEST = "COMPONENTS_PLACEMENT_DELETE_GROUP_MALFUNCTION_REQUEST";

export const COMPONENTS_PLACEMENT_UPDATE_GROUP_POSITION_ERROR = "COMPONENTS_PLACEMENT_UPDATE_GROUP_POSITION_ERROR";
export const COMPONENTS_PLACEMENT_UPDATE_GROUP_POSITION_SUCCESS = "COMPONENTS_PLACEMENT_UPDATE_GROUP_POSITION_SUCCESS";
export const COMPONENTS_PLACEMENT_UPDATE_GROUP_POSITION_REQUEST = "COMPONENTS_PLACEMENT_UPDATE_GROUP_POSITION_REQUEST";

export function getPlacements(turbineType) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: COMPONENTS_PLACEMENT_GET_LIST_REQUEST,
      });
      const {
        malfunctions: { turbines: { [turbineType]: { componentsMalfunctions: malfunctions } } },
      } = getState();

      const { data, status, statusText } = await api.get(`/malfunctions/positions/turbines/${turbineType}`);

      if (status === 200) {
        const placements = getPlacementsWithMalfunctionsName(data, malfunctions);
        await dispatch({
          type: COMPONENTS_PLACEMENT_GET_LIST_SUCCESS,
          payload: {
            turbineType,
            placements,
          },
        });
        await dispatch(setPlacementList());
      } else {
        throw new Error(statusText);
      }
    } catch (error) {
      const { response } = error;
      if (response.status && response.status === 404) {
        await dispatch({
          type: COMPONENTS_PLACEMENT_GET_LIST_SUCCESS,
          payload: [],
        });
        await dispatch(setPlacementList());
        return;
      }
      dispatch(closeComponentsModal());
      errorHandler(dispatch, response);
      dispatch({
        type: COMPONENTS_PLACEMENT_GET_LIST_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("components.errors.error_get_placements"),
          type: "error",
        }),
      );
    }
  };
}

export function placeMalfunction(malfunction) {
  return async (dispatch) => {
    try {
      dispatch({
        type: COMPONENTS_PLACEMENT_POST_MALFUNCTION_REQUEST,
      });

      const {
        turbineType,
        xAxis,
        yAxis,
        malfunctionId,
        malfunctionName,
      } = malfunction;

      const { data, status, statusText } = await api.post(
        `/malfunctions/${malfunctionId}/positions`,
        {
          turbineType,
          xAxis,
          yAxis,
        },
        {
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
        },
      );

      if (status === 201) {
        dispatch(updatePlacementList({
          malfunction: data,
        }));
        dispatch({
          type: COMPONENTS_PLACEMENT_POST_MALFUNCTION_SUCCESS,
          payload: {
            malfunctionName,
            ...data,
          },
        });
        dispatch(
          showMessage({
            title: i18n.t("global.success"),
            text: i18n.t("components.messages.malfunction_placed_successfully"),
            type: "alert",
          }),
        );
      } else {
        throw new Error(statusText);
      }
    } catch (error) {
      const { response } = error;
      dispatch(closeComponentsModal());
      errorHandler(dispatch, response);
      dispatch({
        type: COMPONENTS_PLACEMENT_POST_MALFUNCTION_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("components.errors.error_place_malfunction"),
          type: "error",
        }),
      );
    }
  };
}

export function upadtePlacement(position) {
  return async (dispatch) => {
    try {
      dispatch({
        type: COMPONENTS_PLACEMENT_UPDATE_MALFUNCTION_REQUEST,
      });
      const {
        id,
        xAxis,
        yAxis,
        turbineType,
      } = position;

      const { data, status, statusText } = await api.put(
        `/malfunctions/positions/${id}`,
        {
          turbineType,
          xAxis,
          yAxis,
        },
        {
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
        },
      );
      if (status === 200) {
        dispatch({
          type: COMPONENTS_PLACEMENT_UPDATE_MALFUNCTION_SUCCESS,
          payload: data,
        });
        dispatch(
          showMessage({
            title: i18n.t("global.success"),
            text: i18n.t("components.messages.placement_list_fetched_successfully"),
            type: "alert",
          }),
        );
      } else {
        throw new Error(statusText);
      }
    } catch (error) {
      const { response } = error;
      dispatch(closeComponentsModal());
      errorHandler(dispatch, response);
      dispatch({
        type: COMPONENTS_PLACEMENT_UPDATE_MALFUNCTION_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("components.errors.error_get_placements"),
          type: "error",
        }),
      );
    }
  };
}

export function deletePlacement({ placementId, malfunctionId, turbineType }) {
  return async (dispatch) => {
    try {
      dispatch({
        type: COMPONENTS_PLACEMENT_DELETE_MALFUNCTION_REQUEST,
      });

      const { status, statusText } = await api.delete(`/malfunctions/positions/${placementId}`, { withCredentials: true });

      if (status === 204) {
        await dispatch({
          type: COMPONENTS_PLACEMENT_DELETE_MALFUNCTION_SUCCESS,
          payload: {
            malfunctionId,
            turbineType,
          },
        });
        await dispatch(setPlacementList());
        await dispatch(
          showMessage({
            title: i18n.t("global.success"),
            text: i18n.t("components.messages.malfunction_deleted_successfully"),
            type: "alert",
          }),
        );
      } else {
        throw new Error(statusText);
      }
    } catch (error) {
      const { response } = error;
      dispatch(closeComponentsModal());
      errorHandler(dispatch, response);
      dispatch({
        type: COMPONENTS_PLACEMENT_DELETE_MALFUNCTION_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("components.errors.error_delete_placement"),
          type: "error",
        }),
      );
    }
  };
}

export function groupMalfunctions(source, target) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: COMPONENTS_PLACEMENT_CREATE_GROUP_MALFUNCTION_REQUEST,
      });

      const { components: { components } } = getState();

      const { data, status, statusText } = await api.post(
        `/turbines/${source.turbineType}/groups`,
        {
          sourceMalfunctionId: source.malfunctionId,
          targetMalfunctionId: target.malfunctionId,
          xAxis: target.xAxis,
          yAxis: target.yAxis,
        },
        {
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
        },
      );
      if (status === 201) {
        const formatted = formatMalfunctionsGroup(data, components);
        await dispatch({
          type: COMPONENTS_PLACEMENT_CREATE_GROUP_MALFUNCTION_SUCCESS,
          payload: {
            target: target.malfunctionId,
            source: source.malfunctionId,
            malfunction: formatted,
            turbineType: source.turbineType,
          },
        });
        dispatch(
          showMessage({
            title: i18n.t("global.success"),
            text: i18n.t("components.messages.placement_list_fetched_successfully"),
            type: "alert",
          }),
        );
      } else {
        throw new Error(statusText);
      }
    } catch (error) {
      const { response } = error;
      console.log(error);
      console.log(response);
      dispatch(closeComponentsModal());
      // errorHandler(dispatch, response);
      dispatch({
        type: COMPONENTS_PLACEMENT_CREATE_GROUP_MALFUNCTION_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: response.data.errors[0].message,
          type: "error",
        }),
      );
    }
  };
}

export function addMalfunctionToGroup(source, group) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: COMPONENTS_PLACEMENT_ADD_MALFUNCTION_TO_GROUP_REQUEST,
      });
      group.malfunctions.push(source);
      const { components: { components } } = getState();
      const { status, statusText } = await api.post(
        `/turbines/${source.turbineType}/groups/${group.groupId}/malfunctions/${source.malfunctionId}`,
        {},
        {
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
        },
      );
      if (status === 201) {
        const formatted = formatMalfunctionsGroup(group, components);
        await dispatch({
          type: COMPONENTS_PLACEMENT_ADD_MALFUNCTION_TO_GROUP_SUCCESS,
          payload: {
            group: formatted,
            source: source.malfunctionId,
            turbineType: source.turbineType,
          },
        });
        await dispatch(setPlacementList());
        await dispatch(
          showMessage({
            title: i18n.t("global.success"),
            text: i18n.t("components.messages.malfunction_added_to_group_successfully"),
            type: "alert",
          }),
        );
      } else {
        throw new Error(statusText);
      }
    } catch (error) {
      const { response } = error;
      console.log(error);
      console.log(response);
      dispatch(closeComponentsModal());
      // errorHandler(dispatch, response);
      dispatch({
        type: COMPONENTS_PLACEMENT_ADD_MALFUNCTION_TO_GROUP_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: response.data.errors[0].message,
          type: "error",
        }),
      );
    }
  };
}

export function deleteGroup(groupId, turbineType) {
  return async (dispatch) => {
    try {
      dispatch({
        type: COMPONENTS_PLACEMENT_DELETE_GROUP_MALFUNCTION_REQUEST,
      });

      const { status } = await api.delete(`/groups/${groupId}`);

      if (status === 204) {
        await dispatch({
          type: COMPONENTS_PLACEMENT_DELETE_GROUP_MALFUNCTION_SUCCESS,
          payload: { groupId, turbineType },
        });
        await dispatch(setPlacementList());
        await dispatch(
          showMessage({
            title: i18n.t("global.success"),
            text: i18n.t("components.messages.group_deleted_successfully"),
            type: "alert",
          }),
        );
      }
    } catch (error) {
      const { response } = error;
      console.log(error);
      console.log(response);
      dispatch(closeComponentsModal());
      // errorHandler(dispatch, response);
      dispatch({
        type: COMPONENTS_PLACEMENT_DELETE_GROUP_MALFUNCTION_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: response.data.errors[0].message,
          type: "error",
        }),
      );
    }
  };
}

export function removeMalfunctionFromGroup(groupId, malfunctionId, turbineType) {
  return async (dispatch) => {
    try {
      dispatch({
        type: COMPONENTS_PLACEMENT_REMOVE_GROUP_MALFUNCTION_REQUEST,
      });

      const { status } = await api.delete(`/groups/${groupId}/malfunctions/${malfunctionId}`);

      if (status === 204) {
        await dispatch(closeComponentsModal());
        await dispatch({
          type: COMPONENTS_PLACEMENT_REMOVE_GROUP_MALFUNCTION_SUCCESS,
          payload: {
            groupId,
            malfunctionId,
            turbineType,
          },
        });
        await dispatch(
          showMessage({
            title: i18n.t("global.success"),
            text: i18n.t("components.malfunctions.grouping.malfuncton_ungrouped"),
            type: "alert",
          }),
        );
      }
    } catch (error) {
      const { response } = error;
      console.log(error);
      console.log(response);
      dispatch(closeComponentsModal());
      // errorHandler(dispatch, response);
      dispatch({
        type: COMPONENTS_PLACEMENT_REMOVE_GROUP_MALFUNCTION_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: response.data.errors[0].message,
          type: "error",
        }),
      );
    }
  };
}

export function updateGroupPosition(group) {
  return async (dispatch) => {
    try {
      const { groupId, turbineType } = group;

      dispatch({
        type: COMPONENTS_PLACEMENT_UPDATE_GROUP_POSITION_REQUEST,
      });

      const { status } = await api.put(
        `/turbines/${turbineType}/groups/${groupId}`,
        group,
        {
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
        },
      );
      if (status === 200) {
        await dispatch({
          type: COMPONENTS_PLACEMENT_UPDATE_GROUP_POSITION_SUCCESS,
          payload: group,
        });
        dispatch(
          showMessage({
            title: i18n.t("global.success"),
            text: i18n.t("components.messages.group_position_updated_successfully"),
            type: "alert",
          }),
        );
      }
    } catch (error) {
      const { response } = error;
      console.log(error);
      console.log(response);
      dispatch(closeComponentsModal());
      // errorHandler(dispatch, response);
      dispatch({
        type: COMPONENTS_PLACEMENT_UPDATE_GROUP_POSITION_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: response.data.errors[0].message,
          type: "error",
        }),
      );
    }
  };
}

/**
 * @fileoverview Screen component for place the malfunction on the graphic
 * @author  Lucas Sena
 */

import React, { Component } from "react";
import i18n from "i18n";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Actions from "store/actions";
import MalfunctionGraphic from "components/blocks/MalfunctionGraphic";
import MalfunctionList from "components/blocks/MalfunctionList";
import Button from "components/elements/Button";
import Spinner from "components/elements/Spinner";
import { turbineTypes } from "utils/constants";
import css from "./Placement.module.scss";

class Placement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
    this.handleSelectSvg = this.handleSelectSvg.bind(this);
  }

  async componentDidMount() {
    const {
      closeModal,
      getPlacements,
      setPlacementList,
      showModal,
      turbineType,
    } = this.props;
    showModal({
      type: "wait",
      message: i18n.t("messages.getPlacements"),
    });
    await setPlacementList();
    await getPlacements(turbineType);

    closeModal();
    this.setState({
      isLoading: false,
    });
  }

  async componentWillUnmount() {
    const {
      closeModal,
      getMalfunctions,
      getPlacements,
      showModal,
      turbineType,
    } = this.props;

    showModal({
      type: "wait",
      message: i18n.t("components.messages.synchronizing_malfunctions"),
    });

    // when leaving this screen, update the state data for the last turbine type
    await getMalfunctions(turbineType);
    await getPlacements(turbineType);

    closeModal();
  }

  async handleSelectSvg(newTurbineType) {
    this.setState({
      isLoading: true,
    });

    const {
      closeModal,
      getPlacements,
      getMalfunctions,
      setTurbineType,
      showModal,
      turbineType,
    } = this.props;
    showModal({
      type: "wait",
      message: i18n.t("messages.getPlacements"),
    });

    // update the state of the malfunctions previous turbine
    await getMalfunctions(turbineType);

    // set the current turbine type selected and obtain its placements
    await setTurbineType(newTurbineType);
    await getPlacements(newTurbineType);

    closeModal();
    this.setState({
      isLoading: false,
    });
  }

  render() {
    const {
      props: {
        loading,
        localLoading,
        turbineType,
      },
      state: {
        isLoading,
      },
      handleSelectSvg,
    } = this;
    return (
      <div className={css.screen}>
        { !isLoading && (
          <>
            { loading ? <Spinner className={css.spinner}>{i18n.t("components.loading")}</Spinner> : (
              <>
                <div className={css.malfunction}>
                  <div className={css.dragComponent}>
                    <MalfunctionList />
                  </div>
                </div>
                <div className={css.svg}>
                  <div className={css.buttons}>
                    { Object.values(turbineTypes).map((type) => (
                      <Button
                        className={[css.btn, turbineType === type ? css.active : null].join(" ")}
                        onClick={() => handleSelectSvg(type)}
                        key={type}
                      >
                        { type.toUpperCase().replace("-", " - ") }
                      </Button>
                    ))}
                  </div>
                  { localLoading ? <Spinner className={css.spinner1}>{i18n.t("components.loading")}</Spinner> : (
                    <MalfunctionGraphic svgPath={turbineType} />
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.components.loading,
    localLoading: state.components.localLoading,
    turbineType: state.components.turbineType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(Actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Placement);

export const allowedTypes = [
  "paragraph",
  "text",
  "break",
  "emphasis",
  "strong",
  "list",
  "listItem",
  "heading",
  "link",
  "table",
  "tableHead",
  "tableBody",
  "tableRow",
  "tableCell",
  "image",
];

export default allowedTypes;

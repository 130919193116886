import { globalHistory } from "@reach/router";
import pathToRegexp from "path-to-regexp";

export function getQueryVariable(variable) {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}

export function getRouteParams() {
  try {
    const { location } = globalHistory;

    let path = "/:customerId/activities";
    let regexp = pathToRegexp(path, []);
    let match = regexp.exec(location.pathname);

    if (match) {
      return {
        customerId: match[1],
        assessmentId: undefined,
        cardId: undefined,
      };
    }

    path = "/:customerId/glossary/edit";
    regexp = pathToRegexp(path, []);
    match = regexp.exec(location.pathname);

    if (match) {
      return {
        customerId: match[1],
        assessmentId: undefined,
        cardId: undefined,
      };
    }

    path = "/:customerId/assessments";
    regexp = pathToRegexp(path, []);
    match = regexp.exec(location.pathname);

    if (match) {
      return {
        customerId: match[1],
        assessmentId: undefined,
        cardId: undefined,
      };
    }

    path = "/:customerId/:assetId/assessments";
    regexp = pathToRegexp(path, []);
    match = regexp.exec(location.pathname);

    if (match) {
      return {
        customerId: match[1],
        assessmentId: undefined,
        cardId: undefined,
      };
    }

    path = "/:customerId/:assetId/:machineId/assessments";
    regexp = pathToRegexp(path, []);
    match = regexp.exec(location.pathname);

    if (match) {
      return {
        customerId: match[1],
        assessmentId: undefined,
        cardId: undefined,
      };
    }

    path = "/:customerId/assessments/:assessmentId";
    regexp = pathToRegexp(path, []);
    match = regexp.exec(location.pathname);

    if (match) {
      return {
        customerId: match[1],
        assessmentId: match[2],
        cardId: undefined,
      };
    }

    path = "/:customerId/assessments/:assessmentId/edit";
    regexp = pathToRegexp(path, []);
    match = regexp.exec(location.pathname);

    if (match) {
      return {
        customerId: match[1],
        assessmentId: match[2],
        cardId: undefined,
      };
    }

    path = "/:customerId/:assetId/assessments/:assessmentId";
    regexp = pathToRegexp(path, []);
    match = regexp.exec(location.pathname);

    if (match) {
      return {
        customerId: match[1],
        assessmentId: match[3],
        cardId: undefined,
      };
    }

    path = "/:customerId/:assetId/assessments/:assessmentId/edit";
    regexp = pathToRegexp(path, []);
    match = regexp.exec(location.pathname);

    if (match) {
      return {
        customerId: match[1],
        assessmentId: match[3],
        cardId: undefined,
      };
    }

    path = "/:customerId/:assetId/:machineId/assessments/:assessmentId";
    regexp = pathToRegexp(path, []);
    match = regexp.exec(location.pathname);

    if (match) {
      return {
        customerId: match[1],
        assessmentId: match[4],
        cardId: undefined,
      };
    }

    path = "/:customerId/:assetId/:machineId/assessments/:assessmentId/edit";
    regexp = pathToRegexp(path, []);
    match = regexp.exec(location.pathname);

    if (match) {
      return {
        customerId: match[1],
        assessmentId: match[4],
        cardId: undefined,
      };
    }

    path = "/:customerId/assessments/:assessmentId/cards/:cardId";
    regexp = pathToRegexp(path, []);
    match = regexp.exec(location.pathname);

    if (match) {
      return {
        customerId: match[1],
        assessmentId: match[2],
        cardId: match[3],
      };
    }

    path = "/:customerId/assessments/:assessmentId/cards/:cardId/edit";
    regexp = pathToRegexp(path, []);
    match = regexp.exec(location.pathname);

    if (match) {
      return {
        customerId: match[1],
        assessmentId: match[2],
        cardId: match[3],
      };
    }

    path = "/:customerId/:assetId/assessments/:assessmentId/cards/:cardId/edit";
    regexp = pathToRegexp(path, []);
    match = regexp.exec(location.pathname);

    if (match) {
      return {
        customerId: match[1],
        assessmentId: match[3],
        cardId: match[4],
      };
    }

    path = "/:customerId/:assetId/:machineId/assessments/:assessmentId/cards/:cardId/edit";
    regexp = pathToRegexp(path, []);
    match = regexp.exec(location.pathname);

    if (match) {
      return {
        customerId: match[1],
        assessmentId: match[4],
        cardId: match[5],
      };
    }

    path = "/:customerId/:assetId/assessments/:assessmentId/cards/:cardId";
    regexp = pathToRegexp(path, []);
    match = regexp.exec(location.pathname);

    if (match) {
      return {
        customerId: match[1],
        assessmentId: match[3],
        cardId: match[4],
      };
    }

    path = "/:customerId/:assetId/:machineId/assessments/:assessmentId/cards/:cardId";
    regexp = pathToRegexp(path, []);
    match = regexp.exec(location.pathname);

    if (match) {
      return {
        customerId: match[1],
        assessmentId: match[4],
        cardId: match[5],
      };
    }

    return {
      customerId: undefined,
      assessmentId: undefined,
      cardId: undefined,
    };
  } catch (error) {
    console.error("getRouteParams: Error parsing url for params");
    return {};
  }
}

export function isAbsolute(url) {
  const regex = /^https?:\/\//i;
  return regex.test(url);
}

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Actions from "store/actions";
import Button from "components/elements/Button";
import i18n from "i18n";
import css from "./ModalWarning.module.scss";

class ModalWarning extends Component {
  render() {
    const {
      text,
      closeComponentsModal,
    } = this.props;
    return (
      <div className={[css.inner, css.action].join(" ")}>
        <button className={css.close}>
          <span className="icon-status_warning" />
        </button>
        <div className={css.title}>
          {i18n.t("components.warning")}
        </div>
        <div className={css.content}>
          <p>{ text }</p>
        </div>
        <div className={css.buttons}>
          <Button className={css.btn} onClick={closeComponentsModal}>
            {i18n.t("components.ok")}
          </Button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    text: state.components.modal.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(Actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalWarning);

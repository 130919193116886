import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, navigate } from "@reach/router";
import { ReactComponent as Logo } from "assets/svgs/voith_logo.svg";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import i18n from "i18n";
import ServiceHoursStatus from "components/elements/ServiceHoursStatus";
import DropDown from "components/input/DropDown";
import { getCurrentDomain } from "utils/environment";
import { recursiveFind } from "utils/helper";
import { selectMalfunctionPage, setPathname } from "store/actions";
import css from "./ApplicationHeader.module.scss";
import { appLayouts } from "../../utils/constants";

class ApplicationHeader extends Component {
  constructor(props) {
    super(props);
    this.handleSelectMalfunction = this.handleSelectMalfunction.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
  }

  componentDidMount() {
    const { setPathname } = this.props;
    setPathname(window.location.pathname);
  }

  handleSelectMalfunction({ value: { item: { id, path } } }) {
    const { props: { selectMalfunctionPage }, handleNavigation } = this;
    selectMalfunctionPage(id);
    handleNavigation(`/${path}`);
  }

  handleNavigation(path) {
    const { setPathname } = this.props;
    setPathname(path);
    navigate(path);
  }

  render() {
    const {
      props: {
        title,
        selectedCustomer,
        editable,
        layout,
        page,
        pathname,
      },
      handleSelectMalfunction,
      handleNavigation,
    } = this;
    if (layout === appLayouts.PRINT) {
      return null;
    }

    const homePath = "/";
    const allowedMalfunctionsDropdown = ["/", "/malfunctions/edit", "/malfunctions/placement"];
    const malfunctionsOptions = [
      {
        id: "edit",
        name: i18n.t("components.edit_malfunctions"),
        path: "malfunctions/edit",
      },
      {
        id: "placement",
        name: i18n.t("components.malfunctions_placement"),
        path: "malfunctions/placement",
      },
    ];

    return (
      <header>
        <div className={css.left}>
          {layout === appLayouts.DEFAULT && (
            <div className={css.home} onClick={() => handleNavigation(homePath)}>
              <Link to="/">
                <span className="icon-home" />
              </Link>
            </div>
          )}
          {layout === appLayouts.DEFAULT && (
            <div className={css.title}>{title}</div>
          )}
          {
            allowedMalfunctionsDropdown.includes(pathname) && editable && (
            <div className={css.selectMalfunction}>
              <span className="icon-malfunctions" />
              <DropDown
                placeholder={i18n.t("components.malfunctions.malfunctions")}
                items={malfunctionsOptions}
                id="select-language"
                update={handleSelectMalfunction}
                className={css.dropdown}
                selected={recursiveFind(malfunctionsOptions, page)}
              />
            </div>
            )
          }
          {
            selectedCustomer
            && layout === appLayouts.DEFAULT
            && !allowedMalfunctionsDropdown.includes(pathname) && (
            <nav className={css.navigation}>
              {
                editable
                && selectedCustomer && (
                <button
                  className={css.navigationItem}
                  onClick={() => {
                    const { id } = selectedCustomer;
                    handleNavigation(`/${id}/settings`);
                  }}
                >
                  <span className="icon-settings_01" />
                  {i18n.t("global.settings")}
                </button>
                )
              }
              <button
                className={css.navigationItem}
                onClick={() => {
                  const { id } = selectedCustomer;
                  handleNavigation(`/${id}/activities`);
                }}
              >
                <span className="icon-notification_warning" />
                {i18n.t("global.activityStream")}
              </button>
            </nav>
            )
        }
          <button
            className={css.navigationItem}
            onClick={() => {
              handleNavigation(`${getCurrentDomain()}/_oauth/logout`);
            }}
          >
            <span className="icon-logout" />
            {i18n.t("global.logout")}
          </button>
        </div>
        <div className={css.right}>
          {selectedCustomer && !selectedCustomer.hideServicehours && layout === appLayouts.DEFAULT
              && (
              <ServiceHoursStatus
                servicehoursLeft={selectedCustomer.servicehoursLeft}
                servicehoursTotal={selectedCustomer.servicehoursTotal}
              />
              )
          }
          <div className={css.logo}>
            <Logo />
          </div>
        </div>
      </header>
    );
  }
}

ApplicationHeader.propTypes = {
  title: PropTypes.string,
};

ApplicationHeader.defaultProps = {
  title: "OPL Interact",
};

function mapStateToProp(state) {
  const {
    app,
    customers,
    auth,
    components,
  } = state;
  return {
    selectedCustomer: customers.current,
    editable: auth.isOPL,
    layout: app.layout,
    page: components.page,
    pathname: app.pathname,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({
      selectMalfunctionPage,
      setPathname,
    }, dispatch),
  };
}

export default connect(
  mapStateToProp,
  mapDispatchToProps,
)(ApplicationHeader);

import React, { Component, Fragment, useEffect } from "react";
import { Location, globalHistory } from "@reach/router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  selectCustomer,
  setCustomerDefaultSettings,
  clearCustomer,
  showModal,
  initApp,
} from "store/actions";
import i18n from "i18n";
import timeout from "utils/asyncTimeOut";
import {
  APP_URL,
  AUTH_REDIRECT_URL,
  APP_ERROR_REDIRECT,
} from "utils/constants";
import { getQueryVariable, getRouteParams } from "utils/url";

export const ScrollToTop = ({ children, location }) => {
  useEffect(() => window.scrollTo({ top: 0, left: 0 }), [location.pathname]);
  return children;
};

class AppHandler extends Component {
  async componentDidMount() {
    i18n.on("initialized", async () => {
      const { initApp } = this.props;
      await initApp();
      this.updateStoreToRouteChanges(globalHistory);
      globalHistory.listen((route) => {
        this.updateStoreToRouteChanges(route);
      });
    });
  }

  async componentDidUpdate() {
    const {
      authError, noAccess, appError, showModal,
    } = this.props;
    if (authError) {
      if (!getQueryVariable("redirect")) {
        showModal({
          type: "wait",
          message: i18n.t("errors.errorAuth"),
        });
        await timeout(3000);
        window.location = `${AUTH_REDIRECT_URL}?next=${APP_URL}`;
      } else {
        showModal({
          type: "wait",
          message: i18n.t("errors.errorGeneral"),
        });
      }
    }
    if (noAccess) {
      showModal({
        type: "wait",
        message: i18n.t("errors.errorForbidden"),
        fixed: true,
      });
      await timeout(500);
      window.location = `${APP_ERROR_REDIRECT}`;
    }
    if (appError) {
      showModal({
        type: "wait",
        message: i18n.t("errors.errorGeneral"),
        fixed: true,
      });
    }
  }

  updateStoreToRouteChanges(route) {
    const {
      selectCustomer, clearCustomer, setCustomerDefaultSettings,
    } = this.props;
    const { location } = route;
    const { customerId } = getRouteParams(location.pathname);
    try {
      selectCustomer(Number.parseInt(customerId, 10));
      setCustomerDefaultSettings({
        glossary: "Nothing here",
      });
    } catch (error) {
      clearCustomer();
      if (route) {
        const { navigate } = route;
        if (navigate) {
          navigate("/");
        }
      }
    }
  }

  render() {
    const {
      header, overlays, children, init,
    } = this.props;
    return (
      <Location>
        {({ location }) => [
          <Fragment key="header">{header}</Fragment>,
          <div key="main" className="desktop app">
            <ScrollToTop location={location}>
              <>{[init && children]}</>
            </ScrollToTop>
          </div>,
          <Fragment key="overlays">{overlays}</Fragment>,
        ]}
      </Location>
    );
  }
}

function mapStateToProps(state) {
  return {
    init: state.app.init,
    appError: state.app.error,
    authError: state.auth.error,
    noAccess: state.app.forbidden,
    isAuthenticated: state.auth.isAuthenticated,
    customersError: state.customers.error,
    assessmentsError: state.assessments.error,
    malfunctions: state.malfunctions.malfunctions,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        selectCustomer, setCustomerDefaultSettings, clearCustomer, showModal, initApp,
      },
      dispatch,
    ),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppHandler);

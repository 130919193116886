import React, { Component } from "react";
import PropTypes from "prop-types";
import HealthStatusIndicator from "components/elements/HealthStatusIndicator";
import i18n from "i18n";
import { getCardStatusTypeClass, getCardTypeIconByType } from "utils/types";
import CardStatus from "components/elements/CardStatus";
import css from "./MalfunctionTooltip.module.scss";

export default class MalfunctionTooltip extends Component {
  render() {
    const {
      card,
      onClick,
      malfunction,
      editable,
    } = this.props;
    let title = i18n.t("global.notAvailable");
    if (malfunction) {
      title = malfunction;
    } else if (card && card.tag) {
      title = card.tag;
    }
    if (card) {
      const {
        statusType, hsi, description, type,
      } = card;
      return (
        <div onClick={onClick} className={css.containerMinWidth}>
          <div className={css.title}>
            <span>{title}</span>
            <span className={[css.titleIcon, getCardTypeIconByType(type)].join(" ")} />
          </div>
          <div className={css.details}>
            {description && (
            <div className={css.description}>
              {description}
            </div>
            ) }
            {hsi && (
            <div className={css.hsiContainer}>
              <span className={css.hsiLabel}>HSI</span>
              <HealthStatusIndicator className={[css.hsi, getCardStatusTypeClass(statusType)].join(" ")} value={hsi} />
            </div>
            )}
            {!hsi && (
            <div className={css.hsiContainer}>
              <CardStatus status={statusType} />
            </div>
            )}
          </div>
        </div>
      );
    }
    const { type } = this.props;
    return (
      <div className={css.containerMinWidth}>
        <div className={css.title}>
          <span>{title}</span>
          <span className={[css.titleIcon, getCardTypeIconByType(type)].join(" ")} />
        </div>
        <div className={css.details}>
          {editable && <div onClick={onClick} className={[css.description, css.disabled].join(" ")}>{i18n.t("viewComponents.malfunctionTooltip.addCard")}</div>}
          {!editable && <div className={[css.description, css.disabled].join(" ")}>{i18n.t("viewComponents.malfunctionTooltip.notMonitored")}</div>}
        </div>
      </div>
    );
  }
}

MalfunctionTooltip.propTypes = {
  malfunction: PropTypes.string,
  hsi: PropTypes.number,
  editable: PropTypes.bool,
  subline: PropTypes.string,
  type: PropTypes.string,
};

import React, { PureComponent } from "react";
import { cardType, getCardStatusTypeClass, getCardStatusTypePriority } from "utils/types";
import { cardStatus } from "utils/constants";
import Section from "components/layout/Section";
import Tag from "components/elements/Tag";
import ToolTip from "components/elements/ToolTip";
import MalfunctionTooltip from "components/blocks/MalfunctionTooltip";
import IconHeadline from "components/elements/IconHeadline";
import TitleBar from "components/layout/TitleBar";
import PropTypes from "prop-types";
import css from "./CardTagList.module.scss";

function handleCardsGrouping(cards, components, componentsMalfunctions) {
  let result = components.map((component) => {
    // cards are either linked to a malfunction or directly to a component
    const cardsByType = cards.reduce((collector, card) => {
      // skip component if not linked to any card
      if ((card.componentId && card.componentId === component.id)
        || (card.malfunction && card.malfunction.componentId === component.id)) {
        let malfunction;
        if (card.malfunction) {
          // even though the card already comes with the malfunction element loaded, obtain
          // the malfunction from "componentsMalfunctions" since translations are already handled
          const componentFound = componentsMalfunctions.find(
            (group) => group.componentId === card.malfunction.componentId,
          );
          if (componentFound) {
            malfunction = componentFound.malfunctions.find(
              (m) => m.malfunctionId === card.malfunction.id,
            );
          }
        }
        const item = {
          card,
          malfunction,
        };
        const record = collector.find((record) => record.cardType.type === card.type);
        if (record) {
          record.data.push(item);
        } else {
          const newRecord = {
            cardType: cardType.find((t) => t.type === card.type),
            data: [item],
          };
          collector.push(newRecord);
        }
      }
      return collector;
    }, []);
    return {
      componentId: component.id,
      componentName: component.name,
      cardsByType,
    };
  });

  if (result) {
    // filter components without cards
    result = result.filter((component) => component.cardsByType
      && component.cardsByType.length > 0);
    // sort card types in each component
    result.map((component) => component.cardsByType.sort(
      (a, b) => a.cardType.sort - b.cardType.sort,
    ));
  }

  return result;
}

class CardTagList extends PureComponent {
  render() {
    const {
      cards,
      components,
      componentsMalfunctions,
      onClick,
    } = this.props;

    if (!cards) {
      return null;
    }

    const cardsGrouped = handleCardsGrouping(cards, components, componentsMalfunctions);

    return [
      ...cardsGrouped.map((group) => (
        <Section key={group.componentId} white>
          <TitleBar title={group.componentName} />
          {group.cardsByType
            .map(({ cardType, data }) => (
              <Section
                key={cardType.type}
                className={css.tagSection}
                borderBottom
                noMargin
              >
                <IconHeadline small title={cardType.name} icon={cardType.icon} />
                <ul className={css.tagList}>
                  {data
                    .sort((itemA, itemB) => getCardStatusTypePriority(itemB.card.statusType)
                      - getCardStatusTypePriority(itemA.card.statusType))
                    .map(({ card, malfunction }) => (
                      <li
                        key={card.id}
                        data-tip
                        data-for={`tool-tip-card-${card.id}`}
                      >
                        <button onClick={onClick ? () => onClick(card.id) : null}>
                          <Tag
                            type={getCardStatusTypeClass(card.statusType)}
                            title={malfunction ? malfunction.malfunctionName : card.tag}
                            className={card.statusType === cardStatus.ANOMALY_DETECTED
                              ? css.tagListEntryAnomaly : css.tagListEntry
                            }
                          />
                        </button>
                        <ToolTip
                          className={css.tooltip}
                          id={`tool-tip-card-${card.id}`}
                          place="right"
                        >
                          <MalfunctionTooltip
                            malfunction={malfunction ? malfunction.malfunctionName : undefined}
                            card={card}
                            activated
                          />
                        </ToolTip>
                      </li>
                    ))}
                </ul>
              </Section>
            ))}
        </Section>
      )),
    ];
  }
}

CardTagList.propTypes = {
  cards: PropTypes.array,
  components: PropTypes.array,
  componentsMalfunctions: PropTypes.array,
  onClick: PropTypes.func,
};

CardTagList.defaultProps = {
};
export default CardTagList;

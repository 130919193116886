import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import i18n from "i18n";
import { ReactComponent as Icon } from "assets/svgs/opl_support.svg";
import css from "./ServiceHoursStatus.module.scss";

class ServiceHoursStatus extends PureComponent {
  render() {
    const { className, servicehoursTotal, servicehoursLeft } = this.props;
    return (
      <div className={[className, css.serviceHours].join(" ")}>
        <Icon className={css.icon} />
        {`${Number.isNaN(servicehoursLeft)
          ? "" : servicehoursLeft}h ${i18n.t("viewComponents.serviceHours.left")} 
          (${i18n.t("viewComponents.serviceHours.of")} ${Number.isNaN(servicehoursTotal)
          ? "" : servicehoursTotal}h)`}
      </div>
    );
  }
}

ServiceHoursStatus.propTypes = {
  progress: PropTypes.number,
  servicehoursLeft: PropTypes.number,
  servicehoursTotal: PropTypes.number,
};

export default ServiceHoursStatus;

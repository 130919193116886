import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Actions from "store/actions";
import Button from "components/elements/Button";
import Spinner from "components/elements/Spinner";
import i18n from "i18n";
import css from "./ModalDeleteComponent.module.scss";

class ModalDeleteComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleDelete(componentId) {
    const { deleteComponent } = this.props;
    this.setState({ loading: true });
    deleteComponent(componentId);
  }

  render() {
    const {
      component,
      closeComponentsModal,
    } = this.props;
    const { loading } = this.state;
    return (
      <div className={[css.inner, css.action].join(" ")}>
        { loading ? <Spinner className={css.spinner}>{i18n.t("components.messages.creating_category")}</Spinner> : (
          <>
            <button onClick={closeComponentsModal} className={css.close}>
              <span className="icon-close" />
            </button>
            <div className={css.title}>
              {i18n.t("components.delete_category")}
            </div>
            <div className={css.content}>
              <p>{ i18n.t("components.messages.confirm_delete_category") }</p>
            </div>
            <div className={css.buttons}>
              <Button className={css.btn} onClick={closeComponentsModal} type="outlined">
                {i18n.t("global.cancel")}
              </Button>
              <Button
                onClick={() => {
                  this.handleDelete(component.componentId);
                }}
                type="primary"
                className={css.btn}
              >
                {i18n.t("components.buttons.labels.delete")}
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    component: state.components.modal.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(Actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalDeleteComponent);

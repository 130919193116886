import axios from "axios";

const { REACT_APP_ENVIRONMENT } = process.env;

let apiEndpoint = null;
let authEndpoint = null;

export const devTokenLocal = "eyJhbGciOiJSUzI1NiJ9.eyJqdGkiOiIyODg1ZTQ3Yy1kOGYxLTQ4OTMtOGU0ZC1iZDJjNTA1ZTZiY2YiLCJpYXQiOjE1ODQwMzA4MzEsInN1YiI6IndhYWxfY29ycG9yYXRlQG1haWwucmF5c29uby5jb20iLCJpc3MiOiJodHRwczovL3ZvaXRoLmlvLyIsImF1ZCI6ImFwaSIsInNjb3BlIjoiYWxsIiwiY29udGV4dCI6eyJ1c2VyIjp7InJvbGVzIjpbIm15dm9pdGgtYWxsLWFjY2VzcyIsInN5cy5vcGxfY29ycG9yYXRlIiwiaHlkcm8ub3BsIiwibXl2b2l0aC1hbGwtY3VzdG9tZXIiXSwiZ3JvdXBzIjpbXSwibm9NYWlsQm94IjpmYWxzZSwibGFuZ3VhZ2UiOiJlbi1FTiIsImN1c3RvbWVySWQiOiJlODQ5MzIxYS02MGY0LTRiNjYtYmE2MS1kOGEwMmY5YWQ0OGEiLCJsaW5rcyI6eyJfc2VsZiI6Imh0dHBzOi8vYXBpLnN0YWdpbmcudm9pdGguaW8vYXBpL2lvdC1jb3JlLWlkZW50aXR5L3YwL3VzZXJzLzQ2YjlmMDkzLTEzY2EtNDM0ZC04OGZlLTU1MDY1NjEyYjg4YyIsImN1c3RvbWVyIjoiaHR0cHM6Ly9hcGkuc3RhZ2luZy52b2l0aC5pby9hcGkvaW90LWNvcmUtaWRlbnRpdHkvdjAvY3VzdG9tZXJzLzU5In19fSwiZXhwIjoxNTg0MDU5NjMxfQ.h6cClgqBfZqcAMpTTOYwVX1fa3yEv6ZKK8i-231Ip4Dn41ecmLTfIAbTnGCheVAwn6dy5iVBgwvOCyXBpJjQKDXfONpUdHmVymKHWN9dfB3v9uzBrTCYtrn7KYjKLdOMx9fPcLdGSf5QESEBarH1jgYpBj8lc26kVut_zCywi28go13aedY7D8R7Qlf4p9N0n4gZHoypbVFeYbL244HdCIoLmrxHu3DgymocIj94s5BbVFR6QQpi4x3v28yRCoXYAVdGYIuv3JL3i5afwhxhfzF7gVtG0Wz4ZK3ok2uWJxJVsZ8T_InpFq8XfaJRVeGACWXy2FZsc_YD4rU1anCLSA";
const BASE_URL_AUTH_DEV = "http://localhost:8080"; // eslint-disable-line no-unused-vars
const BASE_URL_AUTH_STAGE = "https://api.staging.voith.io/api/iot-core-identity/v0"; // eslint-disable-line no-unused-vars
const BASE_URL_AUTH_PROD = "https://api.industrial.voith.io/api/iot-core-identity/v0"; // eslint-disable-line no-unused-vars

const BASE_URL_API_DEV = "https://opl-api.staging.voith.io/v0/";
const BASE_URL_API_STAGE = "https://opl-api.staging.voith.io/v0/";
const BASE_URL_API_PROD = "https://opl-api.industrial.voith.io/v0/";

const apiDev = axios.create({
  baseURL: BASE_URL_API_DEV,
  withCredentials: true,
  headers: { Authorization: `Bearer ${devTokenLocal}` },
});

const apiStage = axios.create({
  baseURL: BASE_URL_API_STAGE,
  withCredentials: true,
});

const apiProd = axios.create({
  baseURL: BASE_URL_API_PROD,
  withCredentials: true,
});

const authStaging = axios.create({
  baseURL: BASE_URL_AUTH_STAGE,
});

const authProd = axios.create({
  baseURL: BASE_URL_AUTH_PROD,
});

const authLocal = axios.create({
  baseURL: BASE_URL_AUTH_DEV,
});

if (REACT_APP_ENVIRONMENT === "staging") {
  // staging
  apiEndpoint = apiStage;
  authEndpoint = authStaging;
} else if (REACT_APP_ENVIRONMENT === "prod") {
  // production
  apiEndpoint = apiProd;
  authEndpoint = authProd;
} else {
  // local
  apiEndpoint = apiDev;
  authEndpoint = authLocal;
}

export const api = apiEndpoint;
export const auth = authEndpoint;

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import { allowedTypes } from "utils/markdownTypes";
import { Link } from "@reach/router";
import { isAbsolute } from "utils/url";
import css from "./Paragraph.module.scss";

function MarkDownLink(props) {
  const { target, href, children } = props;
  if (target === "_blank") {
    return (
      <a href={href} target={target}>
        {children}
      </a>
    );
  }
  if (target === "_self") {
    return <Link to={href}>{children}</Link>;
  }
}

class Paragraph extends PureComponent {
  handleLinkTarget = (url) => {
    if (isAbsolute(url)) {
      return "_blank";
    }
    return "_self";
  }

  render() {
    const {
      className, children, title, hidden, markdown, small, noPadding,
    } = this.props;
    return (
      <div
        className={[
          !hidden && !small ? css.default : null,
          hidden ? css.hidden : null,
          small ? css.small : null,
          noPadding ? css.noPadding : null,
          className,
        ].join(" ")}
      >
        {title && (
          <div className={css.title}>
            {hidden && <span className="icon-invisible" />}
            <h5>{title}</h5>
          </div>
        )}
        {markdown && (
          <ReactMarkdown
            linkTarget={this.handleLinkTarget}
            allowedTypes={allowedTypes}
            source={markdown}
            renderers={{ link: MarkDownLink }}
          />
        )}
        {!markdown && children}
      </div>
    );
  }
}

Paragraph.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  markdown: PropTypes.string,
};

Paragraph.defaultProps = {
  markdown: "n/a",
  children: "n/a",
};
export default Paragraph;

import { api } from "utils/api";
import { closeModal, showMessage } from "store/actions/notifications/";
import i18n from "i18n";
import { AUTH_USER_ERROR } from "store/actions/auth/";
import { APP_FORBIDDEN_ACCESS, APP_GLOBAL_ERROR } from "store/actions/app";

export const USER_GET_REQUEST = "USER_GET_REQUEST";
export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const USER_GET_ERROR = "USER_GET_ERROR";

export function getUser() {
  return async (dispatch) => {
    try {
      dispatch({
        type: USER_GET_REQUEST,
      });
      const result = await api.get("/me", {
        withCredentials: true,
      });
      const { status } = result;
      if (status === 200) {
        const { data } = result;
        dispatch({
          type: USER_GET_SUCCESS,
          payload: {
            user: data,
          },
        });
        dispatch(
          showMessage({
            title: i18n.t("global.success"),
            text: `User logged in as ${data.firstName}`,
            type: "warning",
          }),
        );
      } else {
        throw new Error(result);
      }
    } catch (error) {
      const { response } = error;
      dispatch(closeModal());
      if (response) {
        const { status } = response;
        switch (status) {
          case 401:
            dispatch({
              type: AUTH_USER_ERROR,
            });
            break;
          case 403:
            dispatch({
              type: APP_FORBIDDEN_ACCESS,
            });
            break;
          default:
            dispatch({
              type: APP_GLOBAL_ERROR,
            });
        }
      } else {
        dispatch({
          type: APP_GLOBAL_ERROR,
        });
      }
      dispatch({
        type: USER_GET_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("errors.errorGetUser"),
          type: "error",
        }),
      );
    }
  };
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Actions from "store/actions";
import axios from "axios";
import ProgressBar from "components/elements/ProgessBar";
import i18n from "i18n";
import { MAX_UPLOAD_SIZE } from "utils/constants";
import byteSize from "byte-size";
import stripJs from "strip-js";
import css from "./FileSelect.module.scss";

class FileSelect extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.state = {
      fileToUpload: null,
      uploading: false,
      progress: 0,
      error: null,
    };
    this.handleSelectFile = this.handleSelectFile.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleDeleteImage = this.handleDeleteImage.bind(this);
  }

  handleSelectFile(file) {
    const { size, name } = file;
    if (size < MAX_UPLOAD_SIZE) {
      // checks the file name for XSS since it's displayed on screen
      const sanitizedFileName = stripJs(name);
      if (name.toLowerCase() === sanitizedFileName.toLowerCase()) {
        // we're assuming binary files content is ok, just check text files for XSS
        let isBinaryFile = false;
        let isTextFileOk = false;
        const reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onloadend = (evt) => {
          const fileContent = evt.target.result;
          if (fileContent) {
            if (/\ufffd/.test(fileContent) === true) {
              isBinaryFile = true;
            } else if (fileContent.toLowerCase().indexOf("svg") === -1) {
              const sanitizedFileContent = stripJs(fileContent);
              if (sanitizedFileContent
                && fileContent.toLowerCase() === sanitizedFileContent.toLowerCase()) {
                isTextFileOk = true;
              }
            }
            if (isBinaryFile || isTextFileOk) {
              this.setState(
                {
                  fileToUpload: file,
                  error: null,
                },
                this.handleUpload,
              );
            } else {
              this.setState({ error: i18n.t("validation.invalidFileContent") });
            }
          } else {
            this.setState({ error: i18n.t("validation.emptyFileContent") });
          }
        };
      } else {
        this.setState({ error: i18n.t("validation.invalidFileName") });
      }
    } else {
      this.setState({ error: `${i18n.t("validation.fileSizeLimit")} ${byteSize(MAX_UPLOAD_SIZE)}` });
    }
  }

  handleUpload() {
    this.setState(
      () => ({
        uploading: true,
      }),
      async () => {
        const {
          update, id, requestUploadLinkAction, showMessage,
        } = this.props;
        const { fileToUpload } = this.state;

        const sanitizeMimeType = (type) => type || "application/application/octet-stream";

        const { presignedUrl, url } = await requestUploadLinkAction({
          mime: sanitizeMimeType(fileToUpload.type),
          filename: fileToUpload.name,
          size: fileToUpload.size,
        });

        try {
          await axios.put(presignedUrl, fileToUpload, {
            headers: {
              "Content-Type": sanitizeMimeType(fileToUpload.type),
            },
            onUploadProgress: (event) => {
              const { loaded, total } = event;
              this.setState(() => ({
                progress: loaded / total,
              }));
            },
          });
          const imgLink = url;
          this.setState(
            {
              fileToUpload: null,
              uploading: false,
              progress: 0,
            },
            () => {
              showMessage({
                title: i18n.t("global.success"),
                text: `${fileToUpload.name} ${i18n.t(
                  "viewComponents.fileSelect.imageUploadSuccess",
                )}`,
                type: "warning",
              });
              update({
                id,
                value: {
                  url: imgLink,
                  name: fileToUpload.name,
                  size: fileToUpload.size,
                  mime: sanitizeMimeType(fileToUpload.type),
                },
              });
              this.fileInputRef.current.value = "";
            },
          );
        } catch (error) {
          showMessage({
            title: i18n.t("global.error"),
            text: `${i18n.t("viewComponents.fileSelect.imageUploadError")} ${
              fileToUpload.name
            }`,
            type: "error",
            sticky: true,
          });
          console.log(error);
          this.setState(
            {
              fileToUpload: null,
              uploading: false,
              progress: 0,
            },
            () => {
              this.fileInputRef.current.value = "";
            },
          );
        }
      },
    );
  }

  handleDeleteImage() {
    const { update, id } = this.props;
    this.setState(
      {
        fileToUpload: null,
      },
      () => {
        update({ id });
        this.fileInputRef.current.value = "";
      },
    );
  }

  render() {
    const {
      fileToUpload, uploading, progress, error,
    } = this.state;
    const {
      className, label, fileTypes, file, placeholder, id,
    } = this.props;
    return (
      <div className={[css.fileSelect, className].join(" ")}>
        <label htmlFor={id} className={css.label}>{label}</label>
        {!uploading && !file && (
          <button
            onClick={() => {
              this.fileInputRef.current.click();
            }}
            className={css.selectButton}
          >
            <span className={css.info}>
              {fileToUpload && fileToUpload.name}
              {!fileToUpload && placeholder}
            </span>
            <span className={["icon-import", css.icon].join(" ")} />
          </button>
        )}
        {error && (
        <div className={css.errorText}>{i18n.t(error)}</div>
        )}
        <input
          id={id}
          onChange={(event) => this.handleSelectFile(event.target.files[0])}
          ref={this.fileInputRef}
          className={css.input}
          type="file"
          accept={fileTypes}
        />
        {uploading && (
          <ProgressBar className={css.progressBar} progress={progress} />
        )}
        {file && (
          <div className={css.preview}>
            <button
              onClick={this.handleDeleteImage}
              className={css.deleteButton}
            >
              <span className={["icon-delete_01", css.icon].join(" ")} />
            </button>
            <span className={css.info} title={file.name}>
              {file.name}
            </span>
            {file.mime.includes("image") && (
              <img
                className={css.previewImage}
                src={file.url}
                alt={file.name}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

FileSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  update: PropTypes.func,
  fileTypes: PropTypes.string,
  url: PropTypes.string,
  getUploadLinkAction: PropTypes.func,
  clearUploadProcessAction: PropTypes.func,
  placeholder: PropTypes.string,
};

FileSelect.defaultProps = {
  checked: false,
  fileTypes: "image/png",
  placeholder: "",
  getUploadLinkAction: async () => {},
  clearUploadProcessAction: () => {},
};

function mapStateToProps() {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(Actions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FileSelect);

import React, { Component } from "react";
import PropTypes from "prop-types";
import css from "./TabBar.module.scss";

function Tab({
  className, onClick, label, activeTab, handleModal, component, search,
}) {
  const modalEdit = {
    display: true,
    type: "EDIT_COMPONENT",
    data: component,
  };

  const modalDelete = {
    display: true,
    type: "DELETE_COMPONENT",
    data: component,
  };

  function handleDisplay() {
    if (search) {
      if (component.malfunctions.length === 0) {
        return { display: "none" };
      }
    }
    return {};
  }

  return (
    <button
      onClick={() => { onClick(label); }}
      className={[css.tab, className, activeTab === label ? css.active : null].join(" ")}
      style={handleDisplay()}
    >
      {label}
      { search ? (<span>{` (${component.malfunctions.length})`}</span>) : activeTab === label && (
        <>
          <span className="icon-edit" onClick={() => handleModal(modalEdit)} style={{ marginLeft: "8px" }} />
          <span className="icon-close" onClick={() => handleModal(modalDelete)} style={{ marginLeft: "8px" }} />
        </>
      )}
    </button>
  );
}

function AddButton({ onClick }) {
  const modal = {
    display: true,
    type: "ADD_COMPONENT",
    title: "Add Category",
  };
  return (
    <button
      onClick={() => { onClick(modal); }}
      className={css.tab}
    >
      <span className="icon-plus" />
    </button>
  );
}

class TabBar extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  }

  constructor(props) {
    super(props);
    const { children } = this.props;
    {
      const { props } = Array.isArray(children) ? children[0] : children;
      const { label } = props;
      this.state = {
        activeTab: label,
      };
    }
    this.onClickTabItem = this.onClickTabItem.bind(this);
  }

  componentDidUpdate() {
    const { props: { children }, state: { activeTab } } = this;
    if (children[0] && children[0].props.label) {
      const isActive = children.some((item) => item.props.label === activeTab);
      if (!isActive) {
        this.onClickTabItem(children[0].props.label);
      }
    }
  }

  onClickTabItem(tab) {
    this.setState({ activeTab: tab });
  }

  render() {
    const {
      showComponentsModal,
    } = this.props;
    const {
      onClickTabItem,
      props: {
        children,
      },
      state: {
        activeTab,
      },
    } = this;
    return (
      <>
        { Array.isArray(children) && (
          <ol className={css.tabs}>
            {
              children.map((child) => {
                const { component, label, search } = child.props;
                return (
                  <Tab
                    activeTab={activeTab}
                    key={label}
                    label={label}
                    onClick={onClickTabItem}
                    component={component}
                    handleModal={showComponentsModal}
                    search={search}
                  />
                );
              })
            }
            <AddButton onClick={showComponentsModal} />
          </ol>
        )}
        <div className={css.selectedTab}>
          {Array.isArray(children) ? children.map((child) => {
            if (child.props.label !== activeTab) return null;
            return child.props.children;
          }) : children}
        </div>
      </>
    );
  }
}

export default TabBar;

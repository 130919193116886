import React, { PureComponent } from "react";

import i18n from "i18n";
import {
  getCardStatusTypeClass,
} from "utils/types";
import Section from "components/layout/Section";
import Argumentations from "components/blocks/Argumentations";
import Paragraph from "components/elements/Paragraph";
import HealthStatusIndicator from "components/elements/HealthStatusIndicator";
import PropTypes from "prop-types";
import { saflyCheckArrayLength } from "utils/helper";
import css from "./CardContent.module.scss";

class CardContent extends PureComponent {
  render() {
    const {
      card, cardMalfunction, editable, className,
    } = this.props;
    const {
      description,
      argumentations,
      structurePath,
      recommendations,
      hsi,
      risks,
      statusType,
      editorsNote,
      tag,
    } = card;
    const statusTypeClass = getCardStatusTypeClass(statusType);
    // HSI Description is stored as first arg, but it should be placed under malfunction name/tag.
    let hsiDescription;
    let hsiTrendArgumentations;
    if (saflyCheckArrayLength(argumentations) > 0) {
      hsiDescription = argumentations[0].details;
      hsiTrendArgumentations = argumentations.slice(1); // new argumentations array, without first.
    }
    return (
      <>
        <Section
          className={[className, css.section].join(" ")}
          noMargin
          white
        >
          <h5 className={[css.sectionTitle, css.structurePath].join(" ")}>{structurePath}</h5>
          <h2 className={[css.sectionTitleHuge, css.malfunctionTitle].join(" ")}>
            {cardMalfunction ? cardMalfunction.name : tag}
          </h2>
          {hsiDescription && (
            <h3 className={css.hsiDescription}>{hsiDescription}</h3>
          )}
          {description && (
            <h2 className={css.description}>{description}</h2>
          )}
          {editable && editorsNote && (
          <Section>
            <Paragraph
              title="Editor’s note"
              markdown={editorsNote}
              hidden
            />
          </Section>
          )}
        </Section>
        {!!hsi && (
        <Section
          className={css.section}
          noMargin
          white
        >
          <div className={css.sectionTitle}>{i18n.t("global.healthStatusIndicator")}</div>
          <HealthStatusIndicator className={statusTypeClass} value={hsi} />
        </Section>
        )}
        {saflyCheckArrayLength(recommendations) > 0
        && (
          <Section
            className={css.section}
            noMargin
            white
          >
            <div className={css.sectionTitle}>{i18n.t("global.recommendations")}</div>
            {recommendations.map((recommendation) => (
              <div key={recommendation.text} className={[css.roundParagraph, statusTypeClass].join(" ")}>
                <span className={["icon-settings_02", css.roundParagraphIcon].join(" ")} />
                <Paragraph
                  small
                  markdown={recommendation.text}
                  className={css.roundParagraphText}
                />
              </div>
            ))}
          </Section>
        )}
        {saflyCheckArrayLength(risks) > 0
        && (
          <Section
            className={css.section}
            noMargin
            white
          >
            <div className={css.sectionTitle}>{i18n.t("global.risk")}</div>
            { risks.map((risk) => (
              <div key={risk.text} className={[css.roundParagraph, statusTypeClass].join(" ")}>
                <span className={["icon-warning", css.roundParagraphIcon].join(" ")} />
                <Paragraph
                  small
                  markdown={risk.text}
                  className={css.roundParagraphText}
                />
              </div>
            ))}
          </Section>
        )}
        {saflyCheckArrayLength(hsiTrendArgumentations) > 0
        && (
          <Section
            className={css.section}
            noMargin
            white
          >
            <div className={css.sectionTitle}>{i18n.t("global.argumentations")}</div>
            <Argumentations argumentations={hsiTrendArgumentations} />
          </Section>
        )}
      </>
    );
  }
}

CardContent.propTypes = {
  card: PropTypes.object,
  editable: PropTypes.bool,
};

CardContent.defaultProps = {
  editable: false,
};

export default CardContent;

import { api } from "utils/api";
import { closeModal, showMessage } from "store/actions/notifications/";
import i18n from "i18n";
import { AUTH_USER_ERROR } from "store/actions/auth/";
import { APP_FORBIDDEN_ACCESS, APP_GLOBAL_ERROR } from "store/actions/app";
import { extractMalfunctions, getLanguageCodeParam } from "utils/malfunctions";

export const MALFUNCTIONS_GET_REQUEST = "MALFUNCTIONS_GET_REQUEST";
export const MALFUNCTIONS_GET_SUCCESS = "MALFUNCTIONS_GET_SUCCESS";
export const MALFUNCTIONS_GET_ERROR = "MALFUNCTIONS_GET_ERROR";
export const MACHINE_MALFUNCTIONS_GET_REQUEST = "MACHINE_MALFUNCTIONS_GET_REQUEST";
export const MACHINE_MALFUNCTIONS_GET_SUCCESS = "MACHINE_MALFUNCTIONS_GET_SUCCESS";
export const MACHINE_MALFUNCTIONS_GET_ERROR = "MACHINE_MALFUNCTIONS_GET_ERROR";

export function getMalfunctions(turbineType) {
  return async (dispatch) => {
    try {
      dispatch({
        type: MALFUNCTIONS_GET_REQUEST,
      });
      const languageCodeParam = getLanguageCodeParam();
      const result = await api.get(`/turbines/${turbineType}/malfunctions${languageCodeParam}`, {
        withCredentials: true,
      });
      const { status } = result;
      let componentsMalfunctions;
      if (status === 200) {
        const { data } = result;
        componentsMalfunctions = data;
      } else {
        throw new Error(result);
      }
      const malfunctions = extractMalfunctions(componentsMalfunctions);
      dispatch({
        type: MALFUNCTIONS_GET_SUCCESS,
        payload: {
          turbineType,
          malfunctions,
          componentsMalfunctions,
        },
      });
    } catch (error) {
      const { response } = error;
      dispatch(closeModal());
      if (response) {
        const { status } = response;
        switch (status) {
          case 401:
            dispatch({
              type: AUTH_USER_ERROR,
            });
            break;
          case 403:
            dispatch({
              type: APP_FORBIDDEN_ACCESS,
            });
            break;
          default:
            dispatch({
              type: APP_GLOBAL_ERROR,
            });
        }
      } else {
        dispatch({
          type: APP_GLOBAL_ERROR,
        });
      }
      dispatch({
        type: MALFUNCTIONS_GET_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("errors.errorGetMalfunctions"),
          type: "error",
        }),
      );
    }
  };
}

export function getMachineMalfunctions(structureId) {
  return async (dispatch) => {
    let machineMalfunctions;
    try {
      dispatch({
        type: MACHINE_MALFUNCTIONS_GET_REQUEST,
      });
      const result = await api.get(`/structures/${structureId}/malfunctions`, {
        withCredentials: true,
      });
      const { status } = result;
      if (status === 200 || status === 204) {
        const { data } = result;
        machineMalfunctions = data;
      } else {
        throw new Error(result);
      }
      dispatch({
        type: MACHINE_MALFUNCTIONS_GET_SUCCESS,
        payload: {
          structureId,
          machineMalfunctions,
        },
      });
    } catch (error) {
      const { response } = error;
      dispatch(closeModal());
      if (response) {
        const { status } = response;
        switch (status) {
          case 401:
            dispatch({
              type: AUTH_USER_ERROR,
            });
            break;
          case 403:
            dispatch({
              type: APP_FORBIDDEN_ACCESS,
            });
            break;
          default:
            dispatch({
              type: APP_GLOBAL_ERROR,
            });
        }
      } else {
        dispatch({
          type: APP_GLOBAL_ERROR,
        });
      }
      dispatch({
        type: MACHINE_MALFUNCTIONS_GET_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("errors.errorGetMachineMalfunctions"),
          type: "error",
        }),
      );
    }
    return machineMalfunctions;
  };
}

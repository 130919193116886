import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import MultiactionButton from "./MultiactionButton";
import css from "./FloatingButton.module.scss";

class FloatingButton extends PureComponent {
  render() {
    const {
      icon, actions, large, id,
    } = this.props;
    return (
      <div className={css.button}>
        <MultiactionButton id={id} icon={icon} actions={actions} large={large} />
      </div>
    );
  }
}

FloatingButton.propTypes = {
  icon: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, handler: PropTypes.func })),
  large: PropTypes.bool,
  id: PropTypes.string,
};

FloatingButton.defaultProps = {
  icon: "icon-comment",
  large: false,
  id: "floating-button",
};

export default FloatingButton;

/**
 * @fileoverview Glossary component for assessment card pop up
 * @author  Florian Berg
 */

import React, { PureComponent } from "react";
import Section from "components/layout/Section";
import Paragraph from "components/elements/Paragraph";
import css from "./GeneralInformation.module.scss";

class GeneralInformation extends PureComponent {
  // handleEdit() {
  //   const { customerId, closeGlossary } = this.props;
  //   navigate(`/${customerId}/glossary/edit`);
  //   closeGlossary();
  // }

  render() {
    const { glossary, preline, title } = this.props;
    return (
      <div className={css.container}>
        <div className={css.inner}>
          <Section
            className={css.section}
          >
            {preline && <h5 className={css.preline}>{preline}</h5>}
            {title && <h2 className={css.title}>{title}</h2>}
          </Section>
          <Section
            className={css.section}
            noMargin
          >
            <Paragraph markdown={glossary} />
          </Section>
        </div>
      </div>
    );
  }
}

// function mapStateToProps(state) {
//   const { showGlossary, current } = state.customers;
//   if (!current) {
//     return {};
//   }
//   const { glossary, id } = current;
//   return {
//     glossary,
//     customerId: id,
//     show: showGlossary,
//     editor: state.auth.isOPL,
//   };
// }
// function mapDispatchToProps(dispatch) {
//   return {
//     ...bindActionCreators({ closeGlossary }, dispatch),
//   };
// }
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps,
// )(Glossary);

export default GeneralInformation;

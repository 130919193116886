import React, { Component } from "react";
import PropTypes from "prop-types";
import Section from "components/layout/Section";
import MarkdownEdit from "components/input/MarkdownEdit";
import i18n from "i18n";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import uid from "utils/uid";
import Button from "components/elements/Button";
import Field from "components/input/Field";

function Recommendation({
  uuid,
  heading,
  text,
  onUpdate,
  onDelete,
}) {
  return (
    <Section action={() => onDelete(uuid)} actionIcon="delete_01" title={heading} padding>
      <Section>
        <Field
          id="text"
          label={i18n.t("views.editAssessment.recommendation.textLabel")}
          onChange={(update) => {
            onUpdate({ ...update, recommendationId: uuid });
          }}
        >
          {(id, onChange) => (
            <MarkdownEdit
              id={id}
              placeholder={i18n.t("global.textFiledPlaceHolderDefault")}
              text={text}
              update={onChange}
              small
            />
          )}
        </Field>
      </Section>
    </Section>
  );
}

class RecommendationsEdit extends Component {
  handleAddRecommendation = () => {
    const { onUpdate } = this.props;
    let { recommendations } = this.props;
    if (recommendations) {
      recommendations = [
        ...recommendations,
        {
          uuid: uid(),
          text: "",
        },
      ];
    } else {
      recommendations = [
        {
          uuid: uid(),
          text: "",
        },
      ];
    }
    onUpdate({ id: "recommendations", value: recommendations });
  };

  handleDeleteRecommendation = (uuid) => {
    const { recommendations, onUpdate } = this.props;
    onUpdate({
      id: "recommendations",
      value: recommendations.filter((recommendation) => recommendation.uuid !== uuid),
    });
  };

  handleUpdateRecommendation = (update) => {
    const { id, recommendationId, value } = update;
    const { recommendations, onUpdate } = this.props;
    const recommendationsUpdate = {
      id: "recommendations",
      value: recommendations,
    };
    recommendationsUpdate.value = recommendations.map((recommendation) => {
      if (recommendation.uuid === recommendationId) {
        const editedRecommendation = recommendation;
        switch (id) {
          case "text":
            editedRecommendation.text = value;
            break;
          default:
            console.error("scannot update recommendation", update);
        }
        return editedRecommendation;
      }
      return recommendation;
    });
    onUpdate(recommendationsUpdate);
  };

  render() {
    const {
      loading,
      recommendations,
    } = this.props;
    return (
      <>
        {
          recommendations.map((recommendation, index) => (
            <Recommendation
              heading={`${i18n.t(
                "views.editAssessment.recommendation.recommendation",
              )} ${index + 1}`}
              onDelete={this.handleDeleteRecommendation}
              onUpdate={this.handleUpdateRecommendation}
              key={recommendation.uuid}
              {...recommendation}
            />
          ))
        }
        <Button
          type="outlined"
          onClick={this.handleAddRecommendation}
          disabled={loading}
          small
        >
          {i18n.t("views.editAssessment.buttons.addRecommendation")}
        </Button>
      </>
    );
  }
}

RecommendationsEdit.propTypes = {
  id: PropTypes.string,
  recommendations: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string,
    text: PropTypes.string,
  })),
};

function mapStateToProps() {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({}, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecommendationsEdit);

import {
  MESSAGES_UPLOAD_LINK_REQUEST,
  MESSAGES_UPLOAD_LINK_SUCCESS,
  MESSAGES_UPLOAD_LINK_ERROR,
} from "store/actions";

export function uploadMessagesReducer(state, action) {
  switch (action.type) {
    case MESSAGES_UPLOAD_LINK_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case MESSAGES_UPLOAD_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case MESSAGES_UPLOAD_LINK_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}

export default uploadMessagesReducer;

import {
  ASSESSMENTS_OVERVIEW_GET_REQUEST,
  ASSESSMENTS_OVERVIEW_GET_SUCCESS,
  ASSESSMENTS_OVERVIEW_GET_ERROR,
  ASSESSMENT_GET_REQUEST,
  ASSESSMENT_GET_SUCCESS,
  ASSESSMENT_GET_ERROR,
  ASSESSMENTS_CREATE_REQUEST,
  ASSESSMENTS_CREATE_SUCCESS,
  ASSESSMENTS_CREATE_ERROR,
  ASSESSMENTS_SAVE_REQUEST,
  ASSESSMENTS_SAVE_SUCCESS,
  ASSESSMENTS_SAVE_ERROR,
  ASSESSMENTS_PUBLISH_REQUEST,
  ASSESSMENTS_PUBLISH_SUCCESS,
  ASSESSMENTS_PUBLISH_ERROR,
  ASSESSMENTS_DELETE_REQUEST,
  ASSESSMENTS_DELETE_SUCCESS,
  ASSESSMENTS_DELETE_ERROR,
  ASSESSMENTS_CLONE_REQUEST,
  ASSESSMENTS_CLONE_SUCCESS,
  ASSESSMENTS_CLONE_ERROR,
} from "store/actions";

export default function assessmentsApiReducer(state, action) {
  switch (action.type) {
    case ASSESSMENTS_OVERVIEW_GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ASSESSMENTS_OVERVIEW_GET_SUCCESS: {
      const { assessments, customerId } = action.payload;
      return {
        ...state,
        assessmentsoverview: {
          ...state.assessmentsoverview,
          [customerId]: [
            ...assessments,
          ],
        },
        loading: false,
      };
    }
    case ASSESSMENTS_OVERVIEW_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ASSESSMENT_GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ASSESSMENT_GET_SUCCESS: {
      const { assessment, customerId } = action.payload;
      let customerAssessments = state.assessments[customerId];
      if (customerAssessments) {
        const assessmentFound = customerAssessments.find((a) => a.id === assessment.id);
        if (assessmentFound) {
          const index = customerAssessments.indexOf(assessmentFound);
          customerAssessments.splice(index, 1, assessment); // replace element at index
        } else {
          customerAssessments.push(assessment);
        }
      } else {
        customerAssessments = [assessment];
      }
      return {
        ...state,
        assessments: {
          ...state.assessments,
          [customerId]: [
            ...customerAssessments,
          ],
        },
        loading: false,
      };
    }
    case ASSESSMENT_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ASSESSMENTS_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ASSESSMENTS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        draft: null,
      };
    case ASSESSMENTS_CREATE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ASSESSMENTS_SAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ASSESSMENTS_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ASSESSMENTS_SAVE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ASSESSMENTS_PUBLISH_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ASSESSMENTS_PUBLISH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case ASSESSMENTS_PUBLISH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ASSESSMENTS_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ASSESSMENTS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case ASSESSMENTS_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ASSESSMENTS_CLONE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ASSESSMENTS_CLONE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case ASSESSMENTS_CLONE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}

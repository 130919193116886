import React, { Component } from "react";
import PropTypes from "prop-types";
import Section from "components/layout/Section";
import MarkdownEdit from "components/input/MarkdownEdit";
import i18n from "i18n";
import FileSelect from "components/input/FileSelect";
import TextField from "components/input/TextField";
import CheckBox from "components/input/CheckBox";
import DropDown from "components/input/DropDown";
import { getArgumentationUploadLink } from "store/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import uid from "utils/uid";
import { immutableMove } from "utils/helper";
import Button from "components/elements/Button";
import Field from "components/input/Field";
import css from "./ArgumentationsEdit.module.scss";

function Argumentation({
  uuid,
  title,
  heading,
  caption,
  details,
  file,
  isHidden,
  order,
  onUpdate,
  onDelete,
  argumentationsCount,
  onRequestUploadLink,
}) {
  return (
    <Section action={() => onDelete(uuid)} actionIcon="delete_01" title={heading} padding>
      <Section>
        <Field
          id="title"
          label={i18n.t("views.editAssessment.argumentation.titleLabel")}
          onChange={(update) => {
            onUpdate({ ...update, argumentationId: uuid });
          }}
        >
          {(id, onChange) => (
            <TextField
              id={id}
              max={128}
              text={title}
              update={onChange}
              small
            />
          )}
        </Field>
      </Section>
      <Section>
        <Field
          id="details"
          label={i18n.t("views.editAssessment.argumentation.graphTextLabel")}
          onChange={(update) => {
            onUpdate({ ...update, argumentationId: uuid });
          }}
        >
          {(id, onChange) => (
            <MarkdownEdit
              id={id}
              placeholder={i18n.t("global.textFiledPlaceHolderDefault")}
              text={details}
              update={onChange}
              small
            />
          )}
        </Field>
      </Section>
      <Section className={css.fileSelectRow} row>
        <Field
          id="image"
          label={i18n.t("views.editAssessment.argumentation.fileSelectLabel")}
          onChange={(update) => {
            onUpdate({ ...update, argumentationId: uuid });
          }}
        >
          {(id, onChange) => (
            <FileSelect
              className={css.fileSelect}
              id={id}
              update={onChange}
              file={file}
              requestUploadLinkAction={async (fileType) => await onRequestUploadLink(fileType)}
              placeholder={i18n.t("global.addFile")}
            />
          )}
        </Field>
        <Field
          id="isHidden"
          label={i18n.t("views.editAssessment.argumentation.hiddenLabel")}
          onChange={(update) => {
            onUpdate({ ...update, argumentationId: uuid });
          }}
        >
          {(id, onChange) => (
            <CheckBox
              id={id}
              className={css.checkBox}
              update={onChange}
              checked={isHidden}
            />
          )}
        </Field>
        <Field
          id="order"
          label={i18n.t("views.editAssessment.argumentation.orderLabel")}
          onChange={({ id, value }) => {
            onUpdate({
              id,
              value: { to: value.item.order, from: order },
              argumentationId: uuid,
            });
          }}
        >
          {(id, onChange) => (
            <DropDown
              id={id}
              className={css.dropDown}
              placeholder={i18n.t("global.dropDownPlaceholderDefault")}
              items={Array(argumentationsCount)
                .fill()
                .map((i, index) => ({ id: index + 1, name: `${index + 1}`, order: index }))}
              selected={{ id: order + 1, name: `${order + 1}`, order }}
              update={onChange}
            />
          )}
        </Field>
      </Section>
      <Section>
        <Field
          id="caption"
          label={i18n.t("views.editAssessment.argumentation.captionLabel")}
          onChange={(update) => {
            onUpdate({ ...update, argumentationId: uuid });
          }}
        >
          {(id, onChange) => (
            <TextField
              id={id}
              max={128}
              text={caption}
              update={onChange}
              small
            />
          )}
        </Field>
      </Section>
    </Section>
  );
}

class ArgumenationsEdit extends Component {
  handleAddArgumentation = () => {
    const { onUpdate } = this.props;
    let { argumentations } = this.props;
    if (argumentations) {
      argumentations = [
        ...argumentations,
        {
          uuid: uid(),
          url: null,
          isHidden: false,
          title: "",
          caption: "",
          graph: "",
        },
      ];
    } else {
      argumentations = [
        {
          uuid: uid(),
          url: null,
          isHidden: false,
          title: "",
          caption: "",
          graph: "",
        },
      ];
    }
    onUpdate({ id: "argumentations", value: argumentations });
  };

  handleDeleteArgumentation = (uuid) => {
    const { argumentations, onUpdate } = this.props;
    onUpdate({
      id: "argumentations",
      value: argumentations.filter((argumentation) => argumentation.uuid !== uuid),
    });
  };

  handleUpdateArgumentation = (update) => {
    const { id, argumentationId, value } = update;
    const { argumentations, onUpdate } = this.props;
    const argumentationsUpdate = {
      id: "argumentations",
      value: argumentations,
    };
    if (id === "order") {
      argumentationsUpdate.value = immutableMove(
        argumentations,
        value.from,
        value.to,
      );
      console.log(argumentationsUpdate);
    } else {
      argumentationsUpdate.value = argumentations.map((argumentation) => {
        if (argumentation.uuid === argumentationId) {
          const editedArgumentation = argumentation;
          switch (id) {
            case "image": {
              editedArgumentation.file = value;
              break;
            }
            case "title":
              editedArgumentation.title = value;
              break;
            case "caption":
              editedArgumentation.caption = value;
              break;
            case "details":
              editedArgumentation.details = value;
              break;
            case "isHidden":
              editedArgumentation.isHidden = value;
              break;
            default:
              console.error("cannot update argumentation", update);
          }
          return editedArgumentation;
        }
        return argumentation;
      });
    }
    onUpdate(argumentationsUpdate);
  };

  render() {
    const {
      loading,
      argumentations,
      getArgumentationUploadLink,
    } = this.props;
    return (
      <>
        {
          argumentations.map((argumentation, index) => (
            <Argumentation
              heading={`${i18n.t(
                "views.editAssessment.argumentation.argumentation",
              )} ${index + 1}`}
              order={index}
              onDelete={this.handleDeleteArgumentation}
              onUpdate={this.handleUpdateArgumentation}
              onRequestUploadLink={getArgumentationUploadLink}
              key={argumentation.uuid}
              argumentationsCount={argumentations.length}
              {...argumentation}
            />
          ))
        }
        <Button
          type="outlined"
          onClick={this.handleAddArgumentation}
          disabled={loading}
          small
        >
          {i18n.t("views.editAssessment.buttons.addArgumentation")}
        </Button>
      </>
    );
  }
}

ArgumenationsEdit.propTypes = {
  argumentations: PropTypes.array,
  loading: PropTypes.bool,
  getArgumentationUploadLink: PropTypes.func,
};

function mapStateToProps() {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ getArgumentationUploadLink }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ArgumenationsEdit);

import {
  CUSTOMERS_GET_REQUEST,
  CUSTOMERS_GET_SUCCESS,
  CUSTOMERS_GET_ERROR,
} from "store/actions";

export function customersApiReducer(state, action) {
  switch (action.type) {
    case CUSTOMERS_GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CUSTOMERS_GET_SUCCESS: {
      const { customers } = action.payload;
      return {
        ...state,
        customers,
        loading: false,
      };
    }
    case CUSTOMERS_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
export default customersApiReducer;

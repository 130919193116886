import React, { PureComponent } from "react";
import { navigate } from "@reach/router";
import PropTypes from "prop-types";
import css from "./NavigationBar.module.scss";
import MultiactionButton from "../elements/MultiactionButton";

class NavigationBar extends PureComponent {
  render() {
    const {
      title,
      preline,
      publishAction,
      addAction,
      editAction,
      customAction,
      customActionIcon,
      backLink,
      backAction,
      duplicateAction,
      printAction,
      huge,
      small,
      className,
    } = this.props;
    return (
      <nav className={[className, css.container, huge ? css.huge : null, small ? css.small : null].join(" ")}>
        <div className="left">
          {backAction && (
          <button
            type="button"
            onClick={backAction}
            className={css.backButton}
          >
            <span className="icon-arrow-left" />
          </button>
          )}
          {backLink && (
          <button
            type="button"
            onClick={() => {
              navigate(backLink);
            }}
            className={css.backButton}
          >
            <span className="icon-arrow-left" />
          </button>
          )}
          {huge && (
            <div className={css.heading}>
              <div className={css.titleHuge}>{title}</div>
              {preline && <div className={css.prelineHuge}>{preline}</div>}
            </div>
          )}
          {!huge && (
            <div className={css.heading}>
              {preline && <div className={css.preline}>{preline}</div>}
              <div className={css.title}>{title}</div>
            </div>
          )}
        </div>
        <div className="right">
          <div className={css.actionButtons}>
            {printAction && (
              <button
                type="button"
                onClick={printAction}
                className={css.actionButton}
              >
                <span className="icon-print" />
              </button>
            )}
            {publishAction && (
              <button
                type="button"
                onClick={publishAction}
                className={css.actionButton}
              >
                <span className="icon-cloud_upload" />
              </button>
            )}
            {addAction && (
              <button
                type="button"
                onClick={addAction}
                className={css.actionButton}
              >
                <span className="icon-add" />
              </button>
            )}
            {editAction && (
              <button
                type="button"
                onClick={editAction}
                className={css.actionButton}
              >
                <span className="icon-edit" />
              </button>
            )}
            {duplicateAction && (
              <button
                type="button"
                onClick={duplicateAction}
                className={css.actionButton}
              >
                <span className="icon-copy_01" />
              </button>
            )}
            {customAction && (
              <MultiactionButton id="info-button" icon={customActionIcon} actions={customAction} place="bottom" />
            )}
          </div>
        </div>
      </nav>
    );
  }
}

NavigationBar.propTypes = {
  title: PropTypes.string,
  preline: PropTypes.string,
  publishAction: PropTypes.func,
  printAction: PropTypes.func,
  addAction: PropTypes.func,
  editAction: PropTypes.func,
  customAction: PropTypes.array,
  customActionIcon: PropTypes.string,
  backLink: PropTypes.string,
};

NavigationBar.defaultProps = {
  preline: "",
  backLink: "",
};
export default NavigationBar;

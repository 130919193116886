import React, { Component } from "react";
import PropTypes from "prop-types";
import css from "./GenericCard.module.scss";

class GenericCard extends Component {
  render() {
    const {
      onClick, topLeft, topRight, bottomLeft, bottomRight,
    } = this.props;
    return (
      <div onClick={onClick} className={css.container}>
        <div className={css.inner}>
          <div className={css.top}>
            <div className={css.topLeft}>
              {topLeft}
            </div>
            <div className={css.topRight}>
              {topRight}
            </div>
          </div>
          <div className={css.bottom}>
            <div className={css.bottomLeft}>
              {bottomLeft}
            </div>
            <div className={css.bottomRight}>
              {bottomRight}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

GenericCard.propTypes = {
  onClick: PropTypes.func,
  topLeft: PropTypes.node,
  topRight: PropTypes.node,
  bottomLeft: PropTypes.node,
  bottomRight: PropTypes.node,
};

GenericCard.defaultProps = {};

export default GenericCard;

export const languages = [
  {
    languageCode: "en",
    name: "English",
    id: "en",
  },
  {
    languageCode: "de",
    name: "Deutsche",
    id: "de",
  },
  {
    languageCode: "pt",
    name: "Português",
    id: "pt",
  },
  {
    languageCode: "es",
    name: "Español",
    id: "es",
  },
  {
    languageCode: "zh",
    name: "Chinese",
    id: "zh",
  },
];

export const malfunctionTypes = [
  {
    id: "hsi",
    name: "HSI",
  },
  {
    id: "ac",
    name: "AC",
  },
];

export function searchHasResult(results) {
  let has = false;

  results.map((result) => {
    if (result.count >= 1) {
      has = true;
    }
    return result;
  });

  return has;
}

export function getComponentsTranslated(language, components) {
  const linkedComponents = components.map((item) => {
    let { componentName } = item;
    if (item.translations && item.translations.length > 0 && language !== "en") {
      const translation = item.translations.find((t) => t.languageCode === language);
      if (translation) {
        componentName = translation.name;
      }
    }
    const component = {
      id: item.componentId,
      name: componentName,
    };
    return component;
  });
  return linkedComponents;
}

export function rangeGroupMalfunctions(malfunction, placement) {
  const RANGE_SIZE = 20;
  const {
    id: malfunctionId,
    xAxis: mXAxis,
    yAxis: mYAxis,
  } = malfunction;
  const {
    id: placementId,
    xAxis: pXAxis,
    yAxis: pYAxis,
  } = placement;

  if (malfunctionId === placementId) {
    return false;
  }

  if (
    (pXAxis >= (mXAxis - RANGE_SIZE) && pXAxis <= (mXAxis + RANGE_SIZE))
    && (pYAxis >= (mYAxis - RANGE_SIZE) && pYAxis <= (mYAxis + RANGE_SIZE))
  ) {
    return true;
  }
  return false;
}

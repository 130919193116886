import { api } from "utils/api";
import { closeModal, showMessage, showModal } from "store/actions/notifications/";
import i18n from "i18n";
import { AUTH_USER_ERROR } from "store/actions/auth/";
import { APP_FORBIDDEN_ACCESS, APP_GLOBAL_ERROR } from "store/actions/app";

export const CUSTOMERS_GET_REQUEST = "CUSTOMERS_GET_REQUEST";
export const CUSTOMERS_GET_SUCCESS = "CUSTOMERS_GET_SUCCESS";
export const CUSTOMERS_GET_ERROR = "CUSTOMERS_GET_ERROR";

export const CUSTOMER_UPDATE_REQUEST = "CUSTOMER_UPDATE_REQUEST";
export const CUSTOMER_UPDATE_SUCCESS = "CUSTOMER_UPDATE_SUCCESS";
export const CUSTOMER_UPDATE_ERROR = "CUSTOMER_UPDATE_ERROR";

export function getCustomers() {
  return async (dispatch) => {
    try {
      dispatch({
        type: CUSTOMERS_GET_REQUEST,
      });
      const result = await api.get("/customers?addStructure=true&addAssessmentsCount=true", {
        withCredentials: true,
      });
      const { status } = result;
      if (status === 200) {
        const { data } = result;
        dispatch({
          type: CUSTOMERS_GET_SUCCESS,
          payload: {
            customers: data,
          },
        });
      } else {
        throw new Error(result);
      }
    } catch (error) {
      const { response } = error;
      dispatch(closeModal());
      if (response) {
        const { status } = response;
        switch (status) {
          case 401:
            dispatch({
              type: AUTH_USER_ERROR,
            });
            break;
          case 403:
            dispatch({
              type: APP_FORBIDDEN_ACCESS,
            });
            break;
          default:
            dispatch({
              type: APP_GLOBAL_ERROR,
            });
        }
      } else {
        dispatch({
          type: APP_GLOBAL_ERROR,
        });
      }
      dispatch({
        type: CUSTOMERS_GET_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("errors.errorGetCustomers"),
          type: "error",
        }),
      );
    }
  };
}

export function updateCustomer(customer) {
  return async (dispatch) => {
    try {
      dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.updateCustomer"),
        }),
      );
      dispatch({
        type: CUSTOMER_UPDATE_REQUEST,
      });
      const { id } = customer;
      const result = await api.put(
        `customers/${id}`,
        customer,
        {
          headers: {
            "content-type": "application/json",
          },
          withCredentials: true,
        },
      );
      dispatch({
        type: CUSTOMER_UPDATE_SUCCESS,
        payload: {
          customer: result.data,
        },
      });
      await dispatch(getCustomers());
      dispatch(closeModal());
      dispatch(
        showMessage({
          title: i18n.t("global.success"),
          text: i18n.t("messages.updateCustomerSuccess"),
          type: "alert",
        }),
      );
      return true;
    } catch (error) {
      dispatch({
        type: CUSTOMER_UPDATE_ERROR,
      });
      await dispatch(closeModal());
      await dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("errors.errorUpdateCustomer"),
          type: "error",
        }),
      );
      console.log(error);
      return false;
    }
  };
}

/**
 * @fileoverview Screen component for malfunctions settings view
 * @author  Lucas Sena
 */

import React from "react";
import TabBar from "components/elements/TabBar";
import Components from "components/blocks/Components";
import MalfunctionSearch from "components/elements/MalfunctionSearch";
import SelectLanguage from "components/elements/SelectLanguage";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Actions from "store/actions";
import i18n from "i18n";
import css from "./Malfunctions.module.scss";

const data = {
  display: true,
  type: "ADD_COMPONENT",
};

function NoComponent({ showComponentsModal, data }) {
  return (
    <div label="Click on add button to create a new category">
      <section className={css.container}>
        <h3>{ i18n.t("components.text_no_categories") }</h3>
        <h4>{ i18n.t("components.add_a_category") }</h4>
        <span style={{ color: "#2d4275" }} onClick={() => showComponentsModal(data)} className="icon-add" />
      </section>
    </div>
  );
}

function SearchHasNoResult({ params }) {
  return (
    <div label="Search has no result">
      <section className={css.container}>
        <h3>
          { i18n.t("components.messages.no_results_found_for") }
          { `"${params}"` }
        </h3>
        <h4>{ i18n.t("components.messages.please_check_your_search_for_typos") }</h4>
      </section>
    </div>
  );
}

/* eslint-disable */
class Malfunctions extends React.Component {
  render() {
    const { components, showComponentsModal, search } = this.props;
    const { display, results, hasResult, params } = search;
    return (
      <>
        <SelectLanguage />
        <MalfunctionSearch />
        <TabBar showComponentsModal={showComponentsModal}>
          {
            display
              ? hasResult ? results.map((component) => {
                  const { componentName, componentNameTranslated, malfunctions } = component;
                  if (malfunctions.length >= 0) {
                    return (
                      <div label={componentNameTranslated || componentName} key={component.componentId} component={component} search={display}>
                        <Components
                          displayModal={showComponentsModal}
                          component={component}
                          search={display}
                        />
                      </div>
                    );
                  }
              }) : (<SearchHasNoResult params={params} />)
              : components.components.length >= 1 ? components.components.map((component) => {
                const { componentName, componentNameTranslated } = component;
                return (
                  <div label={componentNameTranslated || componentName} key={component.componentId} component={component} search={display}>
                    <Components displayModal={showComponentsModal} component={component} search={display} />
                  </div>
                );
              }) : (<NoComponent showComponentsModal={showComponentsModal} data={data} />)
          }
        </TabBar>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { auth, components } = state;
  return {
    components,
    editable: auth.isOPL,
    search: components.search,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(Actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Malfunctions);

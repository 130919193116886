import reduceReducers from "reduce-reducers";
import { malfunctionsApiReducer } from "./api/malfunctions";

const initialState = {
  turbines: [],
  loading: false,
  error: false,
};

export default reduceReducers(
  initialState,
  malfunctionsApiReducer,
);

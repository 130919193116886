const { REACT_APP_ENVIRONMENT } = process.env;

export function isProduction() {
  return REACT_APP_ENVIRONMENT === "prod";
}

export function isStaging() {
  return REACT_APP_ENVIRONMENT === "staging";
}

export function getCurrentDomain() {
  if (isProduction()) {
    return "https://auth.industrial.voith.io";
  }
  return "https://auth.staging.voith.io";
}

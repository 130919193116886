import React, { PureComponent } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Actions from "store/actions";
import SnackBar from "components/global/SnackBar";
import css from "./Notifications.module.scss";

class Messages extends PureComponent {
  render() {
    const { messages, closeMessage } = this.props;
    if (!messages.length) {
      return null;
    }
    const mappedMessages = messages.map((message) => (
      <SnackBar
        {...message}
        onClose={closeMessage}
        key={message.id}
      />
    ));
    return <div className={css.messages}>{mappedMessages}</div>;
  }
}

function mapStateToProps(state) {
  return {
    messages: state.notifications.messages,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(Actions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Messages);

import React from "react";
import PropTypes from "prop-types";
import { GenericMessage } from "./GenericMessage";

function Comment(props) {
  const {
    className, body, user, mine, createdAt, editAction, files,
  } = props;
  return (
    <GenericMessage
      className={className}
      timeStamp={createdAt}
      editAction={mine && editAction}
      body={body}
      user={user}
      owner={mine}
      files={files}
    />
  );
}

Comment.propTypes = {
  body: PropTypes.string,
  type: PropTypes.string,
};

Comment.defaultProps = {};
export default Comment;

import {
  COMPONENTS_PLACEMENT_SET_LIST,
  COMPONENTS_SELECT_MALFUNCTION_PAGE,
  COMPONENTS_SET_TURBINE_TYPE,
  COMPONENTS_UPDATE_PLACEMENT_LIST,
} from "store/actions";
import { sanitizeMalfunctions } from "utils/placements";

function reducer(state, action) {
  switch (action.type) {
    case COMPONENTS_PLACEMENT_SET_LIST: {
      const items = [];
      const list = [];
      const { placement, components, turbineType } = state;
      const { malfunctions } = action.payload;
      const placements = placement[turbineType];

      components.forEach((item) => {
        const sanitized = sanitizeMalfunctions(item, placements, malfunctions);
        list.push(sanitized);
        items.push({
          id: item.componentId,
          name: item.componentName,
          malfunctions: sanitized,
          active: false,
        });
      });

      const all = {
        id: "all",
        name: "All malfunctions",
        malfunctions: list.flat(),
      };

      items.unshift(all);

      return {
        ...state,
        list: items,
        loading: false,
      };
    }
    case COMPONENTS_UPDATE_PLACEMENT_LIST: {
      const { malfunction } = action.payload;

      const newList = state.list.map((malfunctions) => {
        if (malfunctions.malfunctions.length >= 1) {
          malfunctions.malfunctions.map((item) => {
            if (item.id === malfunction.malfunctionId) {
              const newItem = item;
              newItem.active = false;
              return newItem;
            }
            return item;
          });
        }
        return malfunctions;
      });

      return {
        ...state,
        list: newList,
      };
    }
    case COMPONENTS_SET_TURBINE_TYPE: {
      const { turbineType } = action.payload;
      return {
        ...state,
        turbineType,
      };
    }
    case COMPONENTS_SELECT_MALFUNCTION_PAGE: {
      const { page } = action.payload;
      return {
        ...state,
        page,
      };
    }
    default:
      return state;
  }
}

export default reducer;

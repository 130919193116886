import React, { Component } from "react";
import PropTypes from "prop-types";
import css from "./DateEntry.module.scss";

class DateEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: "",
      month: "",
      day: "",
      valid: true,
    };
  }

  async componentDidMount() {
    const { date } = this.props;
    if (date) {
      const d = new Date(date);
      await this.setState({
        year: d.getFullYear(),
        month: (d.getMonth() + 1).toString().padStart(2, "0"),
        day: d
          .getDate()
          .toString()
          .padStart(2, "0"),
      });
    }
  }

  validate = () => {
    const { year, month, day } = this.state;
    const matches = /^(\d{1,2})[-/](\d{1,2})[-/](\d{4})$/.exec(
      `${month}-${day}-${year}`,
    );
    if (matches == null) return false;
    const m = matches[1] - 1;
    const d = matches[2];
    const y = matches[3];
    const composedDate = new Date(y, m, d);
    return (
      composedDate.getDate() === Number.parseInt(d, 10)
      && composedDate.getMonth() === Number.parseInt(m, 10)
      && composedDate.getFullYear() === Number.parseInt(y, 10)
    );
  };

  handleChange = async () => {
    const { update, id } = this.props;
    const { year, month, day } = this.state;
    const valid = this.validate();
    await this.setState({
      valid,
    });
    if (valid && update) {
      update({
        id,
        value: new Date(
          year,
          Number.parseInt(month, 10) - 1,
          Number.parseInt(day, 10) + 1,
        ),
      });
    }
  };

  handleOnFocus = async () => {
    await this.setState({
      valid: this.validate(),
    });
  };

  render() {
    const { id } = this.props;
    const {
      valid, year, month, day,
    } = this.state;
    return (
      <div className={[css.dateEntry, !valid ? css.invalid : null].join(" ")}>
        <fieldset id={id} className={css.fieldSet}>
          <input
            maxLength="2"
            onFocus={this.handleOnFocus}
            onChange={(event) => {
              const { value } = event.target;
              this.setState(
                {
                  day: value,
                },
                this.handleChange,
              );
            }}
            value={day}
            placeholder="DD"
            className={css.inputDay}
          />
          <span className={css.delimeter} />
          <input
            maxLength="2"
            onFocus={this.handleOnFocus}
            onChange={(event) => {
              const { value } = event.target;
              this.setState(
                {
                  month: value,
                },
                this.handleChange,
              );
            }}
            value={month}
            placeholder="MM"
            className={css.inputMonth}
          />
          <span className={css.delimeter} />
          <input
            maxLength="4"
            onFocus={this.handleOnFocus}
            onChange={(event) => {
              const { value } = event.target;
              this.setState(
                {
                  year: value,
                },
                this.handleChange,
              );
            }}
            value={year}
            placeholder="YYYY"
            className={css.inputYear}
          />
        </fieldset>
      </div>
    );
  }
}

DateEntry.propTypes = {
  id: PropTypes.string,
  editable: PropTypes.bool,
  date: PropTypes.string,
  placeholder: PropTypes.string,
};

DateEntry.defaultProps = {
  editable: true,
  placeholder: "Enter Text",
};
export default DateEntry;

import { api } from "utils/api";
import { showMessage } from "store/actions";
import i18n from "i18n";

export const USER_UPDATE_SETTINGS_REQUEST = "USER_UPDATE_SETTINGS_REQUEST";
export const USER_UPDATE_SETTINGS_ERROR = "USER_UPDATE_SETTINGS_ERROR";
export const USER_UPDATE_SETTINGS_SUCCESS = "USER_UPDATE_SETTINGS_SUCCESS";

export function toggleNotificationSettings() {
  return async (dispatch) => {
    try {
      dispatch({
        type: USER_UPDATE_SETTINGS_REQUEST,
      });
      let result = null;
      result = await api.put("/me/toggle-subscription", {
        withCredentials: true,
      });
      const { status } = result;
      if (status === 200) {
        const { data } = result;
        dispatch({
          type: USER_UPDATE_SETTINGS_SUCCESS,
          payload: {
            user: data,
          },
        });
        dispatch(
          showMessage({
            title: i18n.t("global.success"),
            text: `${i18n.t("messages.updateUserSettingsSuccess")}`,
            type: "warning",
          }),
        );
      } else {
        throw new Error(result.statusText);
      }
    } catch (error) {
      dispatch({
        type: USER_UPDATE_SETTINGS_ERROR,
      });
      await dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: `${i18n.t("errors.errorChangeUserSettings")}`,
          type: "error",
        }),
      );
      throw error;
    }
  };
}

import timeout from "utils/asyncTimeOut";
import i18n from "i18n";
import { api } from "utils/api";
import {
  showModal,
  closeModal,
  showMessage,
} from "store/actions/notifications/";
import { AUTH_USER_ERROR } from "store/actions/auth/";
import { APP_FORBIDDEN_ACCESS, APP_GLOBAL_ERROR } from "store/actions/app";

export const ASSESSMENTS_OVERVIEW_GET_REQUEST = "ASSESSMENTS_OVERVIEW_GET_REQUEST";
export const ASSESSMENTS_OVERVIEW_GET_SUCCESS = "ASSESSMENTS_OVERVIEW_GET_SUCCESS";
export const ASSESSMENTS_OVERVIEW_GET_ERROR = "ASSESSMENTS_OVERVIEW_GET_ERROR";

export const ASSESSMENT_GET_REQUEST = "ASSESSMENT_GET_REQUEST";
export const ASSESSMENT_GET_SUCCESS = "ASSESSMENT_GET_SUCCESS";
export const ASSESSMENT_GET_ERROR = "ASSESSMENT_GET_ERROR";

export const ASSESSMENTS_CREATE_REQUEST = "ASSESSMENTS_CREATE_REQUEST";
export const ASSESSMENTS_CREATE_SUCCESS = "ASSESSMENTS_CREATE_SUCCESS";
export const ASSESSMENTS_CREATE_ERROR = "ASSESSMENTS_CREATE_ERROR";

export const ASSESSMENTS_SAVE_REQUEST = "ASSESSMENTS_SAVE_REQUEST";
export const ASSESSMENTS_SAVE_SUCCESS = "ASSESSMENTS_SAVE_SUCCESS";
export const ASSESSMENTS_SAVE_ERROR = "ASSESSMENTS_SAVE_ERROR";

export const ASSESSMENTS_PUBLISH_REQUEST = "ASSESSMENTS_PUBLISH_REQUEST";
export const ASSESSMENTS_PUBLISH_SUCCESS = "ASSESSMENTS_PUBLISH_SUCCESS";
export const ASSESSMENTS_PUBLISH_ERROR = "ASSESSMENTS_PUBLISH_ERROR";

export const ASSESSMENTS_DELETE_REQUEST = "ASSESSMENTS_DELETE_REQUEST";
export const ASSESSMENTS_DELETE_SUCCESS = "ASSESSMENTS_DELETE_SUCCESS";
export const ASSESSMENTS_DELETE_ERROR = "ASSESSMENTS_DELETE_ERROR";

export const ASSESSMENTS_CLONE_REQUEST = "ASSESSMENTS_CLONE_SUCCESS";
export const ASSESSMENTS_CLONE_SUCCESS = "ASSESSMENTS_CLONE_SUCCESS";
export const ASSESSMENTS_CLONE_ERROR = "ASSESSMENTS_CLONE_ERROR";

export function getAssessment(customerId, assessmentId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ASSESSMENT_GET_REQUEST,
      });
      const result = await api.get(`/customers/${customerId}/assessments/${assessmentId}`, {
        withCredentials: true,
      });
      const { status } = result;
      if (status === 200) {
        const { data } = result;
        dispatch({
          type: ASSESSMENT_GET_SUCCESS,
          payload: {
            customerId,
            assessment: data,
          },
        });
      } else {
        throw new Error(result.statusText);
      }
    } catch (error) {
      const { response } = error;
      dispatch(closeModal());
      if (response) {
        const { status } = response;
        switch (status) {
          case 401:
            dispatch({
              type: AUTH_USER_ERROR,
            });
            break;
          case 403:
            dispatch({
              type: APP_FORBIDDEN_ACCESS,
            });
            break;
          default:
            dispatch({
              type: APP_GLOBAL_ERROR,
            });
        }
      } else {
        dispatch({
          type: APP_GLOBAL_ERROR,
        });
      }
      dispatch({
        type: ASSESSMENT_GET_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("errors.errorGetHealthAssessment"),
          type: "error",
        }),
      );
    }
  };
}

export function getAssessmentsOverview(customerId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ASSESSMENTS_OVERVIEW_GET_REQUEST,
      });
      const result = await api.get(`/customers/${customerId}/assessments/overview`, {
        withCredentials: true,
      });
      const { status } = result;
      if (status === 200) {
        const { data } = result;
        dispatch({
          type: ASSESSMENTS_OVERVIEW_GET_SUCCESS,
          payload: {
            customerId,
            assessments: data,
          },
        });
      } else {
        throw new Error(result.statusText);
      }
    } catch (error) {
      const { response } = error;
      dispatch(closeModal());
      if (response) {
        const { status } = response;
        switch (status) {
          case 401:
            dispatch({
              type: AUTH_USER_ERROR,
            });
            break;
          case 403:
            dispatch({
              type: APP_FORBIDDEN_ACCESS,
            });
            break;
          default:
            dispatch({
              type: APP_GLOBAL_ERROR,
            });
        }
      } else {
        dispatch({
          type: APP_GLOBAL_ERROR,
        });
      }
      dispatch({
        type: ASSESSMENTS_OVERVIEW_GET_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("errors.errorGetHealthAssessment"),
          type: "error",
        }),
      );
    }
  };
}

export function createNewHealthAssessment(customerId, assetId, machineId) {
  return async (dispatch) => {
    try {
      dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.createNewHealthAssessment"),
        }),
      );
      dispatch({
        type: ASSESSMENTS_CREATE_REQUEST,
      });

      const result = await api.post(`/customers/${customerId}/assessments`,
        {
          assetId,
          machineId,
        },
        {
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
        });
      const assessment = result.data;
      dispatch({
        type: ASSESSMENTS_CREATE_SUCCESS,
        payload: {
          assessment,
        },
      });
      await dispatch(getAssessmentsOverview(customerId)); // update assessments overview state
      await dispatch(getAssessment(customerId, assessment.id)); // update assessments state
      dispatch(closeModal());
      dispatch(
        showMessage({
          title: i18n.t("global.success"),
          text: i18n.t("messages.createNewHealthAssessmentSuccess"),
          type: "alert",
        }),
      );
      return assessment.id;
    } catch (error) {
      dispatch({
        type: ASSESSMENTS_CREATE_ERROR,
      });
      await dispatch(closeModal());
      await dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("errors.errorCreateNewHealthAssessment"),
          type: "error",
        }),
      );
      console.log(error);
      return null;
    }
  };
}

export function saveHealthAssessment(customerId, assessment) {
  return async (dispatch) => {
    try {
      dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.saveHealthAssessment"),
        }),
      );
      dispatch({
        type: ASSESSMENTS_SAVE_REQUEST,
      });
      const result = await api.put(
        `/customers/${customerId}/assessments/${assessment.id}`,
        assessment,
        {
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
        },
      );
      const { status } = result;
      if (status === 200) {
        await dispatch(getAssessmentsOverview(customerId)); // update assessments overview state
        await dispatch(getAssessment(customerId, assessment.id)); // update assessments state
        dispatch({
          type: ASSESSMENTS_SAVE_SUCCESS,
        });
        dispatch(
          showMessage({
            title: i18n.t("global.success"),
            text: i18n.t("messages.saveHealthAssessmentSuccess"),
            type: "alert",
          }),
        );
        dispatch(closeModal());
        return true;
      }
      return false;
    } catch (error) {
      dispatch({
        type: ASSESSMENTS_SAVE_ERROR,
      });
      await dispatch(closeModal());
      await dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("errors.errorSaveHealthAssessment"),
          type: "error",
        }),
      );
      console.log(error);
      return false;
    }
  };
}

export function publishHealthAssessment(customerId, assessment) {
  return async (dispatch) => {
    try {
      await dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.publishHealthAssessment"),
        }),
      );
      dispatch({
        type: ASSESSMENTS_PUBLISH_REQUEST,
      });
      const result = await api.put(
        `/customers/${customerId}/assessments/${assessment.id}/publish`,
        assessment,
        {
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
        },
      );
      const { status } = result;
      if (status === 200) {
        dispatch({
          type: ASSESSMENTS_PUBLISH_SUCCESS,
        });
        await dispatch(getAssessmentsOverview(customerId)); // update assessments overview state
        await dispatch(getAssessment(customerId, assessment.id)); // update assessments state
        dispatch(closeModal());
        dispatch(
          showMessage({
            title: i18n.t("global.success"),
            text: i18n.t("messages.publishHealthAssessmentSuccess"),
            type: "alert",
          }),
        );
        dispatch(closeModal());
        return true;
      }
    } catch (error) {
      dispatch({
        type: ASSESSMENTS_PUBLISH_ERROR,
      });
      dispatch(closeModal());
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("errors.errorPublishHealthAssessment"),
          type: "error",
        }),
      );
      console.log(error);
    }
    return false;
  };
}

export function deleteHealthAssessment(customerId, assessmentId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ASSESSMENTS_DELETE_REQUEST,
      });
      await dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.deleteHealthAssessment"),
        }),
      );
      await api.delete(`/customers/${customerId}/assessments/${assessmentId}`, {
        withCredentials: true,
      });
      await timeout(3000);
      await dispatch(getAssessmentsOverview(customerId));
      await dispatch(closeModal());
      dispatch({
        type: ASSESSMENTS_DELETE_SUCCESS,
      });
      await dispatch(
        showMessage({
          title: i18n.t("global.success"),
          text: i18n.t("messages.deleteHealthAssessmentSuccess"),
          type: "alert",
        }),
      );
    } catch (error) {
      dispatch({
        type: ASSESSMENTS_DELETE_ERROR,
      });
      await dispatch(closeModal());
      await dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("errors.errorDeleteHealthAssessment"),
          type: "error",
        }),
      );
    }
  };
}

export function duplicateHealthAssessment(customerId, assessmentId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ASSESSMENTS_CLONE_REQUEST,
      });
      await dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.cloneHealthAssessment"),
        }),
      );
      await api.post(`/customers/${customerId}/assessments/${assessmentId}/clone`, {
        withCredentials: true,
      });
      await timeout(3000);
      await dispatch(getAssessmentsOverview(customerId));
      await dispatch(closeModal());
      dispatch({
        type: ASSESSMENTS_CLONE_SUCCESS,
      });
      await dispatch(
        showMessage({
          title: i18n.t("global.success"),
          text: i18n.t("messages.cloneHealthAssessmentSuccess"),
          type: "alert",
        }),
      );
    } catch (error) {
      dispatch({
        type: ASSESSMENTS_CLONE_ERROR,
      });
      await dispatch(closeModal());
      await dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("errors.errorCloneHealthAssessment"),
          type: "error",
        }),
      );
    }
  };
}

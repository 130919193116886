/**
 * @fileoverview Screen component for assessment overview view
 * @author  Florian Berg
 */

import React, { Component } from "react";
import { navigate } from "@reach/router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Actions from "store/actions";
import NavigationBar from "components/layout/NavigationBar";
import Section from "components/layout/Section";
import FloatingButton from "components/elements/FloatingButton";
import StatusBar from "components/elements/StatusBar";
import AttachedCards from "components/elements/AttachedCards";
import GenericStatus from "components/elements/GenericStatus";
import HealthAssessmentSummary from "components/blocks/HealthAssessmentSummary";
import i18n from "i18n";
import { healthAssessmentPublishValidator } from "utils/validators";
import { formatDate } from "utils/date";
import { distinctArray } from "utils/helper";
import {
  cardType,
} from "utils/types";
import Popup from "components/layout/Popup";
import {
  getMalfunctionSVGPathByType,
  getTurbineGroupsMalfunctions,
} from "utils/malfunctions";
import { getComponentsTranslated } from "utils/components";
import { getPlacementsWithMalfunctionsName } from "utils/placements";
import HotspotView from "components/blocks/HotspotView";
import { cardTypes } from "utils/constants";
import CardTypeList from "components/blocks/CardTypeList";
import { filterCardsByType } from "utils/cards";
import GeneralInformation from "components/blocks/GeneralInformation";
import CardTagList from "components/blocks/CardTagList";
import css from "./AssessmentOverview.module.scss";

class AssessmentOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAssessmentSummary: false,
      showGeneralInformation: false,
    };
  }

  async componentDidMount() {
    const {
      customerId,
      assessmentId,
      assessment,
      cardsOverview,
      showModal,
      closeModal,
      getAssessmentsOverview,
      getAssessment,
    } = this.props;
    // handle scenarios where an assessment is opened directly or from activity screen
    if (!cardsOverview) {
      showModal({
        type: "wait",
        message: i18n.t("messages.getHealthAssessments"),
      });
      await getAssessmentsOverview(customerId);
      closeModal();
    }
    if (!assessment) {
      showModal({
        type: "wait",
        message: i18n.t("messages.getHealthAssessment"),
      });
      await getAssessment(customerId, assessmentId);
      closeModal();
    }
  }

  handlePublish = async () => {
    const { assessment, showModal, publishHealthAssessment } = this.props;
    const errors = healthAssessmentPublishValidator(assessment);
    if (errors.length) {
      showModal({
        type: "action",
        style: "warning",
        message: (
          <div>
            <span>{`${i18n.t("global.missingData")}: `}</span>
            {errors.map((error, index, errors) => (
              <span key={error.name}>
                {`${error.name}${errors.length - index === 1 ? "" : ", "}`}
              </span>
            ))}
          </div>
        ),
        actionText: i18n.t("global.publishAnyways"),
        primaryAction: async () => {
          const {
            assessment, customerId, assetId, machineId,
          } = this.props;
          await publishHealthAssessment(customerId, assessment);
          let path = `/${customerId}/assessments/`;
          if (assetId && !machineId) {
            path = `/${customerId}/${assetId}/assessments/`;
          } else if (assetId && machineId) {
            path = `/${customerId}/${assetId}/${machineId}/assessments/`;
          }
          navigate(path);
        },
      });
    } else {
      showModal({
        type: "action",
        style: "warning",
        message: i18n.t("modals.publishHealthAssessment"),
        actionText: i18n.t("global.publish"),
        primaryAction: async () => {
          const {
            assessment, customerId, assetId, machineId,
          } = this.props;
          await publishHealthAssessment(customerId, assessment);
          let path = `/${customerId}/assessments/`;
          if (assetId && !machineId) {
            path = `/${customerId}/${assetId}/assessments/`;
          } else if (assetId && machineId) {
            path = `/${customerId}/${assetId}/${machineId}/assessments/`;
          }
          navigate(path);
        },
      });
    }
  };

  handleViewCard = (cardId) => {
    const {
      customerId, assetId, machineId, assessmentId,
    } = this.props;
    let path = `/${customerId}/assessments/${assessmentId}/cards/${cardId}`;
    if (assetId && !machineId) {
      path = `/${customerId}/${assetId}/assessments/${assessmentId}/cards/${cardId}`;
    } else if (assetId && machineId) {
      path = `/${customerId}/${assetId}/${machineId}/assessments/${assessmentId}/cards/${cardId}`;
    }
    navigate(path);
  };

  handleShowGeneralInformation = () => {
    this.setState({ showGeneralInformation: true });
  };

  handleCloseGeneralInformation = () => {
    this.setState({ showGeneralInformation: false });
  };

  handleEditGeneralInformation = () => {
    const { customerId } = this.props;
    navigate(`/${customerId}/glossary/edit`);
  }

  handleShowAssessmentSummary = () => {
    this.setState({ showAssessmentSummary: true });
  };

  handleCloseAssessmentSummary = () => {
    this.setState({ showAssessmentSummary: false });
  };

  handleShowPrintView = () => {
    const { customerId, assessmentId } = this.props;
    navigate(`/${customerId}/assessments/${assessmentId}/print`);
  };

  handleEdit = () => {
    const {
      customerId, assetId, machineId, assessmentId,
    } = this.props;
    let path = `/${customerId}/assessments/${assessmentId}/edit`;
    if (assetId && !machineId) {
      path = `/${customerId}/${assetId}/assessments/${assessmentId}/edit`;
    } else if (assetId && machineId) {
      path = `/${customerId}/${assetId}/${machineId}/assessments/${assessmentId}/edit`;
    }
    navigate(path);
  };

  handleCreateCard = async (cardType = cardTypes.MALFUNCTION, options = {}) => {
    const {
      customerId, assetId, machineId, assessmentId, createAssessmentCardDraft,
    } = this.props;
    const cardId = await createAssessmentCardDraft(
      assessmentId,
      cardType,
      options,
    );
    if (cardId) {
      let path = `/${customerId}/assessments/${assessmentId}/cards/${cardId}/edit`;
      if (assetId && !machineId) {
        path = `/${customerId}/${assetId}/assessments/${assessmentId}/cards/${cardId}/edit`;
      } else if (assetId && machineId) {
        path = `/${customerId}/${assetId}/${machineId}/assessments/${assessmentId}/cards/${cardId}/edit`;
      }
      navigate(path);
    }
  };

  handleDuplicate = async () => {
    const {
      customerId, assetId, machineId, assessmentId, duplicateHealthAssessment, showModal,
    } = this.props;
    showModal({
      type: "action",
      style: "warning",
      message: i18n.t("modals.cloneHealthAssessment"),
      actionText: i18n.t("modals.cloneAccept"),
      primaryAction: async () => {
        await duplicateHealthAssessment(customerId, assessmentId);
        let path = `/${customerId}/assessments/`;
        if (assetId && !machineId) {
          path = `/${customerId}/${assetId}/assessments/`;
        } else if (assetId && machineId) {
          path = `/${customerId}/${assetId}/${machineId}/assessments/`;
        }
        navigate(path);
      },
    });
  }

  provideFloatingButtonActions = () => {
    const { createNewMessage } = this.props;
    const actions = [
      { name: i18n.t("global.writeNewMessage"), handler: createNewMessage },
    ];
    return actions;
  };

  provideCustomNavigationBarActions = () => {
    const actions = [
      { name: i18n.t("global.showGeneralInformation"), handler: this.handleShowGeneralInformation },
      { name: i18n.t("global.showSummary"), handler: this.handleShowAssessmentSummary },
    ];
    return actions;
  };

  render() {
    const {
      editable,
      assessment,
      customerId,
      hidePlantGraphic,
      svgPath,
      glossary,
      allowDuplicate,
      components,
      componentsMalfunctions,
      groupsMalfunctions,
      assetId,
      machineId,
      navigationBarPreTitle,
      cardsOverview,
      malfunctions,
      turbineType,
    } = this.props;

    const {
      showAssessmentSummary,
      showGeneralInformation,
    } = this.state;

    if (!assessment) {
      return null;
    }

    const {
      summary,
      editorsNote,
      timeWindowStart,
      timeWindowEnd,
      title,
      activityAt,
      publishedAt,
      asset,
      cards,
      published,
      responsible,
      creator,
    } = assessment;

    let backLinkPath = `/${customerId}/assessments`;
    if (assetId && !machineId) {
      backLinkPath = `/${customerId}/${assetId}/assessments`;
    } else if (assetId && machineId) {
      backLinkPath = `/${customerId}/${assetId}/${machineId}/assessments`;
    }

    return (
      <div className={css.screen}>
        <FloatingButton icon="icon-comment" actions={this.provideFloatingButtonActions()} />
        <NavigationBar
          publishAction={editable ? this.handlePublish : null}
          editAction={editable ? this.handleEdit : null}
          addAction={editable ? this.handleCreateCard : null}
          duplicateAction={allowDuplicate ? this.handleDuplicate : null}
          printAction={this.handleShowPrintView}
          customAction={this.provideCustomNavigationBarActions()}
          customActionIcon="icon-info"
          backLink={backLinkPath}
          preline={navigationBarPreTitle}
          title={title}
        />
        {!hidePlantGraphic && (
          <Section white noMargin>
            <HotspotView
              malfunctions={malfunctions}
              machine={assessment.machine}
              svgPath={svgPath}
              cards={cards}
              onNavigateToCard={this.handleViewCard}
              onCreateCard={this.handleCreateCard}
              editable={editable}
              groupsMalfunctions={groupsMalfunctions}
              turbineType={turbineType}
            />
          </Section>
        )}
        {
          cardType.filter(({ type }) => {
            const distinctCardTypes = distinctArray(
              cards,
              "type",
            );
            return distinctCardTypes.includes(type);
          })
            .filter(({ hasHotspot }) => !hasHotspot)
            .map((type) => (
              <Section key={type.type} white noMargin borderTop>
                <CardTypeList
                  onNavigateToCard={this.handleViewCard}
                  typeFilter={type}
                  cards={cards}
                />
              </Section>
            ))
          }
        <CardTagList
          components={components}
          componentsMalfunctions={componentsMalfunctions}
          cards={cards}
          onClick={this.handleViewCard}
        />
        <Section noMargin borderBottom borderTop>
          <StatusBar
            className={css.statusBar}
            items={[
              <GenericStatus
                label={`
                  ${responsible ? i18n.t("global.responsible") : ""} 
                  ${responsible && creator ? " / " : ""} 
                  ${creator ? i18n.t("global.creator") : ""}`}
                status={`
                  ${
                    responsible
                      ? `${responsible.firstName} ${responsible.lastName}`
                      : ""
                  }
                  ${responsible && creator ? " / " : ""} 
                  ${creator ? `${creator.firstName} ${creator.lastName}` : ""}
                `}
              />,
              <GenericStatus
                label={`${
                  published
                    ? i18n.t("global.published")
                    : i18n.t("global.lastActivity")
                }`}
                status={`${
                  published
                    ? formatDate(publishedAt)
                    : formatDate(activityAt)
                }`}
              />,
            ]}
          />
        </Section>
        <Section noMargin white>
          <StatusBar
            className={css.status}
            items={[
              <AttachedCards
                label=""
                cards={cardsOverview}
                className={css.attachedCards}
              />,
              <GenericStatus
                icon="calendar"
                label={i18n.t("global.timeWindowOfData")}
                status={`${formatDate(timeWindowStart, "dd/MM/yyyy")} - ${formatDate(timeWindowEnd, "dd/MM/yyyy")}`}
              />,
            ]}
          />
        </Section>
        {showGeneralInformation && (
          <Popup
            onEdit={editable ? this.handleEditGeneralInformation : null}
            onClose={this.handleCloseGeneralInformation}
          >
            <Section>
              <GeneralInformation
                title={`${i18n.t("global.generalInformation")}`}
                glossary={glossary}
                preline={asset ? asset.name : ""}
              />
            </Section>
          </Popup>
        )}
        {showAssessmentSummary && (
          <Popup onClose={this.handleCloseAssessmentSummary}>
            <Section>
              <HealthAssessmentSummary
                preline={asset ? asset.name : ""}
                title={title}
                showEditorNote={editable}
                editorsNote={editorsNote}
                summary={summary}
                timeWindowStart={timeWindowStart}
                timeWindowEnd={timeWindowEnd}
                cards={cards}
              />
            </Section>
          </Popup>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const {
    auth,
    assessments,
    customers,
    components,
    malfunctions,
  } = state;
  const {
    customerId, assessmentId,
  } = props;
  const currentCustomer = customers.customers.find(
    (customer) => customer.id === Number(customerId),
  );
  if (!currentCustomer) {
    return null;
  }
  // Assessments are being lazily loaded when clicking on an assessment card. If the user tries to
  // open an assessment without previously viewing the assessment (e.g. clicking on published msg
  // in Activity Stream), the full assessment is not loaded in the state. This scenario is handled
  // by componentDidMount(), which updates the state, triggering mapStateToProps() again.
  if (!assessments.assessments[Number(customerId)]) {
    // If the assessment is opened directly (e.g. clicking on the link of the email of published
    // reports sent to users), the assessmentsoverview is not loaded in the state. This scenario is
    // also handled by componentDidMount().
    if (!assessments.assessmentsoverview) {
      return {
        assessment: undefined,
        cardsOverview: undefined,
      };
    }
    return {
      assessment: undefined,
    };
  }
  const assessment = assessments.assessments[Number(customerId)].find(
    (assessment) => assessment.id === Number(assessmentId),
  );
  if (assessment) {
    if (assessment.publishedAt) {
      assessment.published = true;
    } else {
      assessment.status = "draft";
      assessment.published = false;
    }
    const assessmentHasHotspotCardTypes = filterCardsByType(
      assessment.cards,
      cardType
        .filter((card) => card.hasHotspot).map(({ type }) => type),
    ).length > 0;
    const { machine: { turbine: { turbineType } } } = assessment;
    const turbine = malfunctions.turbines[turbineType];
    const hidePlantGraphic = assessment.machine.turbine.hideGraphic
      || (!assessmentHasHotspotCardTypes && auth.isHPP);
    const groupsMalfunctions = getTurbineGroupsMalfunctions(
      turbine.componentsMalfunctions, assessment.machine.malfunctions, assessment.published,
      assessment.cards,
    );
    const { language } = i18n;
    const componentsTranslated = getComponentsTranslated(language, components.components);
    const asset = currentCustomer.structures.find(
      (structure) => structure.id === assessment.machine.parentId,
    );
    const navigationBarPreTitle = currentCustomer.name.concat(" / ").concat(asset.name)
      .concat(" / ").concat(assessment.machine.name);
    const cardsOverview = assessments.assessmentsoverview[Number(customerId)].find(
      (a) => a.id === Number(assessmentId),
    ).cards;
    const malfunctionsPlacements = getPlacementsWithMalfunctionsName(
      components.placement[turbineType],
      turbine.componentsMalfunctions,
    );
    return {
      assessment,
      glossary: currentCustomer.glossary,
      hidePlantGraphic,
      svgPath: getMalfunctionSVGPathByType(currentCustomer.type),
      editable: auth.isOPL && !assessment.published,
      allowDuplicate: auth.isOPL,
      components: componentsTranslated,
      componentsMalfunctions: turbine.componentsMalfunctions,
      groupsMalfunctions,
      navigationBarPreTitle,
      cardsOverview,
      malfunctions: malfunctionsPlacements,
      turbineType,
    };
  }
  return null;
}
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(Actions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssessmentOverview);

import React from "react";
import { navigate } from "@reach/router";
import { formatDate } from "utils/date";
import Tag from "components/elements/Tag";
import { extractUserRoleFromUser } from "utils/user";
import Paragraph from "./Paragraph";
import css from "./GenericMessage.module.scss";

export function GenericMessage(props) {
  const {
    meta,
    body,
    className,
    icon,
    editAction,
    link,
    timeStamp,
    user = {},
    files,
  } = props;
  function handleLink(event) {
    event.stopPropagation();
    navigate(link);
  }
  const { firstName = "undefined", lastName = "undefined" } = user;
  return (
    <div
      className={[
        css.container,
        className,
        link ? css.containerLink : null,
      ].join(" ")}
      onClick={link && handleLink}
    >
      <div className={css.header}>
        {icon && <span className={[icon, css.headerIcon].join(" ")} />}
        <span className={css.user}>{`${firstName} ${lastName}`}</span>
        {user && (
          <Tag
            className={css.batch}
            title={extractUserRoleFromUser(user)}
            type={extractUserRoleFromUser(user)}
            small
          />
        )}
        {meta && <span className={css.meta}>{meta}</span>}
        {timeStamp && (
          <div className={css.date}>
            {formatDate(timeStamp)}
          </div>
        )}
        {editAction && (
          <button onClick={editAction} className={css.actionButton}>
            <span className="icon-more" />
          </button>
        )}
        {link && (
          <button onClick={link && handleLink} className={css.actionButton}>
            <span className="icon-arrow-right" />
          </button>
        )}
      </div>
      {body && <Paragraph className={css.messageBody} markdown={body} />}
      {files && (
        <ul className={css.fileList}>
          {files.map((file) => (
            <li key={file.id}>
              <a
                href={file.url}
                className={css.download}
                title={file.name}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                <span className="icon-download" />
                {file.name}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default GenericMessage;

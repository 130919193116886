import {
  COMMENTS_GET_REQUEST,
  COMMENTS_GET_SUCCESS,
  COMMENTS_GET_ERROR,
  COMMENTS_POST_REQUEST,
  COMMENTS_POST_SUCCESS,
  COMMENTS_POST_ERROR,
  COMMENTS_UPDATE_REQUEST,
  COMMENTS_UPDATE_SUCCESS,
  COMMENTS_UPDATE_ERROR,
} from "store/actions";

export default function commentsApiReducer(state, action) {
  switch (action.type) {
    case COMMENTS_GET_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case COMMENTS_GET_SUCCESS: {
      const { comments, cardId } = action.payload;
      return {
        ...state,
        comments: {
          ...state.comments,
          [cardId]: comments,
        },
        loading: false,
        error: false,
      };
    }
    case COMMENTS_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case COMMENTS_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case COMMENTS_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        draft: null,
      };
    case COMMENTS_POST_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case COMMENTS_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case COMMENTS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        draft: null,
        error: false,
      };
    case COMMENTS_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import css from "./ProgressBar.module.scss";

class ProgressBar extends PureComponent {
  render() {
    const { className, progress } = this.props;
    return (
      <div className={[className, css.progressBar].join(" ")}>
        <div style={{ width: `${progress * 100}%` }} className={css.progress} />
      </div>
    );
  }
}

ProgressBar.propTypes = {
  progress: PropTypes.number,
};

export default ProgressBar;

// import jwt_decode from "jwt-decode";
import { auth } from "utils/api";

export const AUTH_USER_REQUEST = "AUTH_USER_REQUEST";
export const AUTH_USER_SUCCESS = "AUTH_USER_SUCCESS";
export const AUTH_USER_ERROR = "AUTH_USER_ERROR";

export function authUser() {
  return async (dispatch) => {
    try {
      dispatch({
        type: AUTH_USER_REQUEST,
      });
      let result = null;
      result = await auth.get("/token", {
        withCredentials: true,
      });
      const { status } = result;
      if (status === 200) {
        dispatch({
          type: AUTH_USER_SUCCESS,
        });
      } else {
        throw new Error(result.statusText);
      }
    } catch (error) {
      dispatch({
        type: AUTH_USER_ERROR,
      });
      throw error;
    }
  };
}

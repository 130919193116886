import { showModal, closeModal } from "store/actions/notifications/";
import i18n from "i18n";
import { searchHasResult } from "utils/components";

export const COMPONENTS_SEARCH_CLEAR = "COMPONENTS_SEARCH_CLEAR";
export const COMPONENTS_SEARCH_ERROR = "COMPONENTS_SEARCH_ERROR";
export const COMPONENTS_SEARCH_REQUEST = "COMPONENTS_SEARCH_REQUEST";
export const COMPONENTS_SEARCH_SUCCESS = "COMPONENTS_SEARCH_SUCCESS";

export function searchComponents(params) {
  return async (dispatch, getState) => {
    if (params.trim().length === 0) {
      dispatch({
        type: COMPONENTS_SEARCH_CLEAR,
      });
      return;
    }

    dispatch({
      type: COMPONENTS_SEARCH_REQUEST,
    });

    dispatch(
      showModal({
        type: "wait",
        message: i18n.t("components.messages.searching"),
      }),
    );

    const { components: { components } } = await getState();

    const results = components.map((component) => {
      const response = component.malfunctions.filter((malfunction) => {
        const name = malfunction.name.toLowerCase();
        return name.includes(params.toLowerCase());
      });
      return {
        ...component,
        malfunctions: response,
        count: response.length,
      };
    });

    const hasResult = searchHasResult(results);

    dispatch({
      type: COMPONENTS_SEARCH_SUCCESS,
      payload: {
        results,
        hasResult,
        params,
      },
    });

    dispatch(closeModal());
  };
}

export function clearSearch() {
  return (dispatch) => {
    dispatch({
      type: COMPONENTS_SEARCH_CLEAR,
    });
  };
}

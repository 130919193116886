export * from "./api/components";
export * from "./api/malfunctions";
export * from "./api/placements";
export * from "./modal";
export * from "./search";

export const COMPONENTS_SELECT_MALFUNCTION_PAGE = "COMPONENTS_SELECT_MALFUNCTION_PAGE";
export const COMPONENTS_SET_TURBINE_TYPE = "COMPONENTS_SET_TURBINE_TYPE";
export const COMPONENTS_PLACEMENT_SET_LIST = "COMPONENTS_PLACEMENT_SET_LIST";
export const COMPONENTS_UPDATE_PLACEMENT_LIST = "COMPONENTS_UPDATE_PLACEMENT_LIST";

export function setPlacementList() {
  return (dispatch, getState) => {
    const {
      components: { turbineType },
      malfunctions: { turbines: { [turbineType]: { componentsMalfunctions: malfunctions } } },
    } = getState();
    dispatch({
      type: COMPONENTS_PLACEMENT_SET_LIST,
      payload: {
        malfunctions,
      },
    });
  };
}

export function updatePlacementList({ malfunction }) {
  return (dispatch) => {
    dispatch({
      type: COMPONENTS_UPDATE_PLACEMENT_LIST,
      payload: {
        malfunction,
      },
    });
  };
}

export function setTurbineType(turbineType) {
  return (dispatch) => {
    dispatch({
      type: COMPONENTS_SET_TURBINE_TYPE,
      payload: {
        turbineType,
      },
    });
  };
}

export function selectMalfunctionPage(page) {
  return (dispatch) => {
    dispatch({
      type: COMPONENTS_SELECT_MALFUNCTION_PAGE,
      payload: {
        page,
      },
    });
  };
}

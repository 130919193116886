import {
  ASSESSMENTS_EDIT,
  ASSESSMENTS_CLEAR_EDIT,
} from "store/actions";

export function assessmentsReducer(state, action) {
  switch (action.type) {
    case ASSESSMENTS_EDIT: {
      const { assessment } = action.payload;
      return {
        ...state,
        draft: assessment,
      };
    }
    case ASSESSMENTS_CLEAR_EDIT: {
      return {
        ...state,
        draft: null,
      };
    }
    default:
      return state;
  }
}

export default assessmentsReducer;

/**
 * @fileoverview Screen component for client select view
 * @author  Florian Berg
 */

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  selectCustomer,
  clearCustomer,
  showModal,
  closeModal,
  getAssessmentsOverview,
  setPathname,
} from "store/actions";
import i18n from "i18n";
import TitleBar from "components/layout/TitleBar";
import CustomerCard from "components/blocks/CustomerCard";
import Section from "components/layout/Section";
import CardGrid from "components/layout/CardGrid";
import { navigate } from "@reach/router";
import css from "./CustomerSelect.module.scss";

// Import css modules stylesheet as styles
class ClientSelect extends Component {
  handleViewCard = async (customerId, assetId, machineId) => {
    const {
      showModal,
      closeModal,
      getAssessmentsOverview,
      setPathname,
    } = this.props;
    showModal({
      type: "wait",
      message: i18n.t("messages.getHealthAssessments"),
    });
    await getAssessmentsOverview(customerId);
    closeModal();
    let path = `${customerId}/assessments`;
    if (assetId && !machineId) {
      path = `${customerId}/${assetId}/assessments`;
    } else if (assetId && machineId) {
      path = `${customerId}/${assetId}/${machineId}/assessments`;
    }
    setPathname(path);
    navigate(path);
  };

  render() {
    const { customers, isOPL } = this.props;
    return (
      <div className={css.screen}>
        <Section>
          <TitleBar title="Select Client" />
          <CardGrid>
            {customers.map(({ name, id, assessmentsCount }) => (
              <CustomerCard
                title={name}
                assessmentsCountGrouped={assessmentsCount}
                onClick={(customerId, assetId, machineId) => this.handleViewCard(
                  customerId, assetId, machineId,
                )}
                key={id}
                isOPL={isOPL}
              />
            ))}
          </CardGrid>
        </Section>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const {
    customers, assessments, auth,
  } = state;
  return {
    isOPL: auth.isOPL,
    customers: customers.customers,
    assessments: assessments.assessments,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({
      selectCustomer,
      clearCustomer,
      showModal,
      closeModal,
      getAssessmentsOverview,
      setPathname,
    }, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientSelect);

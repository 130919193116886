import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ToolTip from "components/elements/ToolTip";
import css from "./MultiactionButton.module.scss";

class MultiactionButton extends PureComponent {
  render() {
    const {
      icon, actions, large, place, id,
    } = this.props;
    if (!actions) {
      return null;
    }
    return (
      <>
        <button
          className={[css.button, large ? css.buttonLarge : null].join(" ")}
          data-tip
          data-for={id}
          onClick={actions.length === 1 ? actions[0].handler : null}
        >
          <span className={[css.icon, icon].join(" ")} />
        </button>
        {actions.length > 1
        && (
        <ToolTip place={place} id={id}>
          <ul className={css.actions}>
            {actions.map((action) => {
              const { name, handler } = action;
              return (
                <li key={name}>
                  <button className={[css.action]} onClick={handler}>{name}</button>
                </li>
              );
            })}
          </ul>
        </ToolTip>
        )}
      </>
    );
  }
}

MultiactionButton.propTypes = {
  icon: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, handler: PropTypes.func })),
  large: PropTypes.bool,
};

MultiactionButton.defaultProps = {
  icon: "icon-comment",
  large: false,
};

export default MultiactionButton;

import { api } from "utils/api";
import {
  closeModal,
  showMessage,
  showModal,
} from "store/actions/notifications/";

import { getCustomerActivities } from "store/actions/activities/";

import i18n from "i18n";
import { getRouteParams } from "utils/url";

export const MESSAGES_POST_REQUEST = "MESSAGES_POST_REQUEST";
export const MESSAGES_POST_SUCCESS = "MESSAGES_POST_SUCCESS";
export const MESSAGES_POST_ERROR = "MESSAGES_POST_ERROR";

export const MESSAGES_UPDATE_REQUEST = "MESSAGES_UPDATE_REQUEST";
export const MESSAGES_UPDATE_SUCCESS = "MESSAGES_UPDATE_SUCCESS";
export const MESSAGES_UPDATE_ERROR = "MESSAGES_UPDATE_ERROR";

export function postMessage(message) {
  return async (dispatch) => {
    try {
      dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.sendingMessage"),
        }),
      );
      dispatch({
        type: MESSAGES_POST_REQUEST,
      });

      const { customerId } = getRouteParams();

      const result = await api.post(
        `customers/${customerId}/messages`,
        message,
        {
          headers: {
            "content-type": "application/json",
          },
          withCredentials: true,
        },
      );
      dispatch({
        type: MESSAGES_POST_SUCCESS,
        payload: {
          message: result.data,
        },
      });
      await dispatch(getCustomerActivities(customerId));
      dispatch(closeModal());
      dispatch(
        showMessage({
          title: i18n.t("global.success"),
          text: i18n.t("messages.sendingMessageSuccess"),
          type: "alert",
        }),
      );
    } catch (error) {
      dispatch({
        type: MESSAGES_POST_ERROR,
      });
      await dispatch(closeModal());
      await dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("errors.errorSendingMessage"),
          type: "error",
        }),
      );
      console.log(error);
    }
  };
}

export function updateMessage(message) {
  return async (dispatch) => {
    try {
      dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.sendingMessage"),
        }),
      );
      dispatch({
        type: MESSAGES_UPDATE_REQUEST,
      });

      const { customerId } = getRouteParams();
      const { id } = message;

      const result = await api.put(
        `customers/${customerId}/messages/${id}`,
        message,
        {
          headers: {
            "content-type": "application/json",
          },
          withCredentials: true,
        },
      );
      dispatch({
        type: MESSAGES_UPDATE_SUCCESS,
        payload: {
          message: result.data,
        },
      });
      await dispatch(getCustomerActivities(customerId));
      dispatch(closeModal());
      dispatch(
        showMessage({
          title: i18n.t("global.success"),
          text: i18n.t("messages.sendingMessageSuccess"),
          type: "alert",
        }),
      );
    } catch (error) {
      dispatch({
        type: MESSAGES_UPDATE_ERROR,
      });
      await dispatch(closeModal());
      await dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("errors.errorSendingMessage"),
          type: "error",
        }),
      );
      console.log(error);
    }
  };
}

/**
 * @fileoverview Modal component to write a new message
 * @author  Florian Berg
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  postMessage,
  updateMessage,
  editMessage,
  clearEditMessage,
  getMessageUploadLink,
} from "store/actions";
import i18n from "i18n";
import MessageEdit from "components/blocks/MessageEdit";

class Message extends Component {
  constructor(props) {
    super(props);
    this.handleSend = this.handleSend.bind(this);
  }

  componentDidUpdate() {
    const { draft, clearEditMessage } = this.props;
    if (draft) {
      document.body.classList.add("no-scroll");
      document.addEventListener(
        "keydown",
        (event) => {
          const { key } = event;
          if (key === "Escape") {
            clearEditMessage();
          }
        },
        { once: true },
      );
    } else {
      document.body.classList.remove("no-scroll");
    }
  }

  async handleSend() {
    const {
      updateMessage,
      postMessage,
      editExistingMessage,
      draft,
    } = this.props;
    if (editExistingMessage) {
      updateMessage(draft);
    } else {
      postMessage(draft);
    }
  }

  render() {
    const {
      draft,
      editMessage,
      clearEditMessage,
      editExistingMessage,
      getMessageUploadLink,
    } = this.props;
    if (!draft) {
      return null;
    }
    return (
      <MessageEdit
        draft={draft}
        title={
          editExistingMessage
            ? i18n.t("views.messagePopUp.editMessage")
            : i18n.t("views.messagePopUp.writeNewMessage")
        }
        onUpdate={editMessage}
        onClose={clearEditMessage}
        onRequestUploadLink={getMessageUploadLink}
        onSend={this.handleSend}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        postMessage,
        updateMessage,
        editMessage,
        clearEditMessage,
        getMessageUploadLink,
      },
      dispatch,
    ),
  };
}

function mapStateToProps(state) {
  const { messages } = state;
  const { draft } = messages;
  let editExistingMessage = false;
  if (draft) {
    editExistingMessage = draft.hasOwnProperty("id");
  }
  return {
    draft: messages.draft,
    editExistingMessage,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Message);

import {
  NOTIFICATIONS_SHOW_MODAL,
  NOTIFICATIONS_CLOSE_MODAL,
  NOTIFICATIONS_SHOW_MESSAGE,
  NOTIFICATIONS_CLOSE_MESSAGE,
  NOTIFICATIONS_CLOSE_ALL_MESSAGES,
} from "../actions";

const initialState = {
  modal: null,
  messages: [],
};

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS_SHOW_MODAL: {
      const { modal } = action.payload;
      return {
        ...state,
        modal,
      };
    }
    case NOTIFICATIONS_CLOSE_MODAL:
      return {
        ...state,
        modal: null,
      };
    case NOTIFICATIONS_SHOW_MESSAGE: {
      const now = Date.now();
      const { message } = action.payload;
      return {
        ...state,
        messages: [
          ...state.messages.filter((message) => {
            if (!message.sticky) {
              return now - message.timeStamp < message.duration;
            }
            return true;
          }),
          message,
        ],
      };
    }
    case NOTIFICATIONS_CLOSE_MESSAGE: {
      const { id } = action.payload;
      return {
        ...state,
        messages: [...state.messages.filter((message) => message.id !== id)],
      };
    }
    case NOTIFICATIONS_CLOSE_ALL_MESSAGES: {
      return {
        ...state,
        messages: [],
      };
    }
    default:
      return state;
  }
};

export default notifications;

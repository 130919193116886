import React, { Component } from "react";
import PropTypes from "prop-types";
import i18n from "i18n";
import { ReactComponent as Arrow } from "assets/svgs/ic_arrow.svg";
import css from "./DropDown.module.scss";

function RecursiveList(props) {
  const {
    items,
    onClick,
    maxLevel,
    minLevel,
    level,
    disabledLevels,
    disabledItems,
    selected,
    translationKey,
    translationPath,
  } = props;

  if (level < minLevel) {
    return items.map((item, index) => (
      <RecursiveList
        key={`${index + item.name}`}
        level={level + 1}
        items={item.children}
        maxLevel={maxLevel}
        minLevel={minLevel}
        onClick={onClick}
        disabledLevels={disabledLevels}
        disabledItems={disabledItems}
        selected={selected}
        translationKey={translationKey}
        translationPath={translationPath}
      />
    ));
  }
  return (
    <ul className={css.list}>
      {items.map((item, index) => (
        <li key={`${index + item.name}`}>
          <div
            onClick={() => onClick(item, index, level)}
            className={[
              css.listItem,
              selected ? selected.id === item.id ? css.selected : null : null, //eslint-disable-line
              disabledLevels.includes(level) ? css.levelDisabled : null,
              disabledItems.includes(item) ? css.listItemDisabled : null].join(" ")}
          >
            {item.icon && <span className={[item.icon, css.icon].join(" ")} />}
            <span>{i18n.t([`${translationPath}${item[translationKey]}`, item.name])}</span>
          </div>
          {item.children && level < maxLevel && (
          <RecursiveList
            key={`${index + item.name}`}
            level={level + 1}
            items={item.children}
            maxLevel={maxLevel}
            minLevel={minLevel}
            onClick={onClick}
            disabledLevels={disabledLevels}
            disabledItems={disabledItems}
            selected={selected}
            translationKey={translationKey}
            translationPath={translationPath}
          />
          )}
        </li>
      ))}
    </ul>
  );
}

function DropDownButton(props) {
  const {
    id,
    placeholder,
    onClick,
    selected = null,
    translationKey,
    translationPath,
  } = props;

  return (
    <>
      <button id={id} className={css.button} onClick={onClick}>
        {!selected && placeholder && (
          <div className={css.placeholder}>{placeholder}</div>
        )}
        {selected && (
          <div className={css.placeholder}>
            {selected.icon && (
            <span className={[selected.icon, css.icon].join(" ")} />
            )}
            <span>{i18n.t([`${translationPath}${selected[translationKey]}`, selected.name])}</span>
          </div>
        )}
        <Arrow className={css.arrowDown} />
      </button>
    </>
  );
}

function DropDownContainer(props) {
  const { children, placeholder } = props;
  return (
    <div className={css.container}>
      <div className={css.header}>
        {placeholder && <span className={css.placeholder}>{placeholder}</span>}
      </div>
      {children}
    </div>
  );
}

class DropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleItemClick = (item) => {
    const selectedItem = { ...item };
    delete selectedItem.children;
    this.setState(() => ({
      open: false,
    }));
    const { update, id } = this.props;
    update({ id, value: { item: selectedItem } });
  };

  handleToggle = () => {
    document.addEventListener(
      "click",
      (event) => {
        const dropDown = document.getElementsByClassName(css.dropDown);
        const path = event.path || (event.composedPath && event.composedPath());
        if (!path.includes(dropDown)) {
          this.setState(() => ({
            open: false,
          }));
        }
      },
      { once: true },
    );
    this.setState(() => ({
      open: true,
    }));
  };

  render() {
    const {
      id,
      className,
      label,
      items,
      maxLevel,
      minLevel,
      required,
      placeholder,
      disabledLevels,
      disabledItems,
      selected = null,
      translationKey,
      translationPath,
    } = this.props;
    const { open } = this.state;
    return (
      <div className={[className, css.dropDown].join(" ")}>
        <DropDownButton
          id={id}
          onClick={this.handleToggle}
          label={label}
          selected={selected}
          required={required}
          placeholder={placeholder}
          translationKey={translationKey}
          translationPath={translationPath}
        />
        {open && items && (
          <DropDownContainer placeholder={placeholder}>
            <RecursiveList
              level={0}
              maxLevel={maxLevel}
              minLevel={minLevel}
              onClick={this.handleItemClick}
              items={items}
              disabledLevels={disabledLevels}
              disabledItems={disabledItems}
              selected={selected}
              translationKey={translationKey}
              translationPath={translationPath}
            />
          </DropDownContainer>
        )}
      </div>
    );
  }
}

DropDown.propTypes = {
  id: PropTypes.string,
  items: PropTypes.array,
  label: PropTypes.string,
  selected: PropTypes.object,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  maxLevel: PropTypes.number,
  minLevel: PropTypes.number,
  disabledLevels: PropTypes.array,
  disabledItems: PropTypes.array,
  error: PropTypes.string,
  translationKey: PropTypes.string,
  translationPath: PropTypes.string,
};

DropDown.defaultProps = {
  maxLevel: 2,
  minLevel: 0,
  disabledLevels: [],
  disabledItems: [],
  error: null,
};

export default DropDown;

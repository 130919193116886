/**
 * @fileoverview Screen component for activity stream
 * @author  Florian Berg
 */

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  editMessage,
  createNewMessage,
  updateMessage,
  getCustomerActivities,
  getCustomerAssessmentActivities,
  showActivityNotifcationSettings,
} from "store/actions";
import Section from "components/layout/Section";
import NavigationBar from "components/layout/NavigationBar";
import i18n from "i18n";
import ActionButton from "components/elements/ActionButton";
import MessageList from "components/blocks/MessageList";
import timeout from "utils/asyncTimeOut";
import css from "./ActivityStream.module.scss"; // Import css modules stylesheet as styles

function sortDescendingByHappenedAt(a, b) {
  const keyA = new Date(a.happenedAt);
  const keyB = new Date(b.happenedAt);
  if (keyA > keyB) return -1;
  if (keyA < keyB) return 1;
  return 0;
}

function sortDescendingByPublishedAt(a, b) {
  const keyA = new Date(a.publishedAt);
  const keyB = new Date(b.publishedAt);
  if (keyA > keyB) return -1;
  if (keyA < keyB) return 1;
  return 0;
}

function happenedAfter(timeWindowStart, happenedAt) {
  const d1 = new Date(timeWindowStart);
  const d2 = new Date(happenedAt);
  return d2 >= d1;
}

class ActivityStream extends Component {
  constructor(props) {
    super(props);
    this.handleNewMessage = this.handleNewMessage.bind(this);
    this.handleEditMessage = this.handleEditMessage.bind(this);
    this.customerActivities = [];
    this.currentIndex = 0;
    this.timeWindowStart = null;
    this.timeWindowEnd = null;
  }

  async fetchAssessmentsActivitiesNextTimeRange() {
    const {
      customer,
      publishedAssessments,
      getCustomerAssessmentActivities,
    } = this.props;
    for (const assessment of publishedAssessments) { // eslint-disable-line no-restricted-syntax
      if (assessment.timeWindowStart === this.timeWindowStart
        && assessment.timeWindowEnd === this.timeWindowEnd) {
        await getCustomerAssessmentActivities( // eslint-disable-line no-await-in-loop
          customer.id, assessment.id,
        );
        this.currentIndex += 1;
      }
    }
  }

  async componentDidMount() {
    const {
      customer,
      publishedAssessments,
      getCustomerActivities,
    } = this.props;
    // lazy load activities
    if (publishedAssessments && publishedAssessments.length > 0) {
      // fetch activities (published, commented) within the same range of latest assessment
      const latestAssessment = publishedAssessments[0];
      this.timeWindowStart = latestAssessment.timeWindowStart;
      this.timeWindowEnd = latestAssessment.timeWindowEnd;
      await this.fetchAssessmentsActivitiesNextTimeRange();
    }
    // then fetch user (signedup) and customer (messaged) activities.
    await getCustomerActivities(customer.id);
    document.addEventListener("scroll", this.trackScrolling);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  isBottom = (el) => el.getBoundingClientRect().bottom <= window.innerHeight;

  trackScrolling = async () => {
    const { publishedAssessments } = this.props;
    const activityMessagesDiv = document.getElementById("activityMessages");
    if (this.isBottom(activityMessagesDiv)) {
      document.removeEventListener("scroll", this.trackScrolling);
      if (this.currentIndex <= publishedAssessments.length - 1) {
        const currentIndexAssessment = publishedAssessments[this.currentIndex];
        this.timeWindowStart = currentIndexAssessment.timeWindowStart;
        this.timeWindowEnd = currentIndexAssessment.timeWindowEnd;
        await this.fetchAssessmentsActivitiesNextTimeRange();
        await timeout(1000);
        document.addEventListener("scroll", this.trackScrolling);
      }
    }
  };

  handleNewMessage() {
    const { createNewMessage } = this.props;
    createNewMessage();
  }

  handleEditMessage(message) {
    const { editMessage } = this.props;
    editMessage(message);
  }

  provideCustomNavigationBarActions() {
    const { showActivityNotifcationSettings } = this.props;
    const actions = [
      { name: "", handler: showActivityNotifcationSettings },
    ];
    return actions;
  }

  render() {
    const {
      loading,
      customer,
      publishedAssessments,
      activities,
    } = this.props;
    let customerActivitiesInRange;
    let lazyLoadedActivities = [];
    const customerActivities = activities.customers[customer.id];
    if (customerActivities) {
      customerActivitiesInRange = customerActivities.filter(
        (a) => happenedAfter(this.timeWindowStart, a.happenedAt),
      );
      if (customerActivitiesInRange) {
        if (this.customerActivities.length === 0) {
          this.customerActivities = customerActivitiesInRange;
        } else {
          customerActivitiesInRange.forEach((a1) => {
            const found = this.customerActivities.find(
              (a2) => (a2.happenedAt === a1.happenedAt),
            );
            if (!found) {
              this.customerActivities.push(a1);
            }
          });
        }
      }
      lazyLoadedActivities = lazyLoadedActivities.concat(this.customerActivities);
    }
    if (publishedAssessments) {
      publishedAssessments.forEach((assessment) => {
        const assessmentActivities = activities.assessments[assessment.id];
        if (assessmentActivities) {
          lazyLoadedActivities = lazyLoadedActivities.concat(assessmentActivities);
        }
      });
    }
    if (lazyLoadedActivities.length > 0) {
      lazyLoadedActivities = lazyLoadedActivities.sort(sortDescendingByHappenedAt);
    }
    return (
      <div className={css.screen}>
        <NavigationBar
          preline={customer.name}
          title={i18n.t("global.activityStream")}
          backLink="assessments/"
          customAction={
            this.provideCustomNavigationBarActions()
            }
          customActionIcon="icon-settings_01"
        />
        <Section noMargin className={css.activities}>
          <ActionButton
            icon="icon-documentation"
            onClick={this.handleNewMessage}
            title={`${i18n.t("global.writeNewMessage")}`}
            large
          />
          <div id="activityMessages">
            <MessageList
              onEdit={this.handleEditMessage}
              loading={loading}
              messages={lazyLoadedActivities}
            />
          </div>
        </Section>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const {
    customers,
    assessments,
    activities,
  } = state;
  const { customerId } = props;
  const publishedAssessments = assessments.assessmentsoverview[Number(customerId)].filter(
    (a) => a.publishedAt !== null,
  ).sort(sortDescendingByPublishedAt);
  return {
    publishedAssessments,
    activities,
    loading: activities.loading,
    customer: customers.customers.filter(
      (customer) => customer.id === Number.parseInt(customerId, 10),
    )[0],
  };
}
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        editMessage,
        createNewMessage,
        updateMessage,
        getCustomerActivities,
        getCustomerAssessmentActivities,
        showActivityNotifcationSettings,
      },
      dispatch,
    ),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActivityStream);

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import css from "./HealthStatusIndicator.module.scss";

export class HealthStatusIndicator extends PureComponent {
  render() {
    const { value, className } = this.props;
    if (!value) {
      return null;
    }
    return (
      <div className={[css.container, className].join(" ")}>
        <div className={css.indicator}>
          <div className={css.scale}>
            <span style={{ left: "0%" }} className={css.scaleIndex} />
            <span style={{ left: "25%" }} className={css.scaleIndex} />
            <span style={{ left: "50%" }} className={css.scaleIndex} />
            <span style={{ left: "75%" }} className={css.scaleIndex} />
            <span style={{ left: "calc(100% - 2px)" }} className={css.scaleIndex} />
          </div>
          <div title={value} className={css.valueBar} style={{ width: `${Math.min(Math.max(value, 0), 1.0) * 100}%` }} />
        </div>
        <div className={css.value}>
          {value.toString().replace(".", ",")}
        </div>
      </div>
    );
  }
}

HealthStatusIndicator.propTypes = {
  value: PropTypes.number,
};
HealthStatusIndicator.defaultProps = {};
export default HealthStatusIndicator;

import React, { PureComponent } from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import css from "./ToolTip.module.scss";

class ToolTip extends PureComponent {
  constructor(props) {
    super(props);
    this.toolTip = React.createRef();
  }

  rebuild() {
    this.toolTip.current.globalRebuild();
  }

  render() {
    const {
      className, children, id, place, getContent,
    } = this.props;
    if (getContent) {
      return (
        <ReactTooltip
          ref={this.toolTip}
          id={id}
          place={place}
          className={[className, css.tooltip].join(" ")}
          delayHide={200}
          aria-haspopup="true"
          type="light"
          effect="solid"
          clickable
          getContent={getContent}
        />
      );
    }
    return (
      <ReactTooltip
        ref={this.toolTip}
        id={id}
        place={place}
        className={[className, css.tooltip].join(" ")}
        delayHide={200}
        aria-haspopup="true"
        type="light"
        effect="solid"
        clickable
      >
        {children}
      </ReactTooltip>
    );
  }
}

ToolTip.propTypes = {
  id: PropTypes.string,
  place: PropTypes.string,
  getContent: PropTypes.func,
};

ToolTip.defaultProps = {
  id: "tooltip",
  place: "left",
};
export default ToolTip;

import {
  COMMENTS_NEW_COMMENT,
  COMMENTS_CLEAR_EDIT,
  COMMENTS_EDIT_COMMENT,
} from "store/actions";

export function commentsReducer(state, action) {
  switch (action.type) {
    case COMMENTS_NEW_COMMENT: {
      const { type } = action.payload;
      return {
        ...state,
        draft: {
          type,
        },
      };
    }
    case COMMENTS_CLEAR_EDIT: {
      return {
        ...state,
        draft: null,
      };
    }
    case COMMENTS_EDIT_COMMENT: {
      const { message } = action.payload;
      return {
        ...state,
        draft: message,
      };
    }
    default:
      return state;
  }
}

export default commentsReducer;

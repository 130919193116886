import React from "react";
import MalfunctionCard from "components/elements/MalfunctionCard";
import i18n from "i18n";
import css from "./Components.module.scss";

function AddMalfunctionCard({ displayModal, component, display }) {
  const modal = {
    display: true,
    type: "ADD_MALFUNCTION",
    data: component,
  };
  return (
    <section className={css.card} style={display ? { display: "none" } : {}}>
      <h4>{ i18n.t("components.malfunctions.add_malfunction") }</h4>
      <span style={{ color: "#2d4275" }} onClick={() => displayModal(modal)} className="icon-add" />
    </section>
  );
}

function Components({ component, displayModal, search: display }) {
  return (
    <section className={css.container}>
      <AddMalfunctionCard displayModal={displayModal} component={component} display={display} />
      { component.malfunctions && component.malfunctions.map((malfunction) => (
        <MalfunctionCard
          key={malfunction.id}
          malfunction={malfunction}
        />
      ))}
    </section>
  );
}

export default Components;

import {
  COMPONENTS_SEARCH_CLEAR,
  COMPONENTS_SEARCH_ERROR,
  COMPONENTS_SEARCH_REQUEST,
  COMPONENTS_SEARCH_SUCCESS,
} from "store/actions";

const initialState = {
  error: false,
  loading: false,
  display: false,
  results: [],
  hasResult: false,
  params: "",
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPONENTS_SEARCH_ERROR: {
      return {
        ...state,
        search: {
          error: true,
          loading: false,
          results: [],
          display: false,
          hasResult: false,
          params: "",
        },
      };
    }
    case COMPONENTS_SEARCH_REQUEST: {
      return {
        ...state,
        search: {
          error: false,
          loading: true,
          results: [],
          display: false,
          hasResult: false,
          params: "",
        },
      };
    }
    case COMPONENTS_SEARCH_SUCCESS: {
      const { results, hasResult, params } = action.payload;
      return {
        ...state,
        search: {
          error: false,
          loading: false,
          display: true,
          results,
          hasResult,
          params,
        },
      };
    }

    case COMPONENTS_SEARCH_CLEAR: {
      return {
        ...state,
        search: {
          ...state.search,
          display: false,
          hasResult: false,
        },
      };
    }
    default:
      return state;
  }
};

export default searchReducer;

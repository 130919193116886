import { AUTH_USER_ERROR } from "store/actions/auth/";
import { api } from "utils/api";
import { closeModal, showMessage } from "store/actions/notifications/";
import i18n from "i18n";

import { APP_FORBIDDEN_ACCESS, APP_GLOBAL_ERROR } from "store/actions/app";

export const GET_CUSTOMER_ACTIVITIES_REQUEST = "GET_CUSTOMER_ACTIVITIES_REQUEST";
export const GET_CUSTOMER_ACTIVITIES_SUCCESS = "GET_CUSTOMER_ACTIVITIES_SUCCESS";
export const GET_CUSTOMER_ACTIVITIES_ERROR = "GET_CUSTOMER_ACTIVITIES_ERROR";

export const GET_ASSESSMENT_ACTIVITIES_REQUEST = "GET_ASSESSMENT_ACTIVITIES_REQUEST";
export const GET_ASSESSMENT_ACTIVITIES_SUCCESS = "GET_ASSESSMENT_ACTIVITIES_SUCCESS";
export const GET_ASSESSMENT_ACTIVITIES_ERROR = "GET_ASSESSMENT_ACTIVITIES_ERROR";

export const ACTIVITIES_POST_REQUEST = "ACTIVITIES_POST_REQUEST";
export const ACTIVITIES_POST_SUCCESS = "ACTIVITIES_POST_SUCCESS";
export const ACTIVITIES_POST_ERROR = "ACTIVITIES_POST_ERROR";

export const ACTIVITIES_UPDATE_REQUEST = "ACTIVITIES_UPDATE_REQUEST";
export const ACTIVITIES_UPDATE_SUCCESS = "ACTIVITIES_UPDATE_SUCCESS";
export const ACTIVITIES_UPDATE_ERROR = "ACTIVITIES_UPDATE_ERROR";

export function getCustomerActivities(customerId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_CUSTOMER_ACTIVITIES_REQUEST,
      });
      const result = await api.get(`/customers/${customerId}/activities`, {
        withCredentials: true,
      });
      const { status } = result;
      if (status === 200) {
        const { data } = result;
        dispatch({
          type: GET_CUSTOMER_ACTIVITIES_SUCCESS,
          payload: {
            activities: data,
            customerId,
          },
        });
      } else {
        throw new Error(result.statusText);
      }
    } catch (error) {
      dispatch(closeModal());
      const { response } = error;
      if (response) {
        const { status } = response;
        switch (status) {
          case 401:
            dispatch({
              type: AUTH_USER_ERROR,
            });
            break;
          case 403:
            dispatch({
              type: APP_FORBIDDEN_ACCESS,
            });
            break;
          default:
            dispatch({
              type: APP_GLOBAL_ERROR,
            });
        }
      } else {
        dispatch({
          type: APP_GLOBAL_ERROR,
        });
      }
      dispatch({
        type: GET_CUSTOMER_ACTIVITIES_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("errors.errorGetActivities"),
          type: "error",
        }),
      );
    }
  };
}

export function getCustomerAssessmentActivities(customerId, assessmentId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_ASSESSMENT_ACTIVITIES_REQUEST,
      });
      const result = await api.get(`/customers/${customerId}/assessments/${assessmentId}/activities`, {
        withCredentials: true,
      });
      const { status } = result;
      if (status === 200) {
        const { data } = result;
        dispatch({
          type: GET_ASSESSMENT_ACTIVITIES_SUCCESS,
          payload: {
            activities: data,
            assessmentId,
          },
        });
      } else {
        throw new Error(result.statusText);
      }
    } catch (error) {
      dispatch(closeModal());
      const { response } = error;
      if (response) {
        const { status } = response;
        switch (status) {
          case 401:
            dispatch({
              type: AUTH_USER_ERROR,
            });
            break;
          case 403:
            dispatch({
              type: APP_FORBIDDEN_ACCESS,
            });
            break;
          default:
            dispatch({
              type: APP_GLOBAL_ERROR,
            });
        }
      } else {
        dispatch({
          type: APP_GLOBAL_ERROR,
        });
      }
      dispatch({
        type: GET_ASSESSMENT_ACTIVITIES_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("errors.errorGetActivities"),
          type: "error",
        }),
      );
    }
  };
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import css from "./MultilineInput.module.scss";

class MultilineInput extends Component {
  handleChange = (event) => {
    const { update, id } = this.props;
    if (update) {
      update({ id, value: event.target.value });
    }
  };

  render() {
    const {
      text, placeholder, label, small, id, className,
    } = this.props;
    return (
      <div
        className={[css.multilineInput, small ? css.small : css.normal, className].join(
          " ",
        )}
      >
        {label && <label htmlFor={id} className={css.label}>{label}</label>}
        <div className={css.input}>
          <textarea
            id={id}
            onChange={this.handleChange}
            placeholder={placeholder}
            className={css.textArea}
            value={text || undefined}
          />
        </div>
      </div>
    );
  }
}

MultilineInput.propTypes = {
  editable: PropTypes.bool,
  text: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  small: PropTypes.bool,
  update: PropTypes.func,
  fullWidth: PropTypes.bool,
};

MultilineInput.defaultProps = {
  editable: true,
  text: "",
  placeholder: "Enter Text",
};
export default MultilineInput;

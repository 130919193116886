export * from "./api/comments";
export * from "./upload/comments";

export const COMMENTS_NEW_COMMENT = "COMMENT_NEW_COMMENT";
export const COMMENTS_EDIT_COMMENT = "COMMENT_EDIT_COMMENT";
export const COMMENTS_CLEAR_EDIT = "COMMENT_CLEAR_EDIT";

export function createNewComment(messageType) {
  return (dispatch) => {
    dispatch({
      type: COMMENTS_NEW_COMMENT,
      payload: {
        type: messageType,
      },
    });
  };
}

export function editComment(message, messageType) {
  return (dispatch) => {
    dispatch({
      type: COMMENTS_EDIT_COMMENT,
      payload: { message, messageType },
    });
  };
}

export function clearEditComment() {
  return (dispatch) => {
    dispatch({
      type: COMMENTS_CLEAR_EDIT,
    });
  };
}

export const COMPONENTS_DISPLAY_MODAL = "COMPONENTS_DISPLAY_MODAL";
export const COMPONENTS_CLOSE_MODAL = "COMPONENTS_CLOSE_MODAL";

export function showComponentsModal(modal) {
  return (dispatch, getState) => {
    const { components } = getState();
    if (components.modal && components.modal.display) {
      return;
    }
    dispatch({
      type: COMPONENTS_DISPLAY_MODAL,
      payload: {
        modal,
      },
    });
  };
}

export function closeComponentsModal() {
  return (dispatch, getState) => {
    const { components } = getState();
    if (components.modal && !components.modal.display) {
      return;
    }
    dispatch({
      type: COMPONENTS_CLOSE_MODAL,
    });
  };
}

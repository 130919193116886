import React, { Component } from "react";
import PropTypes from "prop-types";
import i18n from "i18n";
import FileSelect from "components/input/FileSelect";
import css from "./MultiFileUpload.module.scss";

class MultiFileUpload extends Component {
  render() {
    const {
      onUpdate,
      onRequestUploadLink,
      files,
      fileTypes,
      className,
    } = this.props;

    return (
      <div className={[className, css.container].join(" ")}>
        {files
          && files.map((file, index) => (
            <FileSelect
              className={css.fileSelect}
              id="file"
              update={(update) => {
                if (onUpdate) { onUpdate({ ...update, updateIndex: index }); }
              }}
              requestUploadLinkAction={onRequestUploadLink}
              placeholder={i18n.t("global.addFile")}
              file={file}
              key={file.url}
              fileTypes={fileTypes}
            />
          ))}
        <FileSelect
          className={css.fileSelect}
          id="file"
          update={(update) => {
            if (onUpdate) { onUpdate(update); }
          }}
          requestUploadLinkAction={onRequestUploadLink}
          placeholder={i18n.t("global.addFile")}
          fileTypes={fileTypes}
        />
      </div>
    );
  }
}

MultiFileUpload.propTypes = {
  fileTypes: PropTypes.string,
  className: PropTypes.string,
};

MultiFileUpload.defaultProps = {
  fileTypes:
    "image/png, image/jpeg, application/pdf,text/comma-separated-values, application/hds, application/tdms",
};
export default MultiFileUpload;

/**
 * @fileoverview Screen component for health assessment edit view
 * @author  Florian Berg
 */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Actions from "store/actions";
import Headline from "components/elements/Headline";
import Button from "components/elements/Button";
import Section from "components/layout/Section";
import DropDown from "components/input/DropDown";
import TextField from "components/input/TextField";
import NumberInput from "components/input/NumberInput";
import MultilineInput from "components/input/MultilineInput";
import i18n from "i18n";
import { navigate } from "@reach/router";
import timeout from "utils/asyncTimeOut";
import { findCard } from "utils/cards";
import {
  cardStatusTypes, recommendationTypes, cardType,
} from "utils/types";
import { malfunctionTypes, cardStatus } from "utils/constants";
import { cardValidator } from "utils/validators";
import { findMalfunction } from "utils/helper";

import Field from "components/input/Field";
import NavigationBar from "components/layout/NavigationBar";
import { getInputConfigurationByCardType } from "utils/malfunctions";
import { getComponentsTranslated } from "utils/components";
import { MultiSelect } from "components/input/MultiSelect";
import CheckBox from "components/input/CheckBox";
import { convertHSIToStatus } from "utils/hsi";
import ArgumentationsEdit from "./ArgumentationsEdit";
import RecommendationsEdit from "./RecommendationsEdit";
import RisksEdit from "./RisksEdit";
import css from "./AssessmentEdit.module.scss";

class AssessmentEdit extends Component {
  async componentDidMount() {
    const {
      card, assessment, cardId, editAssessmentCard,
    } = this.props;
    try {
      if (card) {
        if (card.id !== cardId) {
          throw new Error("could not find card");
        }
      } else {
        const cardToEdit = findCard(assessment.cards, Number.parseInt(cardId, 10));
        if (!cardToEdit) {
          throw new Error("could not find card");
        } else {
          editAssessmentCard(cardToEdit);
        }
      }
    } catch (error) {
      const { customerId, assessmentId } = this.props;
      await timeout(500);
      navigate(`/${customerId}/assessments/${assessmentId}`);
    }
  }

  componentWillUnmount() {
    const { clearAssessmentCardDraft } = this.props;
    clearAssessmentCardDraft();
  }

  saveCard = async (card) => {
    const {
      assessmentId, customerId, assetId, machineId, saveAssessmentCard,
    } = this.props;
    const { id } = await saveAssessmentCard(
      customerId,
      assessmentId,
      card,
    );
    if (id) {
      let path = `/${customerId}/assessments/${assessmentId}/cards/${id}`;
      if (assetId && !machineId) {
        path = `/${customerId}/${assetId}/assessments/${assessmentId}/cards/${id}`;
      } else if (assetId && machineId) {
        path = `/${customerId}/${assetId}/${machineId}/assessments/${assessmentId}/cards/${id}`;
      }
      navigate(path);
    }
  }

  handleSubmit = async () => {
    const {
      card,
      showModal,
    } = this.props;
    const errors = cardValidator(card);
    if (errors.length) {
      showModal({
        type: "action",
        style: "warning",
        message: (
          <div>
            <span>{`${i18n.t("global.missingData")}: `}</span>
            {errors.map((error, index, errors) => (
              <span key={error.name}>
                {`${error.name}${
                  errors.length - index === 1 ? "" : ", "
                }`}
              </span>
            ))}
          </div>
        ),
        actionText: i18n.t("global.save"),
        primaryAction: () => this.saveCard(card),
      });
    } else {
      showModal({
        type: "action",
        style: "ok",
        message: i18n.t("modals.saveAssessmentCard"),
        actionText: i18n.t("global.save"),
        primaryAction: () => this.saveCard(card),
      });
    }
  };

  handleCancel = () => {
    const { showModal, closeModal } = this.props;
    showModal({
      type: "action",
      style: "warning",
      message: i18n.t("modals.cancelEditHealthAssessment"),
      actionText: i18n.t("global.discard"),
      primaryAction: () => {
        closeModal();
        window.history.back();
      },
    });
  };

  handleDelete = () => {
    const { showModal, deleteAssessmentCard } = this.props;
    showModal({
      type: "action",
      style: "error",
      message: i18n.t("modals.deleteAssessmentCard"),
      actionText: i18n.t("global.delete"),
      primaryAction: async () => {
        const {
          customerId, assetId, machineId, assessmentId, card,
        } = this.props;
        await deleteAssessmentCard(
          customerId,
          assessmentId,
          card.id,
        );
        let path = `/${customerId}/assessments/${assessmentId}`;
        if (assetId && !machineId) {
          path = `/${customerId}/${assetId}/assessments/${assessmentId}`;
        } else if (assetId && machineId) {
          path = `/${customerId}/${assetId}/${machineId}/assessments/${assessmentId}`;
        }
        navigate(path);
      },
    });
  };

  handleChange = (update) => {
    const { id } = update;
    const { card, editAssessmentCard } = this.props;
    switch (id) {
      case "description":
        editAssessmentCard({
          ...card,
          description: update.value,
        });
        break;
      case "component": {
        const { item } = update.value;
        const { id } = item;
        editAssessmentCard({
          ...card,
          componentId: id,
        });
        break;
      }
      case "status": {
        const { type } = update.value.item;
        editAssessmentCard({
          ...card,
          statusType: type,
          highlight: false,
        });
        break;
      }
      case "highlight": {
        editAssessmentCard({
          ...card,
          highlight: update.value,
        });
        break;
      }
      case "recommendationType": {
        const { type } = update.value.item;
        editAssessmentCard({
          ...card,
          recommendationType: type,
        });
        break;
      }
      case "editorsNote": {
        editAssessmentCard({
          ...card,
          editorsNote: update.value,
        });
        break;
      }
      case "argumentations": {
        editAssessmentCard({
          ...card,
          argumentations: update.value,
        });
        break;
      }
      case "recommendations": {
        editAssessmentCard({
          ...card,
          recommendations: update.value,
        });
        break;
      }
      case "risks": {
        editAssessmentCard({
          ...card,
          risks: update.value,
        });
        break;
      }
      case "hsi": {
        editAssessmentCard({
          ...card,
          hsi: update.value,
          statusType: convertHSIToStatus(update.value),
        });
        break;
      }
      case "malfunctionHSI": {
        const { item } = update.value;
        const { id } = item;
        editAssessmentCard({
          ...card,
          malfunctionId: id,
        });
        break;
      }
      case "malfunctionAC": {
        const { item } = update.value;
        const { id } = item;
        editAssessmentCard({
          ...card,
          malfunctionId: id,
        });
        break;
      }
      case "cardType": {
        editAssessmentCard({
          ...card,
          hsi: undefined,
          malfunctionId: null,
          statusType: null,
          tag: null,
          type: update.value.filter((item) => item.value === true)[0].id,
        });
        break;
      }
      case "tag": {
        editAssessmentCard({
          ...card,
          tag: update.value,
        });
        break;
      }
      default:
        console.error("cannot update card");
    }
  };

  render() {
    const {
      components,
      malfunctionsHSI,
      malfunctionsAC,
      card,
      loading,
    } = this.props;
    if (!card) {
      return null;
    }
    const {
      type = cardType.MALFUNCTION,
      description,
      recommendations,
      risks,
      editorsNote,
      argumentations,
      statusType,
      recommendationType,
      componentId,
      hsi,
      malfunctionId,
      tag,
      disableMalfunctionChange,
      highlight,
    } = card;
    const {
      hasHSI,
      hasMalfunctionAC,
      hasMalfunctionHSI,
      hasStatus,
      hasTag,
      hasLinkedComponent,
    } = getInputConfigurationByCardType(type);
    const showHighlight = statusType === cardStatus.OPERATIONAL;
    return (
      <div className={css.screen}>
        <NavigationBar
          backAction={this.handleCancel}
          title={`${i18n.t("global.back").toUpperCase()}`}
          small
        />
        {/* Title */}
        <Section noMargin padding>
          <Headline>{i18n.t("views.editAssessment.title")}</Headline>
        </Section>
        {/* Description */}
        <Section borderTop padding>
          <Field
            id="description"
            label={i18n.t("views.editAssessment.descriptionLabel")}
            onChange={this.handleChange}
          >
            {(id, onChange) => (
              <TextField
                id={id}
                update={onChange}
                text={description || undefined}
                maxLength={64}
              />
            )}
          </Field>
        </Section>
        {/* Card Type */}
        <Section borderTop padding>
          <Field
            id="cardType"
            label={i18n.t("views.editAssessment.cardTypeLabel")}
            onChange={this.handleChange}
          >
            {(id, onChange) => (
              <MultiSelect
                id={id}
                type="single"
                label={i18n.t("views.editAssessment.cardTypeLabel")}
                onChange={onChange}
                items={cardType.map(
                  (item) => ({ ...item, id: item.type, value: (type === item.type) }),
                )}
                disabled={disableMalfunctionChange}
              />
            )}
          </Field>
        </Section>
        {/* Malfunction HSI */}
        {hasMalfunctionHSI && (
        <Section borderTop noMargin padding>
          <Field
            id="malfunctionHSI"
            label={i18n.t("views.editAssessment.malfunctionLabelHSI")}
            onChange={this.handleChange}
          >
            {(id, onChange) => (
              <DropDown
                placeholder={i18n.t("global.dropDownPlaceholderDefault")}
                items={malfunctionsHSI}
                id={id}
                update={onChange}
                selected={findMalfunction(malfunctionsHSI, malfunctionId)}
                disabledLevels={disableMalfunctionChange ? [0] : []}
                required
              />
            )}
          </Field>
        </Section>
        )}
        {/* Microphones AC */}
        {hasMalfunctionAC && (
        <Section borderTop noMargin padding>
          <Field
            id="malfunctionAC"
            label={i18n.t("views.editAssessment.malfunctionLabelAC")}
            onChange={this.handleChange}
          >
            {(id, onChange) => (
              <DropDown
                placeholder={i18n.t("global.dropDownPlaceholderDefault")}
                items={malfunctionsAC}
                id={id}
                update={onChange}
                selected={findMalfunction(malfunctionsAC, malfunctionId)}
                disabledLevels={disableMalfunctionChange ? [0] : []}
                required
              />
            )}
          </Field>
        </Section>
        )}
        {/* HSI */}
        {hasHSI && (
        <Section borderTop noMargin padding row>
          <Section>
            <div className={css.typeEntry}>
              <Field
                id="hsi"
                label={i18n.t("views.editAssessment.hsiLabel")}
                onChange={this.handleChange}
              >
                {(id, onChange) => (
                  <NumberInput
                    id={id}
                    update={onChange}
                    placeholder="0.00"
                    max={1}
                    value={hsi}
                  />
                )}
              </Field>
            </div>
          </Section>
          <Section>
            <Field
              id="status"
              label={i18n.t("views.editAssessment.statusLabel")}
              onChange={this.handleChange}
            >
              {(id, onChange) => (
                <DropDown
                  id={id}
                  update={onChange}
                  placeholder={i18n.t("global.dropDownPlaceholderDefault")}
                  items={cardStatusTypes}
                  maxLevel={0}
                  selected={
                      cardStatusTypes.find((item) => item.type === statusType)
                    }
                />
              )}
            </Field>
          </Section>
          {showHighlight && (
          <Section>
            <CheckBox
              id="highlight"
              description={i18n.t("views.editAssessment.highlight")}
              update={this.handleChange}
              checked={highlight}
              className={css.highlightDiv}
            />
          </Section>
          )}
        </Section>
        )}
        {/* Card Tag */}
        {hasTag && (
        <Section borderTop padding>
          <Field
            id="tag"
            label={i18n.t("views.editAssessment.cardTagLabel")}
            onChange={this.handleChange}
          >
            {(id, onChange) => (
              <TextField
                id={id}
                update={onChange}
                text={tag || undefined}
                maxLength={64}
              />
            )}
          </Field>
        </Section>
        )}
        {/* Status */}
        {hasStatus && (
        <Section borderTop noMargin padding>
          <Field
            id="status"
            label={i18n.t("views.editAssessment.statusLabel")}
            onChange={this.handleChange}
          >
            {(id, onChange) => (
              <DropDown
                id={id}
                update={onChange}
                placeholder={i18n.t("global.dropDownPlaceholderDefault")}
                items={cardStatusTypes}
                maxLevel={0}
                selected={
                    cardStatusTypes.find((item) => item.type === statusType)
                  }
              />
            )}
          </Field>
        </Section>
        )}
        {/* Recommendation Type */}
        <Section borderTop noMargin padding>
          <Field
            id="recommendationType"
            label={i18n.t("views.editAssessment.recommendationTypeLabel")}
            onChange={this.handleChange}
          >
            {(id, onChange) => (
              <DropDown
                placeholder={i18n.t("global.dropDownPlaceholderDefault")}
                items={recommendationTypes}
                id={id}
                maxLevel={0}
                update={onChange}
                selected={
                    recommendationTypes.find(
                      (item) => item.type === recommendationType,
                    )}
              />
            )}
          </Field>
        </Section>
        {/* Linked Component */}
        {hasLinkedComponent && (
        <Section borderTop noMargin padding>
          <Field
            id="component"
            label={i18n.t("views.editAssessment.linkedComponentLabel")}
            onChange={this.handleChange}
          >
            {(id, onChange) => (
              <DropDown
                id={id}
                placeholder={i18n.t("global.dropDownPlaceholderDefault")}
                items={components}
                update={onChange}
                selected={components.find((item) => item.id === componentId)}
              />
            )}
          </Field>
        </Section>
        )}
        { /* Editor Note */ }
        <Section borderTop noMargin padding>
          <Field
            id="editorsNote"
            label={i18n.t("views.editAssessment.editorsNote")}
            onChange={this.handleChange}
          >
            {(id, onChange) => (
              <MultilineInput
                id={id}
                update={onChange}
                placeholder={i18n.t("global.textFiledPlaceHolderDefault")}
                text={editorsNote}
                small
              />
            )}
          </Field>
        </Section>
        { /* Recommendations */ }
        <Section borderTop noMargin padding>
          <RecommendationsEdit
            recommendations={recommendations}
            onUpdate={this.handleChange}
          />
        </Section>
        { /* Risks */ }
        <Section borderTop noMargin padding>
          <RisksEdit
            risks={risks}
            onUpdate={this.handleChange}
          />
        </Section>
        { /* Argumentations */ }
        <Section borderTop noMargin padding>
          <ArgumentationsEdit
            argumentations={argumentations}
            onUpdate={this.handleChange}
          />
        </Section>
        { /* Buttons */ }
        <Section borderTop noMargin padding>
          <Button
            color="gray"
            onClick={this.handleCancel}
            disabled={loading}
          >
            {i18n.t("global.cancel")}
          </Button>
          <Button onClick={() => this.handleSubmit()} disabled={loading}>
            {i18n.t("global.save")}
          </Button>
        </Section>
        <Section borderTop padding noMargin small>
          <Button
            color="error"
            small
            onClick={this.handleDelete}
            disabled={loading}
          >
            {i18n.t("views.editAssessment.buttons.delete")}
          </Button>
        </Section>
      </div>
    );
  }
}

function sortByNameAscending(a, b) {
  if (a.name > b.name) return 1;
  if (b.name > a.name) return -1;
  return 0;
}
function mapStateToProps(state, props) {
  const { customerId, assessmentId } = props;
  const {
    assessments,
    customers,
    components,
    malfunctions,
  } = state;
  const assessment = state.assessments.assessments[customerId].find(
    (assessment) => assessment.id === Number.parseInt(assessmentId, 10),
  );
  if (!assessment) {
    return null;
  }
  const currentCustomer = customers.customers.find(
    (customer) => customer.id === Number.parseInt(customerId, 10),
  );
  if (!currentCustomer) {
    return null;
  }
  const componentsTranslated = getComponentsTranslated(i18n.language, components.components);
  let turbineMalfunctions = malfunctions.turbines[assessment.turbineType].malfunctions;
  const machineMalfunctions = assessment.machine.malfunctions;
  turbineMalfunctions = turbineMalfunctions.filter(
    (m) => machineMalfunctions.find((mm) => mm.malfunctionId === m.id),
  );
  turbineMalfunctions.sort(sortByNameAscending);
  return {
    components: componentsTranslated,
    loading: assessments.loading,
    assessment,
    card: state.assessments.draft,
    malfunctionsHSI: turbineMalfunctions.filter((m) => m.type === malfunctionTypes.HSI),
    malfunctionsAC: turbineMalfunctions.filter((m) => m.type === malfunctionTypes.AC),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(Actions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssessmentEdit);

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { recursiveFind } from "utils/helper";
import i18n from "i18n";
import * as Actions from "store/actions";
import MalfunctionListItem from "components/elements/MalfunctionListItem";
import DropDown from "components/input/DropDown";
import css from "./MalfunctionList.module.scss";

class MalfunctionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: {
        malfunctions: [],
      },
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect({ value: { item } }) {
    this.setState({ selected: item });
  }

  render() {
    const {
      handleSelect,
      props: {
        components,
      },
      state: {
        selected,
      },
    } = this;
    return (
      <div className={css.component}>
        <div className={css.selectBox}>
          <h4>{ i18n.t("components.malfunctions.malfunctions") }</h4>
          <div className={[css.inputContainer, css.dropdown].join(" ")}>
            <DropDown
              update={handleSelect}
              placeholder={i18n.t("components.select_category")}
              items={components}
              selected={recursiveFind(components, selected.id, "id")}
            />
          </div>
        </div>
        <div className={css.items}>
          { selected.malfunctions.length >= 1 ? selected.malfunctions.map((malfunction) => (
            <MalfunctionListItem
              key={malfunction.malfunctionId}
              malfunctionId={malfunction.malfunctionId}
              malfunction={malfunction}
            />
          )) : (<span>{ i18n.t("components.text_no_category_selected") }</span>) }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    components: state.components.list,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(Actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MalfunctionList);

import { userRoles } from "utils/constants";

export function extractUserRoleFromUser(user) {
  try {
    if (user.roles.includes(userRoles.OPL)) {
      return "OPL";
    }
    if (user.roles.includes(userRoles.HPP)) {
      return "HPP";
    }
  } catch (error) {
    console.log(error);
    return "";
  }
  return "";
}

export default extractUserRoleFromUser;

/**
 * @fileoverview Screen component for health assessment edit view
 * @author  Florian Berg
 */

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Actions from "store/actions";
import Headline from "components/elements/Headline";
import Button from "components/elements/Button";
import Section from "components/layout/Section";
import Field from "components/input/Field";
import i18n from "i18n";
import NavigationBar from "components/layout/NavigationBar";
import { MultiSelect } from "components/input/MultiSelect";
import { malfunctionTypes } from "utils/constants";
import CheckBox from "components/input/CheckBox";
import DropDown from "components/input/DropDown";
import NumberInput from "components/input/NumberInput";
import GenericStatus from "components/elements/GenericStatus";
import { formatDate } from "utils/date";
import { getTurbinesDropDownItems } from "utils/helper";
import css from "./Settings.module.scss";

// Import css modules stylesheet as styles
class Settings extends Component {
  constructor(props) {
    super(props);
    this.selectedAsset = undefined;
    this.selectedMachine = undefined;
    this.malfunctionsHsiGrouped = [];
    this.malfunctionsAcGrouped = [];
  }

  handleSubmit = async () => {
    const {
      showModal,
      updateCustomer,
      customer,
    } = this.props;
    showModal({
      type: "action",
      message: i18n.t("modals.saveWarning"),
      actionText: i18n.t("global.save"),
      primaryAction: async () => {
        await updateCustomer(customer);
        window.history.back();
      },
    });
  };

  handleCancel = async () => {
    const { showModal, closeModal } = this.props;
    showModal({
      type: "action",
      style: "warning",
      message: i18n.t("modals.discardWarning"),
      actionText: i18n.t("global.discard"),
      primaryAction: async () => {
        closeModal();
        window.history.back();
      },
    });
  };

  handleChange = async (update) => {
    const { id, value } = update;
    const { item } = value;
    const {
      customer, editCustomer, malfunctions, showModal, closeModal, getMachineMalfunctions,
    } = this.props;
    let { malfunctionsEnabled } = customer;
    if (!malfunctionsEnabled) {
      malfunctionsEnabled = [];
    }
    switch (id) {
      case "servicehoursLeft": {
        editCustomer({
          ...customer,
          servicehoursLeft: value,
        });
        break;
      }
      case "servicehoursTotal": {
        editCustomer({
          ...customer,
          servicehoursTotal: value,
        });
        break;
      }
      case "hidePlantGraphic": {
        editCustomer({
          ...customer,
          hidePlantGraphic: value,
        });
        break;
      }
      case "hideServicehours": {
        editCustomer({
          ...customer,
          hideServicehours: value,
        });
        break;
      }
      case "asset":
        this.selectedAsset = item;
        this.selectedMachine = undefined;
        editCustomer({
          ...customer,
          machineId: undefined,
          turbineType: undefined,
          hidePlantGraphic: undefined,
          malfunctionsEnabled: undefined,
        });
        break;
      case "machine": {
        this.selectedMachine = item;

        // obtains components/malfunctions configured at the global level for the turbine
        const malfunctionsGrouped = malfunctions.turbines[
          this.selectedMachine.turbineType
        ].componentsMalfunctions;

        // reduce components/malfunctions of type HSI
        this.malfunctionsHsiGrouped = malfunctionsGrouped.reduce((collector, record) => {
          const componentGroup = {
            ...record,
            malfunctions: record.malfunctions.filter(
              (m) => m.malfunctionType === malfunctionTypes.HSI,
            ),
          };
          // only add components which has at least one malfunction
          if (componentGroup.malfunctions.length > 0) {
            collector.push(componentGroup);
          }
          return collector;
        }, []);

        // reduce components/malfunctions of type AC
        this.malfunctionsAcGrouped = malfunctionsGrouped.reduce((collector, record) => {
          const componentGroup = {
            ...record,
            malfunctions: record.malfunctions.filter(
              (m) => m.malfunctionType === malfunctionTypes.AC,
            ),
          };
          // only add components which has at least one malfunction
          if (componentGroup.malfunctions.length > 0) {
            collector.push(componentGroup);
          }
          return collector;
        }, []);

        // obtains flat list of malfunctions configured at the global level for the turbine
        const turbineMalfunctions = malfunctions.turbines[
          this.selectedMachine.turbineType
        ].malfunctions;

        // obtains malfunctions enabled for the machine in the DB
        showModal({
          type: "wait",
          message: i18n.t("messages.getMachineMalfunctions"),
        });
        const result = await getMachineMalfunctions(this.selectedMachine.id);
        closeModal();

        // filters malfunctions that should be enabled for the machine
        let machineMalfunctions;
        if (result && result.malfunctions) {
          machineMalfunctions = result.malfunctions.filter(
            (m1) => turbineMalfunctions.find((m2) => m2.id === m1.malfunctionId),
          ).map((m) => m.malfunctionId);
        }
        // update customer state
        editCustomer({
          ...customer,
          machineId: this.selectedMachine.id,
          turbineType: this.selectedMachine.turbineType,
          hidePlantGraphic: this.selectedMachine.hideGraphic,
          malfunctionsEnabled: machineMalfunctions,
        });
        break;
      }
      case "turbineType":
        editCustomer({
          ...customer,
          turbineType: item.id,
        });
        break;
      case "malfunctions-hsi": {
        const hsiChecked = value.filter(
          (item) => item.value === true,
        ).map((item) => item.id);
        const hsiUnchecked = value.filter(
          (item) => item.value === false,
        ).map((item) => item.id);
        editCustomer({
          ...customer,
          malfunctionsEnabled: [...malfunctionsEnabled.filter(
            (ac) => !hsiUnchecked.includes(ac),
          ).filter(
            (ac) => !hsiChecked.includes(ac),
          ), ...hsiChecked],
        });
        break;
      }
      case "malfunctions-ac": {
        const acChecked = value.filter(
          (item) => item.value === true,
        ).map((item) => item.id);
        const acUnchecked = value.filter(
          (item) => item.value === false,
        ).map((item) => item.id);
        editCustomer({
          ...customer,
          malfunctionsEnabled: [...malfunctionsEnabled.filter(
            (ac) => !acUnchecked.includes(ac),
          ).filter(
            (ac) => !acChecked.includes(ac),
          ), ...acChecked],
        });
        break;
      }
      default:
        console.log("unknown update", id, value);
    }
  };

  _validate = () => {};

  render() {
    const {
      customer,
      structures,
    } = this.props;
    if (!customer) {
      return null;
    }
    const {
      turbineType,
      hidePlantGraphic,
      hideServicehours,
      servicehoursLeft,
      servicehoursTotal,
      updatedAt,
      editor,
    } = customer;
    let machines = [];
    if (this.selectedAsset) {
      const asset = structures.find((s) => s.id === this.selectedAsset.id);
      machines = asset.children;
    }
    const turbineTypesItems = getTurbinesDropDownItems();
    let turbineTypeSelected;
    if (this.selectedMachine) {
      turbineTypeSelected = getTurbinesDropDownItems().find(
        (item) => item.id === turbineType,
      );
    }
    return (
      <div className={css.screen}>
        <NavigationBar
          backAction={this.handleCancel}
          title={`${i18n.t("global.back").toUpperCase()}`}
          small
        />
        <Section borderTop noMargin padding>
          <Headline>
            {`${i18n.t("views.settings.title")} ${customer.name}`}
          </Headline>
        </Section>
        <Section borderTop noMargin padding row>
          {editor && (
            <GenericStatus
              label={i18n.t("global.lastEdited")}
              status={`
              ${formatDate(updatedAt)},
              ${i18n.t("global.by")} ${editor.firstName} ${editor.lastName}`}
            />
          )}
          {!editor && (
          <GenericStatus
            label={i18n.t("global.lastEdited")}
            status={`
              ${i18n.t("global.notAvailable")}`}
          />
          )}
        </Section>
        <Section borderTop noMargin padding row>
          <Field
            id="servicehoursLeft"
            label={i18n.t("views.settings.servicehoursLeft")}
            onChange={this.handleChange}
          >
            {(id, onChange) => (
              <NumberInput
                id={id}
                value={servicehoursLeft}
                update={onChange}
                small
              />
            )}
          </Field>
          <Field
            id="servicehoursTotal"
            label={i18n.t("views.settings.servicehoursTotal")}
            onChange={this.handleChange}
          >
            {(id, onChange) => (
              <NumberInput
                id={id}
                value={servicehoursTotal}
                update={onChange}
                small
              />
            )}
          </Field>
          <Field
            id="hideServicehours"
            label={i18n.t("views.settings.hideServicehours")}
            onChange={this.handleChange}
          >
            {(id, onChange) => (
              <CheckBox
                id={id}
                update={onChange}
                checked={hideServicehours}
              />
            )}
          </Field>
        </Section>
        <Section borderTop noMargin padding className={css.sectionFlex}>
          <Field
            id="asset"
            label={i18n.t("views.editHealthAssessment.assetLabel")}
            onChange={this.handleChange}
            className={css.dropDownField}
          >
            {(id, onChange) => (
              <DropDown
                id={id}
                update={onChange}
                placeholder={i18n.t("global.dropDownPlaceholderDefault")}
                items={structures}
                selected={this.selectedAsset}
                minLevel={0}
                maxLevel={0}
              />
            )}
          </Field>
          <Field
            id="machine"
            label={i18n.t("views.editHealthAssessment.machineLabel")}
            onChange={this.handleChange}
            className={[css.dropDownField, css.leftMargin].join(" ")}
          >
            {(id, onChange) => (
              <DropDown
                id={id}
                update={onChange}
                placeholder={i18n.t("global.dropDownPlaceholderDefault")}
                items={machines}
                selected={this.selectedMachine}
                minLevel={0}
                maxLevel={0}
              />
            )}
          </Field>
        </Section>
        {this.selectedMachine && (
          <Section noMargin>
            <Section noMargin className={css.sectionFlex}>
              <Field
                id="turbineType"
                label={i18n.t("views.editHealthAssessment.turbineTypeLabel")}
                onChange={this.handleChange}
                className={css.dropDownField}
              >
                {(id, onChange) => (
                  <DropDown
                    id={id}
                    update={onChange}
                    placeholder={i18n.t("global.dropDownPlaceholderDefault")}
                    items={turbineTypesItems}
                    selected={turbineTypeSelected}
                  />
                )}
              </Field>
              <Field
                id="hidePlantGraphic"
                label={i18n.t("views.settings.hidePlantGraphic")}
                onChange={this.handleChange}
                className={css.leftMargin}
              >
                {(id, onChange) => (
                  <CheckBox
                    id={id}
                    update={onChange}
                    checked={hidePlantGraphic}
                  />
                )}
              </Field>
            </Section>
            <Section noMargin className={css.sectionMalfunctionAC}>
              {this.malfunctionsAcGrouped && this.malfunctionsAcGrouped.map((group, index) => (
                <Field
                  id="malfunctions-ac"
                  key={"malfunctions-ac-".concat(group.componentId)}
                  onChange={this.handleChange}
                  label={index === 0 ? i18n.t("views.settings.selectMalfunctionAC") : ""}
                  labelClassName={index === 0 ? css.sectionTitle : ""}
                  className={css.checkList}
                >
                  {(id, onChange) => (
                    <MultiSelect
                      id={id}
                      type="multi"
                      onChange={onChange}
                      heading={group.componentName}
                      items={group.malfunctions.map(
                        (item) => (
                          {
                            id: item.malfunctionId,
                            key: group.componentId.concat("-").concat(item.malfunctionId),
                            name: item.malfunctionName,
                            value: customer.malfunctionsEnabled
                              && customer.malfunctionsEnabled.includes(item.malfunctionId),
                          }),
                      )}
                    />
                  )}
                </Field>
              ))}
            </Section>
            <Section noMargin>
              {this.malfunctionsHsiGrouped && this.malfunctionsHsiGrouped.map((group, index) => (
                <Field
                  id="malfunctions-hsi"
                  key={"malfunctions-hsi-".concat(group.componentId)}
                  onChange={this.handleChange}
                  label={index === 0 ? i18n.t("views.settings.selectMalfunctionHSI") : ""}
                  labelClassName={index === 0 ? css.sectionTitle : ""}
                  className={css.checkList}
                >
                  {(id, onChange) => (
                    <MultiSelect
                      id={id}
                      type="multi"
                      onChange={onChange}
                      heading={group.componentName}
                      items={group.malfunctions.map(
                        (item) => (
                          {
                            id: item.malfunctionId,
                            key: group.componentId.concat("-").concat(item.malfunctionId),
                            name: item.malfunctionName,
                            value: customer.malfunctionsEnabled
                              && customer.malfunctionsEnabled.includes(item.malfunctionId),
                          }),
                      )}
                    />
                  )}
                </Field>
              ))}
            </Section>
          </Section>
        )}
        <Section borderTop noMargin padding>
          <Button type="outlined" onClick={this.handleCancel}>
            {i18n.t("global.cancel")}
          </Button>
          <Button type="primary" onClick={this.handleSubmit}>
            {i18n.t("global.save")}
          </Button>
        </Section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { customers, malfunctions } = state;
  return {
    customer: customers.current,
    structures: customers.current.structures,
    malfunctions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(Actions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Settings);

import i18n from "i18n";
import { api } from "utils/api";
import { showMessage } from "store/actions/notifications/";
import { closeComponentsModal, showComponentsModal } from "store/actions/components";
import { errorHandler } from "./components";

export const COMPONENTS_MALFUNCTIONS_CREATE_ERROR = "COMPONENTS_MALFUNCTIONS_CREATE_ERROR";
export const COMPONENTS_MALFUNCTIONS_CREATE_REQUEST = "COMPONENTS_MALFUNCTIONS_CREATE_REQUEST";
export const COMPONENTS_MALFUNCTIONS_CREATE_SUCCESS = "COMPONENTS_MALFUNCTIONS_CREATE_SUCCESS";

export const COMPONENTS_MALFUNCTIONS_DELETE_ERROR = "COMPONENTS_MALFUNCTIONS_DELETE_ERROR";
export const COMPONENTS_MALFUNCTIONS_DELETE_REQUEST = "COMPONENTS_MALFUNCTIONS_DELETE_REQUEST";
export const COMPONENTS_MALFUNCTIONS_DELETE_SUCCESS = "COMPONENTS_MALFUNCTIONS_DELETE_SUCCESS";

export const COMPONENTS_MALFUNCTIONS_UPDATE_ERROR = "COMPONENTS_MALFUNCTIONS_UPDATE_ERROR";
export const COMPONENTS_MALFUNCTIONS_UPDATE_REQUEST = "COMPONENTS_MALFUNCTIONS_UPDATE_REQUEST";
export const COMPONENTS_MALFUNCTIONS_UPDATE_SUCCESS = "COMPONENTS_MALFUNCTIONS_UPDATE_SUCCESS";

export function createMalfunction(malfunction) {
  return async (dispatch) => {
    try {
      dispatch({
        type: COMPONENTS_MALFUNCTIONS_CREATE_REQUEST,
      });

      const { componentId, name, type } = malfunction;

      const result = await api.post(`/components/${componentId}/malfunctions`,
        {
          name,
          type,
        },
        {
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
        });

      const { status } = result;
      if (status === 201) {
        const { data } = result;
        dispatch({
          type: COMPONENTS_MALFUNCTIONS_CREATE_SUCCESS,
          payload: {
            componentId,
            ...data,
          },
        });
        dispatch(
          showMessage({
            title: i18n.t("global.success"),
            text: i18n.t("components.messages.malfunction_created_successfully"),
            type: "alert",
          }),
        );
      } else {
        throw new Error(result.statusText);
      }
    } catch (error) {
      const { response } = error;
      dispatch(closeComponentsModal());
      errorHandler(dispatch, response);
      dispatch({
        type: COMPONENTS_MALFUNCTIONS_CREATE_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("components.errors.error_delete_category"),
          type: "error",
        }),
      );
    }
  };
}

export function deleteMalfunction(malfunctionId, componentId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: COMPONENTS_MALFUNCTIONS_DELETE_REQUEST,
      });

      const result = await api.delete(`/malfunctions/${malfunctionId}`, { withCredentials: true });
      const { status } = result;
      if (status === 204) {
        dispatch({
          type: COMPONENTS_MALFUNCTIONS_DELETE_SUCCESS,
          payload: {
            malfunctionId,
            componentId,
          },
        });
        dispatch(
          showMessage({
            title: i18n.t("global.success"),
            text: i18n.t("components.messages.malfunction_deleted_successfully"),
            type: "alert",
          }),
        );
      } else {
        throw new Error(result.statusText);
      }
    } catch (error) {
      const { response } = error;
      const { status } = response;
      if (status === 409) {
        dispatch(closeComponentsModal());
        const text = i18n.t("components.malfunctions.delete_warning");
        const modal = {
          display: true,
          type: "API_WARNING",
          data: text,
        };
        dispatch(showComponentsModal(modal));
        return;
      }
      dispatch(closeComponentsModal());
      errorHandler(dispatch, response);
      dispatch({
        type: COMPONENTS_MALFUNCTIONS_DELETE_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("components.errors.error_delete_malfunction"),
          type: "error",
        }),
      );
    }
  };
}

export function updateMalfunction(malfunction) {
  return async (dispatch) => {
    try {
      dispatch({
        type: COMPONENTS_MALFUNCTIONS_UPDATE_REQUEST,
      });

      const {
        id,
        componentId,
        name,
        type,
        translations,
      } = malfunction;

      const result = await api.put(`/malfunctions/${id}/translations`, {
        id,
        componentId,
        name,
        type,
        translations,
      }, {
        withCredentials: true,
        headers: {
          "content-type": "application/json",
        },
      });

      const { status } = result;
      if (status === 200) {
        const { data } = result;
        dispatch({
          type: COMPONENTS_MALFUNCTIONS_UPDATE_SUCCESS,
          payload: {
            ...data,
            componentId,
          },
        });
        dispatch(
          showMessage({
            title: i18n.t("global.success"),
            text: i18n.t("components.messages.category_updated_successfully"),
            type: "alert",
          }),
        );
      } else {
        throw new Error(result.statusText);
      }
    } catch (error) {
      const { response } = error;
      dispatch(closeComponentsModal());
      errorHandler(dispatch, response);
      dispatch({
        type: COMPONENTS_MALFUNCTIONS_UPDATE_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("components.errors.error_delete_malfunction"),
          type: "error",
        }),
      );
    }
  };
}

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import css from "./Spinner.module.scss";

class Spinner extends PureComponent {
  render() {
    const { children, className } = this.props;
    return (
      <div className={[css.spinner, className].join(" ")}>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          width="50px"
          height="50px"
          viewBox="0 0 50 50"
          className={css.svg}
        >
          <g className={css.outer}>
            <g className={css.inner}>
              <g className={css.innerA}>
                <circle cx="25" cy="25" r="20" className={css.a} />
              </g>
              <g className={css.innerB}>
                <circle cx="25" cy="25" r="16" className={css.b} />
              </g>
              <g className={css.innerC}>
                <circle cx="25" cy="25" r="12" className={css.c} />
              </g>
            </g>
          </g>
        </svg>
        {children && <div className={css.message}>{children}</div>}
      </div>
    );
  }
}

Spinner.propTypes = {
  children: PropTypes.node,
};

export default Spinner;

import {
  COMPONENTS_PLACEMENT_GET_LIST_ERROR,
  COMPONENTS_PLACEMENT_GET_LIST_REQUEST,
  COMPONENTS_PLACEMENT_GET_LIST_SUCCESS,
  COMPONENTS_PLACEMENT_POST_MALFUNCTION_ERROR,
  COMPONENTS_PLACEMENT_POST_MALFUNCTION_REQUEST,
  COMPONENTS_PLACEMENT_POST_MALFUNCTION_SUCCESS,
  COMPONENTS_PLACEMENT_UPDATE_MALFUNCTION_ERROR,
  COMPONENTS_PLACEMENT_UPDATE_MALFUNCTION_REQUEST,
  COMPONENTS_PLACEMENT_UPDATE_MALFUNCTION_SUCCESS,
  COMPONENTS_PLACEMENT_DELETE_MALFUNCTION_ERROR,
  COMPONENTS_PLACEMENT_DELETE_MALFUNCTION_REQUEST,
  COMPONENTS_PLACEMENT_DELETE_MALFUNCTION_SUCCESS,
  COMPONENTS_PLACEMENT_CREATE_GROUP_MALFUNCTION_ERROR,
  COMPONENTS_PLACEMENT_CREATE_GROUP_MALFUNCTION_SUCCESS,
  COMPONENTS_PLACEMENT_CREATE_GROUP_MALFUNCTION_REQUEST,
  COMPONENTS_PLACEMENT_REMOVE_GROUP_MALFUNCTION_ERROR,
  COMPONENTS_PLACEMENT_REMOVE_GROUP_MALFUNCTION_SUCCESS,
  COMPONENTS_PLACEMENT_REMOVE_GROUP_MALFUNCTION_REQUEST,
  COMPONENTS_PLACEMENT_DELETE_GROUP_MALFUNCTION_ERROR,
  COMPONENTS_PLACEMENT_DELETE_GROUP_MALFUNCTION_SUCCESS,
  COMPONENTS_PLACEMENT_DELETE_GROUP_MALFUNCTION_REQUEST,
  COMPONENTS_PLACEMENT_ADD_MALFUNCTION_TO_GROUP_ERROR,
  COMPONENTS_PLACEMENT_ADD_MALFUNCTION_TO_GROUP_SUCCESS,
  COMPONENTS_PLACEMENT_ADD_MALFUNCTION_TO_GROUP_REQUEST,
  COMPONENTS_PLACEMENT_UPDATE_GROUP_POSITION_ERROR,
  COMPONENTS_PLACEMENT_UPDATE_GROUP_POSITION_SUCCESS,
  COMPONENTS_PLACEMENT_UPDATE_GROUP_POSITION_REQUEST,
} from "store/actions";

export default function componentsPlacementApiReducer(state, action) {
  switch (action.type) {
    case COMPONENTS_PLACEMENT_GET_LIST_ERROR:
    case COMPONENTS_PLACEMENT_DELETE_MALFUNCTION_ERROR:
    case COMPONENTS_PLACEMENT_POST_MALFUNCTION_ERROR:
    case COMPONENTS_PLACEMENT_UPDATE_MALFUNCTION_ERROR:
    case COMPONENTS_PLACEMENT_CREATE_GROUP_MALFUNCTION_ERROR:
    case COMPONENTS_PLACEMENT_DELETE_GROUP_MALFUNCTION_ERROR:
    case COMPONENTS_PLACEMENT_ADD_MALFUNCTION_TO_GROUP_ERROR:
    case COMPONENTS_PLACEMENT_UPDATE_GROUP_POSITION_ERROR:
    case COMPONENTS_PLACEMENT_REMOVE_GROUP_MALFUNCTION_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        localLoading: false,
      };
    }
    case COMPONENTS_PLACEMENT_UPDATE_MALFUNCTION_REQUEST:
    case COMPONENTS_PLACEMENT_POST_MALFUNCTION_REQUEST:
    case COMPONENTS_PLACEMENT_UPDATE_GROUP_POSITION_REQUEST:
    case COMPONENTS_PLACEMENT_ADD_MALFUNCTION_TO_GROUP_REQUEST:
    case COMPONENTS_PLACEMENT_CREATE_GROUP_MALFUNCTION_REQUEST: {
      return {
        ...state,
        localLoading: true,
      };
    }
    case COMPONENTS_PLACEMENT_DELETE_MALFUNCTION_REQUEST:
    case COMPONENTS_PLACEMENT_DELETE_GROUP_MALFUNCTION_REQUEST:
    case COMPONENTS_PLACEMENT_REMOVE_GROUP_MALFUNCTION_REQUEST:
    case COMPONENTS_PLACEMENT_GET_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case COMPONENTS_PLACEMENT_GET_LIST_SUCCESS: {
      const {
        turbineType, placements,
      } = action.payload;
      return {
        ...state,
        placement: {
          ...state.placement,
          [turbineType]: placements,
        },
        error: false,
        loading: false,
      };
    }
    case COMPONENTS_PLACEMENT_POST_MALFUNCTION_SUCCESS: {
      const { turbineType } = action.payload;
      return {
        ...state,
        placement: {
          ...state.placement,
          [turbineType]: state.placement[turbineType].concat(action.payload),
        },
        localLoading: false,
        error: false,
        loading: false,
      };
    }
    case COMPONENTS_PLACEMENT_UPDATE_MALFUNCTION_SUCCESS: {
      const {
        id,
        xAxis,
        yAxis,
        turbineType,
      } = action.payload;
      const placements = state.placement[turbineType].map((item) => {
        if (item.id === id) {
          return {
            ...item,
            xAxis,
            yAxis,
          };
        }
        return item;
      });
      return {
        ...state,
        placement: {
          ...state.placement,
          [turbineType]: placements,
        },
        loading: false,
        localLoading: false,
        error: false,
      };
    }
    case COMPONENTS_PLACEMENT_DELETE_MALFUNCTION_SUCCESS: {
      const { placement } = state;
      const {
        malfunctionId,
        turbineType,
      } = action.payload;
      return {
        ...state,
        placement: {
          ...state.placement,
          [turbineType]: placement[turbineType]
            .filter((item) => item.malfunctionId !== malfunctionId),
        },
        error: false,
        loading: false,
      };
    }
    case COMPONENTS_PLACEMENT_CREATE_GROUP_MALFUNCTION_SUCCESS: {
      const {
        target,
        source,
        malfunction,
        turbineType,
      } = action.payload;
      const placement = state.placement[turbineType]
        .filter((item) => item.malfunctionId !== target)
        .filter((item) => item.malfunctionId !== source)
        .concat(malfunction);
      return {
        ...state,
        placement: {
          ...state.placement,
          [turbineType]: placement,
        },
        localLoading: false,
        error: false,
        loading: false,
      };
    }
    case COMPONENTS_PLACEMENT_ADD_MALFUNCTION_TO_GROUP_SUCCESS: {
      const { group, source, turbineType } = action.payload;
      const placement = state.placement[turbineType]
        .filter((item) => item.malfunctionId !== source)
        .filter((item) => item.groupId !== group.groupId)
        .concat(group);
      return {
        ...state,
        placement: {
          ...state.placement,
          [turbineType]: placement,
        },
        localLoading: false,
        error: false,
        loading: false,
      };
    }
    case COMPONENTS_PLACEMENT_DELETE_GROUP_MALFUNCTION_SUCCESS: {
      const { groupId, turbineType } = action.payload;
      const placement = state.placement[turbineType].filter((item) => item.groupId !== groupId);
      return {
        ...state,
        placement: {
          ...state.placement,
          [turbineType]: placement,
        },
        localLoading: false,
        error: false,
        loading: false,
      };
    }
    case COMPONENTS_PLACEMENT_REMOVE_GROUP_MALFUNCTION_SUCCESS: {
      const { groupId, malfunctionId, turbineType } = action.payload;
      const { list } = state;
      const newList = list.map((item) => {
        item.malfunctions.map((malfunction) => {
          if (malfunctionId === malfunction.malfunctionId) {
            const newItem = malfunction;
            newItem.active = true;
            return newItem;
          }
          return malfunction;
        });
        return item;
      });
      const group = state.placement[turbineType].find(({ groupId: gId }) => gId === groupId);
      const malfunctions = group.malfunctions
        .filter(({ malfunctionId: mId }) => mId !== malfunctionId);
      const filtred = state.placement[turbineType].filter((item) => item.groupId !== groupId);
      const placement = Object.assign(filtred.concat({ ...group, malfunctions }), {});
      return {
        ...state,
        placement: {
          ...state.placement,
          [turbineType]: placement,
        },
        list: newList,
        error: false,
        loading: false,
        localLoading: false,
      };
    }
    case COMPONENTS_PLACEMENT_UPDATE_GROUP_POSITION_SUCCESS: {
      const {
        groupId,
        xAxis,
        yAxis,
        turbineType,
      } = action.payload;
      const placement = state.placement[turbineType].map((item) => {
        if (item.groupId === groupId) {
          return {
            ...item,
            xAxis,
            yAxis,
          };
        }
        return item;
      });
      return {
        ...state,
        placement: {
          ...state.placement,
          [turbineType]: placement,
        },
        localLoading: false,
        error: false,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
}

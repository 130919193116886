import {
  showModal,
  closeModal,
  toggleNotificationSettings,
} from "store/actions";
import i18n from "i18n";

export * from "./api/activities";

export function showActivityNotifcationSettings() {
  return async (dispatch) => {
    dispatch(
      showModal({
        type: "settings",
        style: "alert",
        title: i18n.t("modals.emailSettings.title"),
        message: i18n.t(
          "modals.emailSettings.body",
        ),
        actionText: i18n.t("modals.emailSettings.saveSettings"),
        mapState: (state) => ({
          state: state.auth.user.subscribed,
          loading: state.auth.loading,
        }),
        changeSettingAction: async () => {
          await dispatch(toggleNotificationSettings());
        },
        primaryAction: () => {
          dispatch(closeModal());
        },
      }),
    );
  };
}

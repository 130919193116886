import React, { Component } from "react";
import { connect } from "react-redux";
import { findDOMNode } from "react-dom";
import * as Actions from "store/actions";
import { bindActionCreators } from "redux";
import { getXAxis, getYAxis, handleMalfunctionsTurbineGraphic } from "utils/placements";
import MalfunctionsGroupTooltip from "components/blocks/MalfunctionsGroupTooltip";
import ToolTip from "components/elements/ToolTip";
import { DropTarget } from "react-dnd";
import { rangeGroupMalfunctions } from "utils/components";
import i18n from "i18n";
import SvgGraphic from "./SvgGraphic";
import css from "./MalfunctionGraphic.module.scss";

class MalfunctionGraphic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toolTip: React.createRef(),
      selected: {
        malfunctionName: "",
      },
      isDragging: false,
      ref: React.createRef(),
      disabled: false,
      deletable: true,
    };
    this.handleGetToolTipContent = this.handleGetToolTipContent.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.onDrag = this.onDrag.bind(this);
    this.onStop = this.onStop.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.displayModal = this.displayModal.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleRemoveMalfunctionFromGroup = this.handleRemoveMalfunctionFromGroup.bind(this);
    window.malfunctionGraphicComponent = this;
  }

  onDrag() {
    this.setState({ isDragging: true });
  }

  onDrop(malfunction, data) {
    const {
      addMalfunctionToGroup,
      groupMalfunctions,
      malfunctions,
      turbineType,
      upadtePlacement,
      updateGroupPosition,
    } = this.props;
    const source = {
      xAxis: data.lastX,
      yAxis: data.lastY,
      id: malfunction.id,
      malfunctionId: malfunction.groupId ? malfunction.groupId : malfunction.malfunctionId,
      turbineType,
    };

    const target = malfunctions.find((item) => rangeGroupMalfunctions(item, source));

    if (!target) {
      if (!malfunction.groupId) {
        upadtePlacement(source);
        return;
      }
      const group = {
        groupId: malfunction.groupId,
        xAxis: data.lastX,
        yAxis: data.lastY,
        turbineType,
      };
      updateGroupPosition(group);
      return;
    }

    if (target.groupId) {
      addMalfunctionToGroup(source, target);
      return;
    }

    groupMalfunctions(source, target);
  }

  onStop(malfunction, data) {
    const { isDragging } = this.state;
    this.setState({ isDragging: false });
    const { onDrop } = this;
    if (!isDragging) {
      this.setState({ selected: malfunction });
      return;
    }
    onDrop(malfunction, data);
  }

  handleMouseOver(data) {
    this.setState({ selected: data });
  }

  handleGetToolTipContent() {
    const { displayModal, handleRemoveMalfunctionFromGroup, state: { selected, deletable } } = this;
    if (selected.groupId) {
      return (
        <MalfunctionsGroupTooltip
          deletable={deletable}
          groupId={selected.groupId}
          groupTitle={selected.groupName}
          malfunctions={selected.malfunctions}
          cards={selected.malfunctions}
          onDelete={(mId) => handleRemoveMalfunctionFromGroup(selected, mId)}
        />
      );
    }
    return (
      <div className={css.card}>
        <button onClick={() => displayModal(selected)}>
          <span className="icon-delete_01" />
        </button>
        <h4>{ selected.malfunctionName }</h4>
      </div>
    );
  }

  handleRemoveMalfunctionFromGroup(selected, malfunctionId) {
    const {
      deleteGroup,
      removeMalfunctionFromGroup,
      showModal,
      closeModal,
      turbineType,
    } = this.props;
    if (selected.malfunctions.length > 1) {
      showModal({
        type: "action",
        style: "warning",
        message: i18n.t("components.malfunctions.grouping.ungroup_malfunction"),
        actionText: i18n.t("components.malfunctions.grouping.ungroup"),
        primaryAction: async () => {
          closeModal();
          await removeMalfunctionFromGroup(selected.groupId, malfunctionId, turbineType);
        },
      });
      return;
    }

    showModal({
      type: "action",
      style: "warning",
      message: i18n.t("components.malfunctions.grouping.delete_group_warning"),
      actionText: i18n.t("components.buttons.labels.delete"),
      primaryAction: async () => {
        closeModal();
        await deleteGroup(selected.groupId, turbineType);
      },
    });
  }

  displayModal(data) {
    const {
      deletePlacement,
      showModal,
      closeModal,
      turbineType,
    } = this.props;
    showModal({
      type: "action",
      style: "warning",
      message: i18n.t("components.malfunctions.delete_malfunction"),
      actionText: i18n.t("components.buttons.labels.delete"),
      primaryAction: async () => {
        const { id, malfunctionId } = data;
        closeModal();
        await deletePlacement({
          placementId: id,
          malfunctionId,
          turbineType,
        });
      },
    });
  }

  handleDrop(data) {
    const { placeMalfunction, turbineType } = this.props;
    const malfunction = {
      ...data,
      turbineType,
    };
    placeMalfunction(malfunction);
  }

  render() {
    const {
      handleGetToolTipContent,
      handleMouseOver,
      onDrag,
      onStop,
      props: {
        connectDropTarget,
        malfunctions,
        turbineType,
      },
      state: {
        ref,
        toolTip,
        disabled,
      },
    } = this;
    return connectDropTarget(
      <div>
        <SvgGraphic
          svgPath={turbineType}
          width={867}
          height={578}
          onDrag={onDrag}
          onStop={onStop}
          handleMouseOver={handleMouseOver}
          malfunctions={handleMalfunctionsTurbineGraphic(malfunctions)}
          ref={ref}
          disabled={disabled}
        />
        <ToolTip
          id="hotspot-overlay"
          ref={toolTip}
          className={css.info}
          getContent={handleGetToolTipContent}
        />
      </div>,
    );
  }
}

const Types = {
  MALFUNCTION: "MALFUNCTION",
};

const malfunctionGraphicTarget = {
  drop(item, monitor, component) {
    const { malfunction } = monitor.getItem();
    const componentRect = findDOMNode(component).getBoundingClientRect(); //eslint-disable-line
    const { x, y } = monitor.getClientOffset();
    const xAxis = getXAxis(x, componentRect.x);
    const yAxis = getYAxis(y, componentRect.y);
    const data = {
      xAxis,
      yAxis,
      malfunctionName: malfunction.malfunctionName,
      malfunctionId: malfunction.malfunctionId,
    };

    malfunctionGraphicComponent.handleDrop(data); //eslint-disable-line
  },
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType(),
  };
}

function mapStateToProps(state) {
  const {
    components: {
      placement,
      turbineType,
    },
  } = state;
  return {
    malfunctions: placement[turbineType],
    turbineType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(Actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  DropTarget(
    Types.MALFUNCTION,
    malfunctionGraphicTarget,
    collect,
  )(MalfunctionGraphic),
);

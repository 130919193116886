import React, { Component } from "react";
import PropTypes from "prop-types";
import IconHeadline from "components/elements/IconHeadline";
import { getCardStatusType, getCardStatusTypePriority } from "utils/types";
import MalfunctionTooltip from "components/blocks/MalfunctionTooltip";
import ToolTip from "components/elements/ToolTip";
import css from "./CardTypeList.module.scss";

export default class CardTypeList extends Component {
  render() {
    const {
      className, typeFilter, cards, onNavigateToCard,
    } = this.props;
    return (
      <div className={[className, css.container].join(" ")}>
        { typeFilter && <IconHeadline title={typeFilter.name} icon={typeFilter.icon} />}
        <ul className={css.statusList}>
          {cards
            .filter(({ type }) => type === typeFilter.type)
            .sort(
              (cardA, cardB) => getCardStatusTypePriority(cardB.statusType)
                - getCardStatusTypePriority(cardA.statusType),
            )
            .map((card) => (
              <li
                className={css.statusListItem}
                key={card.id}
              >
                <button
                  data-tip
                  data-for={`tool-tip-cardTypeList-${card.id}`}
                  onClick={onNavigateToCard ? () => onNavigateToCard(card.id) : null}
                  key={card.id}
                >
                  <span className={`${getCardStatusType(card.statusType).iconLarge} ${css.icon}`} />
                </button>
                <ToolTip
                  className={css.tooltip}
                  id={`tool-tip-cardTypeList-${card.id}`}
                  place="right"
                >
                  <MalfunctionTooltip card={card} activated />
                </ToolTip>
              </li>
            ))}
        </ul>
      </div>
    );
  }
}

CardTypeList.propTypes = {
  malfunctions: PropTypes.array,
  type: PropTypes.object,
  className: PropTypes.string,
};

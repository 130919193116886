import {
  AUTH_USER_REQUEST,
  AUTH_USER_SUCCESS,
  AUTH_USER_ERROR,
  USER_GET_REQUEST,
  USER_GET_SUCCESS,
  USER_GET_ERROR,
  USER_UPDATE_SETTINGS_REQUEST,
  USER_UPDATE_SETTINGS_SUCCESS,
  USER_UPDATE_SETTINGS_ERROR,
} from "store/actions";
import { userRoles } from "utils/constants";

const initialState = {
  user: {},
  token: null,
  isHPP: false,
  isOPL: false,
  isAuthenticated: false,
  error: false,
  loading: false,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_USER_REQUEST:
      return {
        ...state,
      };
    case AUTH_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
      };
    case AUTH_USER_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        error: true,
      };
    case USER_GET_REQUEST:
      return {
        ...state,
      };
    case USER_GET_SUCCESS: {
      const { user } = action.payload;
      return {
        ...state,
        user,
        isHPP: user.roles.includes(userRoles.HPP),
        isOPL: user.roles.includes(userRoles.OPL),
      };
    }
    case USER_GET_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        isHPP: false,
        isOPL: false,
        error: true,
      };
    case USER_UPDATE_SETTINGS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case USER_UPDATE_SETTINGS_SUCCESS: {
      const { user } = action.payload;
      return {
        ...state,
        user,
        loading: false,
        error: false,
      };
    }
    case USER_UPDATE_SETTINGS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    default:
      return state;
  }
};

export default auth;

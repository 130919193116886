import React, { Component } from "react";
import PropTypes from "prop-types";
import Section from "components/layout/Section";
import MarkdownEdit from "components/input/MarkdownEdit";
import i18n from "i18n";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import uid from "utils/uid";
import Button from "components/elements/Button";
import Field from "components/input/Field";

function Risk({
  uuid,
  heading,
  text,
  onUpdate,
  onDelete,
}) {
  return (
    <Section action={() => onDelete(uuid)} actionIcon="delete_01" title={heading} padding>
      <Section>

        <Field
          id="text"
          label={i18n.t("views.editAssessment.risk.textLabel")}
          onChange={(update) => {
            onUpdate({ ...update, riskId: uuid });
          }}
        >
          {(id, onChange) => (
            <MarkdownEdit
              id={id}
              placeholder={i18n.t("global.textFiledPlaceHolderDefault")}
              text={text}
              update={onChange}
              small
            />
          )}
        </Field>

      </Section>
    </Section>
  );
}

class RisksEdit extends Component {
  handleAddRisk = () => {
    const { onUpdate } = this.props;
    let { risks } = this.props;
    if (risks) {
      risks = [
        ...risks,
        {
          uuid: uid(),
          text: "",
        },
      ];
    } else {
      risks = [
        {
          uuid: uid(),
          text: "",
        },
      ];
    }
    onUpdate({ id: "risks", value: risks });
  };

  handleDeleteRisk = (uuid) => {
    const { risks, onUpdate } = this.props;
    onUpdate({
      id: "risks",
      value: risks.filter((risk) => risk.uuid !== uuid),
    });
  };

  handleUpdateRisk = (update) => {
    const { id, riskId, value } = update;
    const { risks, onUpdate } = this.props;
    const risksUpdate = {
      id: "risks",
      value: risks,
    };
    risksUpdate.value = risks.map((risk) => {
      if (risk.uuid === riskId) {
        const editedRisk = risk;
        switch (id) {
          case "text":
            editedRisk.text = value;
            break;
          default:
            console.error("scannot update risk", update);
        }
        return editedRisk;
      }
      return risk;
    });
    onUpdate(risksUpdate);
  };

  render() {
    const {
      loading,
      risks,
    } = this.props;
    return (
      <>
        {
          risks.map((risk, index) => (
            <Risk
              heading={`${i18n.t(
                "views.editAssessment.risk.risk",
              )} ${index + 1}`}
              onDelete={this.handleDeleteRisk}
              onUpdate={this.handleUpdateRisk}
              key={risk.uuid}
              {...risk}
            />
          ))
        }
        <Button
          type="outlined"
          onClick={this.handleAddRisk}
          disabled={loading}
          small
        >
          {i18n.t("views.editAssessment.buttons.addRisk")}
        </Button>
      </>
    );
  }
}

RisksEdit.propTypes = {
  id: PropTypes.string,
  risks: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string,
    text: PropTypes.string,
  })),
};

function mapStateToProps() {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({}, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RisksEdit);

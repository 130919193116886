import React, { Component } from "react";
import PropTypes from "prop-types";
import Spinner from "components/elements/Spinner";
import i18n from "i18n";
import css from "./Figure.module.scss";

class Figure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      error: false,
    };
  }

  componentDidMount() {
    const { url } = this.props;
    if (url) {
      const img = new Image();
      img.addEventListener("load", () => {
        this.setState(() => ({
          loaded: true,
          error: false,
        }));
      });
      img.addEventListener("error", () => {
        console.log("error loading image");
        this.setState(() => ({
          loaded: false,
          error: true,
        }));
      });
      img.src = url;
    }
  }

  render() {
    const { caption, url } = this.props;
    const { loaded, error } = this.state;
    return (
      <figure className={[css.figure]}>
        {!loaded && (
          <Spinner className={css.spinner}>
            {error && <span>{i18n.t("errors.errorImageNotFound")}</span>}
          </Spinner>
        )}
        {loaded && (
          <div className={css.imageContainer}>
            <img className={css.image} src={url} alt={caption || ""} />
          </div>
        )}
        {caption && <figcaption className={css.caption}>{caption}</figcaption>}
      </figure>
    );
  }
}

Figure.propTypes = {
  url: PropTypes.string,
  caption: PropTypes.string,
};

export default Figure;

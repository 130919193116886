import { api } from "utils/api";
import {
  closeModal,
  showMessage,
  showModal,
} from "store/actions/notifications/";
import i18n from "i18n";
import { APP_FORBIDDEN_ACCESS, APP_GLOBAL_ERROR } from "store/actions/app";
import { AUTH_USER_ERROR } from "store/actions/auth/";
import { getRouteParams } from "utils/url";

export const COMMENTS_GET_REQUEST = "COMMENTS_GET_REQUEST";
export const COMMENTS_GET_SUCCESS = "COMMENTS_GET_SUCCESS";
export const COMMENTS_GET_ERROR = "COMMENTS_GET_ERROR";

export const COMMENTS_POST_REQUEST = "COMMENTS_POST_REQUEST";
export const COMMENTS_POST_SUCCESS = "COMMENTS_POST_SUCCESS";
export const COMMENTS_POST_ERROR = "COMMENTS_POST_ERROR";

export const COMMENTS_UPDATE_REQUEST = "COMMENTS_UPDATE_REQUEST";
export const COMMENTS_UPDATE_SUCCESS = "COMMENTS_UPDATE_SUCCESS";
export const COMMENTS_UPDATE_ERROR = "COMMENTS_UPDATE_ERROR";

export function getComments() {
  return async (dispatch) => {
    try {
      dispatch({
        type: COMMENTS_GET_REQUEST,
      });
      const { customerId, assessmentId, cardId } = getRouteParams();
      const result = await api.get(
        `customers/${customerId}/assessments/${assessmentId}/cards/${cardId}/comments`,
        {
          withCredentials: true,
        },
      );
      const { status } = result;
      if (status === 200) {
        const { data } = result;
        dispatch({
          type: COMMENTS_GET_SUCCESS,
          payload: {
            cardId,
            comments: data,
          },
        });
      } else {
        throw new Error(result.statusText);
      }
    } catch (error) {
      dispatch(closeModal());
      const { response } = error;
      if (response) {
        const { status } = response;
        switch (status) {
          case 401:
            dispatch({
              type: AUTH_USER_ERROR,
            });
            break;
          case 403:
            dispatch({
              type: APP_FORBIDDEN_ACCESS,
            });
            break;
          default:
            dispatch({
              type: APP_GLOBAL_ERROR,
            });
        }
      } else {
        dispatch({
          type: APP_GLOBAL_ERROR,
        });
      }
      dispatch({
        type: COMMENTS_GET_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("errors.errorGetComments"),
          type: "error",
        }),
      );
    }
  };
}

export function postComment(comment) {
  return async (dispatch) => {
    try {
      dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.postComment"),
        }),
      );
      dispatch({
        type: COMMENTS_POST_REQUEST,
      });
      const { customerId, assessmentId, cardId } = getRouteParams();
      const result = await api.post(
        `customers/${customerId}/assessments/${assessmentId}/cards/${cardId}/comments`,
        comment,
        {
          headers: {
            "content-type": "application/json",
          },
          withCredentials: true,
        },
      );
      dispatch({
        type: COMMENTS_POST_SUCCESS,
        payload: {
          comment: result.data,
        },
      });
      await dispatch(getComments());
      dispatch(closeModal());
      dispatch(
        showMessage({
          title: i18n.t("global.success"),
          text: i18n.t("messages.postCommentSuccess"),
          type: "alert",
        }),
      );
    } catch (error) {
      dispatch({
        type: COMMENTS_POST_ERROR,
      });
      await dispatch(closeModal());
      await dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("errors.errorPostComment"),
          type: "error",
        }),
      );
      console.log(error);
    }
  };
}

export function updateComment(comment) {
  return async (dispatch) => {
    try {
      dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.updateComment"),
        }),
      );
      dispatch({
        type: COMMENTS_UPDATE_REQUEST,
      });
      const { customerId, assessmentId, cardId } = getRouteParams();
      const { id } = comment;
      const result = await api.put(
        `customers/${customerId}/assessments/${assessmentId}/cards/${cardId}/comments/${id}`,
        comment,
        {
          headers: {
            "content-type": "application/json",
          },
          withCredentials: true,
        },
      );
      dispatch({
        type: COMMENTS_UPDATE_SUCCESS,
        payload: {
          comment: result.data,
        },
      });
      await dispatch(getComments());
      dispatch(closeModal());
      dispatch(
        showMessage({
          title: i18n.t("global.success"),
          text: i18n.t("messages.updateCommentSuccess"),
          type: "alert",
        }),
      );
    } catch (error) {
      dispatch({
        type: COMMENTS_UPDATE_ERROR,
      });
      await dispatch(closeModal());
      await dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("errors.errorUpdateComment"),
          type: "error",
        }),
      );
      console.log(error);
    }
  };
}

import React, { Component } from "react";
import { transformMalfunctionTypesToCardType } from "utils/types";
import ToolTip from "components/elements/ToolTip";
import Spinner from "components/elements/Spinner";
import i18n from "i18n";
import MalfunctionTooltip from "components/blocks/MalfunctionTooltip";
import MalfunctionsGroupTooltip from "components/blocks/MalfunctionsGroupTooltip";
import PropTypes from "prop-types";
import { handleMalfunctionsTurbineGraphic } from "utils/placements";
import css from "./HotspotView.module.scss";
import SvgGraphic from "./SvgGraphic";

export default class HotspotView extends Component {
  constructor(props) {
    super(props);
    this.toolTip = React.createRef();
    this.state = {
      loading: true,
      selected: null,
      disabled: true,
    };
    this.handleGetToolTipContent = this.handleGetToolTipContent.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOverGroup = this.handleMouseOverGroup.bind(this);
    this.handleMalfunctions = this.handleMalfunctions.bind(this);
  }

  handleGetToolTipContent() {
    const {
      cards,
      onNavigateToCard,
      onCreateCard,
      editable,
      groupsMalfunctions,
    } = this.props;
    const { selected } = this.state;
    if (!selected) {
      return null;
    }
    // grouped malfunctions
    if (selected.groupId) {
      const group = groupsMalfunctions.find((item) => item.groupId === selected.groupId);
      return (
        <MalfunctionsGroupTooltip
          editable={editable}
          groupId={selected.groupId}
          groupTitle={selected.groupName}
          malfunctions={group.malfunctions}
          cards={cards}
          onNavigateToCard={onNavigateToCard}
          onCreateCard={onCreateCard}
        />
      );
    }
    // individual malfunctions
    const matchedCard = cards.find(
      (card) => (card.malfunctionId === selected.malfunctionId),
    );
    if (matchedCard) {
      return (
        <MalfunctionTooltip
          card={matchedCard}
          malfunction={selected.cardTitle}
          onClick={() => onNavigateToCard(matchedCard.id)}
        />
      );
    }
    return (
      <MalfunctionTooltip
        editable={editable}
        type={selected.cardType}
        malfunction={selected.cardTitle}
        onClick={() => onCreateCard(
          selected.cardType,
          { malfunctionId: selected.malfunctionId, disableMalfunctionChange: true },
        )}
      />
    );
  }

  handleMouseOver(malfunction) {
    const { id, malfunctionType, malfunctionName } = malfunction;
    this.setState({
      selected: {
        id,
        cardType: transformMalfunctionTypesToCardType(malfunctionType),
        cardTitle: malfunctionName,
        ...malfunction,
      },
    });
  }

  handleMouseOverGroup(event) {
    const { target } = event;
    const { id } = target;
    const { groupTitle } = target.dataset;
    this.setState({
      selected: {
        groupId: id,
        groupTitle,
      },
    });
  }

  handleMalfunctions(malfunctions) {
    const { cards, editable, machine } = this.props;

    return handleMalfunctionsTurbineGraphic(malfunctions, cards, editable, machine.malfunctions);
  }

  render() {
    const { className, malfunctions, turbineType } = this.props;
    const { loading, disabled } = this.state;
    return (
      <div className={[className, css.container].join(" ")}>
        { loading && <Spinner className={css.spinner}>{i18n.t("viewComponents.hotspotView.loadingOverview")}</Spinner>}
        <SvgGraphic
          svgPath={turbineType}
          width={1150}
          height={766}
          malfunctions={this.handleMalfunctions(malfunctions)}
          handleMouseOver={(e) => this.handleMouseOver(e)}
          ref={React.createRef()}
          disabled={disabled}
        />
        <ToolTip
          id="hotspot-overlay"
          ref={this.toolTip}
          className={css.info}
          getContent={this.handleGetToolTipContent}
        />
      </div>
    );
  }
}

HotspotView.propTypes = {
  malfunctions: PropTypes.array,
  className: PropTypes.string,
  onNavigateToCard: PropTypes.func,
  svgPath: PropTypes.string,
  editable: PropTypes.bool,
  groupsMalfunctions: PropTypes.array,
  turbineType: PropTypes.string,
  machine: PropTypes.object,
};

HotspotView.defaultProps = {
  malfunctions: [],
  className: "",
  svgPath: "",
  editable: false,
  groupsMalfunctions: [],
  turbineType: "francis",
  machine: {},
};

/**
 * @fileoverview Modal component for assessment card pop up
 * @author  Florian Berg
 */

import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Actions from "store/actions";
import Button from "components/elements/Button";
import ToggleButton from "components/input/ToggleButton";
import Spinner from "components/elements/Spinner";
import i18n from "i18n";
import css from "./Modal.module.scss"; // Import css modules stylesheet as styles

function actionModal(props) {
  const {
    message, primaryAction, actionText, style, close,
  } = props;
  return (
    <div className={[css.inner, css.action, css[style]].join(" ")}>
      <button onClick={close} className={[css.close, css[style]].join(" ")}>
        <span className="icon-close" />
      </button>
      <div className={css.title}>
        {style === "alert" && "Do you really want to publish?"}
        {style === "warning" && "Warning"}
        {style === "error" && "Are you sure?"}
      </div>
      <div className={css.message}>{message}</div>
      <div className={css.buttons}>
        <Button onClick={close} type="outlined">
          {i18n.t("global.cancel")}
        </Button>
        <Button
          onClick={() => {
            primaryAction();
          }}
          type="primary"
          color={style}
        >
          {actionText}
        </Button>
      </div>
    </div>
  );
}

function loadingModal(props) {
  const { message } = props;
  return (
    <div className={[css.inner, css.wait].join(" ")}>
      <Spinner className={css.spinner}>{message}</Spinner>
    </div>
  );
}

function settingsModal(props) {
  const {
    title,
    message,
    primaryAction,
    actionText,
    style,
    changeSettingAction,
    state,
    loading,
  } = props;
  return (
    <div className={[css.inner, css.settings, css[style]].join(" ")}>
      <div className={css.title}>{title}</div>
      <div className={css.message}>{message}</div>
      <ToggleButton
        className={css.toggleButton}
        checked={state}
        onChange={changeSettingAction}
      />
      <div className={css.buttons}>
        <Button
          onClick={() => {
            primaryAction();
          }}
          type="primary"
          color={style}
          className={css.wideButton}
          disabled={loading}
        >
          {actionText}
        </Button>
      </div>
    </div>
  );
}

class Modal extends PureComponent {
  componentDidUpdate() {
    const { modal, closeModal } = this.props;
    if (modal) {
      document.body.classList.add("no-scroll");
      const { type } = modal;

      if (type !== "wait") {
        document.addEventListener(
          "keydown",
          (event) => {
            const { key } = event;
            if (key === "Escape") {
              closeModal();
            }
          },
          { once: true },
        );
      }
    } else {
      document.body.classList.remove("no-scroll");
    }
  }

  render() {
    const {
      modal, closeModal, state, loading,
    } = this.props;
    if (!modal) {
      return null;
    }
    return (
      <div className={css.container}>
        {modal.type === "wait" && loadingModal(modal)}
        {modal.type === "action"
          && actionModal({ ...modal, close: closeModal })}
        {modal.type === "settings"
          && settingsModal({
            ...modal,
            close: this.props,
            state,
            loading,
          })}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { modal } = state.notifications;
  if (modal) {
    const { mapState } = modal;
    if (mapState) {
      return { ...mapState(state), modal: state.notifications.modal };
    }
  }
  return {
    modal: state.notifications.modal,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(Actions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Modal);

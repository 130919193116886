import {
  CUSTOMER_SELECT,
  CUSTOMER_CLEAR,
  CUSTOMER_EDIT,
  CUSTOMER_SET_DEFAULTS,
} from "store/actions";

export function customersReducer(state, action) {
  switch (action.type) {
    case CUSTOMER_SELECT: {
      const { customer } = action.payload;
      return {
        ...state,
        current: customer,
      };
    }
    case CUSTOMER_CLEAR: {
      return {
        ...state,
        current: null,
      };
    }
    case CUSTOMER_EDIT: {
      const { customer } = action.payload;
      return {
        ...state,
        current: customer,
      };
    }
    case CUSTOMER_SET_DEFAULTS: {
      const { settings } = action.payload;
      let updatedCustomer = state.current;
      if (settings) {
        if (settings.hasOwnProperty("glossary")) {
          if (updatedCustomer.glossary === null) {
            updatedCustomer = {
              ...updatedCustomer,
              glossary: settings.glossary,
            };
          }
        }
        return {
          ...state,
          current: updatedCustomer,
        };
      }
      console.log("no settings");
      return state;
    }
    default:
      return state;
  }
}

export default customersReducer;

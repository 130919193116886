import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { languages, malfunctionTypes } from "utils/components";
import { recursiveFind } from "utils/helper";
import * as Actions from "store/actions";
import Button from "components/elements/Button";
import DropDown from "components/input/DropDown";
import Translation from "components/elements/Translation";
import Spinner from "components/elements/Spinner";
import i18n from "i18n";
import css from "./ModalEditMalfunction.module.scss";

class ModalEditMalfunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      name: "",
      loading: false,
      translations: [],
      type: "hsi",
      selectedType: {
        code: "hsi",
        name: "HSI",
        id: "hsi",
      },
      count: 0,
      displayTranslations: false,
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleAddTranslation = this.handleAddTranslation.bind(this);
    this.handleSelectTranslation = this.handleSelectTranslation.bind(this);
    this.handleDeleteTranslation = this.handleDeleteTranslation.bind(this);
  }

  componentDidMount() {
    const { props: { malfunction } } = this;
    let count = 0;
    let malfunctionTranslations = [];
    if (malfunction.translations && malfunction.translations.length > 0) {
      malfunctionTranslations = malfunction.translations.map((t) => {
        const updatedT = {
          ...t,
          count,
        };
        count += 1;
        return updatedT;
      });
    }
    this.setState((prevState) => ({
      ...prevState,
      ...malfunction,
      count,
      translations: malfunctionTranslations,
    }));
  }

  handleChange(prop, value) {
    this.setState((state) => ({
      ...state,
      [prop]: value,
    }));
  }

  handleSelect(e) {
    this.setState({
      selectedType: e.value.item,
      type: e.value.item.id,
    });
  }

  async primaryAction() {
    const {
      translations,
      name,
      type,
      id,
      componentId,
    } = this.state;
    const { updateMalfunction, getComponents } = this.props;
    const malfunction = {
      id,
      componentId,
      name,
      type,
      translations,
    };
    this.setState({ loading: true });
    await updateMalfunction(malfunction);
    await getComponents(); // update the components state so that changes already take effect
  }

  handleAddTranslation() {
    const { count } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      displayTranslations: true,
      count: count + 1,
      translations: [
        ...prevState.translations,
        {
          name: "",
          text: "",
          languageCode: "",
          count,
        },
      ],
    }));
  }

  handleDeleteTranslation(translation) {
    const { translations } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      translations: translations.filter((item) => item.id !== translation.id),
    }));
  }

  handleSelectTranslation(e, translation) {
    const { translations } = this.state;
    // *
    const filtred = translations.filter((item) => item.count !== translation.count);
    this.setState((prevState) => ({
      ...prevState,
      translations: [
        ...filtred,
        {
          text: translation.text,
          languageCode: e.value.item.languageCode,
          name: translation.name,
          count: translation.count,
        },
      ],
    }));
    // */
  }

  handleInput(e, translation) {
    const { translations } = this.state;
    const filtred = translations.filter((item) => item.count !== translation.count);
    // *
    this.setState((prevState) => ({
      ...prevState,
      translations: [
        ...filtred,
        {
          ...translation,
          name: e,
          text: e,
        },
      ],
    }));
    // */
  }

  render() {
    const {
      handleInput,
      handleAddTranslation,
      handleDeleteTranslation,
      handleSelectTranslation,
      handleSelect,
      props: {
        closeComponentsModal,
      },
      state: {
        type,
        name,
        error,
        loading,
        translations,
      },
    } = this;
    // sort rows by count ascending
    translations.sort((a, b) => a.count - b.count);

    const languagesRemaining = [...languages
      .filter(({ languageCode }) => translations
        .some((item) => item.languageCode === languageCode))].length;
    const disableAddTranslationButton = (languages.length - 1) <= languagesRemaining;
    return (
      <div className={[css.inner, css.action].join(" ")}>
        { loading ? <Spinner className={css.spinner}>{i18n.t("components.messages.creating_malfunction")}</Spinner> : (
          <>
            <button onClick={closeComponentsModal} className={css.close}>
              <span className="icon-close" />
            </button>
            <div className={css.title}>
              {i18n.t("components.malfunctions.add_new_malfunction")}
            </div>
            <div className={css.content}>
              <div className={css.item}>
                <h4>{i18n.t("components.malfunctions.malfunction_name")}</h4>
                <div className={[css.inputContainer, error ? css.error : ""].join(" ")}>
                  <input
                    id={name}
                    type="text"
                    onChange={(e) => this.handleChange("name", e.target.value)}
                    placeholder={i18n.t("components.malfunctions.malfunction_name")}
                    className={css.input}
                    value={name}
                  />
                  {error && (
                    <span className={css.errorText}>
                      {i18n.t("validation.textTooLong")}
                    </span>
                  )}
                </div>
              </div>
              <div className={css.item}>
                <div className={css.type}>
                  <h4>{i18n.t("components.malfunctions.type_of_malfunction")}</h4>
                  <div className={[css.inputContainer, css.dropdown, error ? css.error : ""].join(" ")}>
                    <DropDown
                      id={type}
                      update={handleSelect}
                      placeholder={i18n.t("global.dropDownPlaceholderDefault")}
                      items={malfunctionTypes}
                      selected={recursiveFind(malfunctionTypes, type, "id")}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className={css.divTranslations}>
                  <h4>{i18n.t("components.translations")}</h4>
                  <button disabled={disableAddTranslationButton} onClick={handleAddTranslation}>
                    <span className="icon-add" style={{ fontSize: "24px" }} />
                    <p>{i18n.t("components.add_translation")}</p>
                  </button>
                </div>
                <div className={[css.translations]}>
                  { translations.length >= 1 ? translations.map((translation) => (
                    <Translation
                      translation={translation}
                      translations={translations}
                      key={translation.count}
                      onChange={handleSelectTranslation}
                      handleInput={handleInput}
                      deleteTranslation={handleDeleteTranslation}
                    />
                  )) : (<p>{i18n.t("components.no_translations_founded")}</p>)}
                </div>
              </div>
            </div>
            <div className={css.buttons}>
              <Button className={css.btn} onClick={closeComponentsModal} type="outlined">
                {i18n.t("global.cancel")}
              </Button>
              <Button
                className={css.btn}
                onClick={() => {
                  this.primaryAction();
                }}
                type="primary"
              >
                {i18n.t("components.buttons.labels.save")}
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    malfunction: state.components.modal.data,
    defaultLanguage: state.components.language,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(Actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalEditMalfunction);

import { getCardStatusTypeClass } from "utils/types";

export function getXAxis(x, offsetX) {
  return Math.abs(x - offsetX);
}

export function getYAxis(y, offsetY) {
  return Math.abs(y - offsetY);
}

export function sanitizeMalfunctions(component, placements, malfunctions) {
  if (component.malfunctions.length === 0 || !placements) {
    return component.malfunctions;
  }
  const result = [];

  const found = malfunctions.find(({ componentId }) => component.componentId === componentId);

  if (found) {
    placements.forEach((placement) => {
      const { malfunctionId, groupId } = placement;
      if (groupId) {
        const grouped = found.malfunctions.filter(({ groupId: gId }) => gId === groupId);
        if (grouped.length >= 1) {
          grouped.forEach((group) => {
            result.push({
              ...group,
              active: false,
            });
          });
        }
        return;
      }
      if (!groupId) {
        const malfunction = found.malfunctions
          .find(({ malfunctionId: mId }) => mId === malfunctionId);
        if (malfunction) {
          result.push({
            ...malfunction,
            active: false,
          });
        }
      }
    });
  }

  component.malfunctions.forEach((malfunction) => {
    const exist = result.find(({ malfunctionId }) => malfunction.id === malfunctionId);
    if (!exist) {
      result.push({
        malfunctionId: malfunction.id,
        malfunctionType: malfunction.type,
        malfunctionName: malfunction.name,
        groupId: null,
        active: true,
      });
    }
  });
  return result;
}

export function getPlacementsWithMalfunctionsName(placements, malfunctionsList) {
  if (placements.length === 0) {
    return [];
  }
  const list = [];

  malfunctionsList.forEach((component) => {
    const { malfunctions } = component;
    malfunctions.forEach((malfunction) => {
      let found;
      const { malfunctionId, groupId } = malfunction;
      if (groupId) {
        found = placements.find(({ groupId: gId }) => gId === groupId);
        if (found) {
          found.groupName = component.componentName;
          found.malfunctionId = malfunction.malfunctionId;

          const alreadyExist = list.find(({ id }) => id === found.id);

          if (alreadyExist) {
            alreadyExist.malfunctions.push(malfunction);
            return;
          }

          list.push({
            ...malfunction,
            groupName: found.groupName,
            componentId: component.componentId,
            malfunctions: [malfunction],
            id: found.id,
          });
          return;
        }
      } else {
        found = placements.find(({ malfunctionId: mId }) => mId === malfunctionId);
      }

      if (!found) {
        return;
      }

      const alreadyExist = list.find(({ id }) => id === found.id);

      if (alreadyExist) {
        return;
      }

      list.push({
        ...malfunction,
        id: found.id,
        malfunctions: [],
        name: malfunction.malfunctionName,
      });
    });
  });
  return list;
}

export function formatMalfunctionsGroup(group, componentsMalfunctions) {
  const { componentId, malfunctions: gMalfunctions } = group;
  const malfunctionsGrouped = [];
  const component = componentsMalfunctions.find(({ componentId: cId }) => componentId === cId);
  gMalfunctions.forEach((item) => {
    const { groupId, malfunctionId, turbineType } = item;
    const malfunction = component.malfunctions.find(({ id }) => id === malfunctionId);
    malfunctionsGrouped.push({
      groupId,
      malfunctionId: malfunction.id,
      malfunctionName: malfunction.name,
      turbineType,
      malfunctionType: malfunction.malfunctionType,
      xAxis: malfunction.xAxis,
      yAxis: malfunction.yAxis,
    });
  });
  if (group.position) {
    const {
      turbineType,
      xAxis,
      malfunctionId,
      yAxis,
    } = group.position;
    return {
      componentId: component.componentId,
      groupId: group.id,
      malfunctionId,
      turbineType,
      xAxis,
      yAxis,
      groupName: component.componentName,
      malfunctions: malfunctionsGrouped,
    };
  }
  const {
    turbineType,
    xAxis,
    malfunctionId,
    yAxis,
    groupId,
  } = group;
  return {
    componentId: component.componentId,
    groupId,
    malfunctionId,
    turbineType,
    xAxis,
    yAxis,
    groupName: component.componentName,
    malfunctions: malfunctionsGrouped,
  };
}

export function handleMalfunctionsTurbineGraphic(
  list,
  cards = undefined,
  editable = false,
  malfunctions = [],
) {
  const returned = [];

  if (!cards) {
    list.forEach((malfunction) => {
      if (malfunction.groupId) {
        returned.push({
          ...malfunction,
          type: "#group-normal",
          malfunctionId: malfunction.groupId,
        });
        return;
      }
      returned.push({
        ...malfunction,
        type: "#malfunction",
        malfunctionId: malfunction.malfunctionId,
      });
    });
  }

  if (cards) {
    list.forEach((malfunction) => {
      const exist = malfunctions.find((item) => item.malfunctionId === malfunction.malfunctionId);
      if (exist) {
        if (malfunction.groupId) {
          let type = "#group-add";
          malfunction.malfunctions.forEach((item) => {
            const result = cards.find(({ malfunctionId }) => malfunctionId === item.malfunctionId);
            if (result) {
              const { statusType: status } = result;
              const statusTypeClass = getCardStatusTypeClass(status);
              if (statusTypeClass === "alert") {
                type = "#group-alert";
              } else if (statusTypeClass === "warning") {
                type = "#group-warning";
              } else if (type !== "warning" && statusTypeClass === "normal") {
                type = "#group-normal";
              }
            }
          });
          returned.push({
            ...malfunction,
            type,
          });
          return;
        }

        const card = cards.find((card) => card.malfunctionId === malfunction.malfunctionId);
        if (card) {
          const { statusType, highlight } = card;
          const statusTypeClass = highlight === true
            ? "#normal-highlight" : `#${getCardStatusTypeClass(statusType)}`;
          returned.push({
            ...malfunction,
            type: statusTypeClass,
          });
          return;
        }

        if (editable) {
          returned.push({
            ...malfunction,
            type: "#add",
          });
        }
      }
    });
  }

  return returned;
}

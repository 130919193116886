import uid from "utils/uid";

export const NOTIFICATIONS_SHOW_MODAL = "NOTIFICATIONS_SHOW_MODAL";
export const NOTIFICATIONS_CLOSE_MODAL = "NOTIFICATIONS_CLOSE_MODAL";
export const NOTIFICATIONS_SHOW_MESSAGE = "NOTIFICATIONS_SHOW_MESSAGE";
export const NOTIFICATIONS_CLOSE_MESSAGE = "NOTIFICATIONS_CLOSE_MESSAGE";
export const NOTIFICATIONS_CLOSE_ALL_MESSAGES = "NOTIFICATIONS_CLOSE_ALL_MESSAGES";

export function showModal(modal) {
  return (dispatch, getState) => {
    const { notifications } = getState();
    if (notifications.modal && notifications.modal.fixed) {
      return;
    }
    dispatch({
      type: NOTIFICATIONS_SHOW_MODAL,
      payload: {
        modal,
      },
    });
  };
}

export function closeModal() {
  return (dispatch, getState) => {
    const { notifications } = getState();
    if (notifications.modal && notifications.modal.fixed) {
      return;
    }
    dispatch({
      type: NOTIFICATIONS_CLOSE_MODAL,
    });
  };
}

export function showMessage(message) {
  return (dispatch) => {
    dispatch({
      type: NOTIFICATIONS_SHOW_MESSAGE,
      payload: {
        message: {
          id: uid(),
          title: message.title,
          text: message.text,
          type: message.type,
          timeStamp: Date.now(),
          duration: message.duration || 3000,
          sticky: message.sticky || false,
        },
      },
    });
  };
}

export function closeMessage(id) {
  return (dispatch) => {
    dispatch({
      type: NOTIFICATIONS_CLOSE_MESSAGE,
      payload: {
        id,
      },
    });
  };
}

export function closeAllNotifications() {
  return (dispatch) => {
    dispatch({
      type: NOTIFICATIONS_CLOSE_ALL_MESSAGES,
    });
  };
}

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import css from "./Section.module.scss";

class Section extends PureComponent {
  render() {
    const {
      title,
      small,
      white,
      children,
      className,
      noMargin,
      borderTop,
      borderBottom,
      padding,
      row,
      overlay,
      action,
      actionIcon,
    } = this.props;
    if (!children) {
      return null;
    }
    return (
      <section
        className={[
          css.section,
          overlay ? css.overlay : undefined,
          borderTop ? css.borderTop : undefined,
          borderBottom ? css.borderBottom : undefined,
          padding ? css.padding : undefined,
          white ? css.white : undefined,
          small ? css.small : undefined,
          noMargin ? css.noMargin : undefined,
          row ? css.row : undefined,
          className,
        ]
          .filter((className) => className !== undefined)
          .join(" ")}
      >
        {title && <div className={css.title}>{title}</div>}
        {action && <button onClick={action} className={css.actionButton}><span className={`icon-${actionIcon}`} /></button>}
        {children}
      </section>
    );
  }
}

Section.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.node,
  white: PropTypes.bool,
  small: PropTypes.bool,
  title: PropTypes.string,
  action: PropTypes.func,
  icon: PropTypes.string,
};

Section.defaultProps = {
  visible: true,
  white: false,
  title: "",
};

export default Section;

import React, { Component } from "react";
import DropDown from "components/input/DropDown";
import { languages } from "utils/components";
import i18n from "i18n";
import { recursiveFind } from "utils/helper";
import css from "./Translation.module.scss";

class Translation extends Component {
  render() {
    const {
      deleteTranslation,
      handleInput,
      onChange,
      translation,
      translations,
    } = this.props;
    return (
      <div className={css.translation} key={translation.count}>
        <div className={css.dropdown}>
          <DropDown
            placeholder={i18n.t("global.dropDownPlaceholderDefault")}
            // never translate to english since it's the default language
            items={languages.filter((item) => item.languageCode !== "en")}
            id="select-language"
            update={(e) => onChange(e, translation)}
            selected={recursiveFind(languages, translation.languageCode, "languageCode")}
            disabledItems={
              [
                ...languages
                  .filter((f) => translations.some((i) => i.languageCode === f.languageCode)),
              ]
            }
          />
        </div>
        <div className={css.inputContainer}>
          <input
            id={translation.id}
            type="text"
            onChange={(e) => handleInput(e.target.value, translation)}
            className={css.inputTranslation}
            value={translation.name}
          />
        </div>
        <div>
          <button className={css.icon} onClick={() => deleteTranslation(translation)}>
            <span className="icon-delete_01" />
          </button>
        </div>
      </div>
    );
  }
}

export default Translation;

import React, { Component } from "react";
import { cardStatus } from "utils/constants";
import PropTypes from "prop-types";
import i18n from "i18n";
import css from "./CardStatus.module.scss";

const Status = (props) => {
  const { status, label, className } = props;
  switch (status) {
    case cardStatus.OPERATIONAL:
      return (
        <div className={[css.status, className].join(" ")}>
          <span className={["icon-status_ok", css.statusIcon].join(" ")} />
          <span className={css.statusTitle}>
            {label && <span className={css.label}>{label}</span>}
            <span className={label ? css.type : css.typeSmall}>
              {i18n.t("types.status.operational")}
            </span>
          </span>
        </div>
      );
    case cardStatus.ANOMALY_DETECTED:
      return (
        <div className={[css.status, className].join(" ")}>
          <span className={["icon-status_warning", css.statusIcon].join(" ")} />
          <span className={css.statusTitle}>
            {label && <span className={css.label}>{label}</span>}
            <span className={label ? css.type : css.typeSmall}>
              {i18n.t("types.status.anomalyDetected")}
            </span>
          </span>
        </div>
      );
    case cardStatus.RISK_OF_DAMAGE:
      return (
        <div className={[css.status, className].join(" ")}>
          <span className={["icon-status_alarm", css.statusIcon].join(" ")} />
          <span className={css.statusTitle}>
            {label && <span className={css.label}>{label}</span>}
            <span className={label ? css.type : css.typeSmall}>
              {i18n.t("types.status.riskOfDamage")}
            </span>
          </span>
        </div>
      );
    default:
      return (
        <div className={[css.status, className].join(" ")}>
          <span className={["icon-status_invalid", css.statusIcon].join(" ")} />
          <span className={css.statusTitle}>
            {label && <span className={css.label}>{label}</span>}
            <span className={label ? css.type : css.typeSmall}>
              {i18n.t("global.notAvailable")}
            </span>
          </span>
        </div>
      );
  }
};

export default class CardStatus extends Component {
  render() {
    return <Status {...this.props} />;
  }
}

CardStatus.propTypes = {
  status: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
};

import {
  COMPONENTS_DISPLAY_MODAL,
  COMPONENTS_CLOSE_MODAL,
} from "store/actions";

const initialState = {
  display: false,
  type: [],
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPONENTS_DISPLAY_MODAL: {
      const { modal } = action.payload;
      return {
        ...state,
        modal,
      };
    }
    case COMPONENTS_CLOSE_MODAL:
      return {
        ...state,
        modal: {
          display: false,
        },
      };
    default:
      return state;
  }
};

export default modalReducer;

import { showModal, closeModal } from "store/actions/notifications/";
import i18n from "i18n";
import { appLayouts, turbineTypes } from "utils/constants";
import { getUser } from "./auth";
import { getCustomers } from "./customers";
import { getMalfunctions } from "./malfunctions";
import { getComponents, getPlacements } from "./components";

export const APP_INIT_REQUEST = "APP_INIT_REQUREST";
export const APP_INIT_COMPLETE = "APP_INIT_COMPLETE";
export const APP_INIT_ERROR = "APP_INIT_ERROR";
export const APP_GLOBAL_ERROR = "APP_GLOBAL_ERROR";

export const APP_FORBIDDEN_ACCESS = "APP_FORBIDDEN_ACCESS";
export const APP_LAYOUT_CHANGE = "APP_LAYOUT_CHANGE";
export const APP_SET_PATHNAME = "APP_SET_PATHNAME";

export function initApp() {
  return async (dispatch, getState) => {
    try {
      console.log("app init ...");
      dispatch({
        type: APP_INIT_REQUEST,
      });
      dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.authUser"),
        }),
      );
      await dispatch(getUser());
      dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.getCustomers"),
        }),
      );
      await dispatch(getCustomers());
      dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.getFrancisTurbineMalfunctions"),
        }),
      );
      await dispatch(getMalfunctions(turbineTypes.FRANCIS));
      await dispatch(getPlacements(turbineTypes.FRANCIS));
      dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.getFrancisMivTurbineMalfunctions"),
        }),
      );
      await dispatch(getMalfunctions(turbineTypes.FRANCIS_MIV));
      await dispatch(getPlacements(turbineTypes.FRANCIS_MIV));
      dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.getFrancisHorizontalTurbineMalfunctions"),
        }),
      );
      await dispatch(getMalfunctions(turbineTypes.FRANCIS_HORIZONTAL));
      await dispatch(getPlacements(turbineTypes.FRANCIS_HORIZONTAL));
      dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.getKaplanHorizontalTurbineMalfunctions"),
        }),
      );
      await dispatch(getMalfunctions(turbineTypes.KAPLAN_HORIZONTAL));
      await dispatch(getPlacements(turbineTypes.KAPLAN_HORIZONTAL));
      dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.getKaplanVerticalTurbineMalfunctions"),
        }),
      );
      await dispatch(getMalfunctions(turbineTypes.KAPLAN_VERTICAL));
      await dispatch(getPlacements(turbineTypes.KAPLAN_VERTICAL));
      dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.getPeltonMivTurbineMalfunctions"),
        }),
      );
      await dispatch(getMalfunctions(turbineTypes.PELTON_MIV));
      await dispatch(getPlacements(turbineTypes.PELTON_MIV));
      dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.getPeltonHorizontalTurbineMalfunctions"),
        }),
      );
      await dispatch(getMalfunctions(turbineTypes.PELTON_HORIZONTAL));
      await dispatch(getPlacements(turbineTypes.PELTON_HORIZONTAL));
      dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.getSynchronousCondenserMalfunctions"),
        }),
      );
      await dispatch(getMalfunctions(turbineTypes.SYNCHRONOUS_CONDENSER));
      await dispatch(getPlacements(turbineTypes.SYNCHRONOUS_CONDENSER));
      dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.getComponents"),
        }),
      );
      await dispatch(getComponents());

      dispatch(closeModal());
      const { app } = getState();
      const { error } = app;
      if (!error) {
        dispatch({
          type: APP_INIT_COMPLETE,
        });
        console.log("app init complete!");
      } else {
        dispatch({
          type: APP_INIT_ERROR,
        });
      }
    } catch (error) {
      dispatch({
        type: APP_INIT_ERROR,
      });
      console.log(error);
    }
  };
}

export function setAppLayout(layout = appLayouts.DEFAULT) {
  return async (dispatch) => {
    dispatch({
      type: APP_LAYOUT_CHANGE,
      payload: {
        layout,
      },
    });
  };
}

export function setPathname(pathname = "/") {
  return async (dispatch) => {
    dispatch({
      type: APP_SET_PATHNAME,
      payload: {
        pathname,
      },
    });
  };
}

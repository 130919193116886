import {
  MESSAGES_POST_REQUEST,
  MESSAGES_POST_SUCCESS,
  MESSAGES_POST_ERROR,
  MESSAGES_UPDATE_REQUEST,
  MESSAGES_UPDATE_SUCCESS,
  MESSAGES_UPDATE_ERROR,
} from "store/actions";

export function messagesApiReducer(state, action) {
  switch (action.type) {
    case MESSAGES_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MESSAGES_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        draft: false,
      };
    case MESSAGES_POST_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case MESSAGES_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case MESSAGES_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        draft: false,
      };
    case MESSAGES_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}

export default messagesApiReducer;

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import css from "./StatusBar.module.scss";

class StatusBar extends PureComponent {
  render() {
    const {
      className, items,
    } = this.props;
    return (
      <div className={[css.container, className].join(" ")}>
        {items.map((item) => (
          <div key={item.props.label} className={css.item}>
            {item}
          </div>
        ))}
      </div>
    );
  }
}

StatusBar.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.node),
};

StatusBar.defaultProps = {
  items: [],
};

export default StatusBar;

import React, { Component } from "react";
import PropTypes from "prop-types";
import css from "./CheckBox.module.scss";

class CheckBox extends Component {
  render() {
    const {
      id, className, description, checked, type, update, disabled,
    } = this.props;
    return (
      <div
        onChange={() => update({ id, value: !checked })}
        className={[css.checkBox, className].join(" ")}
      >
        <input
          id={id}
          className={[css.input,
            type === "radio" ? css.radio : "",
            type === "box" ? css.box : "",
          ].join(" ")}
          type="checkbox"
          checked={checked}
          readOnly
          disabled={disabled ? "disabled" : ""}
        />
        {
          description
          && (
          <div
            htmlFor={id}
            onClick={
              () => {
                if (!disabled) {
                  update({ id, value: !checked });
                }
              }
            }
            className={css.description}
          >
            {description}
          </div>
          )
        }
      </div>
    );
  }
}

CheckBox.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  checked: PropTypes.bool,
  type: PropTypes.string,
  update: PropTypes.func,
  disabled: PropTypes.bool,
};

CheckBox.defaultProps = {
  checked: false,
  type: "box",
  disabled: false,
};
export default CheckBox;

import reduceReducers from "reduce-reducers";
import { messagesReducer } from "./messages";
import { messagesApiReducer } from "./api/messages";
import { uploadMessagesReducer } from "./upload/messages";

const initialState = {
  loading: false,
  error: false,
  draft: null,
};

export default reduceReducers(
  initialState,
  messagesReducer,
  messagesApiReducer,
  uploadMessagesReducer,
);

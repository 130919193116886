import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import css from "./GenericStatus.module.scss";

class GenericStatus extends PureComponent {
  render() {
    const {
      className, label, status, icon,
    } = this.props;
    return (
      <div className={className}>
        <div className={css.title}>
          {icon && (
            <span
              className={[`icon-${icon}`, css.icon].join(" ")}
            />
          )}
          <span>{label}</span>
        </div>
        <div className={css.status}>
          <span>
            {status}
          </span>
        </div>
      </div>
    );
  }
}

GenericStatus.propTypes = {
  status: PropTypes.string,
  label: PropTypes.string,
};

export default GenericStatus;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Row, Col } from "react-flexbox-grid";
import { cardTypes, cardStatus, malfunctionTypes } from "utils/constants";
import { getCardStatusType } from "utils/types";
import css from "./MalfunctionsGroupTooltip.module.scss";

function getMalfunctionRows(malfunctions, cards) {
  const malfunctionRows = [];
  malfunctions.forEach((item) => {
    let cardType;
    let cardId;
    let cardStatusIcon;
    let cardStatusPriority = -1;
    let malfunctionCard;
    if (item.malfunctionType === malfunctionTypes.HSI) {
      cardType = cardTypes.MALFUNCTION;
    } else {
      cardType = cardTypes.ACOUSTIC;
    }
    if (cards) {
      malfunctionCard = cards.find((card) => card.malfunctionId === item.malfunctionId);
    }
    if (malfunctionCard) {
      const { statusType, id } = malfunctionCard;
      cardId = id;
      const cardStatusType = getCardStatusType(statusType);
      cardStatusPriority = cardStatusType.priority;
      if (cardStatusType.type === cardStatus.OPERATIONAL) {
        cardStatusIcon = "icon-check";
      } else {
        cardStatusIcon = cardStatusType.icon;
      }
    }
    const row = {
      malfunctionId: item.malfunctionId,
      malfunctionName: item.malfunctionName,
      malfunctionType: item.malfunctionType,
      priority: cardStatusPriority,
      cardType,
      cardId,
      cardStatusIcon,
    };
    malfunctionRows.push(row);
  });
  // sort rows by priority descending
  malfunctionRows.sort((a, b) => b.priority - a.priority);
  return malfunctionRows;
}

export default class MalfunctionsGroupTooltip extends Component {
  render() {
    const {
      editable,
      groupId,
      groupTitle,
      malfunctions,
      cards,
      onNavigateToCard,
      onCreateCard,
      deletable,
      onDelete,
    } = this.props;
    const malfunctionRows = getMalfunctionRows(malfunctions, cards);
    return (
      <div className={css.containerMinWidth}>
        <Grid fluid>
          <Row key={groupId} className={css.gridHeader}>
            <Col sm={11} className={css.groupTitle}>{groupTitle}</Col>
            <Col sm={1} className={[css.groupIcon, "icon-group"].join(" ")} />
          </Row>
          {
            malfunctionRows.length > 0 && (
              malfunctionRows.map((item, index) => (
                <Row key={item.malfunctionId} className={[index !== 0 ? css.gridRowBorder : "", css.gridRow].join(" ")}>
                  <Col sm={10} className={css.gridRowMalfunctionName}>{item.malfunctionName}</Col>
                  <Col sm={1} className={css.gridRowCardStatusIcon}>
                    {item.cardStatusIcon && <span className={[css.spanStatusIcon, item.cardStatusIcon].join(" ")} />}
                  </Col>
                  <Col sm={1} className={css.gridRowCardAddEditIcon}>
                    {!item.cardId
                        && editable
                        && <span onClick={() => onCreateCard(item.cardType, { malfunctionId: item.malfunctionId, disableMalfunctionChange: true })} className="icon-add_02" />}
                    {item.cardId
                        && <span onClick={() => onNavigateToCard(item.cardId)} className="icon-arrow_right_01" />}
                    {deletable
                        && (
                          <button onClick={() => onDelete(item.malfunctionId)}>
                            <span className="icon-close btn-delete" />
                          </button>
                        )}
                  </Col>
                </Row>
              ))
            )
          }
        </Grid>
      </div>
    );
  }
}

MalfunctionsGroupTooltip.propTypes = {
  editable: PropTypes.bool,
  groupId: PropTypes.string,
  groupTitle: PropTypes.string,
  malfunctions: PropTypes.array,
  cards: PropTypes.array,
  onNavigateToCard: PropTypes.func,
  onCreateCard: PropTypes.func,
  deletable: PropTypes.bool,
};

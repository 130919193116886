import reduceReducers from "reduce-reducers";
import { assessmentsReducer } from "./assessments";
import { assessmentCardsReducer } from "./cards";
import assessmentsApiReducer from "./api/assessments";
import assessmentCardApiReducer from "./api/cards";
import { argumentationUploadReducer } from "./upload/argumentations";
import { summaryUploadReducer } from "./upload/summary";

const initialState = {
  draft: null,
  currentCard: null,
  assessments: [],
  loading: false,
  error: false,
};

export default reduceReducers(
  initialState,
  assessmentsReducer,
  assessmentCardsReducer,
  assessmentsApiReducer,
  assessmentCardApiReducer,
  argumentationUploadReducer,
  summaryUploadReducer,
);

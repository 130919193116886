/**
 * @fileoverview Screen component for assessment overview view
 * @author  Florian Berg
 */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Actions from "store/actions";
import { navigate } from "@reach/router";
import TitleBar from "components/layout/TitleBar";
import NavigationBar from "components/layout/NavigationBar";
import FloatingButton from "components/elements/FloatingButton";
import HealthAssessmentCard from "components/blocks/HealthAssessmentCard";
import Section from "components/layout/Section";
import CardGrid from "components/layout/CardGrid";
import i18n from "i18n";
import { assessmentReleaseStatus } from "utils/constants";
import css from "./HealthAssessmentOverview.module.scss";

// Import css modules stylesheet as styles
class HealthAssessmentOverview extends Component {
  constructor(props) {
    super(props);
    const { customer } = props;
    if (!customer) {
      props.showMessage({
        title: i18n.t("global.error"),
        text: i18n.t("errors.errorCustomerNotFound"),
        type: "error",
      });
      navigate("/");
    }
  }

  async componentDidMount() {
    const {
      showModal,
      closeModal,
      getAssessmentsOverview,
      customerId,
      assessments,
      setPathname,
    } = this.props;
    if (!assessments) {
      showModal({
        type: "wait",
        message: i18n.t("messages.getHealthAssessments"),
      });
      await getAssessmentsOverview(customerId);
      closeModal();
      setPathname(window.location.pathname);
    }
  }

  handleCreateHealthAssessment = async () => {
    const {
      customerId,
      assetId,
      machineId,
      createNewHealthAssessment,
    } = this.props;
    const id = await createNewHealthAssessment(customerId, assetId, machineId);
    if (id) {
      let path = `/${customerId}/assessments/${id}/edit`;
      if (assetId && !machineId) {
        path = `/${customerId}/${assetId}/assessments/${id}/edit`;
      } else if (assetId && machineId) {
        path = `/${customerId}/${assetId}/${machineId}/assessments/${id}/edit`;
      }
      navigate(path);
    }
  };

  handleViewCard = async (id) => {
    const {
      customerId,
      assetId,
      machineId,
      showModal,
      closeModal,
      getAssessment,
    } = this.props;
    showModal({
      type: "wait",
      message: i18n.t("messages.getHealthAssessment"),
    });
    await getAssessment(customerId, id);
    closeModal();
    let path = `/${customerId}/assessments/${id}`;
    if (assetId && !machineId) {
      path = `/${customerId}/${assetId}/assessments/${id}`;
    } else if (assetId && machineId) {
      path = `/${customerId}/${assetId}/${machineId}/assessments/${id}`;
    }
    navigate(path);
  };

  generateCard = (assessment, index) => {
    const {
      title, status, activityAt, publishedAt, published, commentCount, machine, cards,
    } = assessment;
    return (
      <HealthAssessmentCard
        onClick={() => this.handleViewCard(assessment.id)}
        key={index}
        title={machine.name}
        subTitle={title}
        status={status}
        commentCount={commentCount}
        timeStamp={published ? publishedAt : activityAt}
        cards={cards}
      />
    );
  };

  provideFloatingButtonActions = () => {
    const { createNewMessage } = this.props;
    const actions = [
      { name: i18n.t("global.writeNewMessage"), handler: createNewMessage },
    ];
    return actions;
  }

  handleNavigationClick() {
    const { setPathname } = this.props;
    const path = "/";
    setPathname(path);
    navigate(path);
  }

  render() {
    const {
      editor, customer, assessments, navigationBarPreTitle,
    } = this.props;
    if (!customer || !assessments) {
      return null;
    }
    const draftAssessments = assessments.filter(
      (assessment) => !assessment.published,
    );
    const publishedAssessments = assessments.filter(
      (assessment) => assessment.published,
    );
    return (
      <div className={css.screen}>
        <FloatingButton icon="icon-comment" actions={this.provideFloatingButtonActions()} />
        <NavigationBar
          preline={navigationBarPreTitle}
          title={i18n.t("views.healthAssessmentsOverview.title")}
          addAction={editor ? this.handleCreateHealthAssessment : null}
          backAction={() => this.handleNavigationClick()}
        />
        {assessments.length < 1 && (
          <Section>
            <TitleBar title="No Health Assessment" />
          </Section>
        )}
        {editor && draftAssessments.length > 0 && (
          <Section>
            <TitleBar
              title={i18n.t("views.healthAssessmentsOverview.titleDraft")}
            />
            <CardGrid>
              {draftAssessments.map((assessment, index) => this.generateCard(assessment, index))}
            </CardGrid>
          </Section>
        )}
        {publishedAssessments.length > 0 && (
          <Section>
            {editor && (
              <TitleBar
                title={i18n.t("views.healthAssessmentsOverview.titlePublished")}
              />
            )}
            <CardGrid>
              {publishedAssessments.map(
                (assessment, index) => this.generateCard(assessment, index),
              )}
            </CardGrid>
          </Section>
        )}
        {!editor && publishedAssessments.length < 1 && (
          <Section>
            <TitleBar title="No Published Health Assessment" />
          </Section>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const {
    auth, customers, assessments,
  } = state;
  const { customerId, assetId, machineId } = props;
  const currentCustomer = customers.customers.find(
    (customer) => customer.id === Number(customerId),
  );
  if (!currentCustomer) {
    return null;
  }
  // customer assessments are being lazily loaded at customer selection. However, there is a
  // scenario where the user wants to access the customer assessments directly. In this case, it's
  // handled by componentDidMount(), which updates the state, triggering mapStateToProps() again.
  if (!assessments.assessmentsoverview || !assessments.assessmentsoverview[Number(customerId)]) {
    return {
      customer: currentCustomer,
      assessments: undefined,
    };
  }
  let assessmentsOverview = assessments.assessmentsoverview[Number(customerId)];
  if (machineId) {
    assessmentsOverview = assessmentsOverview
      .filter((assessment) => assessment.machine.id === Number(machineId));
  } else if (assetId) {
    assessmentsOverview = assessmentsOverview
      .filter((assessment) => assessment.machine.parentId === Number(assetId));
  }
  let navigationBarPreTitle = currentCustomer.name;
  if (assetId) {
    const assetStructure = currentCustomer.structures.find(
      (structure) => structure.id === Number(assetId),
    );
    if (assetStructure) {
      navigationBarPreTitle = currentCustomer.name.concat(" / ").concat(assetStructure.name);
    }
    if (machineId) {
      const machineStructure = assetStructure.children.find(
        (structure) => structure.id === Number(machineId),
      );
      if (machineStructure) {
        navigationBarPreTitle = currentCustomer.name.concat(" / ").concat(assetStructure.name)
          .concat(" / ").concat(machineStructure.name);
      }
    }
  }
  return {
    editor: auth.isOPL,
    customer: currentCustomer,
    assessments: assessmentsOverview
      .map((assessment, index) => {
        if (assessment.publishedAt) {
          return {
            ...assessment,
            status: index === 0 ? assessmentReleaseStatus.LATEST : "",
            published: true,
          };
        }
        return { ...assessment, status: assessmentReleaseStatus.DRAFT, published: false };
      }),
    hasGlossary: currentCustomer.glossary !== "",
    navigationBarPreTitle,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(Actions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HealthAssessmentOverview);

import React, { Component } from "react";
import PropTypes from "prop-types";
import i18n from "i18n";
import css from "./TextField.module.scss";

class TextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // start: 0,
      // end: 0,
      error: false,
    };
  }

  // workaround based on an open ticket: https://github.com/facebook/react/issues/12762#issuecomment-388502990
  componentDidUpdate(prevProps, prevState) {
    const { value, start, end } = this.state;
    if (value === prevState.value && this.ref.type !== "number") {
      this.ref.setSelectionRange(start, end);
    }
  }

  handleChange = (event) => {
    const { update, id, max } = this.props;
    const { value } = event.target;
    if (value.length <= max) {
      this.setState({ error: false }, () => {
        if (update) {
          update({ id, value });
        }
      });
      this.setState({
        start: this.ref.selectionStart,
        end: this.ref.selectionEnd,
      });
    } else {
      this.setState({ error: true });
    }
  };

  render() {
    const {
      text, placeholder,
    } = this.props;
    const { id } = this.props;
    const { error } = this.state;
    return (
      <div className={[css.container, error ? css.error : ""].join(" ")}>
        <input
          id={id}
          type="text"
          onChange={this.handleChange}
          placeholder={placeholder || i18n.t("global.textFiledPlaceHolderDefault")}
          className={css.input}
          value={text}
          ref={(ref) => {
            this.ref = ref;
          }}
        />
        {error && (
          <span className={css.errorText}>
            {i18n.t("validation.textTooLong")}
          </span>
        )}
      </div>
    );
  }
}

TextField.propTypes = {
  editable: PropTypes.bool,
  text: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  max: PropTypes.number,
};

TextField.defaultProps = {
  editable: true,
  text: "",
  max: 64,
};
export default TextField;

import i18n from "i18n";
import { api } from "utils/api";
import {
  showModal,
  closeModal,
  showMessage,
} from "store/actions/notifications/";

import { getAssessment, getAssessmentsOverview } from "./assessments";

export const ASSESSMENT_CARD_CREATE_REQUEST = "ASSESSMENT_CARD_CREATE_REQUEST";
export const ASSESSMENT_CARD_CREATE_SUCCESS = "ASSESSMENT_CARD_CREATE_SUCCESS";
export const ASSESSMENT_CARD_CREATE_ERROR = "ASSESSMENT_CARD_CREATE_ERROR";

export const ASSESSMENT_CARD_SAVE_REQUEST = "ASSESSMENT_CARD_SAVE_REQUEST";
export const ASSESSMENT_CARD_SAVE_SUCCESS = "ASSESSMENT_CARD_SAVE_SUCCESS";
export const ASSESSMENT_CARD_SAVE_ERROR = "ASSESSMENT_CARD_SAVE_ERROR";

export const ASSESSMENT_CARD_DELETE_REQUEST = "ASSESSMENT_CARD_DELETE_REQUEST";
export const ASSESSMENT_CARD_DELETE_SUCCESS = "ASSESSMENT_CARD_DELETE_SUCCESS";
export const ASSESSMENT_CARD_DELETE_ERROR = "ASSESSMENT_CARD_DELETE_ERROR";

export function createAssessmentCard(customerId, assessmentId, card) {
  return async (dispatch) => {
    try {
      dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.createAssessmentCard"),
        }),
      );
      dispatch({
        type: ASSESSMENT_CARD_CREATE_REQUEST,
      });
      const result = await api.post(
        `/customers/${customerId}/assessments/${assessmentId}/cards`,
        { ...card },
        {
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
        },
      );
      dispatch({
        type: ASSESSMENT_CARD_CREATE_SUCCESS,
        payload: {
          card: result.data,
        },
      });
      await dispatch(getAssessmentsOverview(customerId));
      await dispatch(getAssessment(customerId, assessmentId));
      dispatch(closeModal());
      dispatch(
        showMessage({
          title: i18n.t("global.success"),
          text: i18n.t("messages.createAssessmentCardSuccess"),
          type: "alert",
        }),
      );
      return result.data;
    } catch (error) {
      const { response } = error;
      if (response) {
        const { data, status } = response;
        dispatch({
          type: ASSESSMENT_CARD_SAVE_ERROR,
        });
        await dispatch(closeModal());
        if (status === 400) {
          const { errors } = data;
          errors.forEach((error) => {
            dispatch(
              showMessage({
                title: error.type,
                text: error.message,
                type: "error",
                sticky: true,
              }),
            );
          });
        } else {
          dispatch(
            showMessage({
              title: i18n.t("global.error"),
              text: i18n.t("errors.errorCreateAssessmentCard"),
              type: "error",
              sticky: true,
            }),
          );
        }
      }
    }
    return false;
  };
}

export function updateAssessmentCard(customerId, assessmentId, card) {
  return async (dispatch) => {
    try {
      dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.saveAssessmentCard"),
        }),
      );
      dispatch({
        type: ASSESSMENT_CARD_SAVE_REQUEST,
      });
      const { id } = card;
      const result = await api.put(
        `/customers/${customerId}/assessments/${assessmentId}/cards/${id}`,
        card,
        {
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
        },
      );
      await dispatch(getAssessmentsOverview(customerId));
      await dispatch(getAssessment(customerId, assessmentId));
      dispatch({
        type: ASSESSMENT_CARD_SAVE_SUCCESS,
      });
      dispatch(closeModal());
      dispatch(
        showMessage({
          title: i18n.t("global.success"),
          text: i18n.t("messages.saveAssessmentCardSuccess"),
          type: "alert",
        }),
      );
      return result.data;
    } catch (error) {
      const { response } = error;
      const { data, status } = response;
      dispatch({
        type: ASSESSMENT_CARD_SAVE_ERROR,
      });
      await dispatch(closeModal());
      if (status === 422) {
        const { errors } = data;
        errors.forEach((error) => {
          dispatch(
            showMessage({
              title: error.type,
              text: error.message,
              type: "error",
              sticky: true,
            }),
          );
        });
      } else {
        dispatch(
          showMessage({
            title: i18n.t("global.error"),
            text: i18n.t("errors.errorSaveAssessmentCard"),
            type: "error",
            sticky: true,
          }),
        );
      }
    }
    return false;
  };
}

export function deleteAssessmentCard(customerId, assessmentId, cardId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ASSESSMENT_CARD_DELETE_REQUEST,
      });
      await dispatch(
        showModal({
          type: "wait",
          message: i18n.t("messages.deleteAssessmentCard"),
        }),
      );
      await api.delete(
        `/customers/${customerId}/assessments/${assessmentId}/cards/${cardId}`,
        {
          withCredentials: true,
        },
      );
      await dispatch(getAssessmentsOverview(customerId));
      await dispatch(getAssessment(customerId, assessmentId));
      await dispatch(closeModal());
      dispatch({
        type: ASSESSMENT_CARD_DELETE_SUCCESS,
      });
      await dispatch(
        showMessage({
          title: i18n.t("global.success"),
          text: i18n.t("messages.deleteAssessmentCardSuccess"),
          type: "alert",
        }),
      );
    } catch (error) {
      dispatch({
        type: ASSESSMENT_CARD_DELETE_ERROR,
      });
      await dispatch(closeModal());
      await dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("errors.errorDeleteAssessmentCard"),
          type: "error",
        }),
      );
    }
  };
}

import React, { Component } from "react";
import { DragSource } from "react-dnd";
import css from "./MalfunctionListItem.module.scss";

class MalfunctionListItem extends Component {
  constructor(props) {
    super(props);
    const { malfunction } = this.props;
    this.state = {
      ...malfunction,
    };
    this.handleUpdateMalfunction = this.handleUpdateMalfunction.bind(this);
    window[malfunction.malfunctionId] = this;
  }

  handleUpdateMalfunction({ malfunction }) {
    const { malfunctionId } = this.props;
    if (malfunctionId === malfunction.malfunctionId) {
      this.setState((prevState) => ({
        ...prevState,
        active: false,
      }));
    }
  }

  render() {
    const { isDragging, connectDragSource } = this.props;
    const { active, malfunctionName } = this.state;
    return connectDragSource(
      <div
        className={[css.card, isDragging ? css.isGrabbing : "", active ? "" : css.didDrop].join(" ")}
      >
        <p className={isDragging ? css.text : ""}>{ malfunctionName }</p>
      </div>,
    );
  }
}

const Types = {
  MALFUNCTION: "MALFUNCTION",
};

const dragSource = {
  canDrag({ malfunction: { active } }) {
    return active;
  },
  beginDrag(props) {
    return props;
  },
  endDrag(props, monitor) {
    if (!monitor.didDrop()) {
      console.log(props);
      return;
    }

    const item = {
      ...monitor.getItem(),
      active: false,
    };

    window[item.malfunctionId].handleUpdateMalfunction(item);
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

export default DragSource(Types.MALFUNCTION, dragSource, collect)(MalfunctionListItem);

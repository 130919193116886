/**
 * @fileoverview Modal component to write a new message
 * @author  Florian Berg
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  postComment,
  updateComment,
  editComment,
  clearEditComment,
  getCommentUploadLink,
} from "store/actions";
import i18n from "i18n";
import MessageEdit from "components/blocks/MessageEdit";

class Comment extends Component {
  constructor(props) {
    super(props);
    this.handleSend = this.handleSend.bind(this);
  }

  componentDidUpdate() {
    const { draft, clearEditComment } = this.props;
    if (draft) {
      document.body.classList.add("no-scroll");
      document.addEventListener(
        "keydown",
        (event) => {
          const { key } = event;
          if (key === "Escape") {
            clearEditComment();
          }
        },
        { once: true },
      );
    } else {
      document.body.classList.remove("no-scroll");
    }
  }

  async handleSend() {
    const {
      updateComment,
      postComment,
      editExistingComment,
      draft,
    } = this.props;
    if (editExistingComment) {
      updateComment(draft);
    } else {
      postComment(draft);
    }
  }

  render() {
    const {
      draft,
      editComment,
      clearEditComment,
      editExistingComment,
      getCommentUploadLink,
    } = this.props;
    if (!draft) {
      return null;
    }
    return (
      <MessageEdit
        draft={draft}
        title={
          editExistingComment
            ? i18n.t("views.messagePopUp.editComment")
            : i18n.t("views.messagePopUp.writeNewComment")
        }
        onUpdate={editComment}
        onClose={clearEditComment}
        onRequestUploadLink={getCommentUploadLink}
        onSend={this.handleSend}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        postComment,
        updateComment,
        editComment,
        clearEditComment,
        getCommentUploadLink,
      },
      dispatch,
    ),
  };
}

function mapStateToProps(state) {
  const { comments } = state;
  const { draft } = comments;
  let editExistingComment = false;
  if (draft) {
    editExistingComment = draft.hasOwnProperty("id");
  }
  return {
    draft: comments.draft,
    editExistingComment,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Comment);

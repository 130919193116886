import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {} from "./Headline.module.scss";

class Headline extends PureComponent {
  render() {
    const { children } = this.props;
    return <h2>{children}</h2>;
  }
}

Headline.propTypes = {
  children: PropTypes.node,
};

Headline.defaultProp = {
  children: "Headline",
};
export default Headline;

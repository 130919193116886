import React from "react";
import PropTypes from "prop-types";
import { GenericMessage } from "./GenericMessage";

function Activity(props) {
  const {
    className, user, mine, happenedAt, meta, icon, link,
  } = props;
  return (
    <GenericMessage
      className={className}
      timeStamp={happenedAt}
      icon={icon || "icon-documentation"}
      user={user}
      owner={mine}
      meta={meta}
      link={link}
    />
  );
}

Activity.propTypes = {
  body: PropTypes.string,
  type: PropTypes.string,
};

Activity.defaultProps = {};
export default Activity;

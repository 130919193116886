import {
  ASSESSMENT_CARD_CREATE_REQUEST,
  ASSESSMENT_CARD_CREATE_SUCCESS,
  ASSESSMENT_CARD_CREATE_ERROR,
  ASSESSMENT_CARD_SAVE_REQUEST,
  ASSESSMENT_CARD_SAVE_SUCCESS,
  ASSESSMENT_CARD_SAVE_ERROR,
  ASSESSMENT_CARD_DELETE_REQUEST,
  ASSESSMENT_CARD_DELETE_SUCCESS,
  ASSESSMENT_CARD_DELETE_ERROR,
} from "store/actions";

export default function assessmentCardApiReducer(state, action) {
  switch (action.type) {
    case ASSESSMENT_CARD_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ASSESSMENT_CARD_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        draft: null,
      };
    case ASSESSMENT_CARD_CREATE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ASSESSMENT_CARD_SAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ASSESSMENT_CARD_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ASSESSMENT_CARD_SAVE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ASSESSMENT_CARD_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ASSESSMENT_CARD_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case ASSESSMENT_CARD_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
}

import { combineReducers } from "redux";

import app from "./app";
import assessments from "./assessments";
import customers from "./customers";
import auth from "./auth";
import notifications from "./notifications";
import messages from "./messages";
import activities from "./activities";
import comments from "./comments";
import malfunctions from "./malfunctions";
import components from "./components";

const rootReducer = combineReducers({
  app,
  assessments,
  customers,
  auth,
  notifications,
  messages,
  comments,
  activities,
  malfunctions,
  components,
});

export default rootReducer;

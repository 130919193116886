import {
  MESSAGES_NEW_MESSAGE,
  MESSAGES_CLEAR_EDIT,
  MESSAGES_EDIT_MESSAGE,
} from "store/actions";

export function messagesReducer(state, action) {
  switch (action.type) {
    case MESSAGES_NEW_MESSAGE: {
      const { type } = action.payload;
      return {
        ...state,
        draft: {
          type,
        },
      };
    }
    case MESSAGES_CLEAR_EDIT: {
      return {
        ...state,
        draft: null,
      };
    }
    case MESSAGES_EDIT_MESSAGE: {
      const { message } = action.payload;
      return {
        ...state,
        draft: message,
      };
    }
    default:
      return state;
  }
}

export default messagesReducer;

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import css from "./ActionButton.module.scss";

class ActionButton extends PureComponent {
  render() {
    const {
      icon, onClick, title, large,
    } = this.props;
    return (
      <button
        className={[css.button, large ? css.buttonLarge : null].join(" ")}
        onClick={onClick}
      >
        <span className={[css.icon, icon].join(" ")} />
        <span className={css.title}>{title}</span>
      </button>
    );
  }
}

ActionButton.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  large: PropTypes.bool,
};

ActionButton.defaultProps = {
  icon: "icon-add",
  title: "Action Button",
  large: false,
};

export default ActionButton;

import { cardStatus } from "utils/constants";

export function convertHSIToColorClass(value) {
  if (value >= 0 && value <= 0.4) {
    return "normal";
  }
  if (value > 0.4 && value <= 0.8) {
    return "warning";
  }
  if (value > 0.8) {
    return "alert";
  }
  return null;
}

export function convertHSIToStatus(value) {
  if (value >= 0 && value <= 0.4) {
    return cardStatus.OPERATIONAL;
  }
  if (value > 0.4 && value <= 0.8) {
    return cardStatus.ANOMALY_DETECTED;
  }
  if (value > 0.8) {
    return cardStatus.RISK_OF_DAMAGE;
  }
  return null;
}

/**
 * @fileoverview AssessmentCard component
 * @author  Florian Berg
 */

import React, { PureComponent } from "react";
import i18n from "i18n";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import { findCard } from "utils/cards";
import {
  createNewComment,
  getComments,
  editComment,
  createNewMessage,
  showModal,
  closeModal,
  getAssessment,
} from "store/actions";
import { getRecommonendationTypeName, getCardStatusTypeName, getCardStatusTypeClass } from "utils/types";
import Section from "components/layout/Section";
import NavigationBar from "components/layout/NavigationBar";
import MessageList from "components/blocks/MessageList";
import ActionButton from "components/elements/ActionButton";
import StatusBar from "components/elements/StatusBar";
import GenericStatus from "components/elements/GenericStatus";
import CountStatus from "components/elements/CountStatus";
import FloatingButton from "components/elements/FloatingButton";
import CardContent from "components/blocks/CardContent";
import { saflyCheckArrayLength } from "utils/helper";
import css from "./Assessment.module.scss";

class Assessment extends PureComponent {
  constructor(props) {
    super(props);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleEditComment = this.handleEditComment.bind(this);
    this.handleNewComment = this.handleNewComment.bind(this);
  }

  async componentDidMount() {
    const {
      customerId,
      assessmentId,
      getComments,
      published,
      assessment,
      showModal,
      closeModal,
      getAssessment,
    } = this.props;
    if (published) {
      await getComments();
    }
    if (!assessment) {
      // Coming from click on comments in Activity Stream, the assessment and published props are
      // undefined. Obtain the assessment and comments (since it's always published) in this case.
      showModal({
        type: "wait",
        message: i18n.t("messages.getHealthAssessment"),
      });
      await getAssessment(customerId, assessmentId);
      await getComments();
      closeModal();
    }
  }

  handleEdit() {
    const {
      customerId, assetId, machineId, assessmentId, cardId,
    } = this.props;
    let path = `/${customerId}/assessments/${assessmentId}/cards/${cardId}/edit`;
    if (assetId && !machineId) {
      path = `/${customerId}/${assetId}/assessments/${assessmentId}/cards/${cardId}/edit`;
    } else if (assetId && machineId) {
      path = `/${customerId}/${assetId}/${machineId}/assessments/${assessmentId}/cards/${cardId}/edit`;
    }
    navigate(path);
  }

  handleNewComment() {
    const { createNewComment } = this.props;
    createNewComment();
  }

  handleEditComment(comment) {
    const { editComment } = this.props;
    editComment(comment);
  }

  provideFloatingButtonActions() {
    const {
      createNewMessage,
    } = this.props;
    const actions = [
      { name: i18n.t("global.writeNewMessage"), handler: createNewMessage },
    ];
    return actions;
  }

  render() {
    const {
      card,
      cardMalfunction,
      editable,
      customerId,
      assessmentId,
      comments,
      loading,
      assessment,
      assetId,
      machineId,
    } = this.props;
    if (!card) {
      return null;
    }
    const {
      statusType,
      recommendationType,
      argumentations,
      risks,
      recommendations,
    } = card;
    const statusTypeClass = getCardStatusTypeClass(statusType);
    let backLinkPath = `/${customerId}/assessments/${assessmentId}`;
    if (assetId && !machineId) {
      backLinkPath = `/${customerId}/${assetId}/assessments/${assessmentId}`;
    } else if (assetId && machineId) {
      backLinkPath = `/${customerId}/${assetId}/${machineId}/assessments/${assessmentId}`;
    }
    return (
      <div className={css.screen}>
        <FloatingButton
          icon="icon-comment"
          actions={this.provideFloatingButtonActions()}
        />
        <NavigationBar
          editAction={editable ? this.handleEdit : null}
          backLink={backLinkPath}
          title={`${i18n.t("global.back").toUpperCase()}`}
          small
        />
        <Section white noMargin borderBottom>
          <StatusBar
            className={css.status}
            items={[
              <CountStatus icon="settings_02" label={i18n.t("global.recommendations")} className={statusTypeClass} status={saflyCheckArrayLength(recommendations)} />,
              <CountStatus icon="warning" label={i18n.t("global.risk")} className={statusTypeClass} status={saflyCheckArrayLength(risks)} />,
              <CountStatus icon="KPI" label={i18n.t("global.argumentations")} status={saflyCheckArrayLength(argumentations)} />,
              <CountStatus icon="documentation" label={i18n.t("global.comments")} status={saflyCheckArrayLength(comments)} />,
            ]}
          />
        </Section>
        <Section className={css.sectionCardContent} white noMargin>
          <CardContent editable={editable} card={card} cardMalfunction={cardMalfunction} />
        </Section>
        {assessment.published && (
          <Section
            className={css.section}
            white
            noMargin
          >
            <div className={css.sectionTitleLarge}>{i18n.t("global.comments")}</div>
            <ActionButton
              icon="icon-documentation"
              onClick={this.handleNewComment}
              title={`${i18n.t("global.writeNewComment")}`}
              large
            />
            <MessageList
              onEdit={this.handleEditComment}
              loading={loading}
              messages={comments}
            />
          </Section>
        )}
        <Section white noMargin borderTop>
          <StatusBar
            className={css.status}
            items={[
              <GenericStatus label={i18n.t("global.status")} status={i18n.t(getCardStatusTypeName(statusType))} />,
              <GenericStatus label={i18n.t("views.editAssessment.recommendationTypeLabel")} status={i18n.t(getRecommonendationTypeName(recommendationType))} />,
              <GenericStatus label={i18n.t("views.assessment.publishedInLabel")} status={assessment.title} />,
            ]}
          />
        </Section>
      </div>
    );
  }
}
function mapStateToProps(state, props) {
  const {
    auth, assessments, comments, customers, malfunctions,
  } = state;
  const { customerId, assessmentId, cardId } = props;
  const currentCustomer = customers.customers.find(
    (customer) => customer.id === Number.parseInt(customerId, 10),
  );
  if (!currentCustomer) {
    return null;
  }
  // Assessments (with cards, etc) are being lazily loaded when clicking on an assessment card.
  // If the user tries to open the card without previously viewing the assessment (e.g. clicking
  // on comment in Activity Stream), the full assessment is not loaded in the state. This scenario
  // is handled by componentDidMount(), which updates the state, triggering mapStateToProps() again.
  if (!assessments.assessments[Number(customerId)]) {
    return {
      assessment: undefined,
      card: undefined,
      published: false,
    };
  }
  const assessment = assessments.assessments[Number(customerId)]
    .filter((assessment) => assessment.id === Number.parseInt(assessmentId, 10))
    .map((assessment) => {
      if (assessment.publishedAt) {
        return {
          ...assessment,
          published: true,
        };
      }
      return { ...assessment, status: "draft", published: false };
    })[0];
  if (!assessment) {
    return null;
  }
  const card = findCard(assessment.cards, Number.parseInt(cardId, 10));
  const turbineMalfunctions = malfunctions.turbines[assessment.turbineType].malfunctions;
  return {
    assessment,
    card,
    cardMalfunction: turbineMalfunctions.find((m) => m.id === card.malfunctionId),
    editable: auth.isOPL && !assessment.published,
    published: assessment.published,
    comments: comments.comments[Number(cardId)],
    loading: comments.loading,
    hasGlossary: currentCustomer.glossary !== "",
  };
}
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        createNewComment,
        getComments,
        editComment,
        createNewMessage,
        showModal,
        closeModal,
        getAssessment,
      },
      dispatch,
    ),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Assessment);

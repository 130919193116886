import React, { forwardRef } from "react";
import DraggableCore from "react-draggable";
import css from "./MalfunctionGraphic.module.scss";

const MalfunctionGraphicItem = forwardRef((props, ref) => {
  const {
    onDrag,
    onStop,
    malfunction,
    value,
    onMouseOver,
    disabled,
  } = props;
  const item = {
    ...malfunction,
    malfunctionId: malfunction.groupId ? malfunction.groupId : malfunction.malfunctionId,
  };

  return (
    <DraggableCore
      onDrag={onDrag}
      onMouseDown={() => onMouseOver(item)}
      onStop={(e, draggableData) => onStop(item, draggableData)}
      key={item.id}
      ref={ref}
      scale={value.a}
      disabled={disabled}
      defaultPosition={{
        x: item.xAxis,
        y: item.yAxis,
      }}
    >
      <use
        id={item.id}
        key={item.id}
        xlinkHref={item.type}
        // xlinkHref="#group-normal"
        className={css.malfunction}
        onMouseOver={() => onMouseOver(item)}
        onFocus={() => onMouseOver(item)}
        data-for="hotspot-overlay"
        data-tip="custom show"
        data-event="click"
      />
    </DraggableCore>
  );
});

export default MalfunctionGraphicItem;

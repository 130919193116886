export function findCard(cards, id) {
  return cards.find((card) => card.id === id);
}

export function countStatus(cards) {
  return cards.map((card) => card.statusType);
}

export function filterCardsByType(cards, filter) {
  if (Array.isArray(filter)) {
    return cards.filter((card) => filter.includes(card.type));
  }
  return cards.filter((card) => filter === card.type);
}

export function getLatestCardFromAssessment(assessment) {
  try {
    return assessment.cards.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0)).pop(); // eslint-disable-line
  } catch (error) {
    console.log(error);
    return {};
  }
}

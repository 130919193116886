import validator from "validator";
import i18n from "i18n";

export const argumentationValidator = () => {};

export const cardValidator = (card) => {
  const errors = [];
  const {
    description,
    statusType,
    recommendationType,
    recommendations,
    risks,
    argumentations,
  } = card;
  if (!description || validator.isEmpty(description)) {
    errors.push({
      key: "description",
      name: i18n.t("views.editAssessment.descriptionLabel"),
    });
  }
  if (!statusType || validator.isEmpty(statusType)) {
    errors.push({
      key: "statusType",
      name: i18n.t("views.editAssessment.statusLabel"),
    });
  }
  if (!recommendationType || validator.isEmpty(recommendationType)) {
    errors.push({
      key: "recommendationType",
      name: i18n.t("views.editAssessment.recommendationTypeLabel"),
    });
  }
  if (!recommendations || recommendations.length < 1) {
    errors.push({
      key: "recommendation",
      name: i18n.t("views.editAssessment.recommendationLabel"),
    });
  }
  if (!risks || risks.length < 1) {
    errors.push({
      key: "risk",
      name: i18n.t("views.editAssessment.riskLabel"),
    });
  }
  if (!argumentations) {
    errors.push({
      key: "argumentaions",
      name: i18n.t("validation.missingArgumentations"),
    });
  }
  return errors;
};

export const healthAssessmentValidator = (assessment) => {
  const errors = [];
  const {
    title, timeWindowStart, timeWindowEnd, summary,
  } = assessment;
  if (!title || validator.isEmpty(title)) {
    errors.push({
      key: "title",
      name: i18n.t("views.editHealthAssessment.reportNameLabel"),
    });
  }
  if (!timeWindowStart || validator.isEmpty(timeWindowStart)) {
    errors.push({
      key: "timeWindowStart",
      name: i18n.t("views.editHealthAssessment.startDateLabel"),
    });
  }
  if (!timeWindowEnd || validator.isEmpty(timeWindowEnd)) {
    errors.push({
      key: "timeWindowEnd",
      name: i18n.t("views.editHealthAssessment.endDateLabel"),
    });
  }
  if (!summary || validator.isEmpty(summary)) {
    errors.push({
      key: "summary",
      name: i18n.t("views.editHealthAssessment.summaryLabel"),
    });
  }
  return errors;
};

export const healthAssessmentPublishValidator = (assessment) => {
  const errors = healthAssessmentValidator(assessment);
  const { cards } = assessment;
  if (!cards) {
    errors.push({
      key: "cards",
      name: i18n.t("validation.missingAssessmentCards"),
    });
  }
  return errors;
};

import i18n from "i18n";
import { api } from "utils/api";
import { getLanguageCodeParam } from "utils/malfunctions";
import { showMessage, closeModal } from "store/actions/notifications/";
import { closeComponentsModal, showComponentsModal } from "store/actions/components";
import { AUTH_USER_ERROR } from "store/actions/auth/";
import { APP_FORBIDDEN_ACCESS, APP_GLOBAL_ERROR } from "store/actions/app";
import { navigate } from "@reach/router";

export const COMPONENTS_GET_ERROR = "COMPONENTS_GET_ERROR";
export const COMPONENTS_GET_REQUEST = "COMPONENTS_GET_REQUEST";
export const COMPONENTS_GET_SUCCESS = "COMPONENTS_GET_SUCCESS";

export const COMPONENTS_CREATE_ERROR = "COMPONENTS_CREATE_ERROR";
export const COMPONENTS_CREATE_SUCCESS = "COMPONENTS_CREATE_SUCCESS";
export const COMPONENTS_CREATE_REQUEST = "COMPONENTS_CREATE_REQUEST";

export const COMPONENTS_UPDATE_ERROR = "COMPONENTS_UPDATE_ERROR";
export const COMPONENTS_UPDATE_SUCCESS = "COMPONENTS_UPDATE_SUCCESS";
export const COMPONENTS_UPDATE_REQUEST = "COMPONENTS_UPDATE_REQUEST";

export const COMPONENTS_TRANSLATION_ERROR = "COMPONENTS_TRANSLATION_ERROR";
export const COMPONENTS_TRANSLATION_SUCCESS = "COMPONENTS_TRANSLATION_SUCCESS";
export const COMPONENTS_TRANSLATION_REQUEST = "COMPONENTS_TRANSLATION_REQUEST";

export const COMPONENTS_DELETE_ERROR = "COMPONENTS_DELETE_ERROR";
export const COMPONENTS_DELETE_SUCCESS = "COMPONENTS_DELETE_SUCCESS";
export const COMPONENTS_DELETE_REQUEST = "COMPONENTS_DELETE_REQUEST";

export function errorHandler(dispatch, response) {
  if (response) {
    const { status } = response;
    switch (status) {
      case 401:
        dispatch({
          type: AUTH_USER_ERROR,
        });
        break;
      case 403:
        dispatch({
          type: APP_FORBIDDEN_ACCESS,
        });
        break;
      default:
        dispatch({
          type: APP_GLOBAL_ERROR,
        });
    }
  } else {
    dispatch({
      type: APP_GLOBAL_ERROR,
    });
  }
}

function parseResponse(data) {
  return {
    componentId: data.id,
    componentName: data.name,
    componentSort: data.sort,
    translations: data.translations ? data.translations : [],
  };
}

export function getComponents() {
  return async (dispatch) => {
    try {
      dispatch({
        type: COMPONENTS_GET_REQUEST,
      });
      const languageCodeParam = getLanguageCodeParam();
      const result = await api.get(`/components/malfunctions${languageCodeParam}`, {
        withCredentials: true,
      });
      const { status } = result;
      if (status === 200) {
        const { data } = result;
        dispatch({
          type: COMPONENTS_GET_SUCCESS,
          payload: {
            components: data,
          },
        });
        dispatch(closeModal());
      } else {
        throw new Error(result.statusText);
      }
    } catch (error) {
      const { response } = error;
      console.log(error);
      dispatch(closeComponentsModal());
      errorHandler(dispatch, response);
      dispatch({
        type: COMPONENTS_GET_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("components.errors.error_get_categories"),
          type: "error",
        }),
      );
    }
  };
}

export function createComponent(name, sort, translations = undefined) {
  return async (dispatch) => {
    try {
      dispatch({
        type: COMPONENTS_CREATE_REQUEST,
      });
      const result = await api.post(`/components/translations`,
        {
          name,
          sort,
          translations,
        },
        {
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
        });
      const { status } = result;
      if (status === 201) {
        const { data } = result;
        const parsed = parseResponse(data);
        dispatch({
          type: COMPONENTS_CREATE_SUCCESS,
          payload: {
            ...parsed,
          },
        });
        dispatch(
          showMessage({
            title: i18n.t("global.success"),
            text: i18n.t("components.messages.category_created_successfully"),
            type: "alert",
          }),
        );
      } else {
        throw new Error(result.statusText);
      }
    } catch (error) {
      const { response } = error;
      const { status } = response;
      console.log(error);
      dispatch(closeComponentsModal());
      if (status !== 400) {
        errorHandler(dispatch, response);
      }
      dispatch({
        type: COMPONENTS_CREATE_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("components.errors.error_create_category"),
          type: "error",
        }),
      );
      navigate("/malfunctions/edit");
    }
  };
}

export function deleteComponent(componentId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: COMPONENTS_DELETE_REQUEST,
      });

      const result = await api.delete(`/components/${componentId}`, {
        withCredentials: true,
      });
      const { status } = result;
      if (status === 204) {
        dispatch({
          type: COMPONENTS_DELETE_SUCCESS,
          payload: {
            componentId,
          },
        });
        dispatch(
          showMessage({
            title: i18n.t("global.success"),
            text: i18n.t("components.messages.category_deleted_successfully"),
            type: "alert",
          }),
        );
      } else {
        throw new Error(result.statusText);
      }
    } catch (error) {
      const { response } = error;
      const { status } = response;
      if (status === 409) {
        dispatch(closeComponentsModal());
        const text = i18n.t("components.messages.delete_category_warning");
        const modal = {
          display: true,
          type: "API_WARNING",
          data: text,
        };
        dispatch(showComponentsModal(modal));
        return;
      }

      dispatch(closeComponentsModal());
      errorHandler(dispatch, response);
      dispatch({
        type: COMPONENTS_DELETE_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("components.errors.error_delete_category"),
          type: "error",
        }),
      );
    }
  };
}

export function updateComponent(component) {
  return async (dispatch) => {
    try {
      dispatch({
        type: COMPONENTS_UPDATE_REQUEST,
      });
      const {
        id,
        name,
        sort,
        translations,
      } = component;

      const result = await api.put(`/components/${id}/translations`, {
        name,
        sort,
        translations,
      }, {
        withCredentials: true,
        headers: {
          "content-type": "application/json",
        },
      });

      const { status } = result;
      if (status === 200) {
        const { data } = result;
        const parsed = parseResponse(data);
        dispatch({
          type: COMPONENTS_UPDATE_SUCCESS,
          payload: {
            ...parsed,
          },
        });
        dispatch(
          showMessage({
            title: i18n.t("global.success"),
            text: i18n.t("components.messages.category_updated_successfully"),
            type: "alert",
          }),
        );
      } else {
        throw new Error(result.statusText);
      }
    } catch (error) {
      console.log(error);
      const { response } = error;
      const { status } = response;
      dispatch(closeComponentsModal());
      if (status !== 400) {
        errorHandler(dispatch, response);
      }
      dispatch({
        type: COMPONENTS_UPDATE_ERROR,
      });
      dispatch(
        showMessage({
          title: i18n.t("global.error"),
          text: i18n.t("components.errors.error_update_category"),
          type: "error",
        }),
      );
      navigate("/malfunctions/edit");
    }
  };
}

import { AUTH_USER_ERROR } from "store/actions/auth/";
import { api } from "utils/api";
import { closeModal, showMessage } from "store/actions/notifications/";
import i18n from "i18n";
import { getRouteParams } from "utils/url";
import { fileUploadTypes } from "utils/constants";

export const ARGUMENTATION_UPLOAD_LINK_REQUEST = "ARGUMENTATION_UPLOAD_LINK_REQUEST";
export const ARGUMENTATION_UPLOAD_LINK_SUCCESS = "ARGUMENTATION_UPLOAD_LINK_SUCCESS";
export const ARGUMENTATION_UPLOAD_LINK_ERROR = "ARGUMENTATION_UPLOAD_LINK_ERROR";

export function getArgumentationUploadLink(file) {
  return async (dispatch) => {
    const { customerId, assessmentId } = getRouteParams();
    try {
      dispatch({
        type: ARGUMENTATION_UPLOAD_LINK_REQUEST,
      });
      console.log(file);
      const result = await api.post(
        `/customers/${customerId}/files/presigned-url`,
        {
          mime: file.mime,
          filename: file.filename,
          size: file.size,
          type: fileUploadTypes.ARGUMENTATION,
          assessmentId: Number.parseInt(assessmentId, 10),
        },
        {
          withCredentials: true,
          headers: {
            "content-type": "application/json",
          },
        },
      );
      const { status } = result;
      if (status === 200) {
        dispatch({
          type: ARGUMENTATION_UPLOAD_LINK_SUCCESS,
        });
        const { data } = result;
        return data;
      }
      throw new Error(result.statusText);
    } catch (error) {
      const { response } = error;
      if (response) {
        const { data } = response;
        if (data.status === 401) {
          dispatch({
            type: AUTH_USER_ERROR,
          });
        }
      } else {
        dispatch({
          type: ARGUMENTATION_UPLOAD_LINK_ERROR,
        });
        dispatch(
          showMessage({
            title: i18n.t("global.error"),
            text: i18n.t("errors.errorGetUploadlink"),
            type: "error",
          }),
        );
        dispatch(closeModal());
      }
    }
    return "";
  };
}

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import css from "./CardGrid.module.scss";

class CardGrid extends PureComponent {
  render() {
    const { children } = this.props;
    return <div className={css.cardGrid}>{children}</div>;
  }
}

CardGrid.propTypes = {
  children: PropTypes.node,
};

export default CardGrid;

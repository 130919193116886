import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { auth } from "utils/api";
import {
  APP_URL,
  AUTH_REDIRECT_URL,
} from "utils/constants";

function redirectToAuth() {
  const { pathname } = window.location;
  window.location = `${AUTH_REDIRECT_URL}?next=${APP_URL}${pathname}&redirect=true`;
}

auth.get("/token", {
  withCredentials: true,
}).then((result) => {
  const { status, data } = result;
  if (status === 200) {
    let userLanguage;
    switch (data.language) {
      case "en-US":
        userLanguage = "en";
        break;
      case "en-EN":
        userLanguage = "en";
        break;
      case "de-DE":
        userLanguage = "de";
        break;
      default:
        userLanguage = "en";
    }
    console.log("userLanguage => ".concat(userLanguage));

    i18n
      // load translation using xhr -> see /public/locales
      // learn more: https://github.com/i18next/i18next-xhr-backend
      .use(Backend)
      // init i18next
      .init({
        lng: userLanguage,
        fallbackLng: ["en", "de"],
        debug: false,
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
          queryStringParams: { hash: new Date().getTime() },
          loadPath: "/locales/{{lng}}/translations.json",
        },
      });
  } else {
    redirectToAuth();
  }
}).catch((err) => {
  console.log("Error => ".concat(err));
  redirectToAuth();
});

export default i18n;

import React, { Component } from "react";
import PropTypes from "prop-types";
import css from "./MultiSelect.module.scss";
import CheckBox from "./CheckBox";

export class MultiSelect extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(updatedItem) {
    const {
      id, items, onChange, type,
    } = this.props;
    switch (type) {
      case "multi":
        onChange({
          id,
          value: items.map((item) => {
            if (item.id === updatedItem.id) {
              return { ...item, value: !item.value };
            }
            return item;
          }),
        });
        break;
      case "single":
        onChange({
          id,
          value: items.map((item) => {
            if (item.id === updatedItem.id) {
              return { ...item, value: true };
            }
            return { ...item, value: false };
          }),
        });
        break;
      default:
    }
  }

  render() {
    const {
      className, items, type, heading, disabled,
    } = this.props;
    const { onChange } = this;
    return (
      <div className={[css.multiSelect, className, type === "single" ? css.single : "", type === "multi" ? css.multi : ""].join(" ")}>
        {heading && <div className={css.heading}>{heading}</div>}
        {items.map((item) => (
          <CheckBox
            id={item.id}
            key={item.key ? item.key : item.id}
            className={css.checkBox}
            update={onChange}
            checked={item.value}
            description={item.name}
            type={type === "single" ? "radio" : "box"}
            disabled={disabled}
          />
        ))}
      </div>
    );
  }
}

MultiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape(
    { id: PropTypes.string, name: PropTypes.string, checked: PropTypes.bool },
  )),
  error: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.func,
  type: PropTypes.oneOf(["single", "multi"]),
  disabled: PropTypes.bool,
};

MultiSelect.defaultProps = {
  type: "multi",
};

export default MultiSelect;
